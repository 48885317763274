/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { DependencyList, RefObject, useRef, useEffect } from "react";
import { PublishManager } from "./publishManager";

/**
 * Manages the publishing and polling of a single data product. Use a different
 * usePublishManager hook for each data product that needs to be published, and call
 * the "publish" method on it. The hook will cancel the polling when the component
 * that uses it is unmounted.
 *
 * @param dataProductId ID of the data product to be published
 * @param dependencies List of dependencies
 * @returns PublishManager object
 */
export function usePublishManager(
  dataProductId: string | undefined,
  dependencies: DependencyList
): RefObject<PublishManager | undefined> {
  const manager = useRef<PublishManager>();

  useEffect(() => {
    if (dataProductId) {
      manager.current = new PublishManager(dataProductId);
    }

    return () => manager.current?.cancel();
  }, dependencies);

  return manager;
}
