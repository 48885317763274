/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import clsx from "clsx";
import { createUseStyles } from "react-jss";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "../../themes/palette";

const useStyles = createUseStyles({
  error: {
    color: palette.error,
    backgroundColor: palette.errorLight,
    wordBreak: "break-word",
    fontSize: "0.875rem",
    borderRadius: "4px",
    marginBottom: "1rem",
    padding: "0.875rem",
    borderStyle: "solid",
    borderWidth: "thin",
  },
});

interface ErrorIndicatorProps {
  text: string | undefined;
  className?: string;
}

export const ErrorIndicator: React.FunctionComponent<ErrorIndicatorProps> = ({
  text,
  className,
}) => {
  const classes = useStyles();
  return text ? (
    <div className={clsx(classes.error, className)}>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        style={{ marginRight: "8px" }}
      />
      {text}
    </div>
  ) : null;
};
