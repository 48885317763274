/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useCallback } from "react";

import {
  DataDomain,
  deleteDataDomain,
} from "../../../api/dataProduct/dataDomain/dataDomainApi";
import { DeleteDialog } from "../../../components/dialog/DeleteDialog";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";

interface DeleteDomainModalProps {
  domain: DataDomain;
  opener: (open: () => void) => ReactElement<HTMLElement>;
}

export const DeleteDomainModal: React.FunctionComponent<
  DeleteDomainModalProps
> = ({ domain, opener }: DeleteDomainModalProps) => {
  const { isOpen, close, open } = useDialogOpenState();
  const handleDeletion = useCallback(
    () => deleteDataDomain(domain.id),
    [domain.id]
  );

  return (
    <>
      {opener(open)}
      {isOpen && (
        <DeleteDialog
          isOpen={isOpen}
          close={close}
          onConfirm={handleDeletion}
          entityType="domain"
          entityHumanName={domain.name}
        />
      )}
    </>
  );
};
