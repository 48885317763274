/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import { palette } from "../../../../themes/palette";
import { Spinner } from "../../../../components/spinner/Spinner";
import { LoadMoreIntersectionButton } from "../../../dataproduct/pagination/LoadMoreIntersectionButton";
import { parseISO } from "date-fns";
import { AccessLogEntry } from "./useAccessLogs";
import { TextWithTooltip } from "../TextWithTooltip";
import { UserAndRolesTableCell } from "../UserAndRolesTableCell";
import { QueryDetailsLink } from "../../../ide/QueryDetailsLink";
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";

interface ChangesTableProps {
  logs: AccessLogEntry[];
  hasMoreRecords: boolean;
  isFetchingPage: boolean;
  fetchMore: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    maxHeight: "calc(100vh - 9.75rem)",
    border: `1px solid ${palette.nebulaNavy50}`,
  },
  truncated: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tableHeadCell: {
    fontWeight: 600,
    letterSpacing: "0.5px",
    fontFamily: theme.typography.fontFamily,
  },
  queryDetails: {
    fontWeight: 600,
    fontFamily: theme.typography.fontFamily,
    letterSpacing: "0.5px",
  },
  icon: {
    fontSize: "0.8rem",
    color: palette.purple,
    marginRight: "0.5rem",
  },
}));

export const AccessLogTable: FunctionComponent<ChangesTableProps> = ({
  logs,
  hasMoreRecords,
  isFetchingPage,
  fetchMore,
}) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader style={{ tableLayout: "fixed" }}>
        <colgroup>
          <col style={{ width: "10%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "8rem" }} />
          <col style={{ width: "8rem" }} />
          <col style={{ width: "17%" }} />
          <col style={{ width: "11rem" }} />
          <col />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>Object type</TableCell>
            <TableCell className={classes.tableHeadCell}>
              Object affected
            </TableCell>
            <TableCell className={classes.tableHeadCell}>Access</TableCell>
            <TableCell className={classes.tableHeadCell}>Operation</TableCell>
            <TableCell className={classes.tableHeadCell}>User (Role)</TableCell>
            <TableCell className={classes.tableHeadCell}>Time</TableCell>
            <TableCell className={classes.tableHeadCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => (
            <TableRow hover key={log.id}>
              <TableCell className={classes.truncated}>
                <TextWithTooltip text={log.entityCategory} />
              </TableCell>
              <TableCell className={classes.truncated}>
                <TextWithTooltip text={log.entity} />
              </TableCell>
              <TableCell className={classes.truncated}>
                <TextWithTooltip text={log.accessResult} />
              </TableCell>
              <TableCell className={classes.truncated}>{log.action}</TableCell>
              <TableCell className={classes.truncated}>
                <UserAndRolesTableCell user={log.user} roles={log.roles} />
              </TableCell>
              <TableCell className={classes.truncated}>
                {parseISO(log.atTime.toString()).toLocaleString()}
              </TableCell>
              <TableCell className={classes.truncated}>
                {log.queryId && (
                  <Grid container justifyContent="center">
                    <Grid item>
                      <FontAwesomeIcon icon={faEye} className={classes.icon} />
                    </Grid>
                    <Grid item>
                      <QueryDetailsLink
                        queryId={log.queryId}
                        className={classes.queryDetails}
                      />
                    </Grid>
                  </Grid>
                )}
              </TableCell>
            </TableRow>
          ))}
          {hasMoreRecords && (
            <TableRow>
              <TableCell colSpan={7}>
                {isFetchingPage ? (
                  <Spinner size={50} position="relative" />
                ) : (
                  <LoadMoreIntersectionButton onLoadMore={fetchMore} />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
