/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import orderBy from "lodash/orderBy";

export const getTopValues =
  <T extends Record<string, unknown>>(
    count: number,
    labelKey: keyof T,
    valueKey: keyof T,
    othersLabel: string
  ) =>
  (allEntities: T[]): T[] => {
    const entityValue = (entity: T): number => {
      const value = entity[valueKey] as unknown;

      if (typeof value !== "number") {
        throw new Error(value + " is not a number value");
      }
      return value;
    };

    const entityLabel = (entity: T): string => {
      const label = entity[labelKey] as unknown;

      if (typeof label !== "string") {
        throw new Error(label + " is not a string value");
      }
      return label;
    };

    const filterTopEntities = (allUsers: T[]): T[] => {
      return orderBy(allUsers, [valueKey], ["desc"])
        .filter((entity) => entityValue(entity) > 0)
        .filter((entity) => entityLabel(entity) !== othersLabel)
        .slice(0, count);
    };

    const getOtherEntitiesCount = (
      allEntities: T[],
      topEntities: T[]
    ): number => {
      const sumValues = (entities: T[]): number => {
        return entities
          .map((entity) => entityValue(entity))
          .reduce((a, b) => a + b, 0);
      };

      return sumValues(allEntities) - sumValues(topEntities);
    };

    const topEntities = filterTopEntities(allEntities);
    const otherEntitiesCount = getOtherEntitiesCount(allEntities, topEntities);
    return [
      ...topEntities,
      ...(otherEntitiesCount > 0
        ? [{ [labelKey]: othersLabel, [valueKey]: otherEntitiesCount } as T]
        : []),
    ];
  };
