/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { SingleQueryDetails } from "../../../../api/queryApi";
import { formatDuration } from "../../../../utils/formatDuration";
import capitalize from "lodash/capitalize";
import { DetailsItem } from "../../../../components/details/DetailsItem";

const useStyles = createUseStyles({
  root: {
    margin: "1rem 0",
    display: "flex",
    flexWrap: "wrap",
  },
  item: {
    paddingRight: "2rem",
    maxWidth: "300px",
  },
  itemValue: {
    fontSize: "18px",
  },
});

interface QueryBasicSummaryProps {
  query: SingleQueryDetails;
}

export const QueryBasicSummary: React.FunctionComponent<
  QueryBasicSummaryProps
> = ({ query }) => {
  const classes = useStyles();
  const itemClasses = { item: classes.item, value: classes.itemValue };
  const utilizationRatio = () => {
    if (!query.scheduledTime) {
      return "-";
    } else {
      return `${Math.round((100 * query.cpuTime) / query.scheduledTime)}%`;
    }
  };

  return (
    <div className={classes.root}>
      <DetailsItem
        header="User"
        value={query.user}
        classes={itemClasses}
        valueTooltip={query.user}
      />
      <DetailsItem
        header="Query State"
        value={capitalize(query.state)}
        classes={itemClasses}
      />
      <DetailsItem
        header="CPU Time"
        value={formatDuration(query.cpuTime)}
        classes={itemClasses}
      />
      <DetailsItem
        header="Elapsed Time"
        value={formatDuration(query.elapsedTime)}
        classes={itemClasses}
      />
      <DetailsItem
        header="Active"
        value={utilizationRatio()}
        classes={itemClasses}
      />
    </div>
  );
};
