/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from "react";

import { createUseStyles } from "react-jss";
import ReactMarkdown from "react-markdown";
import { palette, sqlHighlightStyle } from "../../themes/palette";
import Box from "@mui/material/Box";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import clsx from "clsx";

interface MarkDownProps {
  text: string;
  className?: string;
}

const useStyles = createUseStyles({
  root: {
    overflowWrap: "break-word",
  },
});

const Image = ({
  alt,
  src,
  title,
}: {
  alt?: string;
  src?: string;
  title?: string;
}) => <img alt={alt} src={src} title={title} style={{ maxWidth: "100%" }} />;

const BlockQuote = ({ children }: { children: React.ReactNode }) => (
  <blockquote
    style={{
      borderLeft: `0.5rem solid ${palette.nebulaNavy50}`,
      padding: "0.0625rem 0 0.0625rem 0.5rem",
    }}
  >
    {children}
  </blockquote>
);

const Code = ({ children }: { children: React.ReactNode }) => (
  <Box
    style={{
      border: sqlHighlightStyle.border,
      borderRadius: sqlHighlightStyle.borderRadius,
      backgroundColor: sqlHighlightStyle.backgroundColor,
    }}
  >
    <SyntaxHighlighter
      showLineNumbers={false}
      customStyle={{
        backgroundColor: "transparent",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        overflowWrap: "break-word",
      }}
      language="sql"
      style={prism}
      wrapLines={true}
    >
      {children}
    </SyntaxHighlighter>
  </Box>
);

const Paragraph = ({ children }: { children: React.ReactNode }) => (
  <div style={{ whiteSpace: "pre-wrap", fontSize: "1rem" }}>
    <p>{children}</p>
  </div>
);

const Link = ({
  href,
  children,
}: {
  href?: string;
  children?: React.ReactNode;
}) => (
  <a
    href={href}
    target="_blank"
    rel="noreferrer"
    style={{ color: palette.purple, textDecoration: "none" }}
  >
    {children}
  </a>
);

export const MarkDown: React.FunctionComponent<MarkDownProps> = ({
  text,
  className,
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, className)}>
      <ReactMarkdown
        components={{
          img: Image,
          blockquote: BlockQuote,
          code: Code,
          p: Paragraph,
          a: Link,
        }}
      >
        {text}
      </ReactMarkdown>
    </div>
  );
};
