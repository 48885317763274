/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { Dispatch, useCallback, useEffect, useState } from "react";
import {
  DataProductForm,
  getAllTags$,
  TagValue,
} from "../../../api/dataProduct/dataProductApi";
import { TagDetails } from "../../../components/tag/TagDetails";
import { DataProductLinks } from "./DataProductLinks";
import { DataProductOwners } from "./DataProductOwners";
import { DataProductWizardNavigation } from "./DataProductWizardNavigation";
import { PublishFormAction } from "./publishFormReducer";
import { ErrorIndicator } from "../../../components/error/ErrorIndicator";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TagTextField } from "../../../components/tag/TagTextField";

interface DataProductDetailsProps {
  dataProductForm: DataProductForm;
  tags: TagValue[];
  error: string | undefined;
  publishFormDispatch: Dispatch<PublishFormAction>;
  setWizardStep: (newValue: number) => void;
  setError: (newValue: string | undefined) => void;
}

export const DataProductDetails: React.FunctionComponent<
  DataProductDetailsProps
> = ({
  dataProductForm,
  tags,
  publishFormDispatch,
  error,
  setWizardStep,
  setError,
}) => {
  const [allTagNames, setAllTagNames] = useState<string[]>([]);

  useEffect(() => {
    const subscription = getAllTags$().subscribe({
      next: (allTags) => setAllTagNames(allTags.map((tag) => tag.value)),
      error: (e) => setError(e.message),
    });
    return () => subscription.unsubscribe();
  }, []);

  const handleTagValueChange = useCallback(
    (tagValue: string) => publishFormDispatch({ type: "addTag", tagValue }),
    [publishFormDispatch]
  );
  const handleTagValueDelete = useCallback(
    (tagValue) => publishFormDispatch({ type: "removeTag", tagValue }),
    [publishFormDispatch]
  );
  return (
    <div>
      <Typography variant={"h3"}>Data product details</Typography>
      <ErrorIndicator text={error} />

      <Grid container>
        <Grid item xs={12}>
          <DataProductOwners
            owners={dataProductForm.owners}
            ownerDispatch={publishFormDispatch}
          />
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Typography variant={"h5"}>Assign tags</Typography>
          </Grid>

          <Grid item container xs={12} md={5}>
            <TagTextField
              allTagNames={allTagNames}
              onChange={handleTagValueChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TagDetails tags={tags} removeTag={handleTagValueDelete} />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <DataProductLinks
            links={dataProductForm.relevantLinks}
            linkDispatch={publishFormDispatch}
          />
        </Grid>
      </Grid>

      <DataProductWizardNavigation
        dataProductForm={dataProductForm}
        tags={tags}
        wizardStep={3}
        publishFormDispatch={publishFormDispatch}
        setWizardStep={setWizardStep}
        setError={setError}
      />
    </div>
  );
};
