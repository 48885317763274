/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from "react-jss";
import { palette } from "../../../../themes/palette";

export const useSubsectionClasses = createUseStyles({
  header: {
    fontWeight: 600,
    marginLeft: "0px",
  },
  editButton: {
    marginRight: "1rem",
  },
  formButtonContainer: {
    paddingRight: "1rem",
  },
  body: {
    fontSize: "1rem",
    color: palette.black,
    paddingRight: "1rem",
    paddingBottom: "1.5rem",
    overflowWrap: "break-word",
  },
});

export interface OverviewSubsectionClasses {
  header?: string;
  body?: string;
  editButton?: string;
  formButtonContainer?: string;
}
