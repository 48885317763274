/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import TableCell from "@mui/material/TableCell";
import {
  SortableColumn,
  SortingState,
} from "../../../../components/table/SortableColumn";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import { TableHeaderCell } from "../../../../components/table/TableHeaderCell";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import { ExpressionContextMenu } from "./ExpressionContextMenu";
import Button from "@mui/material/Button";
import EditOutlined from "@mui/icons-material/EditOutlined";
import { Expression } from "../../../../api/biac/biacApi";
import { Persisted } from "../../../../api/biac/common";
import { RowExpressionType } from "../MasksAndFilters";
import { createUseStyles } from "react-jss";
import { palette } from "../../../../themes/palette";
import { getExpressionRoles } from "./rowExpressionApi";
import orderBy from "lodash/orderBy";
import { TextWithTooltip } from "../../audit/TextWithTooltip";

interface ExpressionTableProps {
  expressions: Persisted<Expression>[];
  reloadExpressionList: () => void;
  selectedRowExpression: Persisted<Expression> | undefined;
  setSelectedRowExpression: (
    expression: Persisted<Expression> | undefined
  ) => void;
  editRowExpression: (expression: Persisted<Expression>) => void;
  deleteRowExpression: (expression: Persisted<Expression>) => void;
  rowExpressionType: RowExpressionType;
}
const useStyles = createUseStyles({
  expressionCell: {
    color: palette.nebulaNavy300,
    fontSize: "0.75rem",
    fontFamily: "Monaco, monospace",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  nameCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  descriptionCell: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  tableContainer: {
    maxHeight: "calc(100vh - 20rem)",
    border: `1px solid ${palette.nebulaNavy50}`,
  },
});

export const ExpressionTable: React.FunctionComponent<ExpressionTableProps> = ({
  deleteRowExpression,
  editRowExpression,
  expressions,
  rowExpressionType,
}) => {
  const [showExpressionContextMenu, setShowExpressionContextMenu] = useState<{
    isOpen: boolean;
    domElement: HTMLElement;
    expression: Persisted<Expression>;
  }>();

  const classes = useStyles();
  const [sorting, setSorting] = useState<SortingState<keyof Expression>>({
    sortBy: "name",
    sortOrder: "asc",
  });

  const expressionRolesAbortController = useRef<AbortController>();

  const editExpression = useCallback(() => {
    if (showExpressionContextMenu) {
      editRowExpression(showExpressionContextMenu.expression);
    }
  }, [showExpressionContextMenu]);

  const handleContextMenuClick = (
    expression: Persisted<Expression>,
    event: React.MouseEvent
  ) => {
    setShowExpressionContextMenu({
      isOpen: true,
      domElement: event.currentTarget as HTMLElement,
      expression: expression,
    });
  };

  const [
    isContextMenuExpressionDeletable,
    setIsContextMenuExpressionDeletable,
  ] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setIsContextMenuExpressionDeletable(undefined);
    if (showExpressionContextMenu) {
      expressionRolesAbortController.current = new AbortController();
      getExpressionRoles(
        showExpressionContextMenu.expression,
        rowExpressionType,
        expressionRolesAbortController.current
      ).then((roles) => {
        setIsContextMenuExpressionDeletable(roles.length === 0);
      });
    }
  }, [showExpressionContextMenu, rowExpressionType]);

  const sortedExpressions = React.useMemo(
    () => orderBy(expressions, `object.${sorting.sortBy}`, sorting.sortOrder),
    [expressions, sorting]
  );
  return (
    <>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader style={{ tableLayout: "fixed" }}>
          <colgroup>
            <col style={{ width: "2.5rem" }} />
            <col style={{ width: "16rem" }} />
            <col style={{ width: "26%" }} />
            <col style={{ width: "40%" }} />
            <col style={{ width: "7rem" }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableHeaderCell />
              <SortableColumn<keyof Expression>
                label="Name"
                sortingKey="name"
                sortingState={sorting}
                setSortingState={setSorting}
                component="th"
              />
              <SortableColumn<keyof Expression>
                label="Expression"
                sortingKey="expression"
                sortingState={sorting}
                setSortingState={setSorting}
                component="th"
              />
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedExpressions.map((expression) => (
              <TableRow key={expression.id}>
                <TableHeaderCell>
                  <IconButton
                    size="small"
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    onClick={(event) =>
                      handleContextMenuClick(expression, event)
                    }
                  >
                    <FontAwesomeIcon icon={faEllipsisV} />
                  </IconButton>
                </TableHeaderCell>
                <TableCell className={classes.nameCell}>
                  {expression.object.name}
                </TableCell>
                <TableCell className={classes.expressionCell}>
                  <TextWithTooltip text={expression.object.expression} />
                </TableCell>
                <TableCell className={classes.descriptionCell}>
                  <TextWithTooltip text={expression.object.description} />
                </TableCell>
                <TableCell>
                  <Button
                    disableRipple={true}
                    onClick={() => editRowExpression(expression)}
                  >
                    <EditOutlined />
                    &nbsp; Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {showExpressionContextMenu &&
        isContextMenuExpressionDeletable !== undefined && (
          <ExpressionContextMenu
            isOpen={showExpressionContextMenu.isOpen}
            anchorElement={showExpressionContextMenu.domElement}
            edit={editExpression}
            deleteExpression={() =>
              deleteRowExpression(showExpressionContextMenu.expression)
            }
            isDeleteEnabled={isContextMenuExpressionDeletable}
            close={() =>
              setShowExpressionContextMenu(
                showExpressionContextMenu
                  ? { ...showExpressionContextMenu, isOpen: false }
                  : undefined
              )
            }
            rowExpressionType={rowExpressionType}
          />
        )}
    </>
  );
};
