/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createTheme, darken } from "@mui/material/styles";
import { palette } from "./palette";
import { shadows } from "./shadows";

export const materialUiTheme = createTheme({
  typography: {
    fontFamily: "barlow, Roboto Condensed, sans-serif",
    fontWeightRegular: 400,
    h3: {
      fontFamily: "montserrat, sans-serif",
      fontSize: "32px",
      fontWeight: 600,
      lineHeight: "48px",
      letterSpacing: "1px",
      color: palette.nebulaNavy,
      paddingTop: "2rem",
      paddingBottom: "1.5rem",
    },
    h4: {
      fontFamily: "montserrat, sans-serif",
      fontSize: "20px",
      fontWeight: 700,
      letterSpacing: "0.15px",
      lineHeight: "30px",
      marginTop: "8px",
      marginLeft: "48px",
      marginBottom: "16px",
      color: palette.nebulaNavy,
    },
    h5: {
      fontFamily: "montserrat, sans-serif",
      fontSize: "18px",
      fontWeight: 600,
      marginBottom: "16px",
      letterSpacing: "0.15px",
      lineHeight: "27px",
      color: palette.nebulaNavy,
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: "24px",
      letterSpacing: "0.098px",
    },
    body2: {
      lineHeight: "24px",
      letterSpacing: "0.14px",
      fontSize: "14px",
    },
  },
  palette: {
    primary: {
      dark: palette.nebulaNavy,
      main: palette.purple,
    },
    secondary: {
      main: palette.purple,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 800,
      md: 1100,
      lg: 1400,
      xl: 1536,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
          letterSpacing: "1.25px",
          textTransform: "none",
          justifyContent: "normal",
          whiteSpace: "nowrap",
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: darken(palette.purple, 0.15),
          },
          "&.Mui-disabled": {
            color: palette.nebulaNavy200,
            backgroundColor: palette.nebulaNavy50,
          },
        },
        outlined: {
          color: palette.purple,
          borderColor: palette.nebulaNavy100,
          "&:hover": {
            backgroundColor: palette.fusionFuchsia025,
            borderColor: palette.purple,
          },
          "&.Mui-disabled": {
            color: palette.nebulaNavy100,
          },
        },
        textSecondary: {
          color: palette.error,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          "&.Mui-error": {
            color: palette.error,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          letterSpacing: "0.15px",
          "&.Mui-error": {
            color: palette.error,
            marginLeft: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "16px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: palette.nebulaNavy500,
          },
          "&.Mui-error": {
            color: palette.error,
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginRight: "1rem",
          color: palette.error,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            border: `1px solid ${palette.nebulaNavy100}`,
          },
          "&.Mui-focused fieldset": {
            borderColor: `${palette.info} !important`,
          },
          "&.Mui-error fieldset": {
            borderColor: `${palette.error}!important`,
          },
          paddingRight: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiAlert-standardError": {
            background: palette.errorLight,
            border: `1px solid ${palette.errorDark}`,
            boxSizing: "border-box",
            borderRadius: "4px",
            color: palette.errorDark,
            fontSize: "14px",
            lineHeight: "24px",
            ".MuiAlert-icon": {
              color: palette.errorDark,
              marginTop: "0.125rem",
            },
          },
          "&.MuiAlert-standardSuccess": {
            background: palette.successLight,
            border: `1px solid ${palette.successDark}`,
            boxSizing: "border-box",
            borderRadius: "4px",
            color: palette.successDark,
            fontSize: "14px",
            lineHeight: "24px",
            ".MuiAlert-icon": {
              color: palette.successDark,
              marginTop: "0.125rem",
            },
          },
          "&.MuiAlert-standardInfo": {
            background: palette.infoLight,
            border: `1px solid ${palette.infoDark}`,
            boxSizing: "border-box",
            borderRadius: "4px",
            color: palette.infoDark,
            fontSize: "14px",
            lineHeight: "24px",
            ".MuiAlert-icon": {
              color: palette.infoDark,
              marginTop: "0.125rem",
            },
          },
          "&.MuiAlert-standardWarning": {
            background: palette.warningLight,
            border: `1px solid ${palette.warningDark}`,
            boxSizing: "border-box",
            borderRadius: "4px",
            color: palette.warningDark,
            lineHeight: "24px",
            fontSize: "14px",
            ".MuiAlert-icon": {
              color: palette.warningDark,
              marginTop: "0.125rem",
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: shadows[1],
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.nebulaNavy50,
          fontWeight: 400,
          fontSize: "0.875rem",
          color: palette.nebulaNavy,
          lineHeight: "20px",
          letterSpacing: "0.252px",
          borderRadius: "0.25rem",
          padding: "0.75rem 1rem",
        },
        arrow: {
          color: palette.nebulaNavy50,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: palette.white,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: palette.black12,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          "&.Mui-checked": {
            "&$disabled": {
              color: palette.fusionFuchsia100,
            },
            "&$disabled + $track": {
              opacity: "0.5",
              backgroundColor: palette.fusionFuchsia050,
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${palette.nebulaNavy50}`,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: `${palette.nebulaNavy15} !important`,
            color: palette.nebulaNavy,
          },
          "&.Mui-selected": {
            backgroundColor: `${palette.nebulaNavy50} !important`,
            color: palette.nebulaNavy,
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          boxSizing: "border-box",
          "& ul": {
            padding: 0,
            margin: 0,
          },
        },
        option: {
          "&.Mui-focused": {
            backgroundColor: `${palette.nebulaNavy15} !important`,
            color: palette.nebulaNavy,
          },
          '&[aria-selected="true"]': {
            backgroundColor: `${palette.nebulaNavy50} !important`,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.customBlue,
        },
      },
    },
  },
});
