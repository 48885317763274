/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { fromPromise } from "rxjs/internal-compatibility";
import { Observable } from "rxjs";
import { baseUrl } from "../dataProductApi";
import axios from "axios";

export interface UserEntityPermissions {
  canUpdate: boolean;
  canPublish: boolean;
  canDelete: boolean;
}

export interface UserGlobalPermissions {
  canCreateDataDomains: boolean;
  canCreateDataProducts: boolean;
}

export function getUserGlobalPermissions(): Promise<UserGlobalPermissions> {
  return axios
    .get<UserGlobalPermissions>(`${baseUrl}/userPermissions`)
    .then((response) => response.data);
}

export const getUserGlobalPermissions$ =
  (): Observable<UserGlobalPermissions> =>
    fromPromise(getUserGlobalPermissions());
