/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { PropsWithRef, useCallback } from "react";
import { createUseStyles } from "react-jss";
import TextField from "@mui/material/TextField";
import debounce from "lodash/debounce";
import { StandaloneQueryTextEditor } from "@starburstdata/query-editor";
import IconButton from "@mui/material/IconButton";
import CropFreeIcon from "@mui/icons-material/CropFree";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import { InputBaseComponentProps } from "@mui/material/InputBase";
import clsx from "clsx";
import InputAdornment from "@mui/material/InputAdornment";
import {
  grammarValidationUrl,
  languageElementsUrl,
  monacoWebWorkerPath,
} from "../../features/ide/QueryEditor";

const useStyles = createUseStyles({
  textField: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
      alignSelf: "start",
      paddingTop: "1rem",
    },
  },
  container: {
    flex: 1,
  },
  viewEditor: {
    height: "10rem",
    "& .monaco-editor .lines-content": {
      backgroundColor: "transparent",
    },
    "& .monaco-editor .margin": {
      backgroundColor: "transparent",
    },
    "& .monaco-editor": {
      backgroundColor: "transparent",
    },
  },
  viewEditorExpandButton: {
    paddingTop: "5px",
  },
  dialogEditor: {
    height: "20rem",
  },
});

interface QueryTextEditorProviderProps {
  queryText?: string;
  handleTextChange: (text: string | undefined) => void;
}

const QueryTextEditorProvider: React.ComponentType<
  PropsWithRef<QueryTextEditorProviderProps>
> = React.forwardRef<HTMLDivElement, QueryTextEditorProviderProps>(
  ({ queryText, handleTextChange }, ref) => {
    const classes = useStyles();
    return (
      <div ref={ref} className={classes.container}>
        <StandaloneQueryTextEditor
          id="view-editor"
          queryText={queryText ?? ""}
          onTextChanged={handleTextChange}
          editorRootClass={classes.viewEditor}
          grammarValidationUri={grammarValidationUrl}
          languageElementsUri={languageElementsUrl}
          monacoWebWorkerPath={monacoWebWorkerPath}
        />
      </div>
    );
  }
);
const QueryTextEditorInput: React.ElementType<InputBaseComponentProps> =
  QueryTextEditorProvider as React.ElementType<InputBaseComponentProps>;

interface QueryEditorWithDialogProps {
  inputElementClass?: string;
  query?: string;
  onQueryChanged: (text: string | undefined) => void;
  label?: string;
  required?: boolean;
}

export const QueryEditorWithDialog: React.FunctionComponent<
  QueryEditorWithDialogProps
> = ({ inputElementClass, query, onQueryChanged, label, required = false }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const queryTextInDialog = React.useRef<string | undefined>(query);
  const setTextFromDialog = useCallback((value: string | undefined) => {
    queryTextInDialog.current = value;
  }, []);

  const handleTextChange = useCallback(
    debounce((text: string | undefined) => {
      onQueryChanged(text);
      setTextFromDialog(text);
    }, 100),
    [onQueryChanged, setTextFromDialog]
  );

  const handleSubmit = () => {
    setOpen(false);
    onQueryChanged(queryTextInDialog.current);
  };
  const handleClose = () => {
    setOpen(false);
    queryTextInDialog.current = query;
  };

  const providerProps: QueryTextEditorProviderProps = {
    queryText: query,
    handleTextChange: handleTextChange,
  };

  return (
    <>
      <TextField
        required={required}
        label={label}
        variant="outlined"
        margin="none"
        className={clsx(inputElementClass, classes.textField)}
        InputProps={{
          inputComponent: QueryTextEditorInput,
          inputProps: providerProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                className={classes.viewEditorExpandButton}
                onClick={handleClickOpen}
              >
                <CropFreeIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
      />

      <Dialog
        id="standalone-editors-dialog"
        fullWidth
        maxWidth={"md"}
        open={open}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <StandaloneQueryTextEditor
                id="editor-in-dialog"
                queryText={query}
                onTextChanged={setTextFromDialog}
                editorRootClass={classes.dialogEditor}
                grammarValidationUri={grammarValidationUrl}
                languageElementsUri={languageElementsUrl}
                monacoWebWorkerPath={monacoWebWorkerPath}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="close-dialog"
            onClick={handleClose}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            id="submit-dialog"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
