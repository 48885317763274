/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { DataProductLink } from "../../../api/dataProduct/dataProductApi";
import { palette } from "../../../themes/palette";
import IconButton from "@mui/material/IconButton";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

interface LinkDetailsProps {
  article: DataProductLink;
}

const useStyles = createUseStyles({
  link: {
    wordBreak: "break-word",
    "& a": {
      color: palette.purple,
      textDecoration: "none",
    },
  },
});

export const LinkDetails: React.FunctionComponent<LinkDetailsProps> = ({
  article,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.link}>
        <a href={article.url} target="_blank" rel="noopener noreferrer">
          {article.label}
        </a>
      </div>
    </div>
  );
};

const useEditableLinkDetailsClasses = createUseStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteButton: {
    color: palette.error,
  },
});

interface EditableLinkDetailsProps extends LinkDetailsProps {
  onDeleteClick: () => void;
}

export const EditableLinkDetails = ({
  article,
  onDeleteClick,
}: EditableLinkDetailsProps) => {
  const internalClasses = useEditableLinkDetailsClasses();
  return (
    <div className={internalClasses.root}>
      <LinkDetails article={article} />
      <IconButton
        className={internalClasses.deleteButton}
        size="small"
        onClick={onDeleteClick}
      >
        <DeleteOutline />
      </IconButton>
    </div>
  );
};
