/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../themes/palette";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTools } from "@fortawesome/pro-light-svg-icons";

interface BiacProps {
  title: string;
  description: React.ReactNode;
  enabled: () => boolean;
  children: React.ReactElement;
}

const useStyles = createUseStyles({
  container: {},
  iconBox: {
    textAlign: "center",
    marginTop: "6rem",
  },
  icon: {
    fontSize: "8rem",
    color: palette.purple,
  },
  text: {
    marginTop: "2.5rem",
    textAlign: "center",
  },
  title: {
    fontSize: "1.25rem",
  },
  "@media (min-width: 1200px)": {
    container: {
      maxWidth: "33vw",
    },
  },
  "@media (max-width: 1200px)": {
    container: {
      maxWidth: "50vw",
    },
  },
});

export const Biac: React.FunctionComponent<BiacProps> = ({
  children,
  description,
  enabled,
  title,
}) => {
  const classes = useStyles();

  if (enabled()) {
    return children;
  }

  return (
    <Box display="flex" justifyContent="center">
      <Box className={classes.container}>
        <Box className={classes.iconBox}>
          <FontAwesomeIcon className={classes.icon} icon={faTools} />
        </Box>
        <Box className={classes.text}>
          <p className={classes.title}>{title}</p>
          {description}
        </Box>
      </Box>
    </Box>
  );
};
