/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { TagValue } from "../../api/dataProduct/dataProductApi";
import Chip from "@mui/material/Chip";
import { shadows } from "../../themes/shadows";
import { palette } from "../../themes/palette";
import CloseIcon from "@mui/icons-material/Close";

interface TagDetailsProps {
  tags: TagValue[];
  removeTag?: (value: string) => void;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  root: {
    paddingTop: "1.5rem",
  },
  tagsList: {
    display: "flex",
    flexWrap: "wrap",
    padding: "0rem",
    margin: "0.4rem 0 1rem 0",
  },
  deleteIconChip: {
    color: palette.nebulaNavy700,
  },
  chip: {
    marginRight: "0.625rem",
    marginBottom: "0.625rem",
    color: palette.nebulaNavy700,
    backgroundColor: palette.nebulaNavy50,
    boxShadow: shadows[1],
    whiteSpace: "normal",
    wordWrap: "break-word",
    maxWidth: "200px",
  },
});

export const TagDetails: React.FunctionComponent<TagDetailsProps> = ({
  tags,
  removeTag,
  disabled = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.tagsList}>
      {tags?.map(({ value }) => (
        <Chip
          title={value}
          disabled={disabled}
          key={value}
          label={value}
          className={classes.chip}
          deleteIcon={
            removeTag && <CloseIcon className={classes.deleteIconChip} />
          }
          onDelete={removeTag && (() => removeTag(value))}
        />
      ))}
    </div>
  );
};
