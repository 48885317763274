/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import { EmptyOrValue } from "../../utils/value";
import Grid from "@mui/material/Grid";
import { ChosenRole, RoleChooser } from "./grants/RoleChooser";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { PUBLIC_ROLE_HEADER, RoleBasic } from "./CurrentRoleContext";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
  },
  headerText: {
    fontSize: "1.25rem",
    fontWeight: 500,
    textAlign: "center",
    letterSpacing: "0.5px",
    padding: "20px 16px 0",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 1.5rem 1.5rem 1.5rem",
  },
  selectButton: {
    marginLeft: "1rem",
  },
}));

interface CurrentRoleDialogProps {
  currentRoleId: number | "ALL";
  setRole(role: RoleBasic | "ALL", remember: boolean): void;
  closeDialog(): void;
}

export const CurrentRoleDialog: React.FunctionComponent<
  CurrentRoleDialogProps
> = ({ currentRoleId, setRole, closeDialog }) => {
  const classes = useStyles();

  const [selectedRole, setSelectedRole] = useState<
    EmptyOrValue<ChosenRole | null>
  >({
    empty: true,
    value: null,
  });
  const [remember, setRemember] = useState<boolean>(false);

  const handleRememberChange = useCallback((_, checked: boolean) => {
    setRemember(checked);
  }, []);

  const handleSwitchRole = () => {
    if (!selectedRole.empty) {
      if (selectedRole.value && selectedRole.value.id) {
        setRole(
          {
            id: selectedRole.value.id,
            name: selectedRole.value.name,
          },
          remember
        );
      } else {
        setRole("ALL", remember);
      }
      closeDialog();
    }
  };

  return (
    <Dialog open={true} className={classes.root}>
      <Box className={classes.headerText}>
        <Typography variant="h5" style={{ marginBlock: 0 }}>
          Switch role
        </Typography>
      </Box>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        rowSpacing={1}
        style={{ width: "450px", margin: "1rem" }}
      >
        <Grid item>
          <RoleChooser
            disabled={false}
            currentRoleName={PUBLIC_ROLE_HEADER}
            allowStar={currentRoleId !== "ALL"}
            starRoleDescription="All user roles enabled"
            excludedRoles={currentRoleId === "ALL" ? [] : [currentRoleId]}
            value={selectedRole}
            handleChange={setSelectedRole}
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={remember}
                onChange={handleRememberChange}
              />
            }
            label="Remember selected role"
          />
        </Grid>
      </Grid>
      <div className={classes.buttonsWrapper}>
        <Button variant="outlined" color="primary" onClick={closeDialog}>
          Cancel
        </Button>
        <Button
          disabled={selectedRole.empty}
          variant="contained"
          color="primary"
          onClick={handleSwitchRole}
          className={classes.selectButton}
        >
          Switch role
        </Button>
      </div>
    </Dialog>
  );
};
