/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import { palette } from "../../../themes/palette";
import { QueriesSearch } from "./QueriesSearch";
import { QueriesRefresh } from "./QueriesRefresh";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { handleFiltersChange } from "./queryHistorySlice";
import { QueriesContainer } from "./QueriesContainer";
import { PersistenceEnabledBiac } from "../../../components/biac/PersistenceEnabledBiac";
import { useHistory } from "react-router-dom";
import { FilterCriteria } from "./queryFilters";
import { PageContent } from "../../../layout/PageContent";
import Grid from "@mui/material/Grid";

const useStyles = createUseStyles({
  root: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    minHeight: "calc(100% - 16px)",
  },
  line: {
    marginBottom: "1rem",
  },
  header: {
    paddingTop: "0.625rem",
    fontSize: "1.5rem",
    lineHeight: "1.25",
    letterSpacing: "0.26px",
    color: palette.nightBlue,
    marginRight: "auto",
  },
});

export type QueryHistoryMode = "table" | "chart";
export const queryHistoryModeParam = "view";

interface QueryHistoryProps {
  mode: QueryHistoryMode | null;
}

export const QueryHistory: React.FunctionComponent<QueryHistoryProps> = ({
  mode: inputMode,
}) => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [mode, setMode] = useState<QueryHistoryMode>(inputMode ?? "table");

  const toggleMode = (newMode: QueryHistoryMode): void => {
    setMode(newMode);
    history.replace({ search: `?${queryHistoryModeParam}=${newMode}` });
  };

  const filters = useSelector(
    ({ queryHistory }: RootState) => queryHistory.filters
  );

  const showReports = (filters: FilterCriteria | null) => {
    if (!filters) {
      return true;
    }
    return filters.status
      ? filters.status.includes("FAILED") || filters.status.includes("FINISHED")
      : true;
  };

  return (
    <PersistenceEnabledBiac>
      <PageContent title="Query overview">
        <Box className={classes.root}>
          <QueriesSearch
            appliedFilters={filters}
            onFiltersApply={(filters): unknown =>
              dispatch(handleFiltersChange(filters))
            }
          />
          <QueriesContainer
            mode={mode}
            toggleMode={toggleMode}
            showReports={showReports(filters)}
          />
          <Grid container justifyContent="flex-end">
            <QueriesRefresh mode={mode} />
          </Grid>
        </Box>
      </PageContent>
    </PersistenceEnabledBiac>
  );
};
