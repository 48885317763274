/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { useHistory } from "react-router-dom";
import { logoutRoute } from "./login/routing/LoginRouting";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { persistLogoutDialogState } from "../store/sepSessionStore";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  dialogMessage: {
    fontFamily: "montserrat, sans-serif",
    fontSize: "1.25rem",
    fontWeight: 600,
  },
});

interface UnauthorizedDialogProps {
  open: boolean;
  setOpen: (newState: boolean) => void;
}

const modalDismissalReasons = ["backdropClick", "escapeKeyDown"];
export const UnauthorizedDialog: React.FunctionComponent<
  UnauthorizedDialogProps
> = ({ open, setOpen }) => {
  const history = useHistory();
  const classes = useStyles();
  const handleDismiss = () => {
    setOpen(false);
    persistLogoutDialogState(true);
  };

  const handleLogout = () => {
    setOpen(false);
    history.push(logoutRoute);
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={(_, reason) => {
        if (!modalDismissalReasons.includes(reason)) {
          handleDismiss();
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <div className={classes.dialogMessage}>Expired session</div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session has expired. Log back in to start a new session.
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "0 1rem 1rem 0" }}>
        <Button onClick={handleLogout} color="primary" variant={"contained"}>
          Log out
        </Button>
        <Button onClick={handleDismiss} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};
