/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { TopEntitiesChart } from "../../../../components/chart/TopEntitiesChart";
import React, { ReactElement } from "react";
import { ChartWrapper } from "../../../../components/chart/ChartWrapper";
import { HorizontalBarChart } from "../../../../components/chart/HorizontalBarChart";

type Base = { numberOfQueries: number };

interface CpuTimeBaseChartProps<T extends Base> {
  queriesCount: T[] | "loading" | "error" | undefined;
  labelKey: keyof T;
  labelFormatter?: (inputLabel: string) => string;
  className?: string;
}

export const QueriesCountBaseChart = <T extends Base>({
  labelKey,
  queriesCount,
  labelFormatter,
  className,
}: CpuTimeBaseChartProps<T>): JSX.Element => {
  const slicesLimit = 5;

  const height = 200;

  return (
    <TopEntitiesChart<T>
      data={queriesCount}
      labelKey={labelKey}
      valueKey="numberOfQueries"
      limit={slicesLimit}
    >
      {(topUsers): ReactElement => (
        <ChartWrapper
          data={topUsers}
          height={height}
          containerless={true}
          classes={{ chart: className }}
        >
          {(chartData) => (
            <HorizontalBarChart
              data={chartData}
              tooltipText="Queries run"
              height={height}
              yLabelFormatter={labelFormatter}
            />
          )}
        </ChartWrapper>
      )}
    </TopEntitiesChart>
  );
};
