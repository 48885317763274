/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../../themes/palette";
import { HintTooltip } from "../../../components/tooltip/HintTooltip";

const useStyles = createUseStyles({
  helperIcon: {},
  helperButton: {
    height: "1.25rem",
    width: "1.25rem",
    color: palette.nebulaNavy200,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const AuditCsvDownloadTooltipButton: React.FunctionComponent = () => {
  const classes = useStyles();
  return (
    <HintTooltip
      title={
        "Log files can get quite large. The default download limit is 24 hours, but you can use the filter to change the downloaded file limits."
      }
      classes={{
        button: classes.helperButton,
        icon: classes.helperIcon,
      }}
    />
  );
};
