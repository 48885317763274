/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Observable, Subject } from "rxjs";
import { CommentData } from "./commentApi";
const subject = new Subject<CommentEvent>();

export const commentEvent$: Observable<CommentEvent> = subject;

interface CommentDefaultEvent {
  type: "commentAdded" | "commentChanged";
  payload: CommentData;
}
interface CommentDeletedEvent {
  type: "commentDeleted";
  payload: {
    commentId: string;
    dataProductId: string;
  };
}
interface CommentUpvoted {
  type: "commentUpvoted";
  payload: {
    commentId: string;
    dataProductId: string;
  };
}
interface CommentVoteDeleted {
  type: "commentVoteDeleted";
  payload: {
    commentId: string;
    dataProductId: string;
  };
}

type CommentEvent =
  | CommentDefaultEvent
  | CommentDeletedEvent
  | CommentUpvoted
  | CommentVoteDeleted;

export const emitCommentEvent = (event: CommentEvent): void => {
  subject.next(event);
};
