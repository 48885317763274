/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import { DetailsRow } from "../../../../components/details/DetailsRow";
import { DetailsCell } from "../../../../components/details/DetailsCell";
import { formatDuration } from "../../../../utils/formatDuration";
import React from "react";
import { createUseStyles } from "react-jss";
import { SingleQueryDetails } from "../../../../api/queryApi";
import round from "lodash/round";
import { abbreviateNumber } from "../../../../utils/abbreviateNumber";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import { formatBytes } from "../../../../utils/formatBytes";
import Typography from "@mui/material/Typography";

interface QueryAdvancedDetailsSectionProps {
  query: SingleQueryDetails;
}

const useStyles = createUseStyles({
  root: {
    height: "calc(100% - 16px)",
    padding: "8px",
  },
  row: {
    marginBottom: "8px",
  },
});

export const QueryAdvancedDetailsSection: React.FunctionComponent<
  QueryAdvancedDetailsSectionProps
> = ({ query }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullWidth = useMediaQuery(theme.breakpoints.down("md"));

  const utilizationRatio = () => {
    if (!query.scheduledTime) {
      return "-";
    } else {
      return `${Math.round((100 * query.cpuTime) / query.scheduledTime)}%`;
    }
  };

  const parallelism = () => {
    if (!query.elapsedTime) {
      return undefined;
    }

    const parallelismNo = query.cpuTime / query.elapsedTime;
    const precision = Math.round(Math.log10(100 / parallelismNo));
    const boundPrecision = Math.min(Math.max(precision, 0), 4);

    return round(parallelismNo, boundPrecision);
  };

  const cellSize: { xl: 6; lg: 6; xs: 4 } = {
    xl: 6,
    lg: 6,
    xs: 4,
  };

  return (
    <Box pt={1} className={clsx({ [classes.root]: !fullWidth })}>
      <Typography variant={"h5"}>Execution details</Typography>
      <DetailsRow className={classes.row}>
        <DetailsCell
          header="Elapsed time"
          value={formatDuration(query.elapsedTime)}
          tooltip={"Query execution time as measured by a wall clock"}
          {...cellSize}
        />
        <DetailsCell
          header="CPU time"
          value={formatDuration(query.cpuTime)}
          tooltip={"Total accumulated CPU time across threads and workers"}
          {...cellSize}
        />
      </DetailsRow>
      <DetailsRow className={classes.row}>
        <DetailsCell
          header="Parallelism"
          tooltip="Estimated value of how much the workload was processed in
            parallel, calculated from `CPU time` / `Elapsed time`"
          value={parallelism()}
          {...cellSize}
        />
        <DetailsCell
          header="Active"
          tooltip="Estimated value of how efficient processing was performed,
            calculated from `CPU time` / time spent waiting as scheduled"
          value={utilizationRatio()}
          {...cellSize}
        />
      </DetailsRow>
      <DetailsRow className={classes.row}>
        <DetailsCell
          header="Rows read"
          value={abbreviateNumber(query.rowsRead)}
          tooltip="Sum of all rows used in the query input"
          {...cellSize}
        />
        <DetailsCell
          header="Bytes read"
          value={
            query.rowsRead > 0 && query.bytesRead === 0
              ? "-"
              : formatBytes(query.bytesRead)
          }
          tooltip="Sum of all bytes used in the query input"
          {...cellSize}
        />
      </DetailsRow>
      <DetailsRow className={classes.row}>
        <DetailsCell
          header="Rows written"
          value={abbreviateNumber(query.rowsWritten)}
          tooltip="Number of inserted rows"
          {...cellSize}
        />
        <DetailsCell
          header="Bytes written"
          value={
            query.rowsWritten > 0 && query.bytesWritten === 0
              ? "-"
              : formatBytes(query.bytesWritten)
          }
          tooltip="Number of bytes of inserted rows"
          {...cellSize}
        />
      </DetailsRow>
      <DetailsRow className={classes.row}>
        <DetailsCell
          header="Peak memory"
          value={formatBytes(query.peakMemory)}
          tooltip="Maximum amount of memory directly tied to query resources used by a query at one time"
          xs={12}
        />
      </DetailsRow>
    </Box>
  );
};
