/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Typography from "@mui/material/Typography";
import { Doughnut } from "react-chartjs-2";
import { ReportContainer } from "../../../../components/chart/ReportContainer";

import { palette } from "../../../../themes/palette";
import { darken } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";

interface QueryAccelerationPercentageChartProps {
  ratio: number;
  title: string;
  description: string;
  color: string;
}

const useStyles = createUseStyles(() => ({
  text: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.496px",
    "& a": {
      color: palette.purple,
      textDecoration: "none",
      "&:hover": {
        color: darken(palette.purple, 0.5),
      },
    },
  },
  reportContainer: {
    height: "253px",
  },
  reportLayout: {
    display: "flex",
    flexDirection: "row",
    "& *": {
      flex: "0 50%",
    },
  },
  chartContainer: {
    display: "flex",
    justifyContent: "center",
    "& > div": {
      width: "146px",
      height: "146px",
      position: "relative",
      top: "-10px",
    },
  },
  chartOverlay: {
    position: "absolute",
    alignItems: "center",
    top: "calc(50% - 25px)",
    left: "calc(50% - 50px)",
    width: "100px",
    height: "50px",
    textAlign: "center",
    fontFamily: "Montserrat",
  },
  value: {
    fontSize: "28px",
    lineHeight: "28px",
    display: "inline-block",
    fontWeight: "600",
    letterSpacing: "-0.448px",
    color: "rgba(0, 0, 0, 0.87)",
  },
  valueNA: {
    paddingTop: "10px",
  },
  percentage: {
    fontSize: "18px",
    color: "rgba(0, 0, 0, 0.54)",
    display: "inline-block",
    verticalAlign: "top",
    fontWeight: 600,
    marginLeft: "2px",
  },
  accelerated: {
    fontFamily: "barlow",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "14px",
  },
}));

export const QueryAccelerationPercentageChart: React.FunctionComponent<
  QueryAccelerationPercentageChartProps
> = (params) => {
  const classes = useStyles();
  return (
    <ReportContainer className={classes.reportContainer} title={params.title}>
      <div className={classes.reportLayout}>
        <Typography variant="body2">{params.description}</Typography>
        <Box className={classes.chartContainer}>
          <div>
            <Doughnut
              data={{
                datasets: [
                  {
                    data: isNaN(params.ratio)
                      ? [0, 1]
                      : [params.ratio, 1 - params.ratio],
                    backgroundColor: [params.color, "rgba(0, 0, 0, 0.12)"],
                  },
                ],
              }}
              options={{
                legend: { display: false },
                responsive: true,
                aspectRatio: 1,
                maintainAspectRatio: false,
                tooltips: {
                  enabled: false,
                },
                cutoutPercentage: 82,
              }}
            />
            <div className={classes.chartOverlay}>
              {isNaN(params.ratio) ? (
                <div className={`${classes.value} ${classes.valueNA}`}>N/A</div>
              ) : (
                <>
                  <div className={classes.value}>
                    {Math.round(params.ratio * 100)}
                  </div>
                  <div className={classes.percentage}>%</div>
                  <Typography className={classes.accelerated}>
                    Accelerated
                  </Typography>
                </>
              )}
            </div>
          </div>
        </Box>
      </div>
    </ReportContainer>
  );
};
