/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { StageStats } from "../../../../../../api/queryApi";
import { formatRows, parseAndFormatDataSize } from "../utils";

type RemoteSourceProps = StageStats;

export const RemoteSource: React.FunctionComponent<RemoteSourceProps> = ({
  outputDataSize,
  outputPositions,
}: RemoteSourceProps) => {
  return (
    <>
      <div>{parseAndFormatDataSize(outputDataSize)}</div>
      <div>/</div>
      <div>{formatRows(outputPositions)}</div>
    </>
  );
};
