/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/pro-regular-svg-icons";
import { SqlHighlight } from "../../../../components/sql-highlight/SqlHighlight";
import { createUseStyles } from "react-jss";
import { palette, sqlHighlightStyle } from "../../../../themes/palette";
import { QueryFullTextDialog } from "./QueryFullTextDialog";
import { Clipboard } from "../../../../components/clipboard/Clipboard";
import { useHistory } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Code from "@mui/icons-material/Code";
import Fullscreen from "@mui/icons-material/Fullscreen";
import { createWorksheet } from "../../../../api/worksheetApi";
import { v4 as uuidv4 } from "uuid";
import {
  generateTabName,
  persistActiveTabId,
} from "@starburstdata/query-editor";
import { ErrorDialog } from "../../../../components/error/ErrorDialog";

const useStyles = createUseStyles({
  subheader: {
    fontFamily: "montserrat, sans-serif",
    fontSize: "1.125rem",
    lineHeight: "1.6875rem",
    color: palette.nebulaNavy,
    fontWeight: 600,
    letterSpacing: "0.15px",
  },
  content: {
    padding: "1rem",
    border: sqlHighlightStyle.border,
    borderRadius: sqlHighlightStyle.borderRadius,
    backgroundColor: sqlHighlightStyle.backgroundColor,
    minHeight: "12rem",
    cursor: "pointer",
    "&:hover": {
      borderColor: "#BCC2D8",
      transition: "border-color 0.2s linear",
    },
  },
  header: {
    marginBottom: "1rem",
  },
  buttons: {
    marginTop: "-0.25rem",
  },
  seeMoreDescription: {
    fontSize: "0.75rem",
    marginTop: "1.5rem",
    color: palette.black54,
    fontStyle: "italic",
    letterSpacing: "0.5px",
  },
});

interface QueryTextSectionProps {
  text: string;
}

export const QueryTextSection: React.FunctionComponent<
  QueryTextSectionProps
> = ({ text }) => {
  const classes = useStyles();
  const history = useHistory();
  const [isPending, setIsPending] = useState(false);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const abortController = useRef<AbortController | undefined>(undefined);

  useEffect(() => {
    return () => {
      abortController.current?.abort();
    };
  }, []);

  const openInWorksheet = async () => {
    try {
      const worksheetId = uuidv4();
      const localAbortController = new AbortController();

      setIsPending(true);
      abortController.current = localAbortController;

      await createWorksheet(
        {
          id: worksheetId,
          name: generateTabName(),
          tabNumber: new Date().getTime(),
          queryText: text,
        },
        localAbortController
      );
      persistActiveTabId(worksheetId);
      history.push("/ide");
    } catch (e) {
      if (e.name !== "CanceledError") {
        setOpenErrorDialog(true);
        setIsPending(false);
      }
    }
  };

  return (
    <>
      <ErrorDialog
        text={"Failed to create a worksheet. Try again later."}
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
      />
      <Box>
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.header}
        >
          <div className={classes.subheader}>Query text</div>
          <div className={classes.buttons}>
            <Tooltip title="Edit in query editor">
              <IconButton
                color="primary"
                size={"small"}
                style={{
                  marginRight: "8px",
                }}
                disabled={isPending}
                onClick={openInWorksheet}
              >
                <Code />
              </IconButton>
            </Tooltip>
            <Clipboard text={text}>
              <IconButton
                color="primary"
                size={"small"}
                style={{ padding: "6px", marginRight: "8px" }}
              >
                <FontAwesomeIcon icon={faClone} />
              </IconButton>
            </Clipboard>
            <QueryFullTextDialog text={text}>
              {(open): JSX.Element => (
                <Tooltip title="See full query text">
                  <IconButton color="primary" onClick={open} size={"small"}>
                    <Fullscreen />
                  </IconButton>
                </Tooltip>
              )}
            </QueryFullTextDialog>
          </div>
        </Box>
        <QueryFullTextDialog text={text}>
          {(open): ReactElement<HTMLElement> => (
            <div className={classes.content} onClick={open}>
              <SqlHighlight
                sql={text.substring(0, 1000)}
                lines={7}
                ellipsis={
                  <div className={classes.seeMoreDescription}>
                    Click here to see more
                  </div>
                }
                condensedSql={false}
                fontSize={"0.875rem"}
              />
            </div>
          )}
        </QueryFullTextDialog>
      </Box>
    </>
  );
};
