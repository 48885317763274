/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode, useCallback } from "react";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { usePublishManager } from "../workflow/publishManager/usePublishManager";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import { emitDataProductEvent } from "../../../api/dataProduct/dataProductEvent";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";
import { SimpleDialog } from "../../../components/dialog/SimpleDialog";

interface PublishDataProductProps {
  dataProductId: string;
  name: string;
  hasNoDatasets: boolean;
  canUserPublish: boolean | undefined;
  className?: string;
}

export const PublishDataProduct: React.FunctionComponent<
  PublishDataProductProps
> = ({ dataProductId, name, hasNoDatasets, canUserPublish, className }) => {
  const { isOpen, close, open } = useDialogOpenState();
  const tooltipTitle = !canUserPublish
    ? "You do not have permission to publish this data product"
    : hasNoDatasets
    ? "This data product has no datasets to publish"
    : "";
  return (
    <div className={className}>
      <Tooltip title={tooltipTitle}>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={open}
            disabled={hasNoDatasets || !canUserPublish}
          >
            Publish
          </Button>
        </span>
      </Tooltip>
      {isOpen && (
        <PublishDataProductFormDialog
          dataProductId={dataProductId}
          dataProductName={name}
          isOpen={isOpen}
          close={close}
        />
      )}
    </div>
  );
};

interface PublishDataProductFormDialogProps {
  dataProductId: string;
  dataProductName: string;
  isOpen: boolean;
  close: () => void;
}

const PublishDataProductFormDialog = ({
  dataProductId,
  dataProductName,
  isOpen,
  close,
}: PublishDataProductFormDialogProps) => {
  const publishManager = usePublishManager(dataProductId, [dataProductId]);

  const handleConfirmation = useCallback(() => {
    return publishManager.current
      ? publishManager.current.start().then(() =>
          emitDataProductEvent({
            type: "published",
            payload: { dataProductId },
          })
        )
      : Promise.reject({
          message: "Publish manager do not exist",
        });
  }, [publishManager, dataProductId]);
  return (
    <SimpleDialog<PublishDataProductFormProps>
      isOpen={isOpen}
      close={close}
      title="Publish data product"
      confirmButtonLabel="Publish"
      onConfirm={handleConfirmation}
      contentProps={{ dataProductName }}
      Content={PublishDataProductForm}
    />
  );
};

const usePublishDataProductFormStyles = createUseStyles({
  error: {
    marginTop: "1.5rem",
  },
});

interface PublishDataProductFormProps {
  dataProductName: string;
  error?: ReactNode;
}

const PublishDataProductForm = ({
  dataProductName,
  error,
}: PublishDataProductFormProps) => {
  const styles = usePublishDataProductFormStyles();
  return (
    <div>
      Are you ready to publish <b>{dataProductName}</b>?
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};
