/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { PageContent } from "../PageContent";
import { AuthorizedUserBiac } from "../../components/biac/AuthorizedUserBiac";
import { UiFeatures } from "../../api/configApi";
import { SettingsTabs } from "./SettingsTabs";

export const Settings: React.FunctionComponent = () => {
  return (
    <AuthorizedUserBiac uiFeature={UiFeatures.SETTINGS}>
      <PageContent title="Settings">
        <SettingsTabs />
      </PageContent>
    </AuthorizedUserBiac>
  );
};
