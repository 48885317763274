/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from "react";
import { map, switchMap, tap } from "rxjs/operators";
import { createUseStyles } from "react-jss";
import orderBy from "lodash/orderBy";

import { CreateDomainDialog } from "./create/CreateDomainDialog";
import {
  DataDomain,
  getDomains$,
} from "../../../api/dataProduct/dataDomain/dataDomainApi";
import { dataDomainEvent$ } from "../../../api/dataProduct/dataDomain/dataDomainEvent";
import { SortingState } from "../../../components/table/SortableColumn";
import { NoData } from "../../../components/error/NoData";
import { Spinner } from "../../../components/spinner/Spinner";
import { ErrorIndicator } from "../../../components/error/ErrorIndicator";
import { EditDomain } from "./edit/EditDomain";
import { DomainTable } from "./display/DomainTable";
import {
  createDataSuccess,
  isDataSuccess,
  useFetchingState,
} from "./useFetchingState";
import { PageContent } from "../../../layout/PageContent";

const useStyles = createUseStyles({
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 0",
  },
  dataContainer: {
    display: "flex",
  },
  viewContainer: {
    flex: 1,
  },
  editForm: {
    width: "30%",
    padding: "0 1rem 0 3rem",
    height: "100%",
  },
});

export const DomainList: React.FunctionComponent = () => {
  const styles = useStyles();
  const {
    isFetching,
    isError,
    data: domains,
    errorMessage,
    setFetching,
    setState,
    observer,
  } = useFetchingState<DataDomain[]>();
  const [domainToEdit, setDomainToEdit] = useState<DataDomain | null>(null);
  const [sorting, setSorting] = useState<SortingState<keyof DataDomain>>({
    sortBy: "name",
    sortOrder: "asc",
  });

  useEffect(() => {
    setState((prevState) => {
      return isDataSuccess(prevState)
        ? createDataSuccess(sortDomains(prevState.data, sorting))
        : prevState;
    });
  }, [sorting]);

  useEffect(() => {
    const subscription = getDomains$()
      .pipe(map((fetchedDomains) => sortDomains(fetchedDomains, sorting)))
      .subscribe(observer);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = dataDomainEvent$
      .pipe(
        tap(() => setFetching()),
        switchMap(() => getDomains$()),
        map((fetchedDomains) => sortDomains(fetchedDomains, sorting))
      )
      .subscribe(observer);
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (domainToEdit && domains) {
      const newDomainToEdit: DataDomain | null =
        domains.find(({ id }) => id === domainToEdit.id) || null;
      setDomainToEdit(newDomainToEdit);
    }
  }, [domains]);

  const startEditing = useCallback(
    (domain: DataDomain) => setDomainToEdit(domain),
    []
  );
  const finishEditing = useCallback(() => setDomainToEdit(null), []);
  return (
    <PageContent title="Domain management">
      <div className={styles.dataContainer}>
        <div className={styles.viewContainer}>
          <div className={styles.topContainer}>
            <CreateDomainDialog />
            {!!domains && (
              <div>
                {domains.length} domain{domains.length !== 1 && "s"}
              </div>
            )}
          </div>
          {isFetching ? (
            <Spinner size={150} position="absolute" />
          ) : isError ? (
            <ErrorIndicator text={errorMessage} />
          ) : domains?.length ? (
            <DomainTable
              domains={domains}
              onEditClick={startEditing}
              sorting={sorting}
              onSortChange={setSorting}
            />
          ) : (
            <NoData height={250} text="No domain available" icon="table" />
          )}
        </div>
        {domainToEdit && domains && (
          <EditDomain
            domain={domainToEdit}
            allDomains={domains}
            onCancel={finishEditing}
            className={styles.editForm}
          />
        )}
      </div>
    </PageContent>
  );
};

function sortDomains(
  domains: DataDomain[],
  sorting: SortingState<keyof DataDomain>
): DataDomain[] {
  return sorting.sortBy === "assignedDataProducts"
    ? [...domains].sort((a, b) => {
        const lengthDiff =
          b.assignedDataProducts.length - a.assignedDataProducts.length;
        return sorting.sortOrder === "desc" ? lengthDiff : -lengthDiff;
      })
    : orderBy(domains, [sorting.sortBy], [sorting.sortOrder]);
}
