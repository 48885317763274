/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { UiFeatureInfo, UiFeatures } from "../../../../api/configApi";
import { EmptyOrValue } from "../../../../utils/value";
import React, { useCallback, useEffect, useState } from "react";
import { useAllowedUiFeatures } from "../../../../app/ConfigContextProvider";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { palette } from "../../../../themes/palette";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { addPrivilegesStyles } from "../add-privileges-styles";
import Checkbox from "@mui/material/Checkbox";
import { ChooserErrorIcon } from "../../grants/ChooserErrorIcon";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import Paper, { PaperProps } from "@mui/material/Paper";
import { PopperUnstyledProps } from "@mui/base/PopperUnstyled";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";

export interface ChosenFeature {
  id: UiFeatures | null;
  name: string;
}

interface FeatureInfo {
  label: string;
  attribute: UiFeatures | null;
  description: string | null;
}

interface UIFeaturesSelectorProps {
  value: EmptyOrValue<ChosenFeature[] | null>;
  handleChange: (value: EmptyOrValue<ChosenFeature[] | null>) => void;
}

export const UIFeaturesSelector: React.FunctionComponent<
  UIFeaturesSelectorProps
> = ({ value, handleChange }) => {
  const classes = addPrivilegesStyles();
  const [error, setError] = useState<string | null>(null);
  const [uiFeaturesFound, setUiFeaturesFound] = useState<FeatureInfo[]>([]);
  const [uiFeatures, setUiFeatures] = useState<FeatureInfo[]>([]);
  const features: UiFeatureInfo[] = useAllowedUiFeatures() ?? [];
  const allFeaturesChecked =
    !value.empty &&
    value.value &&
    value.value?.some((val) => val.id === UiFeatures.SEP_UI);

  useEffect(() => {
    if (features) {
      const userInterfaceFeatures = features
        .filter((feature) => !feature.group)
        .map<FeatureInfo>((uiFeatureInfo) => ({
          attribute: uiFeatureInfo.feature,
          label: uiFeatureInfo.displayName,
          description: uiFeatureInfo.description,
        }));
      setUiFeaturesFound(userInterfaceFeatures);
      setError(null);
    } else {
      setError("No features found");
    }
  }, []);

  useEffect(() => {
    const selectedLength = value.value?.filter((currentVal) =>
      uiFeatures.some((uiFeature) => currentVal.id === uiFeature.attribute)
    ).length;
    if (!selectedLength) {
      if (value.value) {
        const featuresFound: FeatureInfo[] = [];
        uiFeaturesFound.forEach((applicableFeature) => {
          value.value?.forEach((val) => {
            if (applicableFeature.attribute === val.id) {
              featuresFound.push(applicableFeature);
            }
          });
        });
        setUiFeatures(featuresFound ? featuresFound : []);
      } else {
        setUiFeatures([]);
      }
    }
  }, [value]);

  useEffect(() => {
    return () => handleChange({ empty: true, value: null });
  }, []);

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: FeatureInfo,
      { selected }
    ) => {
      const isOptionGroup = features?.find((allowedFeature) => {
        return allowedFeature.feature === option.attribute;
      })?.group;
      return (
        <li {...props}>
          <Checkbox style={{ marginRight: "8px" }} checked={selected} />
          {isOptionGroup ? (
            <>
              <Box>
                <b>{option.label}</b>
              </Box>
              <Box color={palette.black54} ml="auto">
                {option.description}
              </Box>
            </>
          ) : (
            <div className={classes.optionLabel}>{option.label}</div>
          )}
        </li>
      );
    },
    []
  );
  const onChange = useCallback(
    (event: React.SyntheticEvent, value: FeatureInfo[]) => {
      setUiFeatures(value);
      setValues(value);
    },
    [uiFeatures]
  );

  const handleCheckStar = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        handleChange({
          empty: false,
          value: [{ id: UiFeatures.SEP_UI, name: "SEP UI" }],
        });
        setUiFeatures([]);
      } else {
        handleChange({
          empty: true,
          value: null,
        });
      }
    },
    []
  );

  const setValues = useCallback((value: FeatureInfo[]) => {
    if (!value.length) {
      handleChange({
        empty: true,
        value: null,
      });
    } else {
      handleChange({
        empty: !value,
        value: value.map((val) => {
          return { name: val.label, id: val.attribute };
        }),
      });
    }
  }, []);

  const checkAllChange = useCallback(() => {
    setValues(uiFeaturesFound);
    setUiFeatures(uiFeaturesFound);
  }, [uiFeaturesFound]);

  const renderPopupIcon = useCallback(() => {
    if (error) {
      return (
        <div>
          <ChooserErrorIcon title="Loading features failed" />
        </div>
      );
    } else {
      return <ArrowDropDownOutlinedIcon />;
    }
  }, [error]);

  const CustomPaper = useCallback(
    (props: PaperProps) => (
      <Paper style={{ borderRadius: "4px 4px 0 0" }} {...props} />
    ),
    []
  );

  const SelectAllButton = useCallback(
    (param: PopperUnstyledProps) => (
      <Popper {...param}>
        <Box {...param} />
        <Paper className={classes.popperComponentPaper}>
          <Box justifyContent="flex-end" display="flex">
            <Button
              variant="outlined"
              size="small"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={checkAllChange}
            >
              Select all features
            </Button>
          </Box>
        </Paper>
      </Popper>
    ),
    [checkAllChange]
  );

  return (
    <Grid pl={1} maxWidth="fit-content">
      <Typography variant="h5">
        Which features would you like to select?
      </Typography>
      <Typography className={classes.questionInfo}>
        Use the dropdown to select the SEP UI features. To select all currently
        defined features, as well as features defined in the future, check the{" "}
        <b>All SEP UI features</b> box instead.
      </Typography>
      <Box mb={1.5}>
        <FormControlLabel
          control={
            <Checkbox
              checked={allFeaturesChecked as boolean}
              onChange={handleCheckStar}
              id="select-star"
            />
          }
          label={
            <span>
              All SEP UI features{" "}
              <span style={{ color: palette.black54 }}>(use with caution)</span>
            </span>
          }
        />
      </Box>
      <Box mb={2}>
        <Autocomplete
          value={uiFeatures}
          multiple
          limitTags={2}
          clearText="Clear selected features"
          id="checkboxes-tags-demo"
          options={uiFeaturesFound}
          onChange={onChange}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={renderOption}
          disabled={allFeaturesChecked || !uiFeaturesFound.length}
          popupIcon={renderPopupIcon()}
          isOptionEqualToValue={(option, value) =>
            option.attribute === value.attribute
          }
          ChipProps={{
            className: classes.chip,
            deleteIcon: <CloseIcon className={classes.chipCloseIcon} />,
          }}
          renderInput={(params) => (
            <TextField {...params} label="Ui Features" />
          )}
          PopperComponent={SelectAllButton}
          PaperComponent={CustomPaper}
        />
      </Box>
    </Grid>
  );
};
