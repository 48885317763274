/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from "react-jss";
import { palette } from "./palette";

export const useTabStyles = createUseStyles({
  tabs: {
    borderBottom: `1px solid ${palette.nebulaNavy50}`,
  },
  tab: {
    color: palette.nebulaNavy200,
    textTransform: "initial",
    minHeight: "52px",
    paddingBottom: "15px",
    paddingLeft: "16px",
    paddingRight: "36px",
    minWidth: "0",
    "&:hover": {
      color: palette.purple,
      transition: "color 100ms linear",
    },
  },
  label: {
    marginLeft: "0.75rem",
    fontSize: "0.875rem",
    lineHeight: "1rem",
    letterSpacing: "1.25px",
    fontWeight: 600,
  },
  labelError: {
    color: palette.error,
  },
  selected: {
    cursor: "default",
  },
});
