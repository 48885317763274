/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { SingleQueryDetails } from "../../../api/queryApi";
import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import capitalize from "lodash/capitalize";
import { StatusIcon } from "../../../components/icon/StatusIcon";

const useStyles = createUseStyles({
  queryStatus: {
    fontSize: "0.875rem",
    lineHeight: "1.5rem",
    letterSpacing: "0.5px",
    marginLeft: "12px",
  },
  icon: {
    fontSize: "1.313rem",
  },
});

interface QueryStatusSummaryProps {
  query: SingleQueryDetails;
}

export const QueryStatusSummary: React.FunctionComponent<
  QueryStatusSummaryProps
> = ({ query }) => {
  const classes = useStyles();

  const statusText = useMemo(() => {
    return capitalize(query.state);
  }, [query.state]);

  return (
    <>
      <StatusIcon state={query.state} className={classes.icon} />
      <Box className={classes.queryStatus}>{statusText}</Box>
    </>
  );
};
