/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { QueryStageTableHeader } from "./QueryStageTableHeader";
import { computeRate, formatBuffer, formatTimeDuration } from "./stagesUtil";
import { TaskDetail } from "../../../../api/queryApi";
import { NoData } from "../../../../components/error/NoData";
import { SortingState } from "../../../../components/table/SortableColumn";
import orderBy from "lodash/orderBy";
import clsx from "clsx";
import capitalize from "lodash/capitalize";
import { abbreviateNumber } from "../../../../utils/abbreviateNumber";
import { formatBytes } from "../../../../utils/formatBytes";

const useStyles = createUseStyles({
  root: {
    width: "100%",
  },
  title: {
    flex: "100%",
    textAlign: "left",
  },
  paper: {
    width: "100%",
    marginBottom: "0.625rem",
  },
  stateIcon: {
    fontSize: "1.313rem",
  },
  splitsText: {
    paddingLeft: "1.125rem !important",
  },
  noRowBorder: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  tableContainer: {
    border: "1px solid #E4E7EF",
  },
  tableRow: {
    "& td,th": {
      padding: "12px",
    },
    "& td:first-child,th:first-child": {
      paddingLeft: "16px",
    },
    "&:hover": {
      backgroundColor: "rgba(188,194,216, 0.15)!important",
    },
  },
});

interface QueryStageTaskTableProps {
  tasks: TaskDetail[];
}

export const QueryStageTaskTable: React.FunctionComponent<
  QueryStageTaskTableProps
> = ({ tasks }) => {
  const classes = useStyles();
  const [sorting, setSorting] = useState<SortingState<keyof TaskDetail>>({
    sortBy: "id",
    sortOrder: "asc",
  });
  const taskDetailMapping = (): TaskDetail[] => {
    tasks.forEach((task) => {
      task.bytesPerSecond = computeRate(task.bytesRead, task.elapsedTime);
      task.rowsPerSecond = computeRate(task.rowsRead, task.elapsedTime);
    });
    return tasks;
  };

  return (
    <div className={classes.root}>
      {taskDetailMapping().length === 0 ? (
        <NoData height={200} icon={"table"} text={"No tasks to display"} />
      ) : (
        <TableContainer className={classes.tableContainer}>
          <Table size={"medium"}>
            <QueryStageTableHeader
              sorting={sorting}
              setSorting={setSorting}
              className={classes.tableRow}
            />
            <TableBody>
              {orderBy(
                taskDetailMapping(),
                [sorting.sortBy],
                [sorting.sortOrder]
              ).map((row) => {
                return (
                  <TableRow
                    hover
                    key={row.id}
                    className={clsx(classes.noRowBorder, classes.tableRow)}
                  >
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.nodeHost}</TableCell>
                    <TableCell>{capitalize(row.state)}</TableCell>
                    <TableCell className={classes.splitsText}>
                      {row.queuedSplits}
                    </TableCell>
                    <TableCell className={classes.splitsText}>
                      {row.runningSplits}
                    </TableCell>
                    <TableCell className={classes.splitsText}>
                      {row.blockedSplits}
                    </TableCell>
                    <TableCell className={classes.splitsText}>
                      {row.completedSplits}
                    </TableCell>
                    <TableCell>{abbreviateNumber(row.rowsRead)}</TableCell>
                    <TableCell>{abbreviateNumber(row.rowsPerSecond)}</TableCell>
                    <TableCell>{formatBytes(row.bytesRead)}</TableCell>
                    <TableCell>
                      {abbreviateNumber(row.bytesPerSecond)}
                    </TableCell>
                    <TableCell>{formatTimeDuration(row.elapsedTime)}</TableCell>
                    <TableCell>{formatTimeDuration(row.cpuTime)}</TableCell>
                    <TableCell>{formatBuffer(row.bytesBuffered)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
