/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";
import { createUseStyles } from "react-jss";
import BookmarkMaterialIcon from "@mui/icons-material/Bookmark";
import clsx from "clsx";
import { palette } from "../../../themes/palette";
import { manageBookmark } from "../../../api/dataProduct/dataProductApi";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    cursor: "pointer",
    fontSize: "1.125rem",
  },
  active: {
    color: palette.nebulaNavy700,
  },
  inactive: {
    color: palette.white,
  },
});

type BookmarkWrapper = FunctionComponent<BookmarkWrapperProps>;

interface BookmarkDescriptor {
  isBookmarked: boolean;
  bookmarkCount?: number;
}

interface BookmarkDataProductProps extends BookmarkDescriptor {
  dataProductId: string;
  Wrapper?: BookmarkWrapper;
  className?: string;
}

export const BookmarkDataProduct: FunctionComponent<
  BookmarkDataProductProps
> = ({
  isBookmarked,
  bookmarkCount,
  dataProductId,
  Wrapper = BookmarkToolTipWrapper,
  className,
}: BookmarkDataProductProps) => {
  const styles = useStyles();
  const handleBookmarkChange = useCallback(() => {
    return manageBookmark(dataProductId, !isBookmarked);
  }, [dataProductId, isBookmarked]);
  return (
    <div
      className={clsx(styles.root, className, {
        [styles.active]: isBookmarked,
        [styles.inactive]: !isBookmarked,
      })}
      onClick={handleBookmarkChange}
    >
      <Wrapper isBookmarked={isBookmarked} bookmarkCount={bookmarkCount}>
        <BookmarkIcon isActive={isBookmarked} />
      </Wrapper>
    </div>
  );
};

interface BookmarkWrapperProps extends BookmarkDescriptor {
  children: React.ReactElement;
}

const BookmarkToolTipWrapper: BookmarkWrapper = ({
  isBookmarked,
  children,
}: BookmarkWrapperProps) => (
  <Tooltip
    title={isBookmarked ? "Remove bookmark" : "Bookmark this data product"}
    placement="top-start"
    arrow
  >
    <div>{children}</div>
  </Tooltip>
);

const useBookMarkSimpleWrapperStyles = createUseStyles({
  root: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    fontSize: "0.625rem",
    letterSpacing: "1.5px",
    lineHeight: "16px",
    fontWeight: 600,
  },
  active: {
    color: palette.nebulaNavy700,
  },
  inactive: {
    color: palette.nebulaNavy200,
  },
  bookMarkCount: {
    marginLeft: "0.5rem",
    fontVariantNumeric: "tabular-nums",
  },
});

export const BookMarkSimpleWrapper: BookmarkWrapper = ({
  isBookmarked,
  children,
  bookmarkCount,
}: BookmarkWrapperProps) => {
  const styles = useBookMarkSimpleWrapperStyles();

  return (
    <div
      className={clsx(styles.root, {
        [styles.active]: isBookmarked,
        [styles.inactive]: !isBookmarked,
      })}
    >
      {children}
      <span>
        Bookmarked
        <span className={styles.bookMarkCount}>{bookmarkCount}</span>
      </span>
    </div>
  );
};

interface BookmarkIconProps {
  isActive: boolean;
}

export const BookmarkIcon: FunctionComponent<BookmarkIconProps> = ({
  isActive,
}: BookmarkIconProps) => (
  <BookmarkMaterialIcon
    viewBox="4 0 24 24"
    htmlColor={isActive ? palette.nebulaNavy700 : palette.white}
    stroke={isActive ? palette.nebulaNavy700 : palette.nebulaNavy100}
    strokeWidth="2"
  />
);
