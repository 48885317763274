/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../../../themes/palette";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import { EntityDto, Expression } from "../../../../api/biac/biacApi";

const useStyles = createUseStyles({
  row: {
    padding: "8px 8px 8px 16px",
    fontSize: "0.875rem",
    borderTop: `1px solid ${palette.black12}`,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  buttonIcon: {
    color: palette.error,
    width: "1rem",
  },
  truncated: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: "0.5rem",
  },
  entity: {
    fontFamily: "Barlow",
    fontWeight: 700,
  },
  expressionName: {
    fontFamily: "Barlow",
    fontWeight: 400,
  },
  expressionBody: {
    color: palette.nebulaNavy300,
    fontWeight: 400,
    fontFamily: "Monaco, monospace",
  },
});

interface ExpressionEntryProps {
  doDelete: () => Promise<void>;
  onDeleteSuccess(): void;
  onDeleteFailure(e: Error): void;
  expression: Expression;
  entityDto: EntityDto;
}

export const ExpressionEntry: React.FunctionComponent<ExpressionEntryProps> = ({
  doDelete,
  onDeleteSuccess,
  onDeleteFailure,
  expression,
  entityDto,
}) => {
  const classes = useStyles();
  const [busy, setBusy] = useState<boolean>(false);

  const deleteEntry = useCallback(() => {
    setBusy(true);

    Promise.all([
      doDelete(),
      new Promise<void>((resolve) => setTimeout(resolve, 700)), // minimum wait time for avoiding spinner flash
    ])
      .then(() => onDeleteSuccess())
      .catch((e) => {
        onDeleteFailure(e);
        setBusy(false);
      });
  }, [onDeleteSuccess, onDeleteFailure, doDelete]);

  const entity = useMemo(() => {
    return (
      <Grid container spacing={0.3}>
        <Grid item xs={12} className={clsx(classes.truncated, classes.entity)}>
          <Tooltip
            title={entityDto.printableValue.entityText}
            disableInteractive
          >
            <span>{entityDto.printableValue.entityText}</span>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          className={clsx(classes.truncated, classes.expressionName)}
        >
          <Tooltip title={expression.name} disableInteractive>
            <span>{expression.name}</span>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={12}
          className={clsx(classes.truncated, classes.expressionBody)}
        >
          <Tooltip title={expression.expression} disableInteractive>
            <span>{expression.expression}</span>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }, [expression]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.row}
    >
      <Grid container>
        <Grid item xs={11}>
          {entity}
        </Grid>
        <Grid
          item
          container
          xs={1}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Grid item>
            {busy ? (
              <CircularProgress color="secondary" thickness={4} size={20} />
            ) : (
              <IconButton size="small" onClick={deleteEntry}>
                <FontAwesomeIcon
                  className={classes.buttonIcon}
                  icon={faTrash}
                />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
