/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";

import {
  DataDomain,
  updateDataDomain,
} from "../../../../api/dataProduct/dataDomain/dataDomainApi";
import { palette } from "../../../../themes/palette";
import { ErrorIndicator } from "../../../../components/error/ErrorIndicator";
import { DeleteDomainModal } from "../DeleteDomainModal";
import {
  createSetStateAction,
  useDomainFormReducer,
} from "../form/useDomainFormReducer";
import { DomainForm } from "../form/DomainForm";
import {
  isFormChanged,
  mapToDataDomain,
  mapToDataDomainFormData,
} from "../form/domainFormService";
import { ReassignDomain } from "./ReassignDomain";
import DeleteOutline from "@mui/icons-material/DeleteOutline";

const useStyles = createUseStyles({
  title: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "1rem",
    fontWeight: 500,
  },
  tip: {
    fontSize: "0.75rem",
    color: palette.black54,
    padding: "1rem 0",
  },
  description: {
    marginTop: "1rem",
  },
  buttonsContainer: {
    padding: "1rem 0",
    display: "flex",
    justifyContent: "end",
  },
  deleteButton: {
    color: palette.error,
    fontSize: "0.8125rem",
    marginRight: "auto",
    "& svg": {
      marginRight: "0.25rem",
    },
  },
});

interface EditDomainProps {
  domain: DataDomain;
  allDomains: DataDomain[];
  className?: string;
  onCancel: () => void;
}

export const EditDomain: React.FunctionComponent<EditDomainProps> = ({
  domain,
  allDomains,
  className,
  onCancel,
}: EditDomainProps) => {
  const styles = useStyles();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [editedDomainForm, dispatch] = useDomainFormReducer(
    mapToDataDomainFormData(domain)
  );
  useEffect(() => {
    dispatch(createSetStateAction(mapToDataDomainFormData(domain)));
    setErrorMessage(undefined);
  }, [domain.id]);

  const handleEditionConfirmation = useCallback(() => {
    updateDataDomain(mapToDataDomain(editedDomainForm, domain))
      .then(onCancel)
      .catch(({ message }) => setErrorMessage(message));
  }, [editedDomainForm, domain]);

  return (
    <div className={className}>
      <div className={styles.title}>
        <div>Edit: {domain.name}</div>
        <IconButton size="small" onClick={onCancel}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </div>
      <div className={styles.tip}>
        Changes to domain will effect all data products listed below.
      </div>
      <DomainForm
        formData={editedDomainForm}
        dispatch={dispatch}
        isEditMode={true}
        error={<ErrorIndicator text={errorMessage} />}
      />
      <ReassignDomain domain={domain} allDomains={allDomains} />
      <div className={styles.buttonsContainer}>
        {!domain.assignedDataProducts.length && (
          <DeleteDomainModal
            domain={domain}
            opener={(openDeleteDialog) => (
              <Button
                className={styles.deleteButton}
                size="small"
                onClick={openDeleteDialog}
              >
                <DeleteOutline />
                Delete domain
              </Button>
            )}
          />
        )}
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={onCancel}
          style={{ marginRight: "1rem" }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="small"
          onClick={handleEditionConfirmation}
          disabled={!isFormChanged(editedDomainForm, domain)}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};
