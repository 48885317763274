/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { palette } from "../../../../themes/palette";

interface MultiSelectProps {
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: (newArray: string[]) => void;
  label: string;
}

const useStyles = createUseStyles({
  select: {
    marginRight: "18px",
    marginTop: "1rem",
    height: "36px",
    width: "160px",
  },
  menu: {
    maxHeight: "300px",
  },
  label: {
    color: palette.black54,
    fontSize: "0.875rem",
  },
  selectInput: {
    "&:focus": {
      backgroundColor: "transparent",
    },
    fontSize: "0.875rem",
  },
});

export const MultiSelect: React.FunctionComponent<MultiSelectProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
  label,
}) => {
  const classes = useStyles();

  const renderValue = (selected: unknown) => {
    if (!selected) {
      return null;
    }
    if ((selected as string[]).length === 0 || options.length === 0) {
      return <div className={classes.label}>{label}</div>;
    }
    return (selected as string[]).join(", ");
  };

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedOptions(event.target.value as string[]);
  };

  return (
    <Select
      id="multiple-select"
      variant="outlined"
      displayEmpty
      multiple
      autoWidth
      value={selectedOptions}
      className={classes.select}
      onChange={handleChange}
      renderValue={renderValue}
      classes={{ select: classes.selectInput }}
      MenuProps={{ classes: { paper: classes.menu } }}
    >
      {options.map((name) => (
        <MenuItem key={name} value={name}>
          <Checkbox checked={selectedOptions.indexOf(name) > -1} />
          <ListItemText primary={name} />
        </MenuItem>
      ))}
    </Select>
  );
};
