/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { DataProductState } from "../../../api/dataProduct/dataProductApi";
import { palette } from "../../../themes/palette";

interface DatasetStatusProps {
  status: DataProductState | undefined;
  markedForDeletion: boolean | undefined;
}

const useStyles = createUseStyles({
  status: {
    fontSize: "0.75rem",
    borderRadius: "4px",
    minWidth: "5rem",
    marginRight: "2rem",
    padding: "0.1rem 0.5rem",
    borderStyle: "solid",
    borderWidth: "thin",
    textAlign: "center",
  },
  draft: {
    color: palette.successDark,
    backgroundColor: palette.successLight,
  },
  pending: {
    color: palette.warningDark,
    backgroundColor: palette.warningLight,
  },
  deleted: {
    color: palette.errorDark,
    backgroundColor: palette.errorLight,
  },
});

export const DatasetStatus: React.FunctionComponent<DatasetStatusProps> = ({
  status,
  markedForDeletion,
}) => {
  const classes = useStyles();

  if (markedForDeletion) {
    return (
      <div className={`${classes.status} ${classes.deleted}`}>DELETED</div>
    );
  }
  if (status === DataProductState.DRAFT) {
    return <div className={`${classes.status} ${classes.draft}`}>NEW!</div>;
  }

  if (status === DataProductState.PENDING_CHANGES) {
    return (
      <div className={`${classes.status} ${classes.pending}`}>CHANGED</div>
    );
  }

  return <div />;
};
