/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useRef } from "react";
import ChartComponent, {
  ChartComponentProps,
  HorizontalBar,
} from "react-chartjs-2";
import Chart from "chart.js";
import { chartTooltip } from "./tooltip/ChartTooltip";
import { TooltipContext } from "./tooltip/ChartTooltipContainer";
import { convertRem } from "../../utils/fontSize";
import { abbreviateWholeNumbers } from "../../utils/abbreviateNumber";
import { getTimeAxisFormatter } from "../../utils/timeAxis";

const maxLabelWidth = 88;
const maxLabelCharacters = 13;

interface HorizontalBarChartProps {
  data: Chart.ChartData;
  tooltipText: string;
  height: number;
  xLabelFormatter?: "duration" | ((label: number) => string);
  yLabelFormatter?: (label: string) => string;
  tooltipValueFormatter?: (value: number) => string;
}

Chart.Tooltip.positioners.horizontalBars = function (elements) {
  const tooltipPosition = elements[0].tooltipPosition();
  return {
    x: (tooltipPosition.x + maxLabelWidth) / 2,
    y: tooltipPosition.y - 5,
  };
};

export const HorizontalBarChart: React.FunctionComponent<
  HorizontalBarChartProps
> = ({
  data: inputData,
  tooltipText,
  height,
  tooltipValueFormatter: inputTooltipValueFormatter = (
    value: number | string | undefined
  ): string => value?.toLocaleString() ?? "-",
  xLabelFormatter: inputXLabelFormatter = abbreviateWholeNumbers,
  yLabelFormatter = (label: string) => label,
}) => {
  const chartRef = useRef<ChartComponent<ChartComponentProps> | null>(null);
  const { containerId: tooltipContainerId } = useContext(TooltipContext) || {};

  const datasets = inputData.datasets?.map((dataset) => ({
    ...dataset,
    maxBarThickness: 25,
    minBarLength: 5,
  }));

  const [stepSize, xLabelFormatter] =
    inputXLabelFormatter === "duration"
      ? getTimeAxisFormatter(inputData)
      : [undefined, inputXLabelFormatter];

  const tooltipValueFormatter = (input: string | number | undefined) => {
    if (typeof input === "string") {
      return input;
    } else if (input === undefined) {
      return "-";
    }

    return inputTooltipValueFormatter(input);
  };

  return (
    <div style={{ height }}>
      <HorizontalBar
        ref={chartRef}
        data={{ datasets, labels: inputData.labels }}
        options={{
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
            position: "horizontalBars",
            custom: chartTooltip(
              chartRef,
              tooltipContainerId,
              tooltipValueFormatter
            ),
            callbacks: {
              title(): string {
                return tooltipText;
              },
              label: function (tooltipItem): string {
                return `${tooltipItem.yLabel}:${tooltipItem.xLabel}`;
              },
            },
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  fontSize: convertRem(0.75),
                  padding: 2,
                  callback: function (value) {
                    const textValue = yLabelFormatter(`${value}`);
                    if (textValue.length > maxLabelCharacters) {
                      return (
                        `${value}`.substr(0, maxLabelCharacters - 3) + "..."
                      );
                    }
                    return textValue;
                  },
                },
                gridLines: {
                  display: false,
                },
                afterFit: function (scaleInstance) {
                  scaleInstance.width = maxLabelWidth;
                },
              },
            ],
            xAxes: [
              {
                gridLines: {
                  drawBorder: false,
                  zeroLineWidth: 2,
                },
                ticks: {
                  beginAtZero: true,
                  fontSize: convertRem(0.75),
                  padding: 5,
                  callback: xLabelFormatter,
                  stepSize,
                  maxTicksLimit: 11,
                  autoSkipPadding: 10,
                },
              },
            ],
          },
          plugins: {
            datalabels: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
};
