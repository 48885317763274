/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Tooltip from "@mui/material/Tooltip";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

interface CopyToClipboardProps {
  text: string;
  children: ReactElement<HTMLElement>;
  tooltip?: string;
}

export const Clipboard: React.FunctionComponent<CopyToClipboardProps> = ({
  text,
  children,
  tooltip = "Copy to clipboard",
}) => {
  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const onClose = (): void => {
    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 200);
  };

  return (
    <CopyToClipboard text={text} onCopy={(): void => setCopied(true)}>
      <Tooltip title={copied ? "Copied!" : tooltip} onClose={onClose} arrow>
        {children}
      </Tooltip>
    </CopyToClipboard>
  );
};
