/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, useState } from "react";
import { createUseStyles } from "react-jss";
import Typography from "@mui/material/Typography";
import { createDataProductComment$ } from "../../../api/dataProduct/comment/commentApi";
import { Spinner } from "../../../components/spinner/Spinner";
import { ErrorIndicator } from "../../../components/error/ErrorIndicator";
import { NoData } from "../../../components/error/NoData";
import { Comment } from "./Comment";
import { useDiscussionContext } from "./DiscussionContext";
import { CommentContentEditor } from "./CommentContentEditor";
import { useCallback$ } from "../util/useCallback";

const useStyles = createUseStyles({
  root: {
    marginRight: "1rem",
  },
  title: {
    fontWeight: 600,
    marginLeft: "0px",
  },
  newCommentEditor: {
    marginBottom: "1rem",
  },
});

interface DataProductDiscussionProps {
  dataProductId: string;
}
export const DataProductDiscussion: FunctionComponent<
  DataProductDiscussionProps
> = ({ dataProductId }: DataProductDiscussionProps) => {
  const styles = useStyles();
  const {
    isFetching,
    isError,
    data: comments,
    errorMessage,
  } = useDiscussionContext();
  const [commentInput, setCommentInput] = useState("");
  const addComment = useCallback$(
    () =>
      createDataProductComment$(dataProductId, commentInput).subscribe(() =>
        setCommentInput("")
      ),
    [commentInput, dataProductId]
  );

  return (
    <div className={styles.root}>
      <Typography variant="h4" className={styles.title}>
        Discussion
      </Typography>
      <CommentContentEditor
        className={styles.newCommentEditor}
        content={commentInput}
        onContentChange={setCommentInput}
        onConfirm={addComment}
        onCancel={() => setCommentInput("")}
      />
      {isFetching ? (
        <Spinner size={150} position="absolute" />
      ) : isError ? (
        <ErrorIndicator text={errorMessage} />
      ) : comments?.length ? (
        comments.map(({ id: commentId, ...properties }) => (
          <Comment key={commentId} commentId={commentId} {...properties} />
        ))
      ) : (
        <NoData height={250} text="No comments" icon="table" />
      )}
    </div>
  );
};
