/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { QueryTextSection } from "./QueryTextSection";
import { QueryDetailsSection } from "./QueryDetailsSection";
import { QueryTimeline } from "./QueryTimeline";
import React, { useMemo } from "react";
import { SingleQueryDetails } from "../../../../api/queryApi";
import { QueryStateDetailsSection } from "./QueryStateDetailsSection";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { isLakeAccelerationInfoAvailable } from "../computeLakeAcceleration";
import { QueryAccelerationSection } from "./QueryAccelerationSection";

interface QueryGeneralTabProps {
  query: SingleQueryDetails;
}

const useStyles = createUseStyles((theme: Theme) => ({
  left: {
    [theme.breakpoints.up("lg")]: {
      paddingRight: "32px",
    },
  },
}));
export const QueryGeneralTab: React.FunctionComponent<QueryGeneralTabProps> = ({
  query,
}) => {
  const shouldDisplayLakeAccelerationData = useMemo(
    () => isLakeAccelerationInfoAvailable(query),
    [query]
  );

  const classes = useStyles();
  return (
    <Box my={3}>
      <Grid container spacing={1}>
        <Grid item xl={7} lg={6} xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            className={classes.left}
          >
            <QueryTextSection text={query.queryText} />
            <QueryDetailsSection query={query} />
            {shouldDisplayLakeAccelerationData && (
              <QueryAccelerationSection query={query} />
            )}
          </Box>
        </Grid>
        <Grid item xl={5} lg={6} xs={12}>
          <QueryTimeline query={query} />
        </Grid>
        {query.failureReason && (
          <Grid item xs={12}>
            <Box mb={1}>
              <Divider />
            </Box>
            <QueryStateDetailsSection
              state={query.state}
              queryText={query.queryText}
              failureReason={query.failureReason}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
