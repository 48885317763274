/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import {
  DetailsCell,
  DetailsCellHeader,
} from "../../../components/details/DetailsCell";
import { DetailsRow } from "../../../components/details/DetailsRow";
import { calculateCost, getCpuCost, saveCpuCost } from "./costManagerService";

interface CostManagerDetailRowProps {
  cpuUsageSeconds: number;
  classes?: {
    value?: string;
  };
}

export const CostManagerDetailRow: React.FunctionComponent<
  CostManagerDetailRowProps
> = ({ cpuUsageSeconds, classes = {} }: CostManagerDetailRowProps) => {
  const [hourCost, setHourCost] = useState(getCpuCost());
  const onHourCostChange = useCallback(({ target: { value } }) => {
    if (!isNaN(Number(value))) {
      saveCpuCost(value);
    }
    setHourCost(value);
  }, []);
  const [totalCost, setTotalCost] = useState("");
  useEffect(
    () => setTotalCost(calculateCost(cpuUsageSeconds, hourCost)),
    [cpuUsageSeconds, hourCost]
  );

  return (
    <DetailsRow>
      <Grid item xs={6}>
        <DetailsCellHeader text="COST FACTOR" tooltip="vCPU/hour" />
        <OutlinedInput
          value={hourCost}
          onChange={onHourCostChange}
          inputProps={{
            style: {
              padding: "0.375rem 0.25rem",
            },
          }}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
          placeholder="0.00"
        />
      </Grid>
      <DetailsCell
        header="Estimated Cost"
        value={totalCost}
        classes={{ value: classes.value }}
        xs={6}
      />
    </DetailsRow>
  );
};
