/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { palette } from "../../../themes/palette";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  title: {
    textAlign: "center",
  },
  icon: {
    color: palette.error,
    boxShadow: "unset",
  },
});

interface ChooserErrorIconProps {
  title: string;
  error?: string;
}

export const ChooserErrorIcon: React.FunctionComponent<
  ChooserErrorIconProps
> = ({ title, error }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={
        <>
          <div className={classes.title}>{title}</div>
          {error && <div>{error}</div>}
        </>
      }
    >
      <span>
        <ErrorOutlineIcon className={classes.icon} />
      </span>
    </Tooltip>
  );
};
