/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { mergeLines, prepareLineChartData } from "../../../utils/lineChart";
import { defaultChartPalette } from "../../../themes/palette";
import { ChartWrapper } from "../../../components/chart/ChartWrapper";
import {
  HighlightAreaProps,
  TimeLineChart,
} from "../../../components/chart/TimeLineChart";
import startCase from "lodash/startCase";
import { formatDateInterval } from "../../../utils/formatDateInterval";

interface ClusterQueriesChartProps {
  queries:
    | Array<{
        sampleIntervalStart: string;
        sampleIntervalEnd: string;
        runningQueries: number;
        queuedQueries: number;
      }>
    | "loading"
    | "error";
  height: number;
  highlightArea?: HighlightAreaProps;
}

export const ClusterQueriesChart: React.FunctionComponent<
  ClusterQueriesChartProps
> = ({ queries, height, highlightArea }) => {
  const running = prepareLineChartData(
    "Queries running",
    queries,
    "sampleIntervalStart",
    ({ runningQueries }) => runningQueries,
    { fill: "origin", color: defaultChartPalette[0] }
  );

  const queued = prepareLineChartData(
    "Queries queued",
    queries,
    "sampleIntervalStart",
    ({ queuedQueries }) => queuedQueries,
    { fill: "origin", color: defaultChartPalette[1] }
  );

  const data = mergeLines([running, queued]);

  return (
    <ChartWrapper
      data={data}
      title="Queries"
      height={height}
      legend={{
        type: "dataset",
        variant: "inline",
        valueFormatter: () => null,
        labelFormatter: (inputLabel) =>
          startCase(inputLabel.replace("Queries", "")).trim(),
      }}
    >
      {(chartData): React.ReactNode => (
        <TimeLineChart
          data={chartData}
          height={height}
          yTicksLimit={3}
          xTicksLimit={5}
          xTicksMaxRotation={0}
          yMax={2}
          tooltipDateFormat={formatDateInterval(
            queries,
            ({ sampleIntervalStart }) => sampleIntervalStart,
            ({ sampleIntervalEnd }) => sampleIntervalEnd
          )}
          labelWidth={70}
          highlightArea={highlightArea}
        />
      )}
    </ChartWrapper>
  );
};
