/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import NavigateNext from "@mui/icons-material/NavigateNext";

interface LoadMoreIntersectionButtonProps {
  onLoadMore: () => void;
}

export const LoadMoreIntersectionButton = ({
  onLoadMore,
}: LoadMoreIntersectionButtonProps) => {
  const [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (buttonRef) {
      const observer = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio > 0) {
          onLoadMore();
        }
      });
      observer.observe(buttonRef);
      return () => observer.disconnect();
    }
  }, [buttonRef, onLoadMore]);

  return (
    <IconButton onClick={onLoadMore} size="large" ref={setButtonRef}>
      <NavigateNext />
    </IconButton>
  );
};
