/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { createUseStyles } from "react-jss";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction, useCallback, useState } from "react";
import { DataProductLink } from "../../../api/dataProduct/dataProductApi";
import { SimpleTable } from "../components/SimpleTable";
import { LinkAction } from "./publishFormReducer";
import { maximumTitleLength } from "../util/formUtils";
import Typography from "@mui/material/Typography";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";
import { useTextInputValueChange } from "../../../utils/useTextInputValueChange";
import { SimpleDialog } from "../../../components/dialog/SimpleDialog";

interface DataProductLinksProps {
  links?: DataProductLink[];
  linkDispatch: Dispatch<LinkAction>;
}

const useStyles = createUseStyles({
  tableLabelColumn: {
    width: "30%",
  },
});

export const DataProductLinks: React.FunctionComponent<
  DataProductLinksProps
> = ({ links, linkDispatch }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant={"h5"}>Relevant links</Typography>
      <Grid container>
        <Grid item xs={12}>
          {links && (
            <SimpleTable<DataProductLink>
              rows={links}
              idGenerator={(_, index) => index}
              columns={["label", "url"]}
              deleteRow={(link) =>
                linkDispatch({
                  type: "deleteLink",
                  link,
                })
              }
              classes={{
                columns: {
                  label: classes.tableLabelColumn,
                },
              }}
            />
          )}
        </Grid>
      </Grid>
      <AddLink
        onAdd={(newLink) => {
          linkDispatch({
            type: "addLink",
            link: newLink,
          });
          return Promise.resolve();
        }}
      />
    </>
  );
};

const useAddLinkClasses = createUseStyles({
  addIcon: {
    marginRight: "0.625rem",
  },
  addButton: {
    marginBottom: "2rem",
  },
});
interface AddLinkProps {
  onAdd: (owner: DataProductLink) => Promise<void>;
}

export const AddLink = ({ onAdd }: AddLinkProps) => {
  const internalClasses = useAddLinkClasses();
  const { isOpen, close, open } = useDialogOpenState();
  return (
    <>
      <Button
        variant="text"
        color="primary"
        className={internalClasses.addButton}
        onClick={open}
      >
        <FontAwesomeIcon icon={faPlus} className={internalClasses.addIcon} />
        Add link
      </Button>
      {isOpen && <AddLinkDialog isOpen={isOpen} close={close} onAdd={onAdd} />}
    </>
  );
};

interface AddLinkDialogProps {
  isOpen: boolean;
  close: () => void;
  onAdd: (link: DataProductLink) => Promise<void>;
}

const AddLinkDialog = ({ isOpen, close, onAdd }: AddLinkDialogProps) => {
  const [currentLink, setCurrentLink] = useState<DataProductLink>({
    label: "",
    url: "",
  });
  const handleConfirm = useCallback(
    () => onAdd(currentLink),
    [currentLink, onAdd]
  );
  const isConfirmationButtonDisabled =
    !isUrlValid(currentLink.url) || !isLabelValid(currentLink.label);
  return (
    <SimpleDialog<AddLinkFormProps>
      isOpen={isOpen}
      close={close}
      title="Add relevant link"
      confirmButtonLabel="Add link"
      onConfirm={handleConfirm}
      isConfirmationButtonDisabled={isConfirmationButtonDisabled}
      fullWidth={true}
      contentProps={{
        url: currentLink.url,
        label: currentLink.label,
        dispatchLink: setCurrentLink,
      }}
      Content={AddLinkForm}
    />
  );
};

interface AddLinkFormProps extends DataProductLink {
  dispatchLink: Dispatch<SetStateAction<DataProductLink>>;
}

const AddLinkForm = ({ url, label, dispatchLink }: AddLinkFormProps) => {
  const handleUrlChange = useTextInputValueChange(
    (newUrl) =>
      dispatchLink((prevState) => ({
        ...prevState,
        url: newUrl,
      })),
    [dispatchLink]
  );
  const handleLabelChange = useTextInputValueChange(
    (newLabel) =>
      dispatchLink((prevState) => ({
        ...prevState,
        label: newLabel,
      })),
    [dispatchLink]
  );
  return (
    <>
      <TextField
        value={label}
        label="Label"
        onChange={handleLabelChange}
        autoFocus
        margin="dense"
        id="label"
        fullWidth
        variant="outlined"
        required
        inputProps={{ maxLength: maximumTitleLength }}
      />
      <TextField
        value={url}
        label="Url"
        onChange={handleUrlChange}
        margin="dense"
        id="url"
        type="url"
        helperText={
          isUrlValid(url)
            ? undefined
            : "The url must start with http:// or https://"
        }
        error={!!url && !isUrlValid(url)}
        fullWidth
        variant="outlined"
        required
      />
    </>
  );
};

function isUrlValid(url: string): boolean {
  return url.startsWith("http://") || url.startsWith("https://");
}

function isLabelValid(label: string): boolean {
  return label.trim().length > 0;
}
