/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { Expression, getExpressions } from "../../../../../api/biac/biacApi";
import { Persisted } from "../../../../../api/biac/common";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { palette } from "../../../../../themes/palette";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@mui/material/IconButton";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CREATE_NEW_ID,
  initialFields,
  MaskOrFilterData,
} from "./addition-privileges-constants";
import { EmptyOrValue } from "../../../../../utils/value";
import { useQueryClient } from "../../../useQueryClient";
import sortBy from "lodash/sortBy";
import CircularProgress from "@mui/material/CircularProgress";
import { ChooserErrorIcon } from "../../../grants/ChooserErrorIcon";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { ColumnOrTableAutoComplete } from "./ColumnOrTableAutoComplete";
import { VirtualizedListBoxComponent } from "../../../grants/VirtualizedListBoxComponent";
import { Tooltip } from "../../../../../components/tooltip/Tooltip";
import { addPrivilegesStyles } from "../../add-privileges-styles";

interface ColumnMaskPrivilegeProps {
  currentRoleName: string;
  catalogName: string | null;
  schemaName: string | null;
  tableName: string;
  columns: EmptyOrValue<string[] | null>;
  handleChange: (newState: MaskOrFilterData[]) => void;
}

const isAllSelected = (value: EmptyOrValue<unknown>) => {
  return !value.empty && value.value === null;
};

export const ColumnMaskPrivilege: React.FunctionComponent<
  ColumnMaskPrivilegeProps
> = ({
  currentRoleName,
  catalogName,
  schemaName,
  tableName,
  columns,
  handleChange,
}) => {
  const [availableColumns, setAvailableColumns] = useState<string[]>([]);
  const [existingColumnMaskExpressions, setExistingColumnMaskExpressions] =
    useState<Persisted<Expression>[]>([]);
  const [selectedColumnMasks, setSelectedColumnMasks] = useState<
    MaskOrFilterData[]
  >([]);
  const fetchExpressions = useCallback(() => {
    getExpressions(currentRoleName, "COLUMN_MASK")
      .then(setExistingColumnMaskExpressions)
      .catch(() => {
        setExistingColumnMaskExpressions([]);
      });
  }, []);
  const classes = addPrivilegesStyles();

  useEffect(() => {
    fetchExpressions();
  }, []);

  const { busy, error, execute, reset } = useQueryClient((data) => {
    const columns = sortBy(data.map<string>(([name]) => name as string));
    setAvailableColumns(columns);
  });
  useEffect(() => {
    setSelectedColumnMasks([]);
    if (catalogName && schemaName && tableName && isAllSelected(columns)) {
      execute(
        `SHOW COLUMNS FROM "${catalogName}"."${schemaName}"."${tableName}"`
      );
      return () => {
        setAvailableColumns([]);
        reset();
      };
    } else {
      setAvailableColumns(columns.value ? columns.value : []);
    }
  }, [columns]);

  const handleSelectedColumns = useCallback(
    (
      event: React.SyntheticEvent,
      value: string | null,
      selectedColumnMaskId: string
    ) => {
      setSelectedColumnMasks((currentColumnMasks) =>
        currentColumnMasks.map((currentColumnMask) =>
          currentColumnMask.id === selectedColumnMaskId
            ? {
                ...currentColumnMask,
                selectedValue: value ? value : "",
              }
            : currentColumnMask
        )
      );
    },
    []
  );

  const handleColumnMaskChange = useCallback(
    (
      event: React.SyntheticEvent,
      value: Persisted<Expression> | null,
      selectedColumnMaskId: string
    ) => {
      const selectedColumnMask = value ? value.id : null;
      const columnMask = existingColumnMaskExpressions?.find(
        (columnMaskExpression) => columnMaskExpression.id === selectedColumnMask
      );
      setSelectedColumnMasks((currentColumnMasks) => {
        return currentColumnMasks.map((currentColumnMask) => {
          if (currentColumnMask.id === selectedColumnMaskId) {
            if (
              selectedColumnMask != null &&
              selectedColumnMask != CREATE_NEW_ID
            ) {
              return {
                ...currentColumnMask,
                oldExpression: {
                  name:
                    columnMask && columnMask.object
                      ? columnMask.object.name
                      : "",
                  sqlExpression:
                    columnMask && columnMask.object
                      ? columnMask.object.expression
                      : "",
                },
                expressionId: selectedColumnMask,
                newExpression: null,
              };
            } else if (selectedColumnMask === null) {
              return {
                ...currentColumnMask,
                expressionId: null,
                oldExpression: null,
                newExpression: null,
              };
            } else {
              return {
                ...currentColumnMask,
                oldExpression: null,
                expressionId: selectedColumnMask,
              };
            }
          } else {
            return currentColumnMask;
          }
        });
      });
    },
    [existingColumnMaskExpressions]
  );

  const validateMaskName = useCallback(
    (
      maskName: string | undefined,
      names: (string | undefined)[]
    ): string | null => {
      if (maskName) {
        if (maskName.startsWith(" ") || maskName.endsWith(" ")) {
          return "Mask name cannot start or end with space";
        } else if (
          existingColumnMaskExpressions.some(
            (columnMaskExpression) =>
              columnMaskExpression.object.name.toLowerCase() ===
              maskName.toLowerCase()
          ) ||
          names.filter((name) => name === maskName).length > 1
        ) {
          return "Mask name already exists";
        } else {
          return null;
        }
      }
      return null;
    },
    [existingColumnMaskExpressions]
  );

  const handleColumnMaskNewName = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      selectedColumnMaskId: string
    ) => {
      const currentVal = event.target.value;
      setSelectedColumnMasks((currentColumnMasks) => {
        const maskNames = currentColumnMasks
          .map((maskName) =>
            maskName.id === selectedColumnMaskId
              ? currentVal
              : maskName.newExpression?.name.toLowerCase()
          )
          .filter(Boolean);

        return currentColumnMasks.map((currentColumnMask) => {
          if (currentColumnMask.id === selectedColumnMaskId) {
            return {
              ...currentColumnMask,
              newExpression: {
                name: currentVal,
                expression: currentColumnMask.newExpression?.expression
                  ? currentColumnMask.newExpression.expression
                  : "",
              },
              errorText: validateMaskName(currentVal, maskNames),
            };
          } else {
            return {
              ...currentColumnMask,
              errorText: validateMaskName(
                currentColumnMask.newExpression?.name,
                maskNames
              ),
            };
          }
        });
      });
    },
    [validateMaskName]
  );

  const handleColumnMaskNewExpression = useCallback(
    (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
      selectedColumnMaskId: string
    ) => {
      setSelectedColumnMasks((currentColumnMasks) =>
        currentColumnMasks.map((currentColumnMask) =>
          currentColumnMask.id === selectedColumnMaskId
            ? {
                ...currentColumnMask,
                newExpression: {
                  name: currentColumnMask.newExpression?.name
                    ? currentColumnMask.newExpression.name
                    : "",
                  expression: event.target.value,
                },
              }
            : currentColumnMask
        )
      );
    },
    []
  );

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: Persisted<Expression>
    ) => {
      return (
        <li {...props} key={option.id}>
          {option.id === CREATE_NEW_ID ? (
            <div style={{ color: palette.nebulaNavy300 }}>
              {option.object.name}
            </div>
          ) : (
            <div>
              <div className={classes.optionLabel}>{option.object.name}</div>
              {/*
              {option.object.description && (
                <div className={classes.optionDescription}>
                  {option.object.description}
                </div>
              )}
*/}
            </div>
          )}
        </li>
      );
    },
    []
  );

  const renderPopupIcon = useCallback(() => {
    if (busy) {
      return <CircularProgress size={20} />;
    } else if (error) {
      return (
        <div>
          <ChooserErrorIcon title="Loading tables and views failed" />
        </div>
      );
    } else {
      return <ArrowDropDownOutlinedIcon />;
    }
  }, [busy, error]);

  const deleteSelectedColumns = useCallback(
    (deletingColumnMask: MaskOrFilterData) => {
      setSelectedColumnMasks((currentColumnMasks) =>
        currentColumnMasks.filter(
          (currentColumnMask) => currentColumnMask.id != deletingColumnMask.id
        )
      );
    },
    []
  );

  useEffect(() => {
    handleChange(selectedColumnMasks);
  }, [selectedColumnMasks]);

  return (
    <>
      <Typography variant="subtitle2" mb={1}>
        Mask Columns
      </Typography>
      <Typography variant="body2" width="34rem">
        Add column masks from existing masks or create a new one by providing a
        new name and expression.
      </Typography>
      {selectedColumnMasks.map((selectedColumnMask) => (
        <Box mt={3} mb={1} display="flex" key={selectedColumnMask.id}>
          <Box pr={1}>
            <ColumnOrTableAutoComplete
              handleChange={handleSelectedColumns}
              options={availableColumns}
              selectedData={selectedColumnMask}
              icon={renderPopupIcon()}
              label="Select column"
            />
          </Box>
          {selectedColumnMask.selectedValue && (
            <>
              <Box pr={1}>
                <Autocomplete
                  renderInput={(params) => (
                    <TextField {...params} label="Select mask" required />
                  )}
                  style={{ width: "16rem" }}
                  getOptionLabel={(option) => option.object.name}
                  renderOption={renderOption}
                  options={existingColumnMaskExpressions}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(event, value) =>
                    handleColumnMaskChange(event, value, selectedColumnMask.id)
                  }
                  ListboxComponent={VirtualizedListBoxComponent}
                />
              </Box>
              {selectedColumnMask.oldExpression && (
                <Tooltip
                  title={selectedColumnMask.oldExpression.sqlExpression}
                  placement="right"
                >
                  <Box pr={1}>
                    <TextField
                      value={selectedColumnMask.oldExpression.sqlExpression}
                      label="SQL Expression"
                      disabled
                      style={{ width: "20rem" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <LockOutlinedIcon color="disabled" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
              {selectedColumnMask.expressionId === CREATE_NEW_ID && (
                <>
                  <Box pr={1}>
                    <TextField
                      label="Enter name for new mask"
                      required
                      style={{ width: "16rem" }}
                      value={
                        selectedColumnMask.newExpression
                          ? selectedColumnMask.newExpression.name
                          : ""
                      }
                      onChange={(e) =>
                        handleColumnMaskNewName(e, selectedColumnMask.id)
                      }
                      inputProps={{ maxLength: 40 }}
                      error={!!selectedColumnMask.errorText}
                      helperText={selectedColumnMask.errorText}
                    />
                  </Box>
                  <Box pr={1}>
                    <TextField
                      label="Enter custom SQL"
                      style={{ width: "16rem" }}
                      required
                      value={
                        selectedColumnMask.newExpression
                          ? selectedColumnMask.newExpression.expression
                          : ""
                      }
                      onChange={(e) =>
                        handleColumnMaskNewExpression(e, selectedColumnMask.id)
                      }
                    ></TextField>
                  </Box>
                </>
              )}
            </>
          )}
          <Box>
            <IconButton
              onClick={() => deleteSelectedColumns(selectedColumnMask)}
            >
              <ClearRoundedIcon color="error" />
            </IconButton>
          </Box>
        </Box>
      ))}
      <Box mt={1}>
        <Button
          variant="text"
          onClick={() => {
            setSelectedColumnMasks((columnMasks) => [
              ...columnMasks,
              {
                id: uuidv4(),
                ...initialFields,
              } as MaskOrFilterData,
            ]);
          }}
        >
          <AddIcon /> &nbsp;Add column mask
        </Button>
      </Box>
    </>
  );
};
