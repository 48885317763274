/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import { createUseStyles } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import Box from "@mui/material/Box";
import { ErrorDialog } from "../../components/error/ErrorDialog";
import { downloadLicenseFileData } from "../../api/licenseApi";
import { getLicenseFile } from "./licenseUtil";

const useStyles = createUseStyles({
  buttonBox: {
    marginTop: "2rem",
  },
  faDownloadIcon: {
    marginRight: "0.5rem",
  },
});

interface ManageLicenseProps {
  enableDownload: boolean;
}

export const ManageLicense: React.FunctionComponent<ManageLicenseProps> = ({
  enableDownload,
}) => {
  const classes = useStyles();
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const downloadLicense = useCallback(() => {
    downloadLicenseFileData()
      .then((data) => {
        getLicenseFile(JSON.stringify(data, null, 2));
      })
      .catch(() => setOpenErrorDialog(true));
  }, []);
  return (
    <>
      <ErrorDialog
        text={"Failed to download current license. Try again later."}
        openErrorDialog={openErrorDialog}
        setOpenErrorDialog={setOpenErrorDialog}
      />
      <Box
        className={classes.buttonBox}
        display="flex"
        justifyContent="center"
        mt={2}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={downloadLicense}
          disabled={enableDownload}
        >
          <FontAwesomeIcon
            icon={faDownload}
            className={classes.faDownloadIcon}
          />
          Download current license
        </Button>
      </Box>
    </>
  );
};
