/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Action,
  Entity,
  EntityCategory,
  Grant,
  EffectType,
  Subject,
  AllAttribute,
  Attribute,
  CategoryAttribute,
  NewExpression,
  ReuseExpression,
  CreateGrantError,
} from "../../../api/biac/biacApi";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  catalogInputVisible,
  entityActions,
  mainRadioButtons,
  schemaInputVisible,
  validActionsForFilters,
} from "./add-privilege-constants";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { createUseStyles } from "react-jss";
import { ChosenRole, RolesSelector } from "./privileges/RolesSelector";
import { EmptyOrValue } from "../../../utils/value";
import { SYSTEM_ROLE_ID } from "../../../api/biac/biacRolesApi";
import { userRolePrivileges } from "../userRolePrivileges";
import { RadioAndDropdownGroup } from "./RadioAndDropdownGroup";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import Switch from "@mui/material/Switch";
import { EntityActions } from "./EntityActions";
import { CancelDialog } from "./dialoges/CancelDialog";
import { SavePrivilegesDialog } from "./dialoges/SavePrivilegesDialog";
import { UsersSelector } from "./privileges/UsersSelector";
import {
  ChosenFeature,
  UIFeaturesSelector,
} from "./privileges/UIFeaturesSelector";
import {
  useAllowedUiFeatures,
  useConfigContext,
} from "../../../app/ConfigContextProvider";
import { FunctionsSelector } from "./privileges/FunctionsSelector";
import { DataProductDomain, DomainSelector } from "./privileges/DomainSelector";
import { DataProductSelector } from "./privileges/DataProductSelector";
import { CatalogSelector } from "./privileges/CatalogSelector";
import { PropertiesSelector } from "./privileges/PropertiesSelector";
import { SchemaSelector } from "./privileges/SchemaSelector";
import { LoadingButton } from "../../../components/LoadingButton";
import { TableSelector } from "./privileges/TableSelector";
import { ProcedureSelector } from "./privileges/ProcedureSelector";
import { ColumnsSelector } from "./privileges/ColumnsSelector";
import { AdditionalPrivileges } from "./privileges/additional-privileges/AdditionalPrivileges";
import { MaskOrFilterData } from "./privileges/additional-privileges/addition-privileges-constants";
import { IsTableFunctionSelector } from "./privileges/IsTableFunctionSelector";

const useStyles = createUseStyles({
  card: {
    boxShadow: "-2px 0px 0px rgba(184, 53, 160, 0.12)",
    borderRadius: 0,
    margin: "1rem 0.75rem 0.5rem 0.5rem",
    backgroundColor: "transparent",
    maxWidth: "fit-content",
  },
});

const createColumnMaskArray = (
  columnMasksOrRowFilterData: MaskOrFilterData[],
  mainAttributes: (CategoryAttribute | Attribute)[],
  columnMaskOrRowFilterArray: (NewExpression | ReuseExpression)[]
) => {
  columnMasksOrRowFilterData.forEach((columnMask) => {
    const attributes: (CategoryAttribute | Attribute)[] = [...mainAttributes];
    if (columnMask.oldExpression || columnMask.newExpression) {
      attributes.push({
        key: "column",
        value: columnMask.selectedValue ? columnMask.selectedValue : "",
      });
      mapExpressions(columnMask, columnMaskOrRowFilterArray, attributes);
    }
  });
};

const createRowFilterArray = (
  columnMasksOrRowFilterData: MaskOrFilterData[],
  mainAttributes: (CategoryAttribute | Attribute)[],
  columnMaskOrRowFilterArray: (NewExpression | ReuseExpression)[]
) => {
  columnMasksOrRowFilterData.forEach((rowFilter) => {
    const attributes: (CategoryAttribute | Attribute)[] = [...mainAttributes];
    if (rowFilter.oldExpression || rowFilter.newExpression) {
      attributes.push({
        key: "table",
        value: rowFilter.selectedValue ? rowFilter.selectedValue : "",
      });
      mapExpressions(rowFilter, columnMaskOrRowFilterArray, attributes);
    }
  });
};

const mapExpressions = (
  columnMaskOrFilter: MaskOrFilterData,
  columnMaskOrRowFilterArray: (NewExpression | ReuseExpression)[],
  attributes: (CategoryAttribute | Attribute)[]
) => {
  if (columnMaskOrFilter.expressionId && columnMaskOrFilter.oldExpression) {
    columnMaskOrRowFilterArray.push({
      entity: { attributes: attributes },
      expressionId: columnMaskOrFilter.expressionId,
      forceNone: false,
    } as ReuseExpression);
  } else if (columnMaskOrFilter.newExpression) {
    columnMaskOrRowFilterArray.push({
      entity: { attributes: attributes },
      newExpression: {
        name: columnMaskOrFilter.newExpression.name,
        expression: columnMaskOrFilter.newExpression.expression,
      },
      forceNone: false,
    } as NewExpression);
  }
};

export const AvailablePrivileges: React.FunctionComponent<{
  currentRoleName: string;
  roleId: number;
  grantOption: boolean;
}> = ({ currentRoleName, roleId, grantOption }) => {
  const classes = useStyles();
  const { create, reload } = userRolePrivileges(currentRoleName, roleId);
  const [radioSelection, setRadioSelection] = useState<string>("tables");
  const [entityCategory, setEntityCategory] =
    useState<EntityCategory>("tables");
  const [otherPrivileges, setOtherPrivileges] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);
  const [effectSelection, setEffectSelection] = useState<EffectType>("ALLOW");
  const [isTableFunction, setIsTableFunction] = useState(true);
  const [adminOption, setAdminOption] = useState<boolean>(false);
  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false);
  const [error, setError] = useState<CreateGrantError | null>(null);
  const availableUIFeatures = useAllowedUiFeatures();
  const memoizedExcludedRoles = useMemo(() => {
    return [SYSTEM_ROLE_ID, roleId];
  }, [roleId]);
  const isCatalogSelectorVisible = useMemo(
    () =>
      catalogInputVisible.includes(entityCategory) ||
      (entityCategory === "functions" && isTableFunction),
    [entityCategory, isTableFunction]
  );
  const isSchemaSelectorVisible = useMemo(
    () =>
      schemaInputVisible.includes(entityCategory) ||
      (entityCategory === "functions" && isTableFunction),
    [entityCategory, isTableFunction]
  );
  const defaultOtherPrivilege = () => {
    //when you select radio button default to the functions entity
    if (mainRadioButtons.includes(entityCategory)) {
      setEntityCategory("functions");
      getActions("functions");
    }
    setOtherPrivileges("functions");
  };
  const handleIsTableFunctionChange = (isTableFunctionNewValue: boolean) => {
    setCatalogName({
      empty: true,
      value: null,
    });
    setSchemaName({
      empty: true,
      value: null,
    });
    setFunctions({
      empty: true,
      value: null,
    });
    setIsTableFunction(isTableFunctionNewValue);
  };
  const handleEffectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const effect = (event.target as HTMLInputElement).value;
    setEffectSelection(effect as EffectType);
    if (effect === "DENY") {
      setAdminOption(false);
    }
  };
  const [targetRoles, setTargetRoles] = useState<
    EmptyOrValue<ChosenRole[] | null>
  >({
    empty: true,
    value: null,
  });

  const [dataProductDomain, setDataProductDomain] = useState<
    EmptyOrValue<DataProductDomain | null>
  >({
    empty: true,
    value: null,
  });

  const [dataProducts, setDataProducts] = useState<
    EmptyOrValue<string[] | null>
  >({
    empty: true,
    value: null,
  });

  const onDataProductDomainChanged = useCallback(
    (newDomain: EmptyOrValue<DataProductDomain | null>) => {
      setDataProductDomain(newDomain);
      setDataProducts({
        empty: true,
        value: null,
      });
    },
    []
  );
  const [users, setUsers] = useState<EmptyOrValue<string[] | null>>({
    empty: true,
    value: null,
  });
  const [functions, setFunctions] = useState<EmptyOrValue<string[] | null>>({
    empty: true,
    value: null,
  });

  const [procedures, setProcedures] = useState<EmptyOrValue<string[] | null>>({
    empty: true,
    value: null,
  });

  const [catalogName, setCatalogName] = useState<EmptyOrValue<string | null>>({
    empty: true,
    value: null,
  });
  const [propertiesSelected, setPropertiesSelected] = useState<
    EmptyOrValue<string[] | null>
  >({
    empty: true,
    value: null,
  });
  const [schemaName, setSchemaName] = useState<EmptyOrValue<string | null>>({
    empty: true,
    value: null,
  });
  const [tableName, setTableName] = useState<EmptyOrValue<string | null>>({
    empty: true,
    value: null,
  });
  const [uiFeatures, setUiFeatures] = useState<
    EmptyOrValue<ChosenFeature[] | null>
  >({
    empty: true,
    value: null,
  });
  const [columns, setColumns] = useState<EmptyOrValue<string[] | null>>({
    empty: true,
    value: null,
  });
  const [columnMasks, setColumnMasks] = useState<MaskOrFilterData[]>([]);
  const [rowFilters, setRowFilters] = useState<MaskOrFilterData[]>([]);
  const handleRadioSelection = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      const selectedEntity = event.target.value;
      setRadioSelection(selectedEntity);
      setEffectSelection("ALLOW");
      if (selectedEntity === "other") {
        defaultOtherPrivilege();
      } else {
        setOtherPrivileges("");
        setEntityCategory(selectedEntity as EntityCategory);
        getActions(selectedEntity as EntityCategory);
      }
      clearOrInitializeStates();
    },
    []
  );
  const handleOtherPrivileges = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    if (value) {
      setOtherPrivileges(value as EntityCategory);
      setEntityCategory(value as EntityCategory);
      getActions(value as EntityCategory);
      clearOrInitializeStates();
    }
  };

  const clearOrInitializeStates = useCallback(() => {
    setTargetRoles({
      empty: true,
      value: null,
    });
    setUsers({ empty: true, value: null });
    setFunctions({ empty: true, value: null });
    setDataProductDomain({ empty: true, value: null });
    setDataProducts({ empty: true, value: null });
    setCatalogName({ empty: true, value: null });
    setSchemaName({ empty: true, value: null });
    setPropertiesSelected({ empty: true, value: null });
    setAdminOption(false);
    setColumnMasks([]);
    setRowFilters([]);
    setIsTableFunction(true);
  }, [entityCategory]);

  const resetEntityState = () => {
    setBusy(false);
    clearOrInitializeStates();
    setActions((prev) =>
      Object.fromEntries(
        Object.entries(prev).map(([action]) => [action, false])
      )
    );
    setRadioSelection("tables");
    setEntityCategory("tables");
    setEffectSelection("ALLOW");
    getActions("tables");
  };

  const getActions = (entity: EntityCategory) => {
    const availableActions = entityActions.get(entity) ?? [];
    const checked = availableActions.length === 1;
    const actions = availableActions.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        [currentValue]: checked,
      }),
      {}
    );
    setActions(actions);
  };

  const handleAllActionChange = useCallback(
    ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
      setActions((prev) =>
        Object.fromEntries(
          Object.entries(prev).map(([action]) => [action, checked])
        )
      );
    },
    []
  );

  const [actions, setActions] = useState<Partial<Record<Action, boolean>>>(
    () => {
      const availableActions = entityActions.get("tables") ?? [];
      const checked = availableActions.length === 1;
      return availableActions.reduce(
        (previousValue, currentValue) => ({
          ...previousValue,
          [currentValue]: checked,
        }),
        {}
      );
    }
  );

  const validActionsCount = Object.entries(actions).filter(
    ([key, val]) => validActionsForFilters.includes(key) && val === true
  ).length;

  const handleActionChange = useCallback(
    ({ target: { checked, name } }: React.ChangeEvent<HTMLInputElement>) => {
      setActions((prev) => ({
        ...prev,
        [name]: checked,
      }));
    },
    []
  );

  const checkedActions = Object.values(actions).filter(
    (checked) => checked
  ).length;

  const grants: Grant[] = [];

  const createEntityFromAttributes = (
    attrs: (CategoryAttribute | Attribute)[]
  ): Entity => {
    return {
      attributes: attrs,
    };
  };
  const categoryAttributes: (CategoryAttribute | Attribute)[] = [
    { key: "category", value: entityCategory },
  ];

  const createEntity = (
    allStarSelected: boolean,
    selectedId: number | string | null,
    entityAttributeKey: string | null
  ): Entity => {
    const attributes: (CategoryAttribute | Attribute)[] = [
      ...categoryAttributes,
    ];
    if (!allStarSelected && entityAttributeKey) {
      attributes.push({
        key: entityAttributeKey as Exclude<AllAttribute, "category">,
        value: String(selectedId),
      });
    }
    return createEntityFromAttributes(attributes);
  };

  const handleAddPrivilege = async (forceSave = false) => {
    const selectedSubject: Subject = {
      attributes: [{ key: "role", value: roleId.toString(10) }],
    };
    const actionAndEffects = Object.entries(actions)
      .filter(([, checked]) => checked)
      .map<{
        action: string;
        effect: string;
      }>(([actionString]) => {
        const action = actionString as Action;
        const effect = adminOption
          ? "ALLOW_WITH_GRANT_OPTION"
          : effectSelection;
        return {
          action,
          effect,
        };
      });

    const selectedGrant = (
      grant: { action: string; effect: string },
      createdEntity: Entity
    ) => {
      return {
        subject: selectedSubject,
        action: grant.action as Action,
        effect: grant.effect as EffectType,
        entity: createdEntity,
      };
    };

    switch (entityCategory) {
      case "tables":
        actionAndEffects.forEach((grant) => {
          const attributes: (CategoryAttribute | Attribute)[] = [
            ...categoryAttributes,
          ];
          if (catalogName.value) {
            attributes.push({
              key: "catalog",
              value: catalogName.value,
            });
            if (schemaName.value) {
              attributes.push({
                key: "schema",
                value: schemaName.value,
              });
              if (tableName.value) {
                attributes.push({
                  key: "table",
                  value: tableName.value,
                });
                if (columns.value) {
                  columns.value?.forEach((column) => {
                    const columnAttribute: Attribute = {
                      key: "column",
                      value: column,
                    };
                    grants.push(
                      selectedGrant(
                        grant,
                        createEntityFromAttributes([
                          ...attributes,
                          columnAttribute,
                        ])
                      )
                    );
                  });
                } else {
                  grants.push(
                    selectedGrant(grant, createEntityFromAttributes(attributes))
                  );
                }
              } else {
                grants.push(
                  selectedGrant(grant, createEntityFromAttributes(attributes))
                );
              }
            } else {
              grants.push(
                selectedGrant(grant, createEntityFromAttributes(attributes))
              );
            }
          } else {
            grants.push(
              selectedGrant(grant, createEntityFromAttributes(attributes))
            );
          }
        });
        break;
      case "roles":
        actionAndEffects.forEach((grant) => {
          if (targetRoles.value) {
            targetRoles.value?.forEach((targetRole) => {
              grants.push(
                selectedGrant(grant, createEntity(false, targetRole.id, "role"))
              );
            });
          } else {
            grants.push(
              selectedGrant(
                grant,
                createEntityFromAttributes(categoryAttributes)
              )
            );
          }
        });
        break;
      case "users":
        if (users && !users.empty) {
          actionAndEffects.forEach((grant) => {
            if (users.value) {
              users.value?.forEach((user) => {
                grants.push(
                  selectedGrant(grant, createEntity(false, user, "user"))
                );
              });
            } else {
              grants.push(
                selectedGrant(
                  grant,
                  createEntityFromAttributes(categoryAttributes)
                )
              );
            }
          });
        }
        break;
      case "data_products":
        actionAndEffects.forEach((grant) => {
          const attributes: (CategoryAttribute | Attribute)[] = [
            ...categoryAttributes,
          ];

          if (dataProductDomain.value) {
            attributes.push({
              key: "data-product-domain",
              value: dataProductDomain.value?.name,
            });
            if (dataProducts.value) {
              dataProducts.value?.forEach((dataProductName) => {
                const dataProductAttribute: Attribute = {
                  key: "data-product",
                  value: dataProductName,
                };
                grants.push(
                  selectedGrant(
                    grant,
                    createEntityFromAttributes([
                      ...attributes,
                      dataProductAttribute,
                    ])
                  )
                );
              });
            } else {
              grants.push(
                selectedGrant(grant, createEntityFromAttributes(attributes))
              );
            }
          } else {
            grants.push(
              selectedGrant(grant, createEntityFromAttributes(attributes))
            );
          }
        });
        break;
      case "procedures":
        actionAndEffects.forEach((grant) => {
          const attributes: (CategoryAttribute | Attribute)[] = [
            ...categoryAttributes,
          ];

          if (catalogName.value) {
            attributes.push({
              key: "catalog",
              value: catalogName.value,
            });
          } else {
            grants.push(
              selectedGrant(grant, createEntityFromAttributes(attributes))
            );
            return;
          }

          if (schemaName.value) {
            attributes.push({
              key: "schema",
              value: schemaName.value,
            });
          } else {
            grants.push(
              selectedGrant(grant, createEntityFromAttributes(attributes))
            );
            return;
          }

          if (procedures.value) {
            procedures.value.forEach((procedure) => {
              const dataProductAttribute: Attribute = {
                key: "procedure",
                value: procedure,
              };
              grants.push(
                selectedGrant(
                  grant,
                  createEntityFromAttributes([
                    ...attributes,
                    dataProductAttribute,
                  ])
                )
              );
            });
          } else {
            grants.push(
              selectedGrant(grant, createEntityFromAttributes(attributes))
            );
          }
        });
        break;
      case "queries":
        if (actionAndEffects.length) {
          actionAndEffects.forEach((grant) => {
            grants.push(selectedGrant(grant, createEntity(false, null, null)));
          });
        }
        break;
      case "user_interface":
        if (uiFeatures.value?.length && availableUIFeatures) {
          actionAndEffects.forEach((grant) => {
            uiFeatures.value?.forEach((uiFeature) => {
              const uiEntityAttributes = availableUIFeatures
                .find(
                  (availableFeature) =>
                    availableFeature.feature === uiFeature.id
                )
                ?.attributes?.map<Attribute>((attribute) => attribute);
              grants.push(
                selectedGrant(
                  grant,
                  uiEntityAttributes
                    ? createEntityFromAttributes([
                        ...categoryAttributes,
                        ...uiEntityAttributes,
                      ])
                    : createEntityFromAttributes(categoryAttributes)
                )
              );
            });
          });
        }
        break;
      case "functions":
        actionAndEffects.forEach((grant) => {
          const attributes: (CategoryAttribute | Attribute)[] = [
            ...categoryAttributes,
          ];
          if (isTableFunction) {
            attributes.push({
              key: "function-kind",
              value: "table",
            });
          }

          if (catalogName.value) {
            attributes.push({
              key: "catalog",
              value: catalogName.value,
            });
          }

          if (schemaName.value) {
            attributes.push({
              key: "schema",
              value: schemaName.value,
            });
          }
          if (functions.value) {
            functions.value.forEach((selectedFunction) => {
              const functionAttribute: Attribute = {
                key: "function",
                value: selectedFunction,
              };
              grants.push(
                selectedGrant(
                  grant,
                  createEntityFromAttributes([...attributes, functionAttribute])
                )
              );
            });
          } else {
            grants.push(
              selectedGrant(grant, createEntityFromAttributes(attributes))
            );
          }
        });
        break;
      case "system_session_properties":
        actionAndEffects.forEach((grant) => {
          if (propertiesSelected.value) {
            propertiesSelected.value?.forEach((property) => {
              grants.push(
                selectedGrant(grant, createEntity(false, property, "property"))
              );
            });
          } else {
            grants.push(
              selectedGrant(
                grant,
                createEntityFromAttributes(categoryAttributes)
              )
            );
          }
        });
        break;
      case "catalog_session_properties":
        actionAndEffects.forEach((grant) => {
          const attributes: (CategoryAttribute | Attribute)[] = [
            ...categoryAttributes,
          ];
          if (!catalogName.empty && catalogName.value) {
            attributes.push({
              key: "catalog",
              value: catalogName.value,
            });

            if (propertiesSelected.value) {
              propertiesSelected.value?.forEach((property) => {
                const propertyAttribute: Attribute = {
                  key: "property",
                  value: property,
                };
                grants.push(
                  selectedGrant(
                    grant,
                    createEntityFromAttributes([
                      ...attributes,
                      propertyAttribute,
                    ])
                  )
                );
              });
            } else {
              grants.push(
                selectedGrant(grant, createEntityFromAttributes(attributes))
              );
            }
          } else {
            grants.push(
              selectedGrant(grant, createEntityFromAttributes(attributes))
            );
          }
        });
        break;
    }

    const columnMaskArray: NewExpression | ReuseExpression[] = [];
    const rowFilterArray: NewExpression | ReuseExpression[] = [];
    if (grants.length && entityCategory === "tables") {
      if (columnMasks.length) {
        const attributes: (CategoryAttribute | Attribute)[] =
          grants[0].entity.attributes.filter(
            (attribute) => attribute.key != "column"
          );
        createColumnMaskArray(columnMasks, attributes, columnMaskArray);
      }

      if (rowFilters.length) {
        const attributes: (CategoryAttribute | Attribute)[] =
          grants[0].entity.attributes.filter(
            (attribute) => !["table", "column"].includes(attribute.key)
          );
        createRowFilterArray(rowFilters, attributes, rowFilterArray);
      }
    }
    setBusy(true);
    setError(null);
    // minimum wait time for avoiding spinner flash
    const minimumWait = new Promise<void>((resolve) =>
      setTimeout(resolve, 1000)
    );
    try {
      await create(
        {
          subjectRoleId: roleId,
          grants,
          columnMasks: columnMaskArray,
          rowFilters: rowFilterArray,
        },
        forceSave
      );
    } catch (e) {
      setBusy(false);
      setError(e);
      setSaveDialogOpen(true);
    }

    await Promise.all([reload(), minimumWait]);
    setBusy(false);
    setSaveDialogOpen(true);
  };

  const configContext = useConfigContext();

  return (
    <>
      <RadioAndDropdownGroup
        radioSelection={radioSelection}
        handleRadioSelection={handleRadioSelection}
        otherPrivileges={otherPrivileges}
        handleOtherPrivileges={handleOtherPrivileges}
      />
      <Grid container maxWidth="fit-content">
        <Card className={classes.card}>
          <Grid width="35rem" padding="1rem 0 0 1rem">
            {entityCategory === "functions" && (
              <IsTableFunctionSelector
                value={isTableFunction}
                onChange={handleIsTableFunctionChange}
              />
            )}
            {isCatalogSelectorVisible && (
              <CatalogSelector
                autocomplete
                shouldDisplaySelectAllCheckbox={entityCategory !== "functions"}
                disabled={busy}
                handleChange={setCatalogName}
                value={catalogName}
                entityCategory={entityCategory}
              />
            )}
            {isSchemaSelectorVisible && (
              <SchemaSelector
                shouldDisplaySelectAllCheckbox={entityCategory !== "functions"}
                disabled={catalogName.empty || catalogName.value === null}
                catalogName={catalogName.value}
                value={schemaName}
                handleChange={setSchemaName}
              />
            )}
            {entityCategory === "tables" && (
              <>
                <TableSelector
                  catalogName={catalogName.value}
                  disabled={!schemaName.value}
                  handleChange={setTableName}
                  schemaName={schemaName.value}
                  value={tableName}
                />
                <ColumnsSelector
                  disabled={!tableName.value}
                  catalogName={catalogName.value}
                  schemaName={schemaName.value}
                  tableName={tableName.value}
                  value={columns}
                  handleChange={setColumns}
                />
              </>
            )}
            {entityCategory === "roles" && (
              <RolesSelector
                currentRoleName={currentRoleName}
                allAccessible
                excludedRoles={memoizedExcludedRoles}
                value={targetRoles}
                handleChange={setTargetRoles}
              />
            )}
            {entityCategory === "data_products" && (
              <>
                <DomainSelector
                  value={dataProductDomain}
                  handleChange={onDataProductDomainChanged}
                />
                <DataProductSelector
                  value={dataProducts}
                  handleChange={setDataProducts}
                  domainId={dataProductDomain.value?.id}
                  disabled={
                    dataProductDomain.empty || dataProductDomain.value === null
                  }
                />
              </>
            )}
            {entityCategory === "users" && (
              <UsersSelector
                currentRoleName={currentRoleName}
                value={users}
                handleChange={setUsers}
              />
            )}
            {entityCategory === "user_interface" && (
              <UIFeaturesSelector
                value={uiFeatures}
                handleChange={setUiFeatures}
              />
            )}
            {entityCategory === "functions" && (
              <FunctionsSelector
                disabled={
                  isTableFunction &&
                  (!schemaName || schemaName.empty || !schemaName.value)
                }
                shouldDisplaySelectAllCheckbox={!isTableFunction}
                schemaName={schemaName.value}
                isTableFunction={isTableFunction}
                currentRoleName={currentRoleName}
                value={functions}
                handleChange={setFunctions}
              />
            )}
            {entityCategory === "procedures" && (
              <>
                <ProcedureSelector
                  disabled={
                    !schemaName || schemaName.empty || !schemaName.value
                  }
                  schemaName={schemaName}
                  value={procedures}
                  handleChange={setProcedures}
                />
              </>
            )}
            {(entityCategory === "system_session_properties" ||
              entityCategory === "catalog_session_properties") && (
              <PropertiesSelector
                autocomplete
                disabled={
                  busy ||
                  (entityCategory === "catalog_session_properties" &&
                    !catalogName.value)
                }
                catalogName={catalogName.value}
                handleChange={setPropertiesSelected}
                value={propertiesSelected}
                entityCategory={entityCategory}
              />
            )}
            {entityCategory && (
              <EntityActions
                actions={actions}
                handleAllActionChange={handleAllActionChange}
                handleActionChange={handleActionChange}
                effectSelection={effectSelection}
                handleEffectChange={handleEffectChange}
              />
            )}
          </Grid>
          <Grid item pl={4} mt={1}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={adminOption}
                  disabled={!grantOption || busy || effectSelection === "DENY"}
                  onChange={(_, checked) => {
                    setAdminOption(checked);
                  }}
                />
              }
              label="Allow role receiving grant to grant to others"
            />
          </Grid>
          {entityCategory === "tables" &&
            configContext?.maskAndFiltersEnabled && (
              <AdditionalPrivileges
                catalogName={catalogName.value}
                schemaName={schemaName.value}
                tableName={tableName}
                columns={columns}
                disabled={validActionsCount < 1 || effectSelection === "DENY"}
                handleColumnMaskChange={setColumnMasks}
                handleRowFilterChange={setRowFilters}
                currentRoleName={currentRoleName}
              />
            )}
        </Card>
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        alignItems="flex-end"
        mt={3}
        mb={2}
      >
        <Grid item xs="auto" pr={2}>
          <CancelDialog reset={resetEntityState} />
          <SavePrivilegesDialog
            reset={resetEntityState}
            open={saveDialogOpen}
            setOpen={setSaveDialogOpen}
            forceSave={() => handleAddPrivilege(true)}
            error={error}
          />
        </Grid>
        <Grid item>
          <Tooltip
            title={checkedActions < 1 ? "Select at least one privilege" : ""}
            placement="top-start"
          >
            <span>
              <LoadingButton
                loading={busy}
                onClick={() => handleAddPrivilege()}
                variant="contained"
                disabled={
                  busy ||
                  checkedActions === 0 ||
                  (entityCategory === "tables" &&
                    isSaveDisabled([
                      catalogName,
                      schemaName,
                      tableName,
                      columns,
                    ])) ||
                  (entityCategory === "roles" && targetRoles.empty) ||
                  (entityCategory === "users" && users.empty) ||
                  (entityCategory === "data_products" &&
                    (dataProductDomain.empty ||
                      (dataProductDomain.value && dataProducts.empty))) ||
                  (entityCategory === "user_interface" && uiFeatures.empty) ||
                  (entityCategory === "functions" && functions.empty) ||
                  (entityCategory === "procedures" &&
                    isSaveDisabled([catalogName, schemaName, procedures])) ||
                  (entityCategory === "catalog_session_properties" &&
                    (catalogName.empty ||
                      (catalogName.value && propertiesSelected.empty))) ||
                  (entityCategory === "system_session_properties" &&
                    propertiesSelected.empty) ||
                  isMasksOrFiltersValid(columnMasks) ||
                  isMasksOrFiltersValid(rowFilters)
                }
              >
                {checkedActions > 1 ? "Save privileges" : "Save privilege"}
              </LoadingButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
};

const isSaveDisabled = (values: EmptyOrValue<unknown>[]) => {
  if (values.length === 1) {
    return values[0].empty;
  }
  for (let i = 0; i < values.length; i++) {
    const current = values[i];
    if (current.empty) {
      return true;
    } else if (current.value === null) {
      return false;
    }
  }

  return values[values.length - 1].empty;
};

const isMasksOrFiltersValid = (values: MaskOrFilterData[]) => {
  return (
    values.length > 0 && values.some((value) => isExpressionInvalid(value))
  );
};

const isExpressionInvalid = (value: MaskOrFilterData) => {
  if (value.errorText) {
    return true;
  } else if (value.newExpression && value.oldExpression === null) {
    return !(value.newExpression.name && value.newExpression.expression);
  } else {
    return !(value.oldExpression && value.oldExpression.name);
  }
};
