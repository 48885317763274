/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { AxiosError, AxiosResponse } from "axios";
import { configWithRoleHeader, errorMappingAxios, Persisted } from "./common";

export type AttributeSubject = "user" | "group" | "role";

export type AttributeEntity =
  | "category"
  | "catalog"
  | "schema"
  | "table"
  | "column"
  | "function"
  | "function-kind"
  | "procedure"
  | "property"
  | "query-owner"
  | "data-product-domain"
  | "data-product"
  | "ui-view"
  | "ui-section";

export type AllAttribute = AttributeSubject | AttributeEntity;

export type Action =
  | "SHOW"
  | "CREATE"
  | "ALTER"
  | "DROP"
  | "EXECUTE"
  | "SELECT"
  | "INSERT"
  | "DELETE"
  | "UPDATE"
  | "REFRESH"
  | "IMPERSONATE"
  | "UI"
  | "KILL"
  | "SET"
  | "ASSIGN"
  | "PUBLISH"
  | "DOWNLOAD";

export interface PrintableValue {
  entityText: string;
  specified: boolean;
}

export interface Attribute {
  key: Exclude<AllAttribute, "category">;
  value: string;
}

export interface SubjectAttribute {
  key: AttributeSubject;
  value: string;
}

export interface CategoryAttribute {
  key: "category";
  value: EntityCategory;
}

export interface Subject {
  attributes: SubjectAttribute[];
}

export interface Entity {
  attributes: (CategoryAttribute | Attribute)[];
}

export interface EntityDto {
  attributes: (CategoryAttribute | Attribute)[];
  printableValue: PrintableValue;
}

export interface RoleGrant {
  subjectUser?: string | null;
  subjectGroup?: string | null;
  subjectRoleId?: number | null;
  role: number;
  grantOption: boolean;
}

export type EffectType = "ALLOW" | "ALLOW_WITH_GRANT_OPTION" | "DENY";

export interface Grant {
  subject: Subject;
  action: Action;
  entity: Entity;
  effect: EffectType;
}

export interface GrantDto {
  subject: Subject;
  action: Action;
  entity: EntityDto;
  effect: EffectType;
}

export interface GrantEntry {
  action: Action;
  entity: Entity;
  effect: EffectType;
}

export interface ForceNone {
  entity: Entity;
  forceNone: true;
}

export interface NewExpression {
  entity: Entity;
  forceNone: false;
  newExpression: Expression;
}

export interface ReuseExpression {
  entity: Entity;
  forceNone: false;
  expressionId: number;
}

export type ColumnMask = ForceNone | NewExpression | ReuseExpression;
export type RowFilter = ForceNone | NewExpression | ReuseExpression;

export interface Expression {
  name: string;
  expression: string;
  description?: string;
}

export interface Create {
  subjectRoleId: number;
  grants: GrantEntry[];
  columnMasks: ColumnMask[];
  rowFilters: RowFilter[];
}

export type EntityCategory =
  | "tables"
  | "system_session_properties"
  | "catalog_session_properties"
  | "functions"
  | "procedures"
  | "queries"
  | "roles"
  | "users"
  | "annotations"
  | "data_products"
  | "user_interface";

const baseUrl = "/ui/api/biac/grants";
export interface GrantError {
  id: number;
  oldGrant: Grant;
  newGrant: Grant;
}

export interface ErrorDataDetails {
  conflictingGrants: GrantError[];
  duplicateGrants: Persisted<GrantDto>[];
  type: string;
}

interface ErrorData {
  details: ErrorDataDetails;
  detailsType: string;
  errorCode: string;
  message: string;
}
export type CreateGrantError = AxiosError<ErrorData>;

export const create = (
  currentRoleName: string,
  create: Create,
  forceUpdate: boolean
): Promise<void> => {
  return errorMappingAxios.post(
    `${baseUrl}?forceUpdate=${forceUpdate}`,
    create,
    configWithRoleHeader(currentRoleName)
  );
};

export const deleteGrant = (
  currentRoleName: string,
  grantId: number
): Promise<void> => {
  return errorMappingAxios.delete(
    `${baseUrl}/${grantId}`,
    configWithRoleHeader(currentRoleName)
  );
};

export const getSubjects = (
  currentRoleName: string,
  subjectType: Exclude<AttributeSubject, "role">
): Promise<string[]> => {
  return errorMappingAxios
    .get<string[], AxiosResponse<string[]>>(
      `${baseUrl}/subjects/${subjectType}`,
      configWithRoleHeader(currentRoleName)
    )
    .then((response) => response.data);
};

export const getExpressions = (
  currentRoleName: string,
  expressionType: "ROW_FILTER" | "COLUMN_MASK"
): Promise<Persisted<Expression>[]> => {
  return errorMappingAxios
    .get<Persisted<Expression>[], AxiosResponse<Persisted<Expression>[]>>(
      `${baseUrl}/expressions/${expressionType}`,
      configWithRoleHeader(currentRoleName)
    )
    .then((response) => response.data);
};
