/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { formatBytes } from "../../../../../utils/formatBytes";

export function truncateString(inputString: string, length: number): string {
  if (inputString && inputString.length > length) {
    return inputString.substring(0, length) + "...";
  }
  return inputString;
}

function parseDataSize(value: string): number | null {
  const DATA_SIZE_PATTERN = /^\s*(\d+(?:\.\d+)?)\s*([a-zA-Z]+)\s*$/;
  const match = DATA_SIZE_PATTERN.exec(value);
  if (match === null) {
    return null;
  }
  const number = parseFloat(match[1]);
  switch (match[2]) {
    case "B":
      return number;
    case "kB":
      return number * Math.pow(2, 10);
    case "MB":
      return number * Math.pow(2, 20);
    case "GB":
      return number * Math.pow(2, 30);
    case "TB":
      return number * Math.pow(2, 40);
    case "PB":
      return number * Math.pow(2, 50);
    default:
      return null;
  }
}

function precisionRound(n: number): string {
  if (n < 10) {
    return n.toFixed(2);
  }
  if (n < 100) {
    return n.toFixed(1);
  }
  return Math.round(n).toString();
}

export function parseAndFormatDataSize(value: string): string {
  const parsed = parseDataSize(value);

  if (parsed == null) {
    return "";
  }

  return formatBytes(parsed);
}

function formatCount(count: number): string {
  let unit = "";
  if (count > 1000) {
    count /= 1000;
    unit = "K";
  }
  if (count > 1000) {
    count /= 1000;
    unit = "M";
  }
  if (count > 1000) {
    count /= 1000;
    unit = "B";
  }
  if (count > 1000) {
    count /= 1000;
    unit = "T";
  }
  if (count > 1000) {
    count /= 1000;
    unit = "Q";
  }
  return precisionRound(count) + unit;
}

export function formatRows(count: number): string {
  if (count === 1) {
    return "1 row";
  }

  return formatCount(count) + " rows";
}

export function getStageIdNum(stageId: string): string {
  return stageId.substring(stageId.lastIndexOf(".") + 1);
}
