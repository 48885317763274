/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import axios from "axios";
import { Observable } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import { baseUrl } from "../dataProductApi";
import { emitCommentEvent } from "./commentEvent";

export interface CommentData {
  id: string;
  dataProductId: string;
  commentText: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  upvotedByCurrentUser: boolean;
  upvotes: number;
}

export const listDataProductComments = (
  dataProductId: string
): Promise<CommentData[]> => {
  return axios
    .get<CommentData[]>(`${baseUrl}/products/${dataProductId}/comments`)
    .then((response) => response.data);
};

export const listDataProductComments$ = (
  dataProductId: string
): Observable<CommentData[]> =>
  fromPromise(listDataProductComments(dataProductId));

const createDataProductComment = (
  dataProductId: string,
  commentText: string
): Promise<CommentData> => {
  return axios
    .post<CommentData>(`${baseUrl}/products/${dataProductId}/comments`, {
      commentText,
    })
    .then((response) => response.data)
    .then((comment) => {
      emitCommentEvent({
        type: "commentAdded",
        payload: comment,
      });
      return comment;
    });
};

export const createDataProductComment$ = (
  dataProductId: string,
  commentText: string
): Observable<CommentData> =>
  fromPromise(createDataProductComment(dataProductId, commentText));

export const deleteDataProductComment = (
  dataProductId: string,
  commentId: string
): Promise<void> => {
  return axios
    .delete(`${baseUrl}/products/${dataProductId}/comments/${commentId}`)
    .then(() => {
      emitCommentEvent({
        type: "commentDeleted",
        payload: {
          dataProductId,
          commentId,
        },
      });
    });
};

const updateDataProductComment = (
  dataProductId: string,
  commentId: string,
  commentText: string
): Promise<CommentData> => {
  return axios
    .put<CommentData>(
      `${baseUrl}/products/${dataProductId}/comments/${commentId}`,
      {
        commentText,
      }
    )
    .then((response) => response.data)
    .then((comment) => {
      emitCommentEvent({
        type: "commentChanged",
        payload: comment,
      });
      return comment;
    });
};

export const updateDataProductComment$ = (
  dataProductId: string,
  commentId: string,
  commentText: string
): Observable<CommentData> =>
  fromPromise(updateDataProductComment(dataProductId, commentId, commentText));

export const upvoteComment = (
  dataProductId: string,
  commentId: string
): Promise<void> =>
  axios
    .put(`${baseUrl}/products/${dataProductId}/comments/${commentId}/vote`)
    .then(() => {
      emitCommentEvent({
        type: "commentUpvoted",
        payload: {
          dataProductId,
          commentId,
        },
      });
    });

export const deleteCommentVote = (
  dataProductId: string,
  commentId: string
): Promise<void> =>
  axios
    .delete(`${baseUrl}/products/${dataProductId}/comments/${commentId}/vote`)
    .then(() => {
      emitCommentEvent({
        type: "commentVoteDeleted",
        payload: {
          dataProductId,
          commentId,
        },
      });
    });
