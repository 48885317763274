/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { SingleQueryDetails } from "../../../api/queryApi";

export const isLakeAccelerationInfoAvailable = (
  query: SingleQueryDetails | "loading" | "error"
) =>
  query !== "loading" &&
  query !== "error" &&
  query.accessedMetadata.some((metadataItem) =>
    Object.keys(metadataItem.connectorMetrics).some((key) =>
      ["external-match", "varada-collect", "varada-match"].includes(
        key.split(":")[0]
      )
    )
  );

export const getOverallLakeAccelerationData = (query: SingleQueryDetails) => {
  let externalMatch = 0;
  let externalCollect = 0;
  let varadaMatch = 0;
  let varadaCollect = 0;

  query.accessedMetadata.forEach((metaData) => {
    externalMatch +=
      metaData.connectorMetrics?.["dispatcherPageSource:external_match_columns"]
        ?.total ?? 0;
    externalCollect +=
      metaData.connectorMetrics?.[
        "dispatcherPageSource:external_collect_columns"
      ]?.total ?? 0;
    varadaMatch +=
      metaData.connectorMetrics?.["dispatcherPageSource:varada_match_columns"]
        ?.total ?? 0;
    varadaCollect +=
      (metaData.connectorMetrics?.[
        "dispatcherPageSource:varada_collect_columns"
      ]?.total ?? 0) +
      (metaData.connectorMetrics?.[
        "dispatcherPageSource:prefilled_collect_columns"
      ]?.total ?? 0);
  });

  return {
    overall:
      (varadaCollect + varadaMatch) /
      (varadaCollect + varadaMatch + externalCollect + externalMatch),
    filtering: varadaMatch / (varadaMatch + externalMatch),
    projection: varadaCollect / (varadaCollect + externalCollect),
  };
};

export const getAcceleratedCatalogs = (query: SingleQueryDetails) => {
  const catalogs = new Set<string>();
  query.accessedMetadata.forEach((metaData) => {
    const catalogMetricKey = Object.keys(metaData.connectorMetrics).find(
      (key) => key.split(":")[0] === "CATALOG_NAME"
    );
    const catalog = catalogMetricKey?.split(":")[1];
    if (catalog) {
      catalogs.add(catalog);
    }
  });

  return Array.from(catalogs);
};
