/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
export const generateSqlObjectName = (input: string): string => {
  if (!input) {
    return "";
  }
  // if you are changing this logic please update BE part as well if needed
  // com.starburstdata.presto.dataproduct.util.SchemaUtils.generateSchemaName
  return input
    .toLocaleLowerCase()
    .replace(/\s+/g, "_") // replaces all spaces with single underscore
    .replaceAll(/\W/g, ""); // ignores all non-alphanumeric characters except underscore;
};
export const isEmpty = (str?: string | null): boolean =>
  !str || !isNonEmpty(str);
export const isNonEmpty = (str: string): boolean => str.trim().length > 0;

export const maximumTitleLength = 40;
export const maximumFreeTextLength = 2048;
export const maximumSummaryLength = 150;
