/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import axios, { AxiosError, AxiosRequestConfig } from "axios";

export interface Persisted<T> {
  id: number;
  object: T;
}

const errorMessageHeaderKey = "x-insights-error-message";
function extractErrorMessageFromHeaders(
  headers: Record<string, string> | undefined
): string | undefined {
  for (const [key, value] of Object.entries(headers ?? {})) {
    if (key.toLowerCase() === errorMessageHeaderKey && value) {
      return value;
    }
  }
  return undefined;
}

const handleError = (error: AxiosError<{ message: string | undefined }>) => {
  let errorMessage = null;
  const headerErrorMessage = extractErrorMessageFromHeaders(
    error?.response?.headers
  );
  if (headerErrorMessage) {
    errorMessage = headerErrorMessage;
  } else if (error?.response?.status === 401) {
    errorMessage = "Unauthenticated, try logging in again";
  } else if (error?.response?.status === 403) {
    errorMessage = "Cannot perform action, access denied";
  } else if (error.response?.data?.message) {
    errorMessage = error.response.data.message;
  }
  return errorMessage
    ? Promise.reject({ ...error, message: errorMessage })
    : Promise.reject(error);
};

export const errorMappingAxios = axios.create();
errorMappingAxios.interceptors.response.use((value) => value, handleError);

let currentRole: string | null = null;
export const onRoleChanged = (newRole: string | null): void => {
  currentRole = newRole;
};

export const handleRequest = (req: AxiosRequestConfig) => {
  if (currentRole) {
    req.headers = {
      ...req.headers,
      ...configWithRoleHeader(currentRole).headers,
    };
  }
  return req;
};

axios.interceptors.response.use((value) => value, handleError);

export const configWithRoleHeader = (
  roleHeader: string
): AxiosRequestConfig => ({
  headers: { "x-trino-role": roleHeader },
});
