/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { createUseStyles } from "react-jss";
import { palette } from "../../../themes/palette";
import { getQueryDetails, SingleQueryDetails } from "../../../api/queryApi";
import { Spinner } from "../../../components/spinner/Spinner";
import { faChevronLeft, faRedo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useParams } from "react-router-dom";
import { QueryDetailsTab, QueryDetailsTabsBar } from "./QueryDetailsTabsBar";
import { QueryGeneralTab } from "./general/QueryGeneralTab";
import { QueryAdvancedTab } from "./advanced/QueryAdvancedTab";
import { QueryClusterResourcesTab } from "./cluster/QueryClusterResourcesTab";
import { ErrorBox } from "../../../components/error/ErrorBox";
import { PersistenceEnabledBiac } from "../../../components/biac/PersistenceEnabledBiac";
import { QueryStagesTab } from "./stages/QueryStagesTab";
import { PageContent } from "../../../layout/PageContent";
import { QueryDetailsBreadcrumbs } from "./QueryDetailsBreadcrumbs";
import { QueryStatusSummary } from "./QueryStatusSummary";
import { QueryKill } from "./QueryKill";
import { isQueryEnded } from "../../../utils/queryUtils";
import axios, { Canceler } from "axios";
import { QueryAccelerationTab } from "./acceleration/QueryAccelerationTab";
import { isLakeAccelerationInfoAvailable } from "./computeLakeAcceleration";
import { MetricsCollectionEnabledBiac } from "../../../components/biac/MetricsCollectionEnabledBiac";

const useStyles = createUseStyles({
  root: {
    paddingBottom: "2rem",
    paddingTop: "1.5rem",
  },
  header: {
    fontSize: "1.5rem",
    lineHeight: "1.25",
    letterSpacing: "0.26px",
    color: palette.nightBlue,
    marginBottom: "1.5rem",
  },
  icon: {
    marginRight: "16px",
  },
  button: {
    marginRight: "36px",
  },
  textButton: {
    padding: "0px 8px",
  },
  queryId: {
    marginLeft: 0,
    fontFamily: "montserrat, sans-serif",
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: 600,
  },
  querySummary: {
    marginLeft: "auto",
  },
  rightAlignBtn: {
    marginLeft: "auto",
  },
});

const shouldFetchQueryDetails = (
  query: SingleQueryDetails | "loading" | "error"
): boolean => {
  if (query === "error") {
    return false;
  } else {
    return query === "loading" || !isQueryEnded(query.state);
  }
};

interface QueryDetailsRouteProps {
  queryId: string;
  tab: string;
}

export const navigateBackInHistoryParam = "navigateBack";

export const QueryDetails: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [query, setQuery] = useState<SingleQueryDetails | "loading" | "error">(
    "loading"
  );
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const cancelerRef = useRef<Canceler>();
  const { queryId, tab } = useParams<QueryDetailsRouteProps>();
  const hideKillQueryBtn =
    query === "loading" || query === "error" || isQueryEnded(query.state);

  const shouldDisplayLakeAccelerationTab = useMemo(
    () => isLakeAccelerationInfoAvailable(query),
    [query]
  );

  useEffect(() => {
    setQuery("loading");
    fetchQueryDetails(queryId);
    return () => {
      timeoutRef.current && clearTimeout(timeoutRef.current);
      cancelerRef.current && cancelerRef.current();
    };
  }, [queryId]);

  const retry = useCallback(() => {
    fetchQueryDetails(queryId);
  }, [queryId]);

  const handleAndUpdateQueryDetails = useCallback(
    (details: SingleQueryDetails | "loading" | "error", queryId: string) => {
      setQuery(details);
      if (shouldFetchQueryDetails(details)) {
        timeoutRef.current = setTimeout(() => {
          fetchQueryDetails(queryId);
        }, 1000);
      }
    },
    []
  );

  const fetchQueryDetails = useCallback((queryId: string): void => {
    const { result, cancel } = getQueryDetails(queryId);
    cancelerRef.current = cancel;
    result
      .then((details) => {
        handleAndUpdateQueryDetails(details, queryId);
      })
      .catch((err) => {
        // do not update component state when request is cancelled
        if (!axios.isCancel(err)) {
          handleAndUpdateQueryDetails("error", queryId);
        }
      });
  }, []);

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goToOverview = useCallback(() => {
    history.push("/query");
  }, [history]);

  const processQuery = (
    processFn: (query: SingleQueryDetails) => ReactElement<HTMLElement>
  ): ReactElement<HTMLElement> => {
    if (query === "error") {
      return (
        <ErrorBox
          text={"Could not load query. Retry or visit the page later."}
        />
      );
    } else if (query === "loading") {
      return <Spinner position="relative" delay={500} />;
    } else {
      return processFn(query);
    }
  };

  return (
    <PersistenceEnabledBiac>
      <PageContent
        title={
          <Box display={"flex"}>
            <Box mr={"auto"}>Query details</Box>
            <Button
              variant="text"
              color="primary"
              onClick={goBack}
              className={classes.button}
              classes={{ root: classes.textButton }}
            >
              <FontAwesomeIcon icon={faChevronLeft} className={classes.icon} />
              Back
            </Button>
          </Box>
        }
      >
        <Box flex={1}>
          {query === "error" && (
            <Box display="flex">
              <Button
                variant="text"
                color="primary"
                onClick={retry}
                className={classes.rightAlignBtn}
              >
                <FontAwesomeIcon icon={faRedo} className={classes.icon} />
                Retry
              </Button>
            </Box>
          )}
          {processQuery((query) => (
            <Box>
              <Box display={"flex"} alignItems={"center"} mb={2} height={24}>
                <QueryDetailsBreadcrumbs
                  queryId={query.queryId}
                  goToOverview={goToOverview}
                />
                <Box
                  ml={4}
                  display={"flex"}
                  alignItems={"center"}
                  className={classes.querySummary}
                >
                  <QueryStatusSummary query={query} />
                </Box>
                {!hideKillQueryBtn && (
                  <QueryKill
                    queryId={queryId}
                    displayKillQuery={query.authorizedToKillQuery}
                  />
                )}
              </Box>
              <QueryDetailsTabsBar
                tab={tab as QueryDetailsTab}
                shouldDisplayLakeAccelerationTab={
                  shouldDisplayLakeAccelerationTab
                }
              />
              {tab === QueryDetailsTab.GENERAL && (
                <QueryGeneralTab query={query} />
              )}
              {tab === QueryDetailsTab.CLUSTER && (
                <MetricsCollectionEnabledBiac>
                  <QueryClusterResourcesTab query={query} />
                </MetricsCollectionEnabledBiac>
              )}
              {tab === QueryDetailsTab.STAGES && (
                <QueryStagesTab query={query} />
              )}
              {tab === QueryDetailsTab.ADVANCED && (
                <QueryAdvancedTab query={query} />
              )}
              {tab === QueryDetailsTab.ACCELERATION && (
                <QueryAccelerationTab query={query} />
              )}
            </Box>
          ))}
        </Box>
      </PageContent>
    </PersistenceEnabledBiac>
  );
};
