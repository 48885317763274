/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useState } from "react";
import SvgIconComponent from "@mui/material/SvgIcon";
import { SidePanelItemRaw } from "./SidePanelItemRaw";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

interface SidePanelParentItemProps {
  icon: typeof SvgIconComponent;
  text: string;
  openNav: boolean;
  setOpenNav: (open: boolean) => void;
  paths: string[];
}
const useStyles = createUseStyles({
  moreOpen: {
    transform: "rotate(180deg)",
    transitionDuration: "0.2s",
    transitionTimingFunction: "linear",
  },
  moreClose: {
    transform: "rotate(0deg)",
    transitionDuration: "0.2s",
    transitionTimingFunction: "linear",
  },
  items: {
    overflow: "hidden",
  },
  itemsOpen: {
    height: "100%",
    transitionDuration: "0.3s",
    transitionTimingFunction: "linear",
  },
  itemsClose: {
    height: "0",
    transitionDuration: "0.3s",
  },
  parentItem: {
    cursor: "pointer",
  },
});
export const SidePanelParentItem: React.FunctionComponent<
  SidePanelParentItemProps
> = ({ icon, text, openNav, setOpenNav, children, paths }) => {
  const [isOpen, setIsOpen] = useState(true);

  const isReallyOpen = isOpen && openNav;
  const classes = useStyles();
  const location = useLocation();

  const handleClick = useCallback(() => {
    if (openNav) {
      setIsOpen(!isOpen);
    } else {
      setOpenNav(true);
      setIsOpen(true);
    }
  }, [isOpen, openNav, setOpenNav]);

  return (
    <>
      <SidePanelItemRaw
        className={classes.parentItem}
        onClick={handleClick}
        isSelected={
          (!openNav || !isOpen) &&
          paths.some((path) => location.pathname.startsWith(path))
        }
        icon={icon}
        text={text}
        openNav={openNav}
        badgeProps={{
          classes: {
            root: isReallyOpen ? classes.moreOpen : classes.moreClose,
          },
        }}
        badge={ExpandMoreIcon}
      />
      <div
        style={{ maxHeight: `${React.Children.count(children) * 48}px` }}
        className={clsx(
          classes.items,
          isReallyOpen ? classes.itemsOpen : classes.itemsClose
        )}
      >
        {children}
      </div>
    </>
  );
};
