/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { CSSProperties, FunctionComponent } from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { palette } from "../../../../themes/palette";
import { Spinner } from "../../../../components/spinner/Spinner";
import { ErrorIndicator } from "../../../../components/error/ErrorIndicator";
import { NoData } from "../../../../components/error/NoData";
import { useMetricsFetchingState } from "./useMetricsFetchingState";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    backgroundColor: palette.nebulaNavy15,
    border: `1px solid ${palette.nebulaNavy100}`,
    borderRadius: "0.25rem",
  },
  errorIndicatorContainer: {
    flexGrow: 1,
    backgroundColor: palette.white,
  },
  errorIndicator: {
    margin: 0,
  },
});

interface DataProductMetricsProps {
  dataProductId: string;
  className?: string;
}

export const DataProductMetrics: FunctionComponent<DataProductMetricsProps> = ({
  dataProductId,
  className,
}: DataProductMetricsProps) => {
  const styles = useStyles();
  const {
    isFetching,
    isError,
    errorMessage,
    data: metrics,
  } = useMetricsFetchingState(dataProductId);

  return (
    <div className={clsx(styles.root, className)}>
      {isFetching ? (
        <Spinner size={150} position="relative" />
      ) : isError ? (
        <div className={styles.errorIndicatorContainer}>
          <ErrorIndicator
            text={errorMessage}
            className={styles.errorIndicator}
          />
        </div>
      ) : metrics ? (
        <>
          <SingleMetric label="Number of queries" dividerMode="small" />
          <SingleMetric
            label="Past 7 days"
            value={metrics.sevenDayQueryCount}
            dividerMode="small"
            labelMode="secondary"
          />
          <SingleMetric
            label="Past 30 days"
            value={metrics.thirtyDayQueryCount}
            dividerMode="big"
            labelMode="secondary"
          />
          <SingleMetric label="Number of users" dividerMode="small" />
          <SingleMetric
            label="Past 7 days"
            value={metrics.sevenDayUserCount}
            dividerMode="small"
            labelMode="secondary"
          />
          <SingleMetric
            label="Past 30 days"
            value={metrics.thirtyDayUserCount}
            dividerMode="none"
            labelMode="secondary"
          />
        </>
      ) : (
        <NoData height={250} text="No statistics to display" />
      )}
    </div>
  );
};

const useSingleMetricStyles = createUseStyles({
  root: {
    flexGrow: 1,
  },
  value: {
    fontWeight: 600,
    fontSize: "1.25rem",
    color: palette.nebulaNavy700,
  },
});

const primaryLabelStyle: CSSProperties = {
  fontSize: "0.875rem",
  fontWeight: 600,
  color: palette.nebulaNavy400,
};
const secondaryLabelStyle: CSSProperties = {
  fontSize: "0.625rem",
  color: palette.nebulaNavy400,
};
const dividerSmallStyle: CSSProperties = {
  margin: "0.5625rem 0 0.6875rem 0.875rem",
  paddingRight: "0.875rem",
  borderRight: `1px solid ${palette.nebulaNavy100}`,
};
const dividerBigStyle: CSSProperties = {
  padding: "0.5625rem 0.875rem 0.6875rem 0.875rem",
  borderRight: `1px solid ${palette.nebulaNavy100}`,
};
const dividerNoneStyle: CSSProperties = {
  margin: "0.5625rem 0.875rem 0.6875rem 0.875rem",
};

interface SingleMetricProps {
  label: string;
  labelMode?: "primary" | "secondary";
  dividerMode: "small" | "big" | "none";
  value?: string | number;
}

const SingleMetric = ({
  label,
  labelMode = "primary",
  dividerMode = "small",
  value = "",
}: SingleMetricProps) => {
  const styles = useSingleMetricStyles();
  const labelStyle =
    labelMode === "primary" ? primaryLabelStyle : secondaryLabelStyle;
  const rootStyle =
    dividerMode === "small"
      ? dividerSmallStyle
      : dividerMode === "big"
      ? dividerBigStyle
      : dividerNoneStyle;
  return (
    <div style={rootStyle} className={styles.root}>
      <div style={labelStyle}>{label}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
};
