/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { fromEvent, Observable } from "rxjs";
import { map, throttleTime } from "rxjs/operators";

export interface Scroll {
  x: number;
  y: number;
  scrollDelta: number;
}

export function createScrollObservable$(
  element: SVGSVGElement
): Observable<Scroll> {
  const svgPoint = element.createSVGPoint();
  const scroll$ = fromEvent<WheelEvent>(element, "wheel");
  return scroll$.pipe(
    map((e) => {
      svgPoint.x = e.pageX;
      svgPoint.y = e.pageY;
      const screenCTM = element.getScreenCTM();
      const domMatrix = screenCTM ? screenCTM.inverse() : undefined;
      const scrollPoint = svgPoint.matrixTransform(domMatrix);
      return {
        x: scrollPoint.x,
        y: scrollPoint.y,
        scrollDelta: e.deltaY,
      };
    }),
    throttleTime(30)
  );
}
