/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useState } from "react";
import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { maximumTitleLength } from "../../features/dataproduct/util/formUtils";

interface TagTextFieldProps {
  allTagNames: string[];
  onChange: (tagValue: string) => void;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  newTagOptionPostfix?: string;
  endAdornment?: React.ReactNode;
  isTextValidationRequired?: boolean;
}

const tagFilter = createFilterOptions<string>();

export const TagTextField = ({
  allTagNames,
  onChange,
  disabled = false,
  label,
  placeholder = "Add a tag...",
  newTagOptionPostfix = " (new tag)",
  endAdornment,
  isTextValidationRequired,
}: TagTextFieldProps) => {
  const [tagInputText, setTagInputText] = useState("");
  const [tagValue, setTagValue] = useState("");
  const [error, setError] = useState<boolean>(false);

  const onAutoCompleteChange = useCallback<
    Exclude<
      AutocompleteProps<string, false, false, true>["onChange"],
      undefined
    >
  >(
    (e, newValue) => {
      if (newValue) {
        const tagValue = newValue.replace(newTagOptionPostfix, "");
        if (!error) {
          onChange(tagValue);
        }
      }
      setTagInputText("");
      setTagValue("");
    },
    [onChange, error]
  );

  const onAutoCompleteInputChange = useCallback<
    Exclude<
      AutocompleteProps<string, false, false, true>["onInputChange"],
      undefined
    >
  >(
    (event, value) => {
      if (isTextValidationRequired && value.includes(" ")) {
        setError(true);
      } else {
        setError(false);
      }
      setTagInputText(value);
    },
    [error, isTextValidationRequired]
  );

  const filterOptions: AutocompleteProps<
    string,
    false,
    false,
    true
  >["filterOptions"] = useCallback(
    (options, params) => {
      const filtered = tagFilter(options, params);

      const { inputValue } = params;
      // Suggest the creation of a new value
      const isExisting = options.some(
        (option: string) => inputValue === option
      );
      if (inputValue !== "" && !isExisting && !error) {
        filtered.push(`${inputValue}${newTagOptionPostfix}`);
      }

      return filtered;
    },
    [newTagOptionPostfix, error]
  );

  return (
    <Autocomplete
      disabled={disabled}
      fullWidth
      value={tagValue}
      inputValue={tagInputText}
      options={allTagNames}
      onChange={onAutoCompleteChange}
      onInputChange={onAutoCompleteInputChange}
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          margin="dense"
          label={label}
          placeholder={placeholder}
          inputProps={{
            ...params.inputProps,
            maxLength: maximumTitleLength,
          }}
          error={error}
          helperText={error ? `${label} name cannot contain a space` : null}
          InputProps={{
            ...params.InputProps,
            endAdornment: endAdornment ?? params.InputProps.endAdornment,
          }}
        />
      )}
      selectOnFocus
      clearOnBlur
      blurOnSelect
      handleHomeEndKeys
      freeSolo
    />
  );
};
