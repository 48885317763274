/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
function getRootElementFontSize(): number {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export function convertRem(value: number): number {
  return value * getRootElementFontSize();
}
