/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { createUseStyles } from "react-jss";
import Alert from "@mui/material/Alert";

import { LoadingButton } from "../../components/LoadingButton";

import { LoginInputField } from "./LoginInputField";
import { AuthenticationUiType, login, LoginRequest } from "../../api/loginApi";

const useStyles = createUseStyles({
  root: {
    maxWidth: "446px",
    margin: "auto",
  },
});

interface LoginFormProps {
  type: AuthenticationUiType;
  redirectPath: string;
}

export const LoginForm: React.FunctionComponent<LoginFormProps> = ({
  type,
  redirectPath,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { handleSubmit, formState, control } = useForm<LoginRequest>({
    defaultValues: {
      username: "",
      password: "",
    },
    mode: "onChange",
  });

  const [requestError, setRequestError] = useState<string>();

  const onSubmit = useCallback(
    async (data: LoginRequest) => {
      setRequestError(undefined);
      try {
        const response = await login(data);
        if (response.success) {
          history.push(redirectPath);
        } else {
          setRequestError("Authentication failed");
        }
      } catch (e) {
        setRequestError("Authentication failed");
      }
    },
    [history]
  );

  const isFormValid = formState.isValid;
  return (
    <form
      autoComplete="off"
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        {requestError && (
          <Grid item xs={12}>
            <Alert variant="standard" severity="error">
              {requestError}
            </Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <LoginInputField
            variant="outlined"
            size="medium"
            name="username"
            label="Username"
            fullWidth={true}
            control={control}
            autoFocus={true}
            rules={{
              required: "You must specify the username",
            }}
          />
        </Grid>
        {type === "USERNAME_PASSWORD" && (
          <Grid item xs={12}>
            <LoginInputField
              variant="outlined"
              size="medium"
              name="password"
              type="password"
              label="Password"
              fullWidth={true}
              control={control}
              rules={{
                required: "You must specify the password",
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} style={{ display: "flex" }}>
          <span style={{ flexGrow: 1 }} />
          <LoadingButton
            id="signin-button"
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isFormValid}
            loading={formState.isSubmitting}
          >
            Sign in
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
