/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import Chart, { ChartData, ChartPoint } from "chart.js";
import Box from "@mui/material/Box";
import head from "lodash/head";
import { InlineChartLegend } from "./legend/InlineChartLegend";
import { ColumnarChartLegend } from "./legend/ColumnarChartLegend";
import { datasetLegendProvider } from "./legend/datasetLegendProvider";
import { singleDatasetLegendProvider } from "./legend/singleDatasetLegendProvider";
import { ChartTooltipContainer } from "./tooltip/ChartTooltipContainer";
import { ReportWrapper } from "./ReportWrapper";

interface ChartWrapperProps {
  data: Chart.ChartData | "loading" | "error" | undefined;
  title?: string | React.ReactNode;
  height: number;
  width?: number;
  children: (data: Chart.ChartData) => React.ReactNode;
  containerless?: boolean;
  classes?: { chart?: string; container?: string };
  legend?: {
    type: "dataset" | "data";
    variant: "none" | "inline" | "right";
    valueFormatter?: (
      value: number | null | undefined | number[] | ChartPoint
    ) => string | null;
    labelFormatter?: (inputLabel: string) => string;
    bulletSize?: string;
    className?: string;
    hiddenItems?: string[];
  };
}

export const ChartWrapper: React.FunctionComponent<ChartWrapperProps> = ({
  data,
  title = null,
  width,
  height,
  children,
  containerless = false,
  classes: { chart: chartClasses, container: containerClasses } = {},
  legend: {
    type = "dataset",
    variant: legendVariant,
    valueFormatter: legendValueFormatter = (
      val: number | null | undefined | number[] | ChartPoint
    ) => val?.toString() ?? "-",
    bulletSize,
    labelFormatter,
    className: legendClassName,
    hiddenItems = [],
  } = {
    variant: "none",
  },
}) => {
  const [hiddenLegendItems, setHiddenLegendItems] =
    useState<string[]>(hiddenItems);

  const toggleLegendItemVisibility = (label: string) => () => {
    if (hiddenLegendItems.includes(label)) {
      setHiddenLegendItems(
        hiddenLegendItems.filter((items) => items !== label)
      );
    } else {
      setHiddenLegendItems([...hiddenLegendItems, label]);
    }
  };

  const { filterData, getLegendItems } =
    type === "dataset"
      ? datasetLegendProvider(
          data,
          hiddenLegendItems,
          toggleLegendItemVisibility,
          legendValueFormatter
        )
      : singleDatasetLegendProvider(
          data,
          hiddenLegendItems,
          toggleLegendItemVisibility,
          legendValueFormatter
        );

  return (
    <ReportWrapper<ChartData>
      data={data}
      title={title}
      height={height}
      isEmpty={(data: ChartData) => head(data.datasets)?.data?.length === 0}
      containerless={containerless}
      className={containerClasses}
      titleAmendment={
        legendVariant === "inline" && (
          <InlineChartLegend
            items={getLegendItems()}
            bulletSize={bulletSize}
            labelFormatter={labelFormatter}
          />
        )
      }
    >
      {(processedData: ChartData) => (
        <Box
          className={chartClasses}
          display={legendVariant === "right" ? "flex" : "block"}
        >
          <ChartTooltipContainer
            style={
              !width && legendVariant === "right"
                ? { width: `calc(66% - 1px)` }
                : {}
            }
          >
            <Box style={{ width, height }}>
              {children(
                legendVariant === "none"
                  ? processedData
                  : filterData(processedData)
              )}
            </Box>
          </ChartTooltipContainer>

          {legendVariant === "right" && (
            <ColumnarChartLegend
              bulletSize={bulletSize}
              items={getLegendItems()}
              labelFormatter={labelFormatter}
              className={legendClassName}
            />
          )}
        </Box>
      )}
    </ReportWrapper>
  );
};
