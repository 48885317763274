/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { AxiosError } from "axios";
import {
  DataProductSepTaskError,
  DataProductSepTaskState,
  DataProductSepTaskStatus,
  getWorkflowStatus,
} from "../../../api/dataProduct/dataProductApi";
import { createDelayPromise, PROCESS_CANCELLED_MESSAGE } from "./WorkflowUtils";

export class BaseManager {
  constructor(
    private operationName: string,
    private manage: () => Promise<string>,
    private getManagerStatus: (
      url: string
    ) => Promise<DataProductSepTaskState> = getWorkflowStatus
  ) {}

  private isCanceled = false;

  public async start(): Promise<void> {
    const statusUrl = await this.manage().catch(
      this.handlePublishDataProductError
    );
    return this.pollUntilDone(statusUrl);
  }

  public cancel(): void {
    this.isCanceled = true;
  }

  private async pollUntilDone(statusUrl: string): Promise<void> {
    const response = await this.getManagerStatus(statusUrl);

    switch (response.status) {
      case DataProductSepTaskStatus.COMPLETED:
        return;
      case DataProductSepTaskStatus.ERROR:
        return Promise.reject({
          message: this.mapWorkflowTaskErrorsToErrorMessage(response.errors),
        });
      default:
        await createDelayPromise();
        if (!this.isCanceled) {
          return this.pollUntilDone(statusUrl);
        }
        return Promise.reject({ message: PROCESS_CANCELLED_MESSAGE });
    }
  }

  private handlePublishDataProductError = (
    error: AxiosError<DataProductWorkflowErrorResponse>
  ): Promise<string> => {
    const metadataErrors = error.response?.data.metadata?.errors;
    const message = metadataErrors
      ? this.mapWorkflowTaskErrorsToErrorMessage(metadataErrors)
      : error.message;
    return Promise.reject({ message });
  };

  private mapWorkflowTaskErrorsToErrorMessage = (
    taskErrors: DataProductSepTaskError[]
  ): string[] => {
    return taskErrors.map(
      ({ entityType, entityName, message }) =>
        `Failed to ${
          this.operationName
        } ${entityType.toLocaleLowerCase()} - ${entityName}: ${
          message ?? "Unknown error"
        }`
    );
  };
}

interface DataProductWorkflowErrorResponse {
  message: string;
  errorCode: string;
  metadata?: {
    errors: DataProductSepTaskError[];
  };
}
