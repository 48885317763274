/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, useState } from "react";
import TextField from "@mui/material/TextField";
import { createUseStyles } from "react-jss";
import { ContentErrorProps, SimpleDialog } from "./SimpleDialog";

interface DeleteDialogProps {
  onConfirm: () => Promise<void>;
  isOpen: boolean;
  close: () => void;
  entityType: string;
  entityHumanName: string;
}

export const DeleteDialog: FunctionComponent<DeleteDialogProps> = ({
  isOpen,
  close,
  onConfirm,
  entityType,
  entityHumanName,
}: DeleteDialogProps) => {
  const [isConfirmTextProper, setIsConfirmTextProper] =
    useState<boolean>(false);
  return (
    <SimpleDialog<DeleteDialogContentProps>
      isOpen={isOpen}
      close={close}
      title={`Delete ${entityType}?`}
      confirmButtonLabel="Yes, delete"
      cancelButtonLabel="No, do not delete"
      onConfirm={onConfirm}
      isConfirmationButtonDisabled={!isConfirmTextProper}
      fullWidth={true}
      contentProps={{
        entityType,
        entityHumanName,
        setIsConfirmTextProper,
      }}
      Content={DeleteDialogContent}
    />
  );
};

const useStyles = createUseStyles({
  input: {
    margin: "3rem 0 1rem 0",
  },
});

interface DeleteDialogContentProps {
  entityType: string;
  entityHumanName: string;
  setIsConfirmTextProper: (isProper: boolean) => void;
}

const DeleteDialogContent = ({
  entityHumanName,
  entityType,
  setIsConfirmTextProper,
  error,
}: DeleteDialogContentProps & ContentErrorProps) => {
  const styles = useStyles();
  return (
    <>
      <div>
        Are you sure you want to delete the {entityType}{" "}
        <b>{entityHumanName}</b>? <b>This action cannot be undone.</b>
      </div>
      {error}
      <TextField
        className={styles.input}
        fullWidth
        required
        variant="outlined"
        label="Type DELETE to confirm"
        size="small"
        onChange={(e) => {
          setIsConfirmTextProper(e.target.value === "DELETE");
        }}
      />
    </>
  );
};
