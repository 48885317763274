/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
  downloadAuditAccessLogs,
  getAuditAccessLogs,
} from "../../../../api/biac/audit/auditLogApi";
import { FiltersRowModel } from "../FilterRow";
import { ActionType } from "../ActionType";
import { AuditLogs, useFetchAuditLogs } from "../useFetchAuditLogs";

export interface AccessLogEntry {
  id: number;
  action: ActionType;
  entityCategory: string;
  entity: string;
  entitySpecified: boolean;
  grantOption: boolean;
  accessResult: "ALLOW" | "DENY" | "ROW_FILTER" | "COLUMN_MASK";
  user: string;
  roles: string[];
  queryId?: string;
  atTime: Date;
}

type AccessLogs = AuditLogs<AccessLogEntry>;

export const useAccessLogs = (
  appliedFilters: FiltersRowModel[]
): AccessLogs => {
  return useFetchAuditLogs(
    appliedFilters,
    getAuditAccessLogs,
    downloadAuditAccessLogs
  );
};
