/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { palette } from "../../../themes/palette";
import {
  CommentData,
  updateDataProductComment$,
} from "../../../api/dataProduct/comment/commentApi";
import { DeleteCommentModal } from "./DeleteCommentModal";
import { CommentContentEditor } from "./CommentContentEditor";
import { useCallback$ } from "../util/useCallback";
import { useDataProductPermissionContext } from "../permission/DataProductPermissionContext";
import { useIsLoggedInUserCommentAuthor } from "./useIsLoggedInUserCommentAuthor";
import { ThumbVote } from "./ThumbVote";
import { formatDatetime } from "../../../utils/formatDateInterval";
import { MarkDown } from "../../../components/markDown/MarkDown";

const useStyles = createUseStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "1rem",
    borderBottom: `1px solid ${palette.nebulaNavy50}`,
    marginBottom: "1.1875rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  commentInfo: {
    fontWeight: 400,
    color: palette.black54,
    fontSize: "0.75rem",
  },
  commentEditedIndicator: {
    fontStyle: "italic",
  },
  commentContent: {
    marginTop: "0.875rem",
  },
  thumbVote: {
    justifyContent: "end",
  },
});
enum ContentMode {
  DISPLAY = "display",
  EDIT = "edit",
}
type CommentProps = Omit<CommentData, "id"> & {
  commentId: string;
};
export const Comment: FunctionComponent<CommentProps> = ({
  commentId,
  dataProductId,
  createdBy,
  createdAt,
  updatedAt,
  commentText,
  upvotedByCurrentUser,
  upvotes,
}: CommentProps) => {
  const styles = useStyles();
  const [contentMode, setContentMode] = useState(ContentMode.DISPLAY);
  const [editedContent, setEditedContent] = useState("");
  const handleEditClick = useCallback(() => {
    setContentMode(ContentMode.EDIT);
    setEditedContent(commentText);
  }, [commentText]);
  const handleEditConfirm = useCallback$(
    () =>
      updateDataProductComment$(
        dataProductId,
        commentId,
        editedContent
      ).subscribe(() => setContentMode(ContentMode.DISPLAY)),
    [dataProductId, commentId, editedContent]
  );
  const handleEditCancel = useCallback(() => {
    setContentMode(ContentMode.DISPLAY);
  }, []);

  const isAuthorOfComment = useIsLoggedInUserCommentAuthor(createdBy);
  const { canUpdate: canUpdateDataProduct } = useDataProductPermissionContext();
  const canManageComment = isAuthorOfComment || canUpdateDataProduct;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.commentInfo}>
          <div>{createdBy}</div>
          <div>
            <span>{formatDatetime(createdAt)}</span>
            {updatedAt !== createdAt && (
              <span className={styles.commentEditedIndicator}> (edited)</span>
            )}
          </div>
        </div>
        <div>
          <DeleteCommentModal
            dataProductId={dataProductId}
            commentId={commentId}
            createdAt={createdAt}
            createdBy={createdBy}
            opener={(openModal) => (
              <IconButton
                color="primary"
                size="small"
                onClick={openModal}
                disabled={!canManageComment}
              >
                <DeleteOutline />
              </IconButton>
            )}
          />
          <IconButton
            color="primary"
            size="small"
            onClick={handleEditClick}
            disabled={!isAuthorOfComment}
          >
            <Edit />
          </IconButton>
        </div>
      </div>
      <div className={styles.commentContent}>
        {contentMode === ContentMode.DISPLAY ? (
          <MarkDown text={commentText} />
        ) : (
          <CommentContentEditor
            content={editedContent}
            onContentChange={setEditedContent}
            onConfirm={handleEditConfirm}
            onCancel={handleEditCancel}
          />
        )}
      </div>
      {contentMode === ContentMode.DISPLAY && (
        <ThumbVote
          dataProductId={dataProductId}
          commentId={commentId}
          isVoted={upvotedByCurrentUser}
          voteCount={upvotes}
          className={styles.thumbVote}
        />
      )}
    </div>
  );
};
