/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { Dispatch, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
  createDataProduct,
  DataProductForm,
  saveTagsForDataProduct,
  SchemaDataProduct,
  TagValue,
  updateDataProduct,
} from "../../../api/dataProduct/dataProductApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { createUseStyles } from "react-jss";
import { Mode } from "../components/ViewSchema";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";
import { SimpleDialog } from "../../../components/dialog/SimpleDialog";
import { PublishFormAction } from "./publishFormReducer";
import {
  createDataProductDetailsPath,
  useDataProductDashboardRedirection,
} from "../routing/dataProductRoutingUtils";
import {
  mapToCreateDataProductPayload,
  mapToUpdateDataProductPayload,
} from "./dataProductMapper";
import { Tooltip } from "../../../components/tooltip/Tooltip";

interface DataProductWizardNavigationProps {
  dataProductForm: DataProductForm;
  tags?: TagValue[];
  wizardStep: number;
  mode?: Mode;
  publishFormDispatch: Dispatch<PublishFormAction>;
  setTags?: (newValue: TagValue[]) => void;
  setWizardStep: (newValue: number) => void;
  setError: (newValue: string | undefined) => void;
  addNewDataset?: () => void;
  saveDisabled?: boolean;
  saveAndContinueDisabled?: boolean;
}

const useStyles = createUseStyles({
  buttonIcon: {
    marginRight: "0.625rem",
  },
  saveAndContinueButton: {
    "&.Mui-disabled": {
      pointerEvents: "auto",
    },
  },
});

export const DataProductWizardNavigation: React.FunctionComponent<
  DataProductWizardNavigationProps
> = ({
  dataProductForm,
  tags,
  wizardStep,
  publishFormDispatch,
  setWizardStep,
  setError,
  addNewDataset = () => void 0,
  saveDisabled = false,
  saveAndContinueDisabled = false,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const goToDashboard = useDataProductDashboardRedirection();

  const setDataProductForm = (value: SchemaDataProduct) =>
    publishFormDispatch({ type: "setDataProductForm", dataProduct: value });

  const saveDataProductAndTags = async () => {
    let updatedDataProduct: SchemaDataProduct;
    // remove any empty views from the data product
    dataProductForm.datasets = dataProductForm.datasets.filter(
      (dataset) => !!dataset.name
    );

    if (dataProductForm?.id) {
      updatedDataProduct = await updateDataProduct(
        dataProductForm.id,
        mapToUpdateDataProductPayload(dataProductForm)
      );
    } else {
      updatedDataProduct = await createDataProduct(
        mapToCreateDataProductPayload(dataProductForm)
      );
    }

    if (updatedDataProduct?.id) {
      await saveTagsForDataProduct(updatedDataProduct.id, tags || []);
    }
    setDataProductForm(updatedDataProduct);
    return updatedDataProduct;
  };
  const saveAndContinue = async () => {
    try {
      const updatedDataProduct = await saveDataProductAndTags();
      if (wizardStep === 3) {
        history.push(createDataProductDetailsPath(updatedDataProduct.id));
      } else {
        setWizardStep(wizardStep + 1);
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const saveAsDraft = async () => {
    try {
      const updatedDataProduct = await saveDataProductAndTags();
      if (updatedDataProduct) {
        goToDashboard();
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Button
        variant="text"
        color="primary"
        onClick={() => {
          if (wizardStep === 1) {
            goToDashboard();
          } else {
            setWizardStep(wizardStep - 1);
          }
          setError(undefined);
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft} className={classes.buttonIcon} />
        Back
      </Button>
      <div>
        {wizardStep === 2 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              addNewDataset();
            }}
            style={{ marginRight: "1rem" }}
          >
            <FontAwesomeIcon icon={faPlus} className={classes.buttonIcon} />
            Add another dataset
          </Button>
        )}
        <CancelWizard />
        <Button
          variant="outlined"
          color="primary"
          onClick={saveAsDraft}
          style={{ marginRight: "1rem" }}
          disabled={saveDisabled}
        >
          Save as draft
        </Button>
        <Tooltip
          title={
            !saveDisabled && saveAndContinueDisabled
              ? "You must show columns on all datasets before you can continue"
              : ""
          }
        >
          <span>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.saveAndContinueButton}
              onClick={saveAndContinue}
              disabled={saveDisabled || saveAndContinueDisabled}
            >
              Save and {wizardStep === 3 ? "review" : "continue"}
            </Button>
          </span>
        </Tooltip>
      </div>
    </Grid>
  );
};

const CancelWizard: React.FunctionComponent = () => {
  const { isOpen, close, open } = useDialogOpenState();
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={open}
        style={{ marginRight: "1rem" }}
      >
        Cancel
      </Button>
      {isOpen && <CancelWizardDialog isOpen={isOpen} close={close} />}
    </>
  );
};

interface CancelWizardDialogProps {
  isOpen: boolean;
  close: () => void;
}

const CancelWizardDialog: React.FunctionComponent<CancelWizardDialogProps> = ({
  isOpen,
  close,
}: CancelWizardDialogProps) => {
  const goToDashboard = useDataProductDashboardRedirection();
  const onConfirm = useCallback(() => {
    goToDashboard();
    return Promise.resolve();
  }, []);

  return (
    <SimpleDialog
      isOpen={isOpen}
      close={close}
      title="Cancel confirmation"
      cancelButtonLabel="Continue"
      confirmButtonLabel="Yes, cancel"
      onConfirm={onConfirm}
      fullWidth={true}
      contentProps={{}}
      Content={CancelWizardForm}
    />
  );
};

const CancelWizardForm: React.FunctionComponent = () => (
  <div>Are you sure you want to cancel? Your progress will not be saved.</div>
);
