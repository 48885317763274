/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
export function calculateCost(
  cpuUsageSeconds: number,
  cpuHourCost: string,
  precision = 2
): string {
  const cpuUsageHours = cpuUsageSeconds / 3600;
  const price = Number(cpuHourCost);
  const totalCost = (cpuUsageHours * price).toFixed(precision);
  return `$${totalCost}`;
}

const cpuCostPersistenceKey = "vcpu-cost";
const defaultCpuCost = "0.00";

export function saveCpuCost(cost: string): void {
  return sessionStorage.setItem(cpuCostPersistenceKey, cost);
}

export function getCpuCost(): string {
  return sessionStorage.getItem(cpuCostPersistenceKey) || defaultCpuCost;
}
