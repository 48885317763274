/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { Tooltip, TooltipPlacement } from "./Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";

interface HintTooltipProps {
  title: React.ReactNode;
  classes?: {
    button?: string;
    icon?: string;
  };
  placement?: TooltipPlacement;
}

export const HintTooltip: React.FunctionComponent<HintTooltipProps> = ({
  title,
  classes,
  placement,
}) => {
  if (!title) {
    return null;
  }

  return (
    <Tooltip title={title} placement={placement}>
      <IconButton size="small" tabIndex={-1} className={classes?.button}>
        <HelpIcon className={classes?.icon} />
      </IconButton>
    </Tooltip>
  );
};
