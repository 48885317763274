/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from "react";
import { SingleQueryDetails } from "../../../../api/queryApi";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SearchField } from "../../../dataproduct/components/search/SearchField";
import { AccelerationDetailsTable, Row } from "./AccelerationDetailsTable";
import { NoData } from "../../../../components/error/NoData";

interface AccelerationDetailsProps {
  query: SingleQueryDetails;
}

type CollectedData = Record<
  string,
  Record<string, Record<string, Record<string, Record<string, number>>>>
>;

function getCustomMetricsCollectedData(query: SingleQueryDetails) {
  const collectedData: CollectedData = {};
  query.accessedMetadata.forEach((metadata) => {
    if (
      metadata.connectorMetrics &&
      Object.keys(metadata.connectorMetrics).length > 0
    ) {
      const catalog = Object.keys(metadata.connectorMetrics)
        .find((k) => k.split(":")[0] === "CATALOG_NAME")
        ?.split(":")[1];

      const schema = Object.keys(metadata.connectorMetrics)
        .find((k) => k.split(":")[0] === "SCHEMA_NAME")
        ?.split(":")[1];
      const table = Object.keys(metadata.connectorMetrics)
        .find((k) => k.split(":")[0] === "TABLE_NAME")
        ?.split(":")[1];

      if (catalog && schema && table) {
        if (!collectedData[catalog]) {
          collectedData[catalog] = {};
        }
        if (!collectedData[catalog][schema]) {
          collectedData[catalog][schema] = {};
        }
        if (!collectedData[catalog][schema][table]) {
          collectedData[catalog][schema][table] = {};
        }

        Object.entries(metadata.connectorMetrics)
          .filter(
            (i) =>
              ![
                "dispatcherPageSource",
                "dictionary",
                "TABLE_NAME",
                "SCHEMA_NAME",
                "CATALOG_NAME",
              ].includes(i[0].split(":")[0])
          )
          .forEach((pair) => {
            const [metric, col] = pair[0].split(":");
            if (col) {
              const value = pair[1].total;
              if (!collectedData[catalog][schema][table][col]) {
                collectedData[catalog][schema][table][col] = {
                  "external-match": 0,
                  "external-collect": 0,
                  "varada-match": 0,
                  "varada-collect": 0,
                  prefilled: 0,
                };
              }
              collectedData[catalog][schema][table][col][metric] += value;
            }
          });
      }
    }
  });
  return Object.keys(collectedData).length === 0 ? undefined : collectedData;
}

export const AccelerationDetails: React.FunctionComponent<
  AccelerationDetailsProps
> = (params) => {
  const collectedData = useMemo(
    () => getCustomMetricsCollectedData(params.query),
    [params.query]
  );

  const data = useMemo(() => {
    const data: Row[] = [];
    if (collectedData) {
      Object.entries(collectedData).forEach(([catalog, catalogData]) =>
        Object.entries(catalogData).forEach(([schema, schemaData]) =>
          Object.entries(schemaData).forEach(([table, tableData]) =>
            Object.entries(tableData).forEach(([column, columnData]) => {
              columnData["varada-collect"] += columnData["prefilled"];
              const overall =
                (columnData["varada-collect"] + columnData["varada-match"]) /
                (columnData["varada-collect"] +
                  columnData["varada-match"] +
                  columnData["external-collect"] +
                  columnData["external-match"]);
              const filtering =
                columnData["varada-match"] /
                (columnData["varada-match"] + columnData["external-match"]);
              const projection =
                columnData["varada-collect"] /
                (columnData["varada-collect"] + columnData["external-collect"]);
              data.push({
                catalog,
                schema,
                table,
                column,
                overall: isNaN(overall) ? 0 : overall,
                filtering: isNaN(filtering) ? 0 : filtering,
                projection: isNaN(projection) ? 0 : projection,
              });
            })
          )
        )
      );
    }
    return data;
  }, [collectedData]);

  const [searchTerm, setSearchTerm] = React.useState("");
  const preparedData = useMemo(
    () =>
      data.filter(
        (row) =>
          row.catalog.includes(searchTerm) ||
          row.schema.includes(searchTerm) ||
          row.table.includes(searchTerm) ||
          row.column.includes(searchTerm)
      ),
    [data, searchTerm]
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mt={4}
        mb={0}
      >
        <Typography variant={"h5"}>Acceleration Details</Typography>
        <SearchField
          value={searchTerm}
          onValueChange={setSearchTerm}
          minLength={0}
        />
      </Box>
      {preparedData.length > 0 && (
        <AccelerationDetailsTable
          query={params.query}
          searchTerm={searchTerm}
          data={preparedData}
        />
      )}
      {preparedData.length === 0 && (
        <NoData
          height={500}
          icon="table"
          text="No Items match your search criteria"
        />
      )}
    </>
  );
};
