/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import chunk from "lodash/chunk";
import {
  isDataError,
  isDataFetching,
  useFetchingState,
} from "../domain/useFetchingState";

const debounceMs = 400;
const pageSize = 100;

export interface UsePaginatedRecordsManagerResult<SearchOptions, Record> {
  changeSearchOptions: (searchOptions: SearchOptions) => void;
  isFetchingFirstRecords: boolean;
  isFetchingPage: boolean;
  isError: boolean;
  records: Record[];
  errorMessage: string | undefined;
  hasMoreRecords: boolean;
  fetchMore: () => void;
  refetchRecord: (id: string) => void;
}

export function usePaginatedRecordsManager<SearchOptions, Record>(
  initialSearchOptions: SearchOptions,
  fetchIdentifiers$: (searchOptions: SearchOptions) => Observable<string[]>,
  fetchRecords$: (identifiers: string[]) => Observable<Record[]>,
  extractId: (record: Record) => string
): UsePaginatedRecordsManagerResult<SearchOptions, Record> {
  const idChunksFetchApi = useFetchingState<string[][]>();
  const currentPageRecordsFetchApi = useFetchingState<Record[]>();
  const [fetchedRecords, setFetchedRecords] = useState<Record[]>([]);
  const [hasMoreRecords, setHasMoreRecords] = useState(false);
  const pageIndexToLoadNext = useRef<number>(0);

  const searchOptions$ = useMemo(
    () => new BehaviorSubject<SearchOptions>(initialSearchOptions),
    []
  );
  const changeSearchOptions = useCallback((searchOptions: SearchOptions) => {
    searchOptions$.next(searchOptions);
  }, []);

  const fetchMore$ = useMemo(
    () => new BehaviorSubject<"fetch more">("fetch more"),
    []
  );
  const fetchMore = useCallback(() => {
    if (hasMoreRecords) {
      fetchMore$.next("fetch more");
    }
  }, [hasMoreRecords]);

  const refetchRecord$ = useMemo(() => new Subject<string>(), []);
  const refetchRecord = useCallback((recordId: string) => {
    refetchRecord$.next(recordId);
  }, []);

  useEffect(() => {
    const subscription = searchOptions$
      .pipe(
        debounceTime(debounceMs),
        tap(() => {
          idChunksFetchApi.setFetching();
          pageIndexToLoadNext.current = 0;
          setFetchedRecords([]);
        }),
        switchMap((searchOptions) =>
          fetchIdentifiers$(searchOptions).pipe(
            map((allIdentifiers) => chunk(allIdentifiers, pageSize)),
            tap((identifiersChunks = []) =>
              idChunksFetchApi.setData(identifiersChunks)
            ),
            catchError(({ message }) => {
              idChunksFetchApi.setError(message);
              return of([]);
            })
          )
        ),
        switchMap((identifiersChunks) =>
          fetchMore$.pipe(
            tap(() => currentPageRecordsFetchApi.setFetching()),
            exhaustMap(() => {
              const idsToFetch =
                identifiersChunks[pageIndexToLoadNext.current] || [];
              return idsToFetch.length ? fetchRecords$(idsToFetch) : of([]);
            }),
            tap((records) => {
              pageIndexToLoadNext.current = pageIndexToLoadNext.current + 1;
              setHasMoreRecords(
                pageIndexToLoadNext.current < identifiersChunks.length
              );
              currentPageRecordsFetchApi.setData(records);
            }),
            catchError(() => {
              setHasMoreRecords(false);
              return of([]);
            })
          )
        )
      )
      .subscribe((some) => {
        setFetchedRecords((prevState) => [...prevState, ...some]);
      });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const subscription = refetchRecord$
      .pipe(
        mergeMap((id) => fetchRecords$([id])),
        map(([replacement]) => replacement),
        tap((replacement) => {
          const replacementId = extractId(replacement);
          setFetchedRecords((prevState) => {
            const recordToReplaceIndex = prevState.findIndex(
              (prevRecord) => extractId(prevRecord) === replacementId
            );
            if (recordToReplaceIndex !== undefined) {
              const newState = [...prevState];
              newState[recordToReplaceIndex] = replacement;
              return newState;
            }
            return prevState;
          });
        })
      )
      .subscribe();
    return () => subscription.unsubscribe();
  }, []);

  const isFetchingPage = isDataFetching(currentPageRecordsFetchApi.state);
  return {
    changeSearchOptions,
    isFetchingFirstRecords:
      isDataFetching(idChunksFetchApi.state) ||
      (fetchedRecords.length === 0 && isFetchingPage),
    isFetchingPage,
    isError: isDataError(idChunksFetchApi.state),
    records: fetchedRecords,
    errorMessage: idChunksFetchApi.errorMessage,
    hasMoreRecords,
    fetchMore,
    refetchRecord,
  };
}
