/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, useEffect, useState } from "react";
import { SingleScorePart } from "./SingleScorePart";
import {
  defaultColor,
  defaultScale,
  defaultStrokeColor,
  mapScoreToPartialScores,
} from "./RatingService";
import { palette } from "../../../themes/palette";
import { createUseStyles } from "react-jss";
import Typography from "@mui/material/Typography";

interface RatingScoreProps {
  score: number;
  voterCount: number;
  onClick?: () => void;
  scale?: number;
  color?: string;
  strokeColor?: string;
  className?: string;
}

const useStyles = createUseStyles({
  voterCount: {
    marginLeft: "0.25rem",
    fontVariantNumeric: "tabular-nums",
    fontWeight: 600,
    fontSize: "0.625rem",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    color: palette.black54,
  },
});

export const RatingScore: FunctionComponent<RatingScoreProps> = ({
  score,
  voterCount,
  onClick,
  scale = defaultScale,
  color = defaultColor,
  strokeColor = defaultStrokeColor,
  className,
}: RatingScoreProps) => {
  const [scaleScores, setScaleScores] = useState<number[]>([]);
  const localStyles = useStyles();
  useEffect(() => {
    setScaleScores(mapScoreToPartialScores(score, scale));
  }, [score, scale]);
  return (
    <div className={className} onClick={onClick}>
      {scaleScores.map((partialScore, index) => (
        <SingleScorePart
          key={index}
          color={color}
          strokeColor={strokeColor}
          score={partialScore}
        />
      ))}{" "}
      <Typography variant="overline" className={localStyles.voterCount}>
        {voterCount}
      </Typography>
    </div>
  );
};
