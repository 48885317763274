/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { GraphNodeBasic } from "./GroupNode";
import { graphlib, layout } from "dagre";
import { GraphGroupBasic } from "./GroupCluster";
import { GraphEdge } from "./GroupEdgePath";

export function calculateLayout(
  groups: GraphGroupBasic[],
  nodes: GraphNodeBasic[],
  edges: GraphEdge[]
): graphlib.Graph {
  const graphLayout = initializeGraphLayout();
  groups.forEach((g) => {
    graphLayout.setNode(g.id, {});
  });
  nodes.forEach((n) => {
    graphLayout.setNode(n.id, {
      shape: "rect",
      height: n.height,
      width: n.width,
    });
    if (n.groupId) {
      graphLayout.setParent(n.id, n.groupId);
    }
  });
  edges.forEach((e) => {
    graphLayout.setEdge(e.from, e.to, {});
  });
  layout(graphLayout);
  return graphLayout;
}

function getPredecessors(graph: graphlib.Graph, nodeId: string): string[] {
  return (graph.predecessors(nodeId) || []) as unknown[] as string[];
}

function getNestedPredecessors(
  graph: graphlib.Graph,
  nodeId: string
): string[] {
  const predecessors = getPredecessors(graph, nodeId);
  return [
    ...predecessors,
    ...predecessors.map((p) => getNestedPredecessors(graph, p)).flat(),
  ];
}

function getSuccessors(graph: graphlib.Graph, nodeId: string): string[] {
  return (graph.successors(nodeId) || []) as unknown[] as string[];
}

function getNestedSuccessors(graph: graphlib.Graph, nodeId: string): string[] {
  const successors = getSuccessors(graph, nodeId);
  return [
    ...successors,
    ...successors.map((p) => getNestedSuccessors(graph, p)).flat(),
  ];
}

enum NodeRelation {
  PREDECESSOR = "predecessor",
  SUCCESSOR = "successor",
}

export function getSubgraph(
  graph: graphlib.Graph | null,
  nodeId: string
): Record<string, NodeRelation> {
  const result: Record<string, NodeRelation> = {};
  if (!graph) {
    return result;
  }
  getNestedSuccessors(graph, nodeId).forEach(
    (successor) => (result[successor] = NodeRelation.SUCCESSOR)
  );
  getNestedPredecessors(graph, nodeId).forEach(
    (predecessor) => (result[predecessor] = NodeRelation.PREDECESSOR)
  );
  return result;
}

function initializeGraphLayout(): graphlib.Graph {
  return new graphlib.Graph({ compound: true })
    .setGraph({ rankdir: "BT" })
    .setDefaultEdgeLabel(function () {
      return {};
    });
}
