/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { getQueriesByTime, QueriesByTime } from "../../../api/queryReportApi";
import { ChartWrapper } from "../../../components/chart/ChartWrapper";
import { TimeLineChart } from "../../../components/chart/TimeLineChart";
import { prepareLineChartData } from "../../../utils/lineChart";
import { LazyReport } from "./LazyReport";
import { formatDateInterval } from "../../../utils/formatDateInterval";

export const QueriesByTimeChart: React.FunctionComponent = () => {
  const height = 210;

  const chartData = (
    queriesByTime: QueriesByTime | "loading" | "error" | undefined
  ) =>
    queriesByTime
      ? prepareLineChartData(
          "Queries run",
          queriesByTime,
          "intervalStart",
          "numberOfQueries",
          { fill: "origin" }
        )
      : undefined;

  return (
    <LazyReport name={"queriesByTime"} load={getQueriesByTime}>
      {(queriesByTime) => (
        <ChartWrapper
          data={chartData(queriesByTime)}
          title={`Queries over time`}
          height={height}
        >
          {(chartData): React.ReactNode => (
            <TimeLineChart
              data={chartData}
              height={height}
              tooltipDateFormat={formatDateInterval(
                queriesByTime,
                (record) => record.intervalStart,
                (record) => record.intervalEnd
              )}
              xTicksLimit={6}
              xTicksMaxRotation={0}
            />
          )}
        </ChartWrapper>
      )}
    </LazyReport>
  );
};
