/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useConfigContext } from "../../../../app/ConfigContextProvider";
import { createUseStyles } from "react-jss";
import { EditableSection } from "../../edit/EditableSection";
import { patchDataProduct } from "../../../../api/dataProduct/dataProductApi";
import { maximumSummaryLength } from "../../util/formUtils";
import { DataProductMetrics } from "../metrics/DataProductMetrics";
import { palette } from "../../../../themes/palette";
import { TextFieldWithMarkdownPreview } from "../../../../components/markDown/TextFieldWithMarkdownPreview";
import { MarkDown } from "../../../../components/markDown/MarkDown";
import { OverviewSubsectionClasses } from "./useSubsectionClasses";

const useClasses = createUseStyles({
  metrics: {
    marginRight: "1rem",
    marginBottom: "2rem",
  },
  textField: {
    marginTop: "0.75rem",
  },
  markDown: {
    marginTop: "-0.4375rem",
  },
});

interface OverviewBasicInfoProps {
  dataProductId: string;
  catalogName: string;
  summary: string;
  description: string;
  isPublished: boolean;
  classes?: OverviewSubsectionClasses;
}

export const OverviewBasicInfo = ({
  catalogName,
  summary,
  dataProductId,
  description,
  isPublished,
  classes,
}: OverviewBasicInfoProps) => {
  const dataProductStatsEnabled = useConfigContext()?.dataProductStatsEnabled;
  const internalClasses = useClasses();
  const createForm = useCallback(
    () => ({
      summary,
      description,
    }),
    [summary, description]
  );

  return (
    <EditableSection<BasicInfoFormData>
      header="Overview"
      headerVariant="h4"
      createForm={createForm}
      isFormChanged={areFormsDifferent}
      onSave={(formData) => patchDataProduct(dataProductId, formData)}
      classes={classes}
    >
      {(isEditModeOn, editedFormData, dispatchEditedFormData) => (
        <>
          <Typography variant="subtitle2">Catalog</Typography>
          <div className={classes?.body}>{catalogName}</div>

          <Typography variant="subtitle2">Summary</Typography>
          <div className={classes?.body}>
            {isEditModeOn ? (
              <TextField
                className={internalClasses.textField}
                fullWidth={true}
                value={editedFormData.summary || ""}
                label="Summary"
                variant="outlined"
                margin="dense"
                required
                multiline
                rows={3}
                inputProps={{ maxLength: maximumSummaryLength }}
                onChange={({ target: { value } }) =>
                  dispatchEditedFormData((prevState) => ({
                    ...prevState,
                    summary: value,
                  }))
                }
              />
            ) : (
              summary
            )}
          </div>

          {isPublished && dataProductStatsEnabled && (
            <DataProductMetrics
              dataProductId={dataProductId}
              className={internalClasses.metrics}
            />
          )}

          <Typography variant="subtitle2">Description</Typography>
          <div className={classes?.body}>
            {isEditModeOn ? (
              <Box
                bgcolor={palette.nebulaNavy25}
                mt={1}
                borderRadius="4px"
                border={`1px solid ${palette.nebulaNavy50}`}
              >
                <TextFieldWithMarkdownPreview
                  value={editedFormData.description}
                  minRows={4}
                  previewContentMinHeight={82}
                  placeholder="Enter Description"
                  onChange={({ target: { value } }) =>
                    dispatchEditedFormData((prevState) => ({
                      ...prevState,
                      description: value,
                    }))
                  }
                />
              </Box>
            ) : (
              <MarkDown
                text={description}
                className={internalClasses.markDown}
              />
            )}
          </div>
        </>
      )}
    </EditableSection>
  );
};

interface BasicInfoFormData {
  summary: string;
  description: string;
}

function areFormsDifferent(
  form1: BasicInfoFormData,
  form2: BasicInfoFormData
): boolean {
  return (
    form1.description !== form2.description || form1.summary !== form2.summary
  );
}
