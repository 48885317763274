/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import React, { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { createUseStyles } from "react-jss";
import { palette } from "../../../themes/palette";

interface TextFilterProps {
  value: string;
  setValue: (newValue: string) => void;
  placeholder?: string;
}

const useStyles = createUseStyles({
  icon: {
    color: palette.black54,
    marginRight: "3px",
  },
  input: {
    backgroundColor: "white",
  },
});

export const TextFilter: React.FunctionComponent<TextFilterProps> = ({
  value,
  setValue,
  placeholder = "Search queries",
}) => {
  const classes = useStyles();

  const onTextChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);
  };

  return (
    <Grid item lg={6} xs={8}>
      <TextField
        value={value || ""}
        onChange={onTextChange}
        className={classes.input}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faSearch} className={classes.icon} />
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
        variant="outlined"
        margin="dense"
      />
    </Grid>
  );
};
