/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StatusIcon } from "../../../../components/icon/StatusIcon";
import { createUseStyles } from "react-jss";
import { TaskDetail } from "../../../../api/queryApi";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import {
  SortableColumn,
  SortingState,
} from "../../../../components/table/SortableColumn";
import { palette } from "../../../../themes/palette";

interface HeadRowCell {
  id: keyof TaskDetail;
  label: string | React.ReactElement;
  disableSorting?: boolean;
}

const useStyles = createUseStyles({
  iconStyle: {
    fontSize: "20px",
    color: palette.black,
  },
});

interface QueryStageTableHeaderProps {
  sorting: SortingState<keyof TaskDetail>;
  setSorting: (newState: SortingState<keyof TaskDetail>) => void;
  className: string;
}

export const QueryStageTableHeader: React.FunctionComponent<
  QueryStageTableHeaderProps
> = ({ sorting, setSorting, className }) => {
  const classes = useStyles();
  const headRowCells: HeadRowCell[] = [
    {
      id: "id",
      label: "ID",
    },
    {
      id: "nodeHost",
      label: "Host (Node)",
    },
    {
      id: "state",
      label: "State",
      disableSorting: true,
    },
    {
      id: "queuedSplits",
      label: (
        <Tooltip title={"Queued Splits"} placement={"top-start"}>
          <span>
            <StatusIcon className={classes.iconStyle} state={"QUEUED"} />
          </span>
        </Tooltip>
      ),
    },
    {
      id: "runningSplits",
      label: (
        <Tooltip title={"Running Splits"} placement={"top-start"}>
          <span>
            <StatusIcon className={classes.iconStyle} state={"RUNNING"} />
          </span>
        </Tooltip>
      ),
    },
    {
      id: "blockedSplits",
      label: (
        <Tooltip title={"Blocked Splits"} placement={"top-start"}>
          <span>
            <StatusIcon className={classes.iconStyle} state={"ABORTED"} />
          </span>
        </Tooltip>
      ),
    },
    {
      id: "completedSplits",
      label: (
        <Tooltip title={"Completed Splits"} placement={"top-start"}>
          <span>
            <StatusIcon className={classes.iconStyle} state={"FINISHED"} />
          </span>
        </Tooltip>
      ),
    },
    { id: "rowsRead", label: "Rows" },
    {
      id: "rowsPerSecond",
      label: "Rows/s",
    },
    { id: "bytesRead", label: "Bytes" },
    {
      id: "bytesPerSecond",
      label: "Bytes/s",
    },
    {
      id: "elapsedTime",
      label: "Elapsed",
    },
    { id: "cpuTime", label: "CPU time" },
    {
      id: "bytesBuffered",
      label: "Buffered",
    },
  ];
  return (
    <TableHead>
      <TableRow className={className}>
        {headRowCells.map((headCell) => (
          <SortableColumn<keyof TaskDetail>
            key={headCell.id}
            label={headCell.label}
            sortingKey={headCell.id}
            sortingState={sorting}
            setSortingState={setSorting}
            component="td"
            disabled={headCell.disableSorting}
          />
        ))}
      </TableRow>
    </TableHead>
  );
};
