/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import React from "react";
import { SingleQueryDetails } from "../../../../api/queryApi";
import { formatDuration } from "../../../../utils/formatDuration";
import { DetailsCell } from "../../../../components/details/DetailsCell";
import { DetailsRow } from "../../../../components/details/DetailsRow";
import { useSectionStyles } from "./useSectionStyles";

interface QueryDetailsSectionProps {
  query: Readonly<SingleQueryDetails>;
}

export const QueryDetailsSection: React.FunctionComponent<
  QueryDetailsSectionProps
> = ({ query }) => {
  const classes = useSectionStyles();
  return (
    <Box mb={1}>
      <div className={classes.header}>Basic information</div>
      <Box>
        <DetailsRow>
          <DetailsCell
            header="User"
            value={query.user}
            classes={{ value: classes.itemValue }}
          />
          <DetailsCell
            header="Client"
            value={query.source}
            classes={{ value: classes.itemValue }}
          />
          <DetailsCell
            header="CPU time"
            value={formatDuration(query.cpuTime)}
            classes={{ value: classes.itemValue }}
          />
        </DetailsRow>
        <DetailsRow>
          <DetailsCell
            header="Principal"
            value={query.principal}
            classes={{ value: classes.itemValue }}
          />
          <DetailsCell
            header="Client address"
            value={query.clientAddress}
            classes={{ value: classes.itemValue }}
          />
          <DetailsCell
            header="Elapsed time"
            value={formatDuration(query.elapsedTime)}
            classes={{ value: classes.itemValue }}
          />
        </DetailsRow>
        <DetailsRow>
          <DetailsCell
            header="User groups"
            value={query.groups?.join(", ") ?? ""}
            classes={{ value: classes.itemValue }}
          />
        </DetailsRow>
      </Box>
    </Box>
  );
};
