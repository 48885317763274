/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import axios from "axios";

export interface LicenseInfo {
  owner: string;
  tier: string;
  type: "UNKNOWN" | "JSON" | "AWS";
  expiry: string;
  hasFile: boolean;
  docUrl: string;
  features: LicenseFeature[];
}

export interface LicenseFeature {
  code: string;
  displayName: string;
  enabled: boolean;
}

const baseUrl = "/ui/api/insights/license";
const downloadLicenseUrl = `${baseUrl}/download`;

export const getLicenseInfo = (): Promise<LicenseInfo> => {
  return axios.get(baseUrl).then((response) => response.data);
};

export const downloadLicenseFileData = (): Promise<unknown> => {
  return axios.get(downloadLicenseUrl).then((res) => res.data);
};
