/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from "react-jss";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";

interface SpinnerProps {
  delay?: number; // in millies
  position: "absolute" | "relative" | "no-wrapper";
  className?: string;
  size?: string | number;
  placeholder?: ReactNode;
}

const useStyles = createUseStyles({
  absolute: {
    position: "absolute",
    left: "50%",
    marginLeft: "-75px",
    top: "50%",
    marginTop: "-110px",
  },
  relative: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
});

export const Spinner: React.FunctionComponent<SpinnerProps> = ({
  delay = 0,
  position,
  className,
  size,
  placeholder = null,
}: SpinnerProps) => {
  const [{ delayElapsed }, setState] = useState({
    delayElapsed: false,
  });
  const timeoutHandler = useRef<number | undefined>(undefined);
  const classes = useStyles();

  useEffect(() => {
    const clearTimeout = (): void => {
      window.clearTimeout(timeoutHandler.current);
    };

    const startTimeout = (): void => {
      clearTimeout();
      setState({ delayElapsed: false });
      timeoutHandler.current = window.setTimeout(
        () => setState((prevState) => ({ ...prevState, delayElapsed: true })),
        delay
      );
    };

    startTimeout();
    return clearTimeout;
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {delayElapsed ? (
        position === "no-wrapper" ? (
          <CircularProgress className={className} size={size} />
        ) : (
          <div
            className={clsx(
              {
                [classes.absolute]: position === "absolute",
                [classes.relative]: position === "relative",
              },
              className
            )}
          >
            <CircularProgress size={size} />
          </div>
        )
      ) : (
        placeholder
      )}
    </>
  );
};
