/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  FunctionComponent,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { ChartTooltipModel } from "chart.js";
import ChartComponent, { ChartComponentProps } from "react-chartjs-2";
import { palette } from "../../../themes/palette";
import head from "lodash/head";
import zip from "lodash/zip";
import ReactDOM from "react-dom";
import { createUseStyles } from "react-jss";
import { shadows } from "../../../themes/shadows";

export const chartTooltip =
  (
    chartRef: React.MutableRefObject<ChartComponent<ChartComponentProps> | null>,
    containerId: string | undefined,
    // todo: deprecated - use callbacks.label instead
    valueFormatter: (input: string | number | undefined) => string = (input) =>
      input?.toString() ?? ""
  ): ((tooltipModel: ChartTooltipModel) => void) =>
  (tooltipModel: ChartTooltipModel) => {
    const chart = chartRef.current;
    if (!chart || !containerId) {
      return;
    }

    const container = document.getElementById(containerId);
    const left = chart?.chartInstance.canvas?.offsetLeft;
    const top = chart?.chartInstance.canvas?.offsetTop;
    const width = chart?.chartInstance.canvas?.offsetWidth;

    if (left === undefined || top === undefined || width === undefined) {
      return;
    }

    ReactDOM.render(
      <ChartTooltip
        chartLeft={left}
        chartTop={top}
        chartWidth={width}
        tooltipModel={tooltipModel}
        valueFormatter={valueFormatter}
      />,
      container
    );
  };

interface ChartTooltipProps {
  chartLeft: number;
  chartTop: number;
  chartWidth: number;
  tooltipModel: ChartTooltipModel;
  valueFormatter: (input: string | number | undefined) => string;
}

const maxWidth = 250;

const useStyles = createUseStyles({
  root: {
    position: "absolute",
    backgroundColor: palette.nebulaNavy50,
    border: "1px solid #BCC2D8",
    boxShadow: shadows[2],
    borderRadius: "4px",
    lineHeight: "1.4em",
    fontSize: "0.825rem",
    padding: "4px 8px",
    color: palette.nebulaNavy,
    pointerEvents: "none",
    zIndex: 10000,
    userSelect: "none",
    minWidth: "150px",
    maxWidth: `${maxWidth}px`,
  },
  header: {
    fontWeight: 600,
    padding: "6px 0 6px 0",
    textAlign: "center",
  },
  line: {
    padding: "3px 2px 3px 2px",
    display: "flex",
    alignItems: "center",
  },
  color: {
    minHeight: "0.875rem",
    maxHeight: "0.875rem",
    minWidth: "0.875rem",
    maxWidth: "0.875rem",
    marginRight: "0.375rem",
    borderRadius: "3px",
  },
  value: {
    fontWeight: 600,
    paddingLeft: "1rem",
    marginLeft: "auto",
  },
  label: {
    wordBreak: "break-word",
    maxWidth: "160px",
  },
});

const extractTooltipEntries = (
  tooltipModel: ChartTooltipModel,
  valueFormatter: (input: string | number | undefined) => string
) => {
  return zip(tooltipModel.body, tooltipModel.labelColors).flatMap((point) => {
    const line = point[0]?.lines[0];
    if (!line) {
      return [];
    }
    const splitIndex = line.lastIndexOf(":");

    return [
      {
        label: line.substring(0, splitIndex),
        value: valueFormatter(
          parseFloat(line.substring(splitIndex + 1, line.length))
        ),
        color: (point[1] as unknown as { backgroundColor: string })
          .backgroundColor,
      },
    ];
  });
};

const ChartTooltip: FunctionComponent<ChartTooltipProps> = ({
  chartLeft,
  chartTop,
  chartWidth,
  tooltipModel,
  valueFormatter,
}) => {
  const classes = useStyles();
  const targetRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setWidth(targetRef.current.offsetWidth);
    }
  }, [targetRef.current]);

  if (tooltipModel.opacity === 0) {
    return null;
  }

  const left = (function () {
    const margin = -20;
    const getOffset = () => {
      if (chartWidth - tooltipModel.caretX < (maxWidth + margin) / 2) {
        return -margin - width; // move to left
      } else if (tooltipModel.caretX < (maxWidth + margin) / 2) {
        return margin; // move to right
      } else {
        return -width / 2; // center align
      }
    };

    return chartLeft + tooltipModel.caretX + getOffset();
  })();
  const top = chartTop + tooltipModel.caretY;

  const header = head(tooltipModel.title);
  const values = extractTooltipEntries(tooltipModel, valueFormatter);

  return (
    <div ref={targetRef} style={{ top, left }} className={classes.root}>
      <div className={classes.header}>{header}</div>
      <div>
        {values.map(({ label, value, color }) => (
          <div key={label} className={classes.line}>
            <div style={{ backgroundColor: color }} className={classes.color} />
            <div className={classes.label}>{label}</div>
            <div className={classes.value}>{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
