/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Overview } from "../features/overview/Overview";
import {
  QueryHistory,
  queryHistoryModeParam,
} from "../features/query/history/QueryHistory";
import { QueryDetails } from "../features/query/details/QueryDetails";
import { ClusterHistory } from "../features/cluster/ClusterHistory";
import { UsageMetrics } from "../features/usage/UsageMetrics";
import { QueryEditor } from "../features/ide/QueryEditor";
import { Roles } from "../features/biac/Roles";
import { DataProductRouting } from "../features/dataproduct/routing/DataProductRouting";
import { dataProductModuleRootPath } from "../features/dataproduct/routing/dataProductRoutingUtils";
import { MainLayout } from "../layout/MainLayout";
import { Settings } from "../layout/settings/Settings";
import { AuditLog } from "../features/biac/audit/AuditLog";
import { AppContext } from "./AppContext";
import {
  loginRoute,
  LoginRouting,
  logoutRoute,
} from "../layout/login/routing/LoginRouting";
import { LogoutPage } from "../layout/logout/LogoutPage";
import { UIFeatureRoute } from "../components/biac/routes/UIFeatureRoute";
import { UiFeatures } from "../api/configApi";
import { AddPrivileges } from "../features/biac/add-privileges/AddPrivileges";
import { MasksAndFilters } from "../features/biac/masks-and-filters/MasksAndFilters";
import { QueryDetailsTab } from "../features/query/details/QueryDetailsTabsBar";

const viewQueryParam = new URLSearchParams(location.search).get(
  queryHistoryModeParam
);

export const AppRouter: React.FunctionComponent = () => {
  return (
    <Router basename={`${process.env.PUBLIC_URL}`}>
      <Switch>
        <Route path={loginRoute} exact>
          <LoginRouting />
        </Route>
        <Route path={logoutRoute} exact>
          <LogoutPage />
        </Route>
        <Route path="*">
          <AppContext>
            <MainLayout>
              <Switch>
                <UIFeatureRoute
                  feature={UiFeatures.DATA_PRODUCTS}
                  path={dataProductModuleRootPath}
                >
                  <DataProductRouting />
                </UIFeatureRoute>
                <Route
                  exact
                  path="/query/:queryId"
                  render={({
                    match: {
                      params: { queryId },
                    },
                  }) => (
                    <Redirect
                      to={`/query/${queryId}/${QueryDetailsTab.GENERAL}`}
                    />
                  )}
                />
                <UIFeatureRoute
                  feature={UiFeatures.QUERIES}
                  path="/query/:queryId/:tab"
                >
                  <QueryDetails />
                </UIFeatureRoute>

                <UIFeatureRoute
                  path="/query"
                  exact
                  feature={UiFeatures.QUERIES}
                >
                  <QueryHistory
                    mode={
                      viewQueryParam === "table" || viewQueryParam === "chart"
                        ? viewQueryParam
                        : null
                    }
                  />
                </UIFeatureRoute>
                <UIFeatureRoute
                  path="/cluster"
                  exact
                  feature={UiFeatures.CLUSTER_HISTORY}
                >
                  <ClusterHistory />
                </UIFeatureRoute>
                <UIFeatureRoute
                  path="/usage"
                  exact
                  feature={UiFeatures.USAGE_METRICS}
                >
                  <UsageMetrics />
                </UIFeatureRoute>
                <UIFeatureRoute
                  path="/ide"
                  exact
                  feature={UiFeatures.QUERY_EDITOR}
                >
                  <QueryEditor />
                </UIFeatureRoute>
                <Route path="/roles" exact>
                  <Roles />
                </Route>
                <Route path="/roles/:roleId" exact>
                  <AddPrivileges />
                </Route>
                <Route path="/log/audit" exact>
                  <AuditLog />
                </Route>
                <UIFeatureRoute
                  path="/settings"
                  exact
                  feature={UiFeatures.SETTINGS}
                >
                  <Settings />
                </UIFeatureRoute>
                <Route path="/masks-and-filters" exact>
                  <MasksAndFilters />
                </Route>
                <UIFeatureRoute
                  path={["/", "/index.html"]}
                  feature={UiFeatures.OVERVIEW}
                >
                  <Overview />
                </UIFeatureRoute>
              </Switch>
            </MainLayout>
          </AppContext>
        </Route>
      </Switch>
    </Router>
  );
};
