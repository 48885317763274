/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import { createUseStyles } from "react-jss";

import { createDataDomain } from "../../../../api/dataProduct/dataDomain/dataDomainApi";
import { SimpleDialog } from "../../../../components/dialog/SimpleDialog";
import { useDomainFormReducer } from "../form/useDomainFormReducer";
import { CreateDomainFormProps, DomainForm } from "../form/DomainForm";
import { useDialogOpenState } from "../../../../components/dialog/useDialogOpenState";
import { useUserGlobalPermissionContext } from "../../permission/UserGlobalPermissionContext";
import { Tooltip } from "../../../../components/tooltip/Tooltip";

export const CreateDomainDialog: React.FunctionComponent = () => {
  const { isOpen, close, open } = useDialogOpenState();
  const canUserCreateDomains =
    useUserGlobalPermissionContext().canCreateDataDomains;
  return (
    <>
      <Tooltip
        title={
          !canUserCreateDomains
            ? "You do not have permission to create domains"
            : ""
        }
      >
        <span>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={open}
            disabled={!canUserCreateDomains}
          >
            Create new domain
          </Button>
        </span>
      </Tooltip>

      {isOpen && <CreateDomainFormDialog isOpen={isOpen} close={close} />}
    </>
  );
};

const useStyles = createUseStyles({
  form: {
    padding: "1rem 0",
  },
});

interface CreateDomainFormDialogProps {
  isOpen: boolean;
  close: () => void;
}

const CreateDomainFormDialog = ({
  isOpen,
  close,
}: CreateDomainFormDialogProps) => {
  const styles = useStyles();
  const [formData, dispatch] = useDomainFormReducer({
    name: "",
    description: "",
    schemaLocation: "",
    assignedDataProducts: [],
  });
  const onConfirm = useCallback(() => createDataDomain(formData), [formData]);

  return (
    <SimpleDialog<CreateDomainFormProps>
      isOpen={isOpen}
      close={close}
      title="Create a new domain"
      confirmButtonLabel="Create domain"
      onConfirm={onConfirm}
      isConfirmationButtonDisabled={!formData.name}
      fullWidth={true}
      contentProps={{
        className: styles.form,
        dispatch,
        formData,
      }}
      Content={DomainForm}
    />
  );
};
