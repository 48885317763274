/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { faPaste } from "@fortawesome/pro-solid-svg-icons/faPaste";
import clsx from "clsx";
import { createUseStyles } from "react-jss";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "../../themes/palette";

const useStyles = createUseStyles({
  box: {
    background: palette.codeHighlightGrey,
    "& .MuiInputBase-inputMultiline": {
      fontFamily: "Monaco, monospace",
      fontSize: "14px",
      fontWeight: 400,
      wordBreak: "break-all",
    },
  },
  boxInput: {
    alignItems: "start",
  },
  copyButton: {
    position: "relative",
    top: "9px",
    right: "-10px",
  },
  copyIcon: {
    backgroundColor: palette.white,
    border: "1px solid #bcc2d8",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
});

const CopyToClipboardAdornment: React.FunctionComponent<{ text: string }> = ({
  text,
}) => {
  const styles = useStyles();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const openTooltip = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      if (mounted.current) {
        setTooltipOpen(false);
      }
    }, 2000);
  };

  return (
    <InputAdornment className={styles.copyButton} position="end">
      <CopyToClipboard text={text} onCopy={() => openTooltip()}>
        <Tooltip title="Copied!" open={tooltipOpen}>
          <IconButton size="small" color="primary" className={styles.copyIcon}>
            <FontAwesomeIcon icon={faPaste} />
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    </InputAdornment>
  );
};

interface CodeBoxProps {
  label?: string;
  text: string;
  className?: string;
}

export const CodeBox: React.FunctionComponent<CodeBoxProps> = ({
  label,
  text,
  className,
}) => {
  const localClasses = useStyles();

  return (
    <TextField
      variant="outlined"
      label={label}
      multiline={true}
      fullWidth={true}
      className={clsx(localClasses.box, className)}
      InputProps={{
        readOnly: true,
        className: localClasses.boxInput,
        endAdornment: <CopyToClipboardAdornment text={text} />,
      }}
      value={text}
    />
  );
};

export default CodeBox;
