/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LoginPage } from "../LoginPage";
import { checkLoginStatus } from "../../../api/loginApi";

export const loginRoute = "/login";
export const logoutRoute = "/logout";
const uiBaseUrl = "/ui/insights/";

const redirectPath = new URLSearchParams(window.location.search)
  .get("redirect")
  ?.replaceAll(uiBaseUrl, "");

export const LoginRouting: React.FunctionComponent = () => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    checkLoginStatus()
      .then((status) => {
        status === 200 && setIsAuthorized(true);
      })
      .catch(() => {
        setIsAuthorized(false);
      });
  }, []);

  return (
    <Switch>
      <Route path={loginRoute} exact>
        {/*if the user is already authorized and tried to visit login screen redirect to home screen*/}
        {isAuthorized && <Redirect to={"/"} />}
        {/*if the user tried to visit a specific path without logging
          e.g <server>/ui/insights/<path> we capture the path and redirects to that specific path after logging in
          or we will redirect to home screen or cluster overview */}
        <LoginPage redirectPath={redirectPath ?? "/"} />
      </Route>
    </Switch>
  );
};
