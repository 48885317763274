/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { createUseStyles } from "react-jss";
import { SortCategory } from "../dataProductSortingService";

const useStyles = createUseStyles({
  select: {
    marginRight: "1.125rem",
    marginTop: "1rem",
    height: "36px",
    width: "160px",
    fontSize: "0.875rem",
  },
  selectInput: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
});

interface SortDataProductListProps {
  value: SortCategory;
  onValueChange: (value: SortCategory) => void;
}

export const SortDataProductList: React.FunctionComponent<
  SortDataProductListProps
> = ({ onValueChange, value }: SortDataProductListProps) => {
  const handleChange = (e: SelectChangeEvent<SortCategory>) => {
    onValueChange(e.target.value as SortCategory);
  };
  const className = useStyles();

  return (
    <Select
      value={value}
      onChange={handleChange}
      variant="outlined"
      className={className.select}
      classes={{ select: className.selectInput }}
    >
      <MenuItem value={SortCategory.NAME_ASC}>Alpha: A to Z</MenuItem>
      <MenuItem value={SortCategory.NAME_DESC}>Alpha: Z to A</MenuItem>
      <MenuItem value={SortCategory.CREATED_DATE_ASC}>Oldest</MenuItem>
      <MenuItem value={SortCategory.CREATED_DATE_DESC}>Newest</MenuItem>
      <MenuItem value={SortCategory.LAST_QUERIED_AT_ASC}>
        Last queried ascending
      </MenuItem>
      <MenuItem value={SortCategory.LAST_QUERIED_AT_DESC}>
        Last queried descending
      </MenuItem>
      <MenuItem value={SortCategory.STATUS_ASC}>Status ascending</MenuItem>
      <MenuItem value={SortCategory.STATUS_DESC}>Status descending</MenuItem>
      <MenuItem value={SortCategory.BOOKMARK_ASC}>Bookmarked first</MenuItem>
      <MenuItem value={SortCategory.BOOKMARK_DESC}>Bookmarked last</MenuItem>
      <MenuItem value={SortCategory.RATING_AVERAGE_ASC}>Low rated</MenuItem>
      <MenuItem value={SortCategory.RATING_AVERAGE_DESC}>Top rated</MenuItem>
    </Select>
  );
};
