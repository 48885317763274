/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from "react";
import { QueriesByUser } from "../../../api/queryReportApi";
import { TopEntitiesChart } from "../../../components/chart/TopEntitiesChart";
import { ChartWrapper } from "../../../components/chart/ChartWrapper";
import { BarChart } from "../../../components/chart/BarChart";

interface QueriesByUserOverviewChartProps {
  queriesByUser: QueriesByUser | "loading" | "error";
  height: number;
}

export const QueriesByUserOverviewChart: React.FunctionComponent<
  QueriesByUserOverviewChartProps
> = ({ queriesByUser, height }) => {
  const slicesLimit = 5;

  return (
    <TopEntitiesChart<QueriesByUser[number]>
      data={queriesByUser}
      labelKey="user"
      valueKey="numberOfQueries"
      limit={slicesLimit}
      otherLabel="other"
    >
      {(topUsers): ReactElement => (
        <ChartWrapper
          data={topUsers}
          title={null}
          height={height}
          legend={{
            type: "data",
            variant: "right",
            bulletSize: "1rem",
            valueFormatter: () => null,
          }}
          containerless={true}
        >
          {(chartData) => (
            <BarChart
              data={chartData}
              tooltipText="Queries run"
              height={height}
            />
          )}
        </ChartWrapper>
      )}
    </TopEntitiesChart>
  );
};
