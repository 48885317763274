/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { AttributeSubject } from "../../api/biac/biacApi";
import { SubjectChooser } from "./grants/SubjectChooser";
import { ChosenRole, RoleChooser } from "./grants/RoleChooser";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { EmptyOrValue } from "../../utils/value";
import { PUBLIC_ROLE_ID, SYSTEM_ROLE_ID } from "../../api/biac/biacRolesApi";
import Typography from "@mui/material/Typography";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
  },
  headerText: {
    fontSize: "1.25rem",
    fontWeight: 500,
    textAlign: "center",
    letterSpacing: "0.5px",
    padding: "1.25rem 2rem 0 2rem",
  },
  buttonsWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0 1.5rem 1.5rem 1.5rem",
  },
  addButton: {
    marginLeft: "1rem",
  },
}));

interface GrantToRoleProps {
  currentRoleName: string;
  roleId: number;
  roleName: string;
  grantOption: boolean;
  users: string[];
  groups: string[];
  roles: number[];
  grantRoleToSubject(
    roleId: number,
    subjectKind: AttributeSubject,
    subject: string,
    adminOption: boolean
  ): Promise<void>;
  reload(): Promise<void>;
  closeDialog(): void;
}

export const GrantToRole: React.FunctionComponent<GrantToRoleProps> = ({
  currentRoleName,
  roleId,
  roleName,
  grantOption,
  users,
  groups,
  roles,
  reload,
  grantRoleToSubject,
  closeDialog,
}) => {
  const classes = useStyles();

  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [entityCategory, setEntityCategory] =
    useState<AttributeSubject>("user");
  const handleEntityCategoryChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      const entity = event.target.value as AttributeSubject;
      setEntityCategory(entity);

      setUser({
        empty: true,
        value: null,
      });
      setGroupName({
        empty: true,
        value: null,
      });
      setTargetRole({
        empty: true,
        value: null,
      });
      setAdminOption(false);
    },
    []
  );

  const [user, setUser] = useState<EmptyOrValue<string | null>>({
    empty: true,
    value: null,
  });
  const [targetRole, setTargetRole] = useState<EmptyOrValue<ChosenRole | null>>(
    {
      empty: true,
      value: null,
    }
  );
  const [groupName, setGroupName] = useState<EmptyOrValue<string | null>>({
    empty: true,
    value: null,
  });
  const [adminOption, setAdminOption] = useState<boolean>(false);

  const handleAssignToRole = async () => {
    let subject: string | null = null;

    switch (entityCategory) {
      case "user":
        if (user.value) {
          subject = user.value;
        }
        break;
      case "group":
        if (groupName.value) {
          subject = groupName.value;
        }
        break;
      case "role":
        if (targetRole.value) {
          subject = targetRole.value.id
            ? targetRole.value.id.toString(10)
            : null;
        }
        break;
    }

    if (!subject) {
      setError("Subject is empty");
      return;
    }

    setBusy(true);
    setError(null);

    // minimum wait time for avoiding spinner flash
    const minimumWait = new Promise<void>((resolve) =>
      setTimeout(resolve, 700)
    );

    try {
      await grantRoleToSubject(roleId, entityCategory, subject, adminOption);
    } catch (e) {
      setBusy(false);
      setError(e.message);
      return;
    }

    await Promise.all([reload(), minimumWait]);
    closeDialog();
  };

  const excludedRoles = useMemo(
    () => [SYSTEM_ROLE_ID, PUBLIC_ROLE_ID, roleId, ...roles],
    [roleId, roles]
  );

  return (
    <Dialog open={true} className={classes.root}>
      <div className={classes.headerText}>
        <Typography variant="h5" style={{ margin: 0 }}>
          Assign to role: {roleName}
        </Typography>
      </div>
      <Grid
        container
        wrap="nowrap"
        direction="column"
        rowSpacing={1}
        style={{ width: "450px", margin: "1rem" }}
      >
        {error && (
          <Grid item>
            <Alert variant="standard" severity="error">
              {error}
            </Alert>
          </Grid>
        )}
        <Grid item>Entity</Grid>
        <Grid item>
          <TextField
            select
            required
            fullWidth
            margin="dense"
            label="Entity category"
            disabled={busy}
            value={entityCategory}
            onChange={handleEntityCategoryChange}
            variant="outlined"
          >
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="group">Group</MenuItem>
            <MenuItem value="role">Role</MenuItem>
          </TextField>
        </Grid>
        <Grid item>
          {entityCategory === "user" && (
            <SubjectChooser
              currentRoleName={currentRoleName}
              disabled={busy}
              excludedSubjects={users}
              label="User"
              subjectType="user"
              value={user}
              handleChange={setUser}
            />
          )}
          {entityCategory === "group" && (
            <SubjectChooser
              currentRoleName={currentRoleName}
              disabled={busy}
              excludedSubjects={groups}
              label="Group"
              subjectType="group"
              value={groupName}
              handleChange={setGroupName}
            />
          )}
          {entityCategory === "role" && (
            <RoleChooser
              currentRoleName={currentRoleName}
              disabled={busy}
              allAccessible
              excludedRoles={excludedRoles}
              value={targetRole}
              handleChange={setTargetRole}
            />
          )}
        </Grid>
        {entityCategory && (
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={adminOption}
                  disabled={!grantOption || busy}
                  onChange={(_, checked) => {
                    setAdminOption(checked);
                  }}
                />
              }
              label={`Allow ${entityCategory} receiving privilege to grant to others`}
            />
          </Grid>
        )}
      </Grid>
      <div className={classes.buttonsWrapper}>
        <Button
          variant="outlined"
          color="primary"
          onClick={closeDialog}
          disabled={busy}
        >
          Cancel
        </Button>
        <Button
          disabled={
            busy ||
            !entityCategory ||
            (entityCategory === "user" && !user.value) ||
            (entityCategory === "group" && !groupName.value) ||
            (entityCategory === "role" && !targetRole.value)
          }
          variant="contained"
          color="primary"
          onClick={handleAssignToRole}
          className={classes.addButton}
        >
          {busy && (
            <CircularProgress
              color="secondary"
              thickness={4}
              size={20}
              style={{
                marginRight: "8px",
              }}
            />
          )}
          Assign
        </Button>
      </div>
    </Dialog>
  );
};
