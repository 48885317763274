/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { WorkersChart } from "./chart/WorkersChart";
import { WorkersTable } from "./WorkersTable";
import { CpuLoadHistory, NodeSummaries } from "../../api/overviewApi";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { WorkersSummary } from "./WorkersSummary";
import { OverviewChartsHeight } from "./Overview";

interface WorkersOverviewProps {
  nodeSummaries: NodeSummaries | "loading" | "error";
  previousNodeSummaries: NodeSummaries | undefined;
  cpuUsage: CpuLoadHistory | "loading" | "error";
  lastScalingTime: string;
  chartClassName: string;
  summaryClassName: string;
  height: OverviewChartsHeight;
  storageUsage?: number;
}

export const WorkersOverview: React.FunctionComponent<WorkersOverviewProps> = ({
  cpuUsage,
  nodeSummaries,
  previousNodeSummaries,
  lastScalingTime,
  chartClassName,
  summaryClassName,
  height,
  storageUsage,
}) => {
  return (
    <Card>
      <Box display="flex">
        <Box className={chartClassName} style={{ marginTop: 0 }}>
          <WorkersChart cpuUsage={cpuUsage} height={height.smallChart} />
        </Box>
        {Array.isArray(cpuUsage) && (
          <WorkersSummary
            nodeSummaries={nodeSummaries}
            className={summaryClassName}
            lastClusterScaling={lastScalingTime}
            storageUsage={storageUsage}
          />
        )}
      </Box>
      {Array.isArray(cpuUsage) && (
        <WorkersTable
          nodeSummaries={nodeSummaries}
          previousNodeSummaries={previousNodeSummaries}
          maxHeight={height.table}
        />
      )}
    </Card>
  );
};
