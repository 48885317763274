/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { navigateBackInHistoryParam } from "../query/details/QueryDetails";

interface QueryDetailsLinkProps {
  queryId?: string;
  className?: string;
}

export const QueryAccelerationLink: React.FunctionComponent<
  QueryDetailsLinkProps
> = ({ className, queryId, children }) => {
  return (
    <Link
      className={className}
      component={RouterLink}
      underline="none"
      to={{
        pathname: `/query/${queryId}/lake-acceleration`,
        state: { [navigateBackInHistoryParam]: true },
      }}
    >
      {children}
    </Link>
  );
};
