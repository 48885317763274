/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { TopBar } from "./TopBar";
import { SidePanel } from "./sidePanel/SidePanel";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import { UnauthorizedDialog } from "./UnauthorizedDialog";
import { retrieveLogoutDialogState } from "../store/sepSessionStore";
import axios from "axios";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  content: {
    position: "relative",
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    maxWidth: "100%",
    overflowX: "auto",
    height: "100vh",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  children,
}) => {
  const classes = useStyles();
  const [openNav, setOpenNav] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const toggleSidePanel = () => {
    setOpenNav((prev) => !prev);
  };
  // For Unauthorized requests or session timeout requests
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        if (!retrieveLogoutDialogState()) {
          setOpenDialog(true);
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <>
      <UnauthorizedDialog open={openDialog} setOpen={setOpenDialog} />
      <div className={classes.root}>
        <TopBar onOpen={toggleSidePanel} drawerEnabled={true} />
        <SidePanel openNav={openNav} setOpenNav={setOpenNav} />
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {children}
        </main>
      </div>
    </>
  );
};
