/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode } from "react";
import { Tooltip } from "../../../components/tooltip/Tooltip";

export const TextWithTooltip = ({ text }: { text: ReactNode }) => {
  return (
    <Tooltip title={text}>
      <span>{text}</span>
    </Tooltip>
  );
};
