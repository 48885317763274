/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Chart from "chart.js";

export function registerBelowPositioner(): void {
  if (!Chart.Tooltip.positioners.below) {
    Chart.Tooltip.positioners.below = function (elements) {
      const yOffset = 7;

      return {
        x: elements[0]._model.x,
        y: elements[0]._chart.scales["y-axis-0"].bottom + yOffset,
      };
    };
  }
}
