/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement, useEffect, useState } from "react";
import { AccessControlInfo, getAccessControlInfo } from "../api/configApi";
import { Spinner } from "../components/spinner/Spinner";
import { ErrorBox } from "../components/error/ErrorBox";
import { CurrentRoleContext } from "../features/biac/CurrentRoleContext";
import { Loadable } from "../utils/loadable";
import { ConfigContextProvider } from "./ConfigContextProvider";

interface AppContextProps {
  children: ReactElement;
}

export const AppContext: React.FunctionComponent<AppContextProps> = ({
  children,
}) => {
  const [accessControlInfo, setAccessControlInfo] =
    useState<Loadable<AccessControlInfo>>("loading");
  useEffect(() => {
    getAccessControlInfo()
      .then((info) => setAccessControlInfo(info))
      .catch(() => setAccessControlInfo("error"));
  }, []);

  if (accessControlInfo === "loading") {
    return <Spinner position={"absolute"} />;
  } else if (accessControlInfo === "error") {
    return (
      <ErrorBox
        text={"Could not load the user information. Unexpected error occurred"}
      />
    );
  }
  return (
    <CurrentRoleContext
      enabled={accessControlInfo.accessControlEnabled}
      user={accessControlInfo.user}
    >
      <ConfigContextProvider>{children}</ConfigContextProvider>
    </CurrentRoleContext>
  );
};
