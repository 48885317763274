/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { configWithRoleHeader, errorMappingAxios } from "../common";
import { ChangeLogEntry } from "../../../features/biac/audit/change-log/useChangeLogs";
import { AccessLogEntry } from "../../../features/biac/audit/access-log/useAccessLogs";

const accessLogsUrl = "/ui/api/biac/audit/accessLogs";
const changesLogsUrl = "/ui/api/biac/audit/changeLogs";

export interface FilterCriteria {
  startDate: Date | null;
  endDate: Date | null;
}

export interface AuditLogPaginationParam {
  size?: number;
  token?: string;
}

export interface Paginated<T> {
  result: T[];
  nextPageToken?: string;
}

type PaginatedAuditChangeLogs = Paginated<ChangeLogEntry>;
type PaginatedAuditAccessLogs = Paginated<AccessLogEntry>;

export const getAuditChangeLogs = (
  roleHeader: string,
  filter: Partial<FilterCriteria> | null,
  pagination: AuditLogPaginationParam = {}
): Promise<PaginatedAuditChangeLogs> => {
  return errorMappingAxios
    .get<PaginatedAuditChangeLogs>(changesLogsUrl, {
      ...configWithRoleHeader(roleHeader),
      params: {
        filter,
        pagination,
      },
    })
    .then(({ data }) => data);
};

export const getAuditAccessLogs = (
  roleHeader: string,
  filter: Partial<FilterCriteria> | null,
  pagination: AuditLogPaginationParam = {}
): Promise<PaginatedAuditAccessLogs> => {
  return errorMappingAxios
    .get<PaginatedAuditAccessLogs>(accessLogsUrl, {
      ...configWithRoleHeader(roleHeader),
      params: {
        filter,
        pagination,
      },
    })
    .then(({ data }) => data);
};

export const downloadAuditChangeLogs = (
  roleHeader: string,
  filter: Partial<FilterCriteria> | null
): Promise<Blob> => {
  return errorMappingAxios
    .get(`${changesLogsUrl}/export`, {
      ...configWithRoleHeader(roleHeader),
      params: { filter },
    })
    .then(({ data }) => data);
};

export const downloadAuditAccessLogs = (
  roleHeader: string,
  filter: Partial<FilterCriteria> | null
): Promise<Blob> => {
  return errorMappingAxios
    .get(`${accessLogsUrl}/export`, {
      ...configWithRoleHeader(roleHeader),
      params: { filter },
    })
    .then(({ data }) => data);
};
