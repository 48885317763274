/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { configWithRoleHeader, errorMappingAxios, Persisted } from "./common";
import { EntityDto, Expression, GrantDto, RoleGrant, Subject } from "./biacApi";
import { Observable } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";

export const PUBLIC_ROLE_ID = -1;
export const SYSTEM_ROLE_ID = -2;

export interface Role {
  name: string;
  description: string | null;
}

export interface RoleInfo {
  role: Persisted<Role>;
  grantOption: boolean;
  canDropRole: boolean;
  canAddPrivileges: boolean;
}

export interface GrantRoleToSubject {
  roleId: number;
  subject: Subject;
  adminOption: boolean;
}

export interface RoleGrantInfo {
  roleGrant: Persisted<RoleGrant>;
  canManage: boolean;
}

export interface GrantInfo {
  grant: Persisted<GrantDto>;
  canManage: boolean;
}

export interface ColumnMaskInfo {
  id: number;
  entity: EntityDto;
  expression: Persisted<Expression>;
}

export interface RowFilterInfo {
  id: number;
  entity: EntityDto;
  expression: Persisted<Expression>;
}

const baseUrl = "/ui/api/biac/roles";

export const getApplicableRoles = (
  currentRoleName: string
): Promise<RoleInfo[]> => {
  return errorMappingAxios
    .get(`${baseUrl}/applicableRoles`, configWithRoleHeader(currentRoleName))
    .then(({ data }) => data);
};

export const getAccessibleRoles = (
  currentRoleName: string
): Promise<RoleInfo[]> => {
  return errorMappingAxios
    .get(`${baseUrl}/accessibleRoles`, configWithRoleHeader(currentRoleName))
    .then(({ data }) => data);
};

export const getModifiableRoles = (
  currentRoleName: string
): Promise<RoleInfo[]> => {
  return errorMappingAxios
    .get(`${baseUrl}/modifiableRoles`, configWithRoleHeader(currentRoleName))
    .then(({ data }) => data);
};

export const canCreateRole = (currentRoleName: string): Promise<boolean> => {
  return errorMappingAxios
    .get(`${baseUrl}/canCreateRole`, configWithRoleHeader(currentRoleName))
    .then(({ data }) => data);
};

export const createRole = (
  currentRoleName: string,
  role: Role
): Promise<void> => {
  return errorMappingAxios.post(
    baseUrl,
    role,
    configWithRoleHeader(currentRoleName)
  );
};

export const deleteRole = (
  currentRoleName: string,
  roleId: number
): Promise<void> => {
  return errorMappingAxios.delete(
    `${baseUrl}/${roleId}`,
    configWithRoleHeader(currentRoleName)
  );
};

export const grantRoleToSubject = (
  currentRoleName: string,
  roleToUser: GrantRoleToSubject
): Promise<Persisted<RoleGrant>[]> => {
  return errorMappingAxios
    .post(
      `${baseUrl}/grantRoleToSubject`,
      roleToUser,
      configWithRoleHeader(currentRoleName)
    )
    .then(({ data }) => data);
};

export const getUserGrantsForRole = (
  currentRoleName: string,
  roleId: number
): Promise<RoleGrantInfo[]> => {
  return errorMappingAxios
    .get(
      `${baseUrl}/${roleId}/userGrants`,
      configWithRoleHeader(currentRoleName)
    )
    .then(({ data }) => data);
};

export const getRoleGrantsForRole = (
  currentRoleName: string,
  roleId: number
): Promise<RoleGrantInfo[]> => {
  return errorMappingAxios
    .get(
      `${baseUrl}/${roleId}/roleGrants`,
      configWithRoleHeader(currentRoleName)
    )
    .then(({ data }) => data);
};

export const getGroupGrantsForRole = (
  currentRoleName: string,
  roleId: number
): Promise<RoleGrantInfo[]> => {
  return errorMappingAxios
    .get(
      `${baseUrl}/${roleId}/groupGrants`,
      configWithRoleHeader(currentRoleName)
    )
    .then(({ data }) => data);
};

export const revokeRoleGrant = (
  currentRoleName: string,
  roleGrantId: number
): Promise<void> => {
  return errorMappingAxios.delete(
    `${baseUrl}/roleGrants/${roleGrantId}`,
    configWithRoleHeader(currentRoleName)
  );
};

export const getRolePrivileges = (
  currentRoleName: string,
  roleId: number
): Promise<GrantInfo[]> => {
  return errorMappingAxios
    .get(
      `${baseUrl}/${roleId}/privileges`,
      configWithRoleHeader(currentRoleName)
    )
    .then(({ data }) => data);
};

export const getColumnMasks = (
  currentRoleName: string,
  roleId: number
): Observable<ColumnMaskInfo[]> => {
  return fromPromise(
    errorMappingAxios
      .get(
        `${baseUrl}/${roleId}/columnMasks`,
        configWithRoleHeader(currentRoleName)
      )
      .then(({ data }) => data)
  );
};

export const deleteColumnMask = (
  currentRoleName: string,
  roleId: number,
  id: number
): Promise<void> => {
  return errorMappingAxios.delete(
    `${baseUrl}/${roleId}/columnMasks/${id}`,
    configWithRoleHeader(currentRoleName)
  );
};

export const getRowFilters = (
  currentRoleName: string,
  roleId: number
): Observable<RowFilterInfo[]> => {
  return fromPromise(
    errorMappingAxios
      .get(
        `${baseUrl}/${roleId}/rowFilters`,
        configWithRoleHeader(currentRoleName)
      )
      .then(({ data }) => data)
  );
};

export const deleteRowFilter = (
  currentRoleName: string,
  roleId: number,
  id: number
): Promise<void> => {
  return errorMappingAxios.delete(
    `${baseUrl}/${roleId}/rowFilters/${id}`,
    configWithRoleHeader(currentRoleName)
  );
};
