/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { GroupedReports } from "../../../../components/chart/GroupedReports";
import { CpuTimeBaseChart } from "./CpuTimeBaseChart";
import { QueriesCountBaseChart } from "./QueriesCountBaseChart";
import React from "react";
import { LazyReport } from "../LazyReport";
import {
  getCpuTimeBySource,
  getQueriesBySource,
} from "../../../../api/queryReportApi";

type Tab = "cpu" | "queries";

export const TopSourcesUsageCharts: React.FunctionComponent = () => {
  return (
    <GroupedReports<Tab>
      title={"Top sources"}
      tabs={[
        { tab: "cpu", label: "By CPU time" },
        { tab: "queries", label: "By queries" },
      ]}
    >
      {(tab) => (
        <>
          {tab === "cpu" && (
            <LazyReport name={"cpuTimeBySource"} load={getCpuTimeBySource}>
              {(cpuTime) => (
                <CpuTimeBaseChart cpuTime={cpuTime} labelKey={"source"} />
              )}
            </LazyReport>
          )}
          {tab === "queries" && (
            <LazyReport name={"queriesBySource"} load={getQueriesBySource}>
              {(queriesCount) => (
                <QueriesCountBaseChart
                  queriesCount={queriesCount}
                  labelKey={"source"}
                />
              )}
            </LazyReport>
          )}
        </>
      )}
    </GroupedReports>
  );
};
