/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Observable, Subject } from "rxjs";
import { DataDomain } from "./dataDomainApi";
const subject = new Subject<DataDomainEvent>();

export const dataDomainEvent$: Observable<DataDomainEvent> = subject;

type DataDomainDefaultEventType =
  | "dataDomainCreated"
  | "dataDomainChanged"
  | "dataDomainDeleted";

interface DataDomainDefaultEvent {
  type: DataDomainDefaultEventType;
  payload: Partial<DataDomain>;
}
interface DataDomainAssignedEvent {
  type: "dataDomainAssigned";
  payload: {
    id: string;
    productIds: string[];
  };
}

type DataDomainEvent = DataDomainDefaultEvent | DataDomainAssignedEvent;

export const emitDataDomainEvent = (event: DataDomainEvent): void => {
  subject.next(event);
};
