/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import React, { useContext } from "react";
import { RunningQueriesChart } from "./chart/RunningQueriesChart";
import { CpuUsageChart } from "./chart/CpuUsageChart";
import { CpuLoadHistory, RecentQueriesInTime } from "../../api/overviewApi";
import { ConfigContext } from "../../app/ConfigContextProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import { OverviewChartsHeight } from "./Overview";

interface QueriesOverviewProps {
  queriesInTime: RecentQueriesInTime | "loading" | "error";
  cpuUsage: CpuLoadHistory | "loading" | "error";
  height: OverviewChartsHeight;
  wideChartClass: string;
  narrowChartClass: string;
}

export const QueriesOverview: React.FunctionComponent<QueriesOverviewProps> = ({
  queriesInTime,
  cpuUsage,
  height,
  wideChartClass,
  narrowChartClass,
}) => {
  const topUsersChartsAvailable = useContext(ConfigContext)?.allQueries;
  const twoCols = useMediaQuery("(min-width:1400px)");

  if (!topUsersChartsAvailable && twoCols) {
    return (
      <Box display={"flex"}>
        <Box className={wideChartClass}>
          <CpuUsageChart cpuUsage={cpuUsage} height={height.smallChart} />
        </Box>
        <Box className={narrowChartClass}>
          <RunningQueriesChart
            queriesInTime={queriesInTime}
            height={height.smallChart}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box>
          <CpuUsageChart cpuUsage={cpuUsage} height={height.smallChart} />
        </Box>
        <Box mt={2}>
          <RunningQueriesChart
            queriesInTime={queriesInTime}
            height={height.smallChart}
          />
        </Box>
      </Box>
    );
  }
};
