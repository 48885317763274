/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { palette } from "../../../../../themes/palette";

interface DatasetFilterButtonProps {
  isContained: boolean;
  label: string;
  handleOnClick: () => void;
  className?: string;
}

const useStyles = createUseStyles({
  button: {
    marginRight: "5px",
    borderRadius: "20px",
    fontSize: "0.5rem",
    color: palette.white,
  },
});

export const DatasetFilterButton: React.FunctionComponent<
  DatasetFilterButtonProps
> = ({ isContained, label, handleOnClick, className = "" }) => {
  const classes = useStyles();
  return (
    <Button
      className={clsx(classes.button, className)}
      variant={isContained ? "contained" : "outlined"}
      onClick={handleOnClick}
      size="small"
    >
      {isContained && (
        <FontAwesomeIcon icon={faCheck} style={{ marginRight: "8px" }} />
      )}
      {label}
    </Button>
  );
};
