/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { palette } from "../../../themes/palette";
import {
  deleteDataProductRating,
  rateDataProduct,
} from "../../../api/dataProduct/dataProductApi";
import { SimpleDialog } from "../../../components/dialog/SimpleDialog";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";
import { Rating, RatingProps } from "./Rating";
import {
  defaultColor,
  defaultStrokeColor,
  unratedColor,
  unratedStrokeColor,
} from "./RatingService";

interface RatingDialogProps {
  score: number;
  entityName: string;
  entityId: string;
  openDialogComponentFactory: (open: () => void) => ReactElement;
}

export const RatingDialog: FunctionComponent<RatingDialogProps> = ({
  openDialogComponentFactory,
  entityName,
  entityId,
  score,
}) => {
  const { isOpen, close, open } = useDialogOpenState();
  return (
    <>
      {openDialogComponentFactory(open)}
      {isOpen && (
        <RatingFormDialog
          entityId={entityId}
          entityName={entityName}
          score={score}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};

interface RatingFormDialogProps {
  score: number;
  entityName: string;
  entityId: string;
  isOpen: boolean;
  close: () => void;
}

const useRatingFormDialogStyles = createUseStyles({
  deleteButtonContainer: {
    display: "flex",
    flexGrow: 1,
  },
  deleteButton: {
    color: palette.error,
    display: "flex",
    justifySelf: "start",
  },
  deleteIcon: {
    marginRight: "0.5rem",
  },
});

const RatingFormDialog: FunctionComponent<RatingFormDialogProps> = ({
  score,
  entityName,
  entityId,
  isOpen,
  close,
}: RatingFormDialogProps) => {
  const styles = useRatingFormDialogStyles();
  const [scoreCopy, setScoreCopy] = useState(score);
  const [hoveredScore, setHoveredScore] = useState<number>();
  useEffect(() => setScoreCopy(score), [score]);
  const onConfirm = () => rateDataProduct(entityId, scoreCopy);
  const onDelete = () => deleteDataProductRating(entityId).then(close);
  return (
    <SimpleDialog<RatingProps>
      isOpen={isOpen}
      close={close}
      title={`Update ${entityName} rating`}
      confirmButtonLabel="Update rating"
      onConfirm={onConfirm}
      isConfirmationButtonDisabled={scoreCopy === score}
      fullWidth={true}
      contentProps={{
        score: scoreCopy,
        color: scoreCopy ? defaultColor : unratedColor,
        strokeColor:
          scoreCopy || hoveredScore ? defaultStrokeColor : unratedStrokeColor,
        onChange: setScoreCopy,
        onHoverScore: setHoveredScore,
      }}
      Content={Rating}
      startAdornmentAction={
        !!score && (
          <div className={styles.deleteButtonContainer}>
            <Button className={styles.deleteButton} onClick={onDelete}>
              <DeleteOutline className={styles.deleteIcon} /> Delete my rating
            </Button>
          </div>
        )
      }
    />
  );
};
