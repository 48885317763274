/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { GroupedReports } from "../../../../components/chart/GroupedReports";
import { InputDataBaseChart } from "./InputDataBaseChart";
import { OutputDataBaseChart } from "./OutputDataBaseChart";
import { LazyReport } from "../LazyReport";
import {
  getInputDataByUser,
  getOutputDataByUser,
} from "../../../../api/queryReportApi";

type Tab = "input" | "output";

export const TopUsersIOCharts: React.FunctionComponent = () => {
  return (
    <GroupedReports<Tab>
      title={"Top users"}
      tabs={[
        { tab: "input", label: "By data read" },
        { tab: "output", label: "By data written" },
      ]}
    >
      {(tab) => (
        <>
          {tab === "input" && (
            <LazyReport name={"inputDataByUser"} load={getInputDataByUser}>
              {(inputData) => (
                <InputDataBaseChart inputData={inputData} labelKey={"user"} />
              )}
            </LazyReport>
          )}
          {tab === "output" && (
            <LazyReport name={"outputDataByUser"} load={getOutputDataByUser}>
              {(outputData) => (
                <OutputDataBaseChart
                  outputData={outputData}
                  labelKey={"user"}
                />
              )}
            </LazyReport>
          )}
        </>
      )}
    </GroupedReports>
  );
};
