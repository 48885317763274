/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import clsx from "clsx";
import { createUseStyles } from "react-jss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Edit from "@mui/icons-material/Edit";
import TableContainer from "@mui/material/TableContainer";
import { SimpleDataProduct } from "../../../../api/dataProduct/dataProductApi";
import {
  SortableColumn,
  SortingState,
} from "../../../../components/table/SortableColumn";
import { DataDomain } from "../../../../api/dataProduct/dataDomain/dataDomainApi";
import { palette } from "../../../../themes/palette";
import { DataDomainPermissionContextProvider } from "../../permission/DataDomainPersmissionContext";
import { DeleteDomainModal } from "../DeleteDomainModal";
import { DomainContextMenu } from "../DomainContextMenu";
import { TableHeaderCell } from "../../../../components/table/TableHeaderCell";

const useStyles = createUseStyles({
  iconCell: {
    width: "2rem",
  },
  headerCell: {
    backgroundColor: palette.white,
    fontWeight: 600,
  },
});

interface DomainTableProps {
  domains: DataDomain[];
  sorting: SortingState<keyof DataDomain>;
  onSortChange: (newSorting: SortingState<keyof DataDomain>) => void;
  onEditClick: (domain: DataDomain) => void;
}

export const DomainTable: React.FunctionComponent<DomainTableProps> = ({
  domains,
  sorting,
  onSortChange,
  onEditClick,
}: DomainTableProps) => {
  const styles = useStyles();
  const [canUserEdit, setCanUserEdit] = useState<boolean>(false);

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeaderCell />
            <SortableColumn<keyof DataDomain>
              label="Domain name"
              sortingKey="name"
              sortingState={sorting}
              setSortingState={onSortChange}
              component="th"
            />
            <SortableColumn<keyof DataDomain>
              label="# of data products"
              sortingKey="assignedDataProducts"
              sortingState={sorting}
              setSortingState={onSortChange}
              component="th"
            />
            <TableCell className={styles.headerCell}>
              Data products assigned to this domain
            </TableCell>
            <TableCell className={clsx(styles.iconCell, styles.headerCell)} />
          </TableRow>
        </TableHead>
        <TableBody>
          {domains.map((domain) => (
            <TableRow key={domain.id}>
              <TableCell>
                <DeleteDomainModal
                  domain={domain}
                  opener={(openDeleteDialog) => (
                    <DataDomainPermissionContextProvider
                      dataDomainId={domain.id}
                    >
                      <DomainContextMenu
                        domain={domain}
                        onEditClick={() => onEditClick(domain)}
                        onDeleteClick={openDeleteDialog}
                        setCanUserEdit={setCanUserEdit}
                      />
                    </DataDomainPermissionContextProvider>
                  )}
                />
              </TableCell>
              <TableCell>
                <Tooltip title={domain.description || false} arrow>
                  <span>{domain.name}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{domain.assignedDataProducts.length}</TableCell>
              <TableCell>
                <AssignedProducts products={domain.assignedDataProducts} />
              </TableCell>
              <TableCell>
                <Tooltip
                  title={
                    !canUserEdit
                      ? "You do not have permission to edit this domain"
                      : ""
                  }
                >
                  <span>
                    <IconButton
                      size="small"
                      onClick={() => onEditClick(domain)}
                      disabled={!canUserEdit}
                    >
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface AssignedProductsProps {
  products: SimpleDataProduct[];
}

const AssignedProducts = ({ products }: AssignedProductsProps) => {
  const styles = useAssignedProductsStyles();
  return products.length ? (
    <>{products.map(({ name }) => name).join(", ")}</>
  ) : (
    <span className={styles.noProduct}>No data assigned</span>
  );
};

const useAssignedProductsStyles = createUseStyles({
  noProduct: {
    color: palette.black54,
  },
});
