/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { useCallback, useEffect, useRef, useState } from "react";
import { AttributeSubject, getSubjects } from "../../../api/biac/biacApi";

interface Subjects {
  subjects: string[];
  busy: boolean;
  error: string | null;
}

export const useSubjects = (
  currentRoleName: string,
  subjectType: Exclude<AttributeSubject, "role">
): Subjects => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const [subjects, setSubjects] = useState<Subjects["subjects"]>([]);
  const [busy, setBusy] = useState<Subjects["busy"]>(false);
  const [error, setError] = useState<Subjects["error"]>(null);

  const clearLoading = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
      setBusy(false);
    }
  }, []);

  useEffect(() => {
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        setBusy(true);
        timeoutRef.current = undefined;
      }, 200);
    }
    setError(null);
    getSubjects(currentRoleName, subjectType)
      .then((subjects) => setSubjects(subjects))
      .catch((e) => setError(e.message))
      .finally(() => clearLoading());
  }, [currentRoleName, subjectType]);

  return {
    subjects,
    busy,
    error,
  };
};
