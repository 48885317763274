/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { palette } from "../../../themes/palette";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import { QueriesTable } from "./QueriesTable";
import { handleSortingChange } from "./queryHistorySlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { Reports } from "../report/Reports";
import { QueryHistoryMode } from "./QueryHistory";

const useStyles = createUseStyles({
  header: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "12px",
  },
  headerText: {
    fontSize: "20px",
    lineHeight: "30px",
    fontFamily: "montserrat, sans-serif",
    fontWeight: 600,
    marginRight: "auto",
    color: palette.nebulaNavy,
  },
  button: {
    fontSize: "1.125rem",
  },
  activeButton: {
    backgroundColor: palette.purple,
    color: "white",
    "&:hover": {
      backgroundColor: palette.purple,
    },
  },
  buttonText: {
    textTransform: "initial",
    fontSize: "0.875rem",
    fontWeight: 600,
    marginLeft: "0.5rem",
    lineHeight: "1rem",
    letterSpacing: "1.25px",
  },
});

interface QueriesContainerProps {
  mode: QueryHistoryMode;
  toggleMode: (newMode: QueryHistoryMode) => void;
  showReports: boolean;
}

export const QueriesContainer: React.FunctionComponent<
  QueriesContainerProps
> = ({ mode, toggleMode, showReports }) => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();

  const sorting = useSelector(
    ({ queryHistory }: RootState) => queryHistory.sorting
  );

  return (
    <Box mt={1}>
      <Box className={classes.header}>
        <div className={classes.headerText}>Queries</div>
        <ButtonGroup
          variant="outlined"
          color="primary"
          aria-label="contained primary button group"
        >
          <Button
            className={clsx(classes.button, {
              [classes.activeButton]: mode === "table",
            })}
            onClick={() => toggleMode("table")}
          >
            <TableChartOutlinedIcon fontSize="medium" />
            <span className={classes.buttonText}>Table</span>
          </Button>
          <Button
            className={clsx(classes.button, {
              [classes.activeButton]: mode === "chart",
            })}
            onClick={() => toggleMode("chart")}
          >
            <AnalyticsOutlinedIcon fontSize="medium" />
            <span className={classes.buttonText}>Reports</span>
          </Button>
        </ButtonGroup>
      </Box>

      {mode === "table" ? (
        <QueriesTable
          sorting={sorting}
          setSorting={(sorting): unknown =>
            dispatch(handleSortingChange(sorting))
          }
        />
      ) : null}

      {mode === "chart" ? <Reports showReports={showReports} /> : null}
    </Box>
  );
};
