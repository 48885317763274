/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../themes/palette";
import { revokeRoleGrant } from "../../api/biac/biacRolesApi";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { RoleGrant } from "./useRoleGrants";
import capitalize from "lodash/capitalize";
import { Tooltip } from "../../components/tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield } from "@fortawesome/pro-solid-svg-icons/faUserShield";

const useStyles = createUseStyles({
  buttonProgress: {
    margin: "0.3125rem 1.5rem",
  },
  row: {
    padding: "8px 8px 8px 16px",
    fontSize: "0.875rem",
    borderTop: `1px solid ${palette.black12}`,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  cell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  adminOptionIcon: {
    marginRight: "0.25rem",
  },
});

interface GrantEntryProps extends RoleGrant {
  currentRoleName: string;
  roleName: string;
  onUnassign(): void;
  onUnassignSuccess(): void;
  onUnassignFailure(e: Error): void;
}

export const GrantEntry: React.FunctionComponent<GrantEntryProps> = ({
  currentRoleName,
  roleName,
  grantId,
  subjectKind,
  subject,
  grantOption,
  canManage,
  onUnassign,
  onUnassignSuccess,
  onUnassignFailure,
}) => {
  const classes = useStyles();
  const [busy, setBusy] = useState<boolean>(false);

  const unassign = useCallback(() => {
    setBusy(true);
    onUnassign();
    Promise.all([
      revokeRoleGrant(currentRoleName, grantId),
      new Promise<void>((resolve) => setTimeout(resolve, 700)), // minimum wait time for avoiding spinner flash
    ])
      .then(() => onUnassignSuccess())
      .catch((e) => {
        onUnassignFailure(e);
        setBusy(false);
      });
  }, [
    grantId,
    onUnassign,
    onUnassignSuccess,
    onUnassignFailure,
    currentRoleName,
  ]);

  const unassignButton = (
    <>
      {busy ? (
        <CircularProgress
          color="secondary"
          thickness={4}
          size={20}
          className={classes.buttonProgress}
        />
      ) : (
        <Button size="small" color="primary" onClick={unassign}>
          Unassign
        </Button>
      )}
    </>
  );

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.row}
    >
      <div className={classes.cell} style={{ width: "8rem" }}>
        <div>{capitalize(subjectKind)}</div>
      </div>
      <div className={classes.cell} style={{ flexGrow: 1 }}>
        {grantOption ? (
          <Tooltip
            title={`Allow ${subjectKind} receiving privilege to grant to others`}
          >
            <span>
              <FontAwesomeIcon
                size="sm"
                className={classes.adminOptionIcon}
                icon={faUserShield}
              />
              {subject}
            </span>
          </Tooltip>
        ) : (
          subject
        )}
      </div>
      {canManage && (
        <>
          {subjectKind === "role" ? (
            <Tooltip
              title={`Unassign may cause a loss of access to "${roleName}" role`}
            >
              <span>{unassignButton}</span>
            </Tooltip>
          ) : (
            unassignButton
          )}
        </>
      )}
    </Box>
  );
};
