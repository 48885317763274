/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHistory } from "react-router-dom";

interface CancelDialogProps {
  reset(): void;
}

export const CancelDialog: React.FunctionComponent<CancelDialogProps> = ({
  reset,
}) => {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const goBack = useCallback(() => {
    history.push("/roles");
  }, [history]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndReset = () => {
    handleClose();
    reset();
    goBack();
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Cancel
      </Button>
      <Dialog
        open={open}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle
          id="cancel-dialog-title"
          fontWeight={600}
          fontFamily={"montserrat, sans-serif"}
        >
          Are you sure want to cancel ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will clear all the selections, and returns you to the{" "}
            <b>Roles and privileges</b> screen.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0 1rem 1rem" }}>
          <Button onClick={handleClose} variant="outlined">
            Go back to editing
          </Button>
          <Button onClick={handleCloseAndReset} variant="contained">
            Go back to Roles and privileges
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
