/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { getLicenseInfo, LicenseInfo } from "../../api/licenseApi";
import { ErrorBox } from "../../components/error/ErrorBox";
import { Spinner } from "../../components/spinner/Spinner";
import { ManageLicense } from "./ManageLicense";
import { Loadable } from "../../utils/loadable";
import { LicenseInformation } from "./LicenseInformation";
import Typography from "@mui/material/Typography";
import { AuthorizedUserBiac } from "../../components/biac/AuthorizedUserBiac";
import { UiFeatures } from "../../api/configApi";

const useStyles = createUseStyles({
  title: {
    margin: "1rem 0 1.5rem 0",
    fontSize: "1.25rem",
    lineHeight: "30px",
  },
});

export const License: React.FunctionComponent = () => {
  const classes = useStyles();

  const [licenseInfo, setLicenseInfo] =
    useState<Loadable<LicenseInfo>>("loading");

  useEffect(() => {
    let isComponentMounted = true;
    getLicenseInfo()
      .then((licenseInfo) => {
        if (isComponentMounted) {
          setLicenseInfo(licenseInfo);
        }
      })
      .catch(() => setLicenseInfo("error"));
    return () => {
      isComponentMounted = false;
    };
  }, []);

  if (licenseInfo === "error") {
    return (
      <ErrorBox text={"Could not load license information. Retry later."} />
    );
  } else if (licenseInfo === "loading") {
    return <Spinner position="relative" delay={500} />;
  }

  return (
    <AuthorizedUserBiac uiFeature={UiFeatures.SETTINGS_LICENSE}>
      <>
        <Typography variant={"h5"} className={classes.title}>
          License Information
        </Typography>
        <LicenseInformation licenseInfo={licenseInfo} />
        <ManageLicense enableDownload={!licenseInfo.hasFile} />
      </>
    </AuthorizedUserBiac>
  );
};
