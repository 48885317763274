/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { palette } from "../../../themes/palette";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { ChooserErrorIcon } from "./ChooserErrorIcon";
import { EmptyOrValue } from "../../../utils/value";
import {
  getAccessibleRoles,
  getApplicableRoles,
} from "../../../api/biac/biacRolesApi";

export interface ChosenRole {
  id: number | null;
  name: string;
}

interface RoleInfo {
  label: string;
  roleId: number | null;
  description: string | null;
}

interface RoleChooserProps {
  currentRoleName: string;
  disabled: boolean;
  allowStar?: boolean;
  starRoleDescription?: string;
  allAccessible?: boolean;
  excludedRoles?: number[];
  value: EmptyOrValue<ChosenRole | null>;
  handleChange: (value: EmptyOrValue<ChosenRole | null>) => void;
}

export const RoleChooser: React.FunctionComponent<RoleChooserProps> = ({
  currentRoleName,
  disabled,
  allowStar,
  starRoleDescription = "All roles",
  allAccessible,
  excludedRoles,
  value,
  handleChange,
}) => {
  const all = useMemo<RoleInfo>(
    () => ({
      label: "*",
      roleId: null,
      description: starRoleDescription,
    }),
    [starRoleDescription]
  );

  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [applicableRoles, setApplicableRoles] = useState<RoleInfo[]>(
    allowStar ? [all] : []
  );

  const [role, setRole] = useState<RoleInfo | null>(null);

  useEffect(() => {
    if (value.empty !== !!role?.roleId || value.value?.id !== role?.roleId) {
      if (value.value) {
        const role = applicableRoles.find(
          ({ roleId }) => roleId === value.value?.id
        );
        setRole(role ? role : null);
      } else {
        setRole(null);
      }
    }
  }, [value]);

  useEffect(() => {
    setBusy(true);
    setError(null);
    (allAccessible ? getAccessibleRoles : getApplicableRoles)(currentRoleName)
      .then((applicableRoles) => {
        const roles = applicableRoles
          .filter(({ role: { id } }) =>
            excludedRoles ? !excludedRoles.includes(id) : true
          )
          .map<RoleInfo>(
            ({
              role: {
                id,
                object: { name, description },
              },
            }) => ({
              roleId: id,
              label: name,
              description,
            })
          );
        setBusy(false);
        setApplicableRoles(allowStar ? [all, ...roles] : roles);
      })
      .catch((e) => {
        setBusy(false);
        setError(e.message);
      });
  }, [currentRoleName, excludedRoles, allowStar]);

  useEffect(() => {
    return () => handleChange({ empty: true, value: null });
  }, []);

  const optionLabel = useCallback(
    (option: RoleInfo | string) =>
      typeof option === "string" ? option : option.label,
    []
  );

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: RoleInfo) => (
      <li {...props}>
        <div>
          <div>{option.label}</div>
          {option.description && (
            <div style={{ fontSize: "0.875rem", color: palette.black54 }}>
              {option.description}
            </div>
          )}
        </div>
      </li>
    ),
    []
  );

  const onChange = useCallback((_, value: RoleInfo | null) => {
    setRole(value);
    if (!value) {
      handleChange({
        empty: true,
        value: null,
      });
    } else if (value === all) {
      handleChange({
        empty: false,
        value: { id: all.roleId, name: all.label },
      });
    } else {
      handleChange({
        empty: !value,
        value: { id: value.roleId, name: value.label },
      });
    }
  }, []);

  return (
    <Autocomplete<RoleInfo, false, false, false>
      loading={busy}
      disabled={disabled}
      openOnFocus
      options={applicableRoles}
      getOptionLabel={optionLabel}
      renderOption={renderOption}
      value={role}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={!disabled}
          fullWidth
          margin="dense"
          label="Role"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {!disabled && busy ? (
                  <CircularProgress color="secondary" size={20} />
                ) : null}
                {!disabled && error ? (
                  <ChooserErrorIcon title="Loading roles failed" />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
