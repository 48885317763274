/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import axios from "axios";
import { SchemaDataProduct } from "../../../../api/dataProduct/dataProductApi";
import { Role } from "../../../../api/biac/biacRolesApi";
import { Persisted } from "../../../../api/biac/common";
import { Expression } from "../../../../api/biac/biacApi";
import { RowExpressionType } from "../MasksAndFilters";

export const baseUrl = "/ui/api/biac/expressions";

const getApiSection = (expressionType: RowExpressionType) => {
  switch (expressionType) {
    case RowExpressionType.Filter:
      return "rowFilter";
    case RowExpressionType.Mask:
      return "columnMask";
  }
};

export async function createExpression(
  rowExpression: Expression,
  expressionType: RowExpressionType
): Promise<void> {
  await axios.post<SchemaDataProduct>(
    `${baseUrl}/${getApiSection(expressionType)}`,
    rowExpression
  );
}

export async function getExpressionsOfType(
  rowExpressionType: RowExpressionType
): Promise<Persisted<Expression>[]> {
  return (
    await axios.get<Persisted<Expression>[]>(
      `${baseUrl}/${getApiSection(rowExpressionType)}`
    )
  ).data;
}

export async function updateExpressionRow(
  rowExpression: Persisted<Expression>,
  rowExpressionType: RowExpressionType
): Promise<void> {
  await axios.put(
    `${baseUrl}/${getApiSection(rowExpressionType)}/${rowExpression.id}`,
    rowExpression.object
  );
}

export async function deleteRowExpression(
  rowExpression: Persisted<Expression>,
  rowExpressionType: RowExpressionType
): Promise<void> {
  await axios.delete(
    `${baseUrl}/${getApiSection(rowExpressionType)}/${rowExpression.id}`
  );
}

export async function getExpressionRoles(
  rowExpression: Persisted<Expression>,
  rowExpressionType: RowExpressionType,
  abortController: AbortController
): Promise<Persisted<Role>[]> {
  return (
    await axios.get<Persisted<Role>[]>(
      `${baseUrl}/${getApiSection(rowExpressionType)}/${
        rowExpression.id
      }/roles`,
      { signal: abortController.signal }
    )
  ).data;
}
