/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { ChartData } from "chart.js";
import moment from "moment";

type Unit = "ms" | "s" | "m" | "h" | "d" | "y";

// returns [stepSize, labelFormattingFunction]
export const getTimeAxisFormatter = (
  inputData: ChartData
): [number | undefined, (label: number) => string] => {
  const [stepSize, stepUnit, precision] = getTimeAxisStepSize(inputData);
  return [stepSize, formatTimeAxisLabel(stepUnit, precision)];
};

// returns [stepSize, stepUnit, precision]
const getTimeAxisStepSize = (inputData: ChartData): [number, Unit, number] => {
  const values =
    inputData.datasets?.flatMap((dataset) => {
      const data: Array<number | { y: number }> = (dataset.data || []) as Array<
        number | { y: number }
      >;

      return data.map((row) => (typeof row === "number" ? row : row.y));
    }) ?? [];

  if (values.length === 0) {
    return [1, "ms", 0];
  }

  const maxValue = Math.max(...values);
  if (maxValue < 1000) {
    return [1, "ms", 0];
  } else if (maxValue < 1000 * 3) {
    return [100, "s", 1];
  } else if (maxValue < 1000 * 60 * 3) {
    return [1000, "s", 0];
  } else if (maxValue < 1000 * 60 * 60 * 3) {
    return [1000 * 60, "m", 0];
  } else if (maxValue < 1000 * 60 * 60 * 24 * 3) {
    return [1000 * 60 * 60, "h", 0];
  } else if (maxValue < 1000 * 60 * 60 * 24 * 365 * 3) {
    return [1000 * 60 * 60 * 24, "d", 0];
  } else {
    return [1000 * 60 * 60 * 24 * 365, "y", 0];
  }
};

const formatTimeAxisLabel =
  (unit: Unit, precision: number) =>
  (value: number): string => {
    if (value === 0) {
      return `0${unit}`;
    }

    const momentUnit = unit === "ms" ? "S" : unit;
    return moment
      .duration(value, "milliseconds")
      .format(`${momentUnit}[${unit}]`, precision);
  };
