/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { ExtendButtonBase } from "@mui/material/ButtonBase";
import { TabTypeMap } from "@mui/material/Tab";
import { useTabContext } from "@mui/lab/TabContext";
import clsx from "clsx";
import React from "react";
import { useDiscussionContext } from "../../discussion/DiscussionContext";
import { createUseStyles } from "react-jss";
import { palette } from "../../../../themes/palette";
import { DataProductTabs } from "../DataProductTabs";
import { DefaultTab } from "../../../../components/tab/DefaultTab";

const useClasses = createUseStyles({
  commentCount: {
    padding: "0 0.5rem",
    borderRadius: "0.5rem",
  },
  commentCountActive: {
    backgroundColor: palette.fusionFuchsia050,
  },
  commentCountInactive: {
    backgroundColor: palette.opaqueLightGrey,
  },
  label: {
    marginLeft: "0.75rem",
    fontSize: "0.875rem",
    lineHeight: "1rem",
    letterSpacing: "1.25px",
    fontWeight: 600,
  },
});

export const DiscussionTab: ExtendButtonBase<TabTypeMap> = (
  props: TabTypeMap["props"]
) => {
  const { data: comments } = useDiscussionContext();
  const internalClasses = useClasses();
  const tabContext = useTabContext();
  return (
    <DefaultTab
      label={
        <div className={internalClasses.label}>
          <span>Discussion</span>{" "}
          {!!comments && (
            <span
              className={clsx(internalClasses.commentCount, {
                [internalClasses.commentCountActive]:
                  tabContext?.value === DataProductTabs.DISCUSSION_TAB,
                [internalClasses.commentCountInactive]:
                  tabContext?.value !== DataProductTabs.DISCUSSION_TAB,
              })}
            >
              {comments.length}
            </span>
          )}
        </div>
      }
      disableRipple
      {...props}
    />
  );
};
