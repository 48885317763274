/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../themes/palette";

interface TabBadgeProps {
  value: number;
}

const useStyle = createUseStyles({
  badge: {
    backgroundColor: palette.nebulaNavy50,
    borderRadius: "8px",
    minWidth: "1.5rem",
    display: "inline-block",
    marginLeft: "0.5rem",
    fontSize: "0.75rem",
    padding: "1px 4px",
    boxSizing: "border-box",
    textAlign: "center",
  },
});

export const TabBadge: React.FunctionComponent<TabBadgeProps> = (params) => {
  const classes = useStyle();
  return <div className={classes.badge}>{params.value}</div>;
};
