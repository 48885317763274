/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import {
  AggregateUsageMetrics,
  CumulativeCpuUsageResponse,
  downloadData,
  filtersToSearchCriteria,
  getUsageMetricsPreview,
  sendUsageMetrics,
  UsageMetricsErrorResponse,
  UsageMetricsPreviewResponse,
} from "../../api/usageApi";
import { CumulativeUsageChart } from "./CumulativeUsageChart";
import { UsageMetricsSummary } from "./UsageMetricsSummary";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { createUseStyles } from "react-jss";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Spinner } from "../../components/spinner/Spinner";
import { isAfter } from "date-fns";
import { NoData } from "../../components/error/NoData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { UsageMetricsPreview } from "./UsageMetricsPreview";
import { Loadable } from "../../utils/loadable";
import { ErrorDialog } from "../../components/error/ErrorDialog";
import { saveFile } from "../../utils/fileDownloadUtils";

interface UsageMetricsOverviewProps {
  cpuUsage: CumulativeCpuUsageResponse | "loading" | "error";
  summary: AggregateUsageMetrics | "loading" | "error";
}

const useStyles = createUseStyles({
  card: {
    padding: "14px 8px 32px 8px",
    minHeight: "300px",
  },
});

export const UsageMetricsOverview: React.FunctionComponent<
  UsageMetricsOverviewProps
> = ({ cpuUsage, summary }) => {
  const height = 210;
  const classes = useStyles();
  const twoCols = useMediaQuery(useTheme().breakpoints.up("lg"));

  const filters = useSelector(
    ({ usageMetrics }: RootState) => usageMetrics.filters
  );
  const [previewData, setPreviewData] = useState<
    | Loadable<UsageMetricsPreviewResponse>
    | UsageMetricsErrorResponse
    | "done"
    | null
  >(null);

  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);

  const download = () => {
    const searchCriteria = filtersToSearchCriteria(filters);
    if (searchCriteria) {
      return downloadData(searchCriteria)
        .then((data) => {
          saveFile("usage.json", data);
        })
        .catch(() => setOpenErrorDialog(true));
    }
  };

  const preview = () => {
    const searchCriteria = filtersToSearchCriteria(filters);
    if (searchCriteria) {
      setPreviewData("loading");
      getUsageMetricsPreview(searchCriteria)
        .then((usage) => setPreviewData(usage))
        .catch(() => setPreviewData("error"));
    }
  };

  const send = () => {
    const searchCriteria = filtersToSearchCriteria(filters);
    if (searchCriteria) {
      setPreviewData("loading");
      sendUsageMetrics(searchCriteria)
        .then(() => setPreviewData("done"))
        .catch((error) => setPreviewData(new UsageMetricsErrorResponse(error)));
    }
  };

  if (cpuUsage === "loading" || summary === "loading") {
    return (
      <Card className={classes.card}>
        <Spinner position={"relative"} delay={500} />
      </Card>
    );
  }

  if (
    filters.startDay &&
    filters.endDay &&
    isAfter(filters.startDay, filters.endDay)
  ) {
    return (
      <Card className={classes.card}>
        <NoData height={250} />
      </Card>
    );
  }

  return (
    <>
      <Box mb={2}>
        <UsageMetricsPreview
          usageMetrics={previewData}
          handleSend={() => send()}
          handleClose={() => setPreviewData(null)}
        />
        <Grid container>
          <Grid item sm={12} lg={4} xl={5}>
            <UsageMetricsSummary aggregateMetrics={summary} />
          </Grid>
          <Grid item sm={12} lg={8} xl={7}>
            <Box ml={twoCols ? 3 : 0}>
              <CumulativeUsageChart cpuUsage={cpuUsage} height={height} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
        <ErrorDialog
          text={"Failed to download usage metrics. Try again later."}
          openErrorDialog={openErrorDialog}
          setOpenErrorDialog={setOpenErrorDialog}
        />
        <Button variant="outlined" color="primary" onClick={download}>
          <FontAwesomeIcon icon={faDownload} style={{ marginRight: "8px" }} />
          Download usage metrics
        </Button>
        <Box ml={1} mr={1} />
        <Button variant="contained" color="primary" onClick={preview}>
          <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: "8px" }} />
          Send to Starburst
        </Button>
      </Box>
    </>
  );
};
