/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { VirtualizedListBoxComponent } from "../../../grants/VirtualizedListBoxComponent";
import { MaskOrFilterData } from "./addition-privileges-constants";

interface ColumnOrTableAutoCompleteProps {
  selectedData: MaskOrFilterData;
  handleChange: (
    event: React.SyntheticEvent,
    value: string | null,
    selectedColumnMaskId: string
  ) => void;
  options: string[];
  icon: React.ReactNode;
  label: string;
}

export const ColumnOrTableAutoComplete: React.FunctionComponent<
  ColumnOrTableAutoCompleteProps
> = ({ handleChange, selectedData, options, icon, label }) => {
  return (
    <Autocomplete
      style={{ width: "16rem" }}
      value={selectedData.selectedValue ? selectedData.selectedValue : null}
      onChange={(event, value) => handleChange(event, value, selectedData.id)}
      filterOptions={(options) => options.filter((option) => option != "")}
      renderInput={(params) => <TextField {...params} label={label} required />}
      options={options}
      popupIcon={icon}
      isOptionEqualToValue={(option, value) => option === value}
      ListboxComponent={VirtualizedListBoxComponent}
      aria-required
    />
  );
};
