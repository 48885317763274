/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";

import { DataDomain } from "../../../../api/dataProduct/dataDomain/dataDomainApi";
import { useDialogOpenState } from "../../../../components/dialog/useDialogOpenState";
import { ReassignFormDialog } from "./ReassignDomainDialog";

interface ReassignAllDialogProps {
  domain: DataDomain;
  allDomains: DataDomain[];
}

export const ReassignAllDialog: React.FunctionComponent<
  ReassignAllDialogProps
> = ({ domain, allDomains }: ReassignAllDialogProps) => {
  const { isOpen, close, open } = useDialogOpenState();
  return (
    <>
      <span onClick={open}>Reassign all</span>
      {isOpen && (
        <ReassignFormDialog
          isOpen={isOpen}
          close={close}
          domain={domain}
          allDomains={allDomains}
          products={domain.assignedDataProducts}
          description={
            <>
              Select a new domain for these data products.{" "}
              <b>All data products will be reassign to this domain.</b>
            </>
          }
        />
      )}
    </>
  );
};
