/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { Dispatch } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import {
  DataProductForm,
  TagValue,
} from "../../../api/dataProduct/dataProductApi";
import { DataDomain } from "../../../api/dataProduct/dataDomain/dataDomainApi";
import Avatar from "@mui/material/Avatar";
import { palette } from "../../../themes/palette";
import Grid from "@mui/material/Grid";
import { TagDetails } from "../../../components/tag/TagDetails";
import Button from "@mui/material/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { DeleteDialog } from "../../../components/dialog/DeleteDialog";
import { PublishFormAction } from "./publishFormReducer";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";
import Typography from "@mui/material/Typography";
import { LinkList } from "../components/LinkList";
import { CloneDatasetModal } from "../components/CloneDatasetModal";

interface DataProductPublishSummaryProps {
  dataProductForm: DataProductForm;
  tags: TagValue[];
  domain?: DataDomain;
  wizardStep: number;
  publishFormDispatch: Dispatch<PublishFormAction>;
  setSelectedDatasetIndex: (newValue: number) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    marginBottom: "1rem",
    marginTop: "2rem",
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: palette.purple,
    width: "1.5rem",
    height: "1.5rem",
  },
  step: {
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  content: {
    marginBottom: "1rem",
    fontSize: "0.8rem",
  },
  subTitle: {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  datasetIndex: {
    color: palette.black38,
  },
  datasetButtonsContainer: {
    "& > button": {
      minWidth: "unset",
    },
  },
  truncatedValue: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginTop: "0.3rem",
    marginBottom: "0.3rem",
    color: palette.black,
  },
  datasetFullName: {
    fontSize: "0.75rem",
    fontFamily: "Monaco, monospace",
    color: palette.nebulaNavy300,
    borderBottom: `1px solid ${palette.black12}`,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    height: "2rem",
  },
  owner: {
    marginBottom: "0.5rem",
  },
}));

export const DataProductPublishSummary: React.FunctionComponent<
  DataProductPublishSummaryProps
> = ({
  dataProductForm,
  tags,
  domain,
  wizardStep,
  publishFormDispatch,
  setSelectedDatasetIndex,
}) => {
  const classes = useStyles();

  const handleDeletion = (index: number) => {
    publishFormDispatch({ type: "deleteDataset", datasetIndex: index });
    if (index > 0) {
      setSelectedDatasetIndex(index - 1);
    }
    return Promise.resolve();
  };

  const wizardDetails = (
    stepNumber: number,
    enabled: boolean,
    title: string
  ) => {
    return (
      <Grid
        container
        className={classes.container}
        style={{ opacity: enabled ? "1" : "0.3" }}
        spacing={1}
      >
        <Grid item>
          <Avatar className={classes.avatar}>
            <div className={classes.step}>{stepNumber}</div>
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant={"h5"}>{title}</Typography>
        </Grid>
      </Grid>
    );
  };
  const isStep1 = wizardStep >= 1;
  const isStep2 = wizardStep >= 2;
  const isStep3 = wizardStep >= 3;

  return (
    <div>
      {wizardDetails(1, isStep1, "Define data product")}
      {dataProductForm.name && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Title</div>
          <div className={classes.truncatedValue}>{dataProductForm.name}</div>
        </div>
      )}

      {dataProductForm.catalogName && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Catalog</div>
          <div className={classes.truncatedValue}>
            {dataProductForm.catalogName}
          </div>
        </div>
      )}

      {dataProductForm.schemaName && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Schema name</div>
          <div className={classes.truncatedValue}>
            {dataProductForm.schemaName}
          </div>
        </div>
      )}

      {domain && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Domain</div>
          <div className={classes.truncatedValue}>{domain?.name}</div>
        </div>
      )}

      {dataProductForm.summary && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Summary</div>
          <div className={classes.truncatedValue}>
            {dataProductForm.summary}
          </div>
        </div>
      )}

      {dataProductForm.description && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Description</div>
          <div className={classes.truncatedValue}>
            {dataProductForm.description}
          </div>
        </div>
      )}

      {wizardDetails(2, isStep2, "Define datasets")}
      {isStep2 &&
        dataProductForm.datasets &&
        dataProductForm.datasets.length > 0 && (
          <div className={classes.content}>
            <div className={classes.subTitle}>Published dataset names</div>
            {dataProductForm.datasets.map(
              (dataset, index) =>
                dataset.name && (
                  <div key={dataset.name}>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Grid item>
                        <div className={classes.datasetIndex}>
                          Dataset {index + 1}:
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className={classes.truncatedValue}>
                          {dataset.name}
                        </div>
                      </Grid>
                      <Grid item className={classes.datasetButtonsContainer}>
                        {dataProductForm.id && (
                          <CloneDatasetModal
                            dataProductId={dataProductForm.id}
                            schemaName={dataProductForm.schemaName}
                            catalogName={dataProductForm.catalogName}
                            namesAlreadyUsed={dataProductForm.datasets.map(
                              ({ name }) => name
                            )}
                            disabled={!isStep3}
                            view={dataset}
                            type={dataset.type}
                            handleClone={(cloneName: string) => {
                              publishFormDispatch({
                                type: "cloneDataset",
                                cloneName,
                                datasetIndex: index,
                              });
                              return Promise.resolve();
                            }}
                          />
                        )}
                        <DeleteDatasetModal
                          name={dataset.name}
                          onConfirm={() => handleDeletion(index)}
                        />
                      </Grid>
                    </Grid>
                    <div className={classes.datasetFullName}>
                      {dataProductForm.schemaName}.{dataset.name}
                    </div>
                  </div>
                )
            )}
          </div>
        )}

      {wizardDetails(3, isStep3, "Data product details")}
      {isStep3 && dataProductForm.owners && dataProductForm.owners?.length > 0 && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Owners</div>
          {dataProductForm.owners?.map((owner) => (
            <div key={owner.email} className={classes.owner}>
              <div className={classes.truncatedValue}>{owner.name}</div>
              <div className={classes.truncatedValue}>{owner.email}</div>
            </div>
          ))}
        </div>
      )}

      {isStep3 && tags && tags?.length > 0 && (
        <div className={classes.content}>
          <div className={classes.subTitle}>Tags</div>
          <TagDetails tags={tags} />
        </div>
      )}

      {isStep3 &&
        dataProductForm.relevantLinks &&
        dataProductForm.relevantLinks?.length > 0 && (
          <div className={classes.content}>
            <div className={classes.subTitle}>Relevant links</div>
            <LinkList
              relevantLinks={dataProductForm.relevantLinks}
              linkWrapper="div"
              wrapperProps={{ className: classes.truncatedValue }}
            />
          </div>
        )}
    </div>
  );
};

interface DeleteDatasetModalProps {
  name: string;
  onConfirm: () => Promise<void>;
}

export const DeleteDatasetModal: React.FunctionComponent<
  DeleteDatasetModalProps
> = ({ name, onConfirm }: DeleteDatasetModalProps) => {
  const { isOpen, close, open } = useDialogOpenState();
  return (
    <>
      <Button variant="text" style={{ color: palette.error }} onClick={open}>
        <DeleteOutline />
      </Button>
      {isOpen && (
        <DeleteDialog
          entityType="dataset"
          entityHumanName={name}
          onConfirm={onConfirm}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};
