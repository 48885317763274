/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useEffect, useMemo, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import sortBy from "lodash/sortBy";
import { EmptyOrValue } from "../../../../utils/value";
import { useQueryClient } from "../../useQueryClient";
import { palette } from "../../../../themes/palette";
import { ChooserErrorIcon } from "../../grants/ChooserErrorIcon";
import { addPrivilegesStyles } from "../add-privileges-styles";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { VirtualizedListBoxComponent } from "../../grants/VirtualizedListBoxComponent";
import Typography from "@mui/material/Typography";

interface SchemaInfo {
  label: string;
  schemaName: string | null;
  description: string | null;
}

interface SchemaSelectorProps {
  disabled: boolean;
  shouldDisplaySelectAllCheckbox: boolean;
  catalogName: string | null;
  value: EmptyOrValue<string | null>;
  handleChange: (value: EmptyOrValue<string | null>) => void;
}

export const SchemaSelector: React.FunctionComponent<SchemaSelectorProps> = ({
  disabled,
  catalogName,
  value,
  handleChange,
  shouldDisplaySelectAllCheckbox,
}) => {
  const classes = addPrivilegesStyles();
  const [selectedSchemaName, setSelectedSchemaName] =
    useState<SchemaInfo | null>(null);
  const allChecked = !value.empty && value.value === null;
  const [schemas, setSchemas] = useState<SchemaInfo[]>([]);

  const { busy, error, execute, reset } = useQueryClient((data) => {
    const schemas = sortBy(
      data
        .filter(([schemaName]) => schemaName !== "information_schema")
        .map<SchemaInfo>(([schemaName]) => ({
          label: schemaName as string,
          schemaName: schemaName as string,
          description: null,
        })),
      ({ label }) => label
    );
    setSchemas(schemas);
  });

  useEffect(() => {
    if (!disabled && catalogName) {
      execute(`SHOW SCHEMAS FROM "${catalogName}"`);
      return () => {
        setSchemas([]);
        reset();
      };
    }
  }, [disabled, catalogName]);

  const resetSelection = useCallback(() => {
    onChange(null, null);
  }, []);

  useEffect(() => {
    resetSelection();
    return () => resetSelection();
  }, [catalogName]);

  const optionLabel = useCallback((option: SchemaInfo) => option.label, []);

  const optionSelected = useCallback(
    (option: SchemaInfo, value: SchemaInfo) =>
      option.schemaName === value.schemaName,
    []
  );

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: SchemaInfo) => (
      <li {...props}>
        <Box display="flex" width="100%">
          <Box>{option.label}</Box>
          <Box color={palette.black54} ml="auto">
            {option.description}
          </Box>
        </Box>
      </li>
    ),
    []
  );

  const onChange = useCallback(
    (event: React.SyntheticEvent | null, value: SchemaInfo | null) => {
      setSelectedSchemaName(value);
      if (!value) {
        handleChange({
          empty: true,
          value: null,
        });
      } else {
        handleChange({
          empty: !value,
          value: value.schemaName,
        });
      }
    },
    []
  );

  const renderPopupIcon = useCallback(() => {
    if (busy) {
      return <CircularProgress size={20} />;
    } else if (error) {
      return (
        <div>
          <ChooserErrorIcon title="Loading schemas failed" />
        </div>
      );
    } else {
      return <ArrowDropDownOutlinedIcon />;
    }
  }, [busy, error]);

  const handleCheckStar = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        handleChange({
          empty: false,
          value: null,
        });
        setSelectedSchemaName(null);
      } else {
        handleChange({
          empty: true,
          value: null,
        });
      }
    },
    []
  );

  const schemaSelectorLabel = useMemo(() => {
    if (catalogName && !busy && schemas.length === 0) {
      return "No schemas available";
    } else {
      return "Schema";
    }
  }, [catalogName, busy, schemas]);

  return (
    <Grid pl={1}>
      <Typography variant="h5">
        Which schema would you like to select?
      </Typography>
      <Typography className={classes.questionInfo}>
        Use the dropdown to select a specific schema from selected catalogs.{" "}
        {shouldDisplaySelectAllCheckbox && (
          <>
            To select all currently defined schemas from the selected catalogs,
            as well as any schema defined in the future in the selected
            catalogs, check the <b>All schemas</b> box instead.
          </>
        )}
      </Typography>
      {shouldDisplaySelectAllCheckbox && (
        <Box mb={1.5}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={disabled}
                checked={allChecked}
                onChange={handleCheckStar}
                id="select-star"
              />
            }
            label={
              <span>
                All schemas{" "}
                <span style={{ color: palette.black54 }}>
                  (use with caution)
                </span>
              </span>
            }
          />
        </Box>
      )}
      <Box width="100%" mb={3}>
        <Autocomplete
          loading={busy}
          disabled={disabled || allChecked || !schemas.length}
          openOnFocus
          options={schemas}
          getOptionLabel={optionLabel}
          isOptionEqualToValue={optionSelected}
          ListboxComponent={VirtualizedListBoxComponent}
          renderOption={renderOption}
          value={selectedSchemaName}
          onChange={onChange}
          popupIcon={renderPopupIcon()}
          fullWidth={false}
          renderInput={(params) => (
            <TextField {...params} label={schemaSelectorLabel} />
          )}
        />
      </Box>
    </Grid>
  );
};
