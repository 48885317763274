/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Box from "@mui/material/Box";
import { Spinner } from "../spinner/Spinner";
import clsx from "clsx";
import { ErrorBox } from "../error/ErrorBox";
import { createUseStyles } from "react-jss";
import { ReportContainer } from "./ReportContainer";
import { NoData } from "../error/NoData";

interface ReportWrapperProps<T> {
  data: T | "loading" | "error" | undefined;
  title: string | React.ReactNode;
  height: number;
  titleAmendment?: React.ReactNode;
  isEmpty: (data: T) => boolean;
  children: (data: T) => React.ReactNode;
  containerless?: boolean;
  className?: string;
}

const useStyles = createUseStyles({
  spinner: {
    paddingTop: "20px",
  },
  smallSpinner: {
    paddingTop: "0px",
  },
});

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const ReportWrapper = <T extends unknown>({
  data,
  title,
  height,
  isEmpty,
  children,
  titleAmendment,
  containerless = false,
  className,
}: ReportWrapperProps<T>): JSX.Element => {
  const classes = useStyles();

  const process = (): React.ReactElement => {
    if (data === "loading" || data === undefined) {
      return (
        <Box style={{ height }} ml="auto" mr="auto">
          <Spinner
            size={height >= 150 ? 150 : 100}
            className={clsx({
              [classes.spinner]: height >= 150,
              [classes.smallSpinner]: height < 150,
            })}
            position="relative"
            delay={500}
          />
        </Box>
      );
    } else if (data === "error") {
      return <ErrorBox text="Could not load report" height={height} />;
    } else if (isEmpty(data)) {
      return <NoData height={height} />;
    } else {
      return <>{children(data)}</>;
    }
  };

  if (containerless) {
    return process();
  } else {
    return (
      <ReportContainer
        title={title}
        titleAmendment={titleAmendment}
        className={className}
      >
        {process()}
      </ReportContainer>
    );
  }
};
