/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { palette } from "../../themes/palette";
import {
  faCheck,
  faTimes,
  faClock,
  faPlayCircle,
  faBan,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faPlayCircle as faPlayCircleSolid,
  faCheck as faCheckSolid,
  faClock as faClockSolid,
  faTimes as faTimesSolid,
  faBan as faBanSolid,
} from "@fortawesome/pro-solid-svg-icons";
import React, { CSSProperties } from "react";
import { QueryState } from "../../api/queriesApi";
import { StageState, TaskState } from "../../api/queryApi";

interface StatusIconProps {
  state: QueryState | StageState | TaskState;
  className?: string;
  style?: CSSProperties;
  variant?: "regular" | "solid";
}

export const StatusIcon: React.FunctionComponent<StatusIconProps> = ({
  state,
  className = "",
  style = {},
  variant = "regular",
}) => {
  if (state === "RUNNING") {
    return (
      <FontAwesomeIcon
        style={style}
        className={className}
        color={palette.teal}
        icon={variant === "regular" ? faPlayCircle : faPlayCircleSolid}
      />
    );
  } else if (state === "FINISHED") {
    return (
      <FontAwesomeIcon
        style={style}
        className={className}
        color={palette.green}
        icon={variant === "regular" ? faCheck : faCheckSolid}
      />
    );
  } else if (state === "QUEUED") {
    return (
      <FontAwesomeIcon
        style={style}
        className={className}
        color={palette.orange}
        icon={variant === "regular" ? faClock : faClockSolid}
      />
    );
  } else if (state === "FAILED") {
    return (
      <FontAwesomeIcon
        style={style}
        className={className}
        color={palette.error}
        icon={variant === "regular" ? faTimes : faTimesSolid}
      />
    );
  } else if (state === "ABORTED" || state === "CANCELED") {
    return (
      <FontAwesomeIcon
        style={style}
        className={className}
        color={palette.error}
        icon={variant === "regular" ? faBan : faBanSolid}
      />
    );
  } else {
    return null;
  }
};
