/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from "react";
import {
  Column,
  defaultHeaderRowRenderer,
  defaultRowRenderer,
  Table,
  TableHeaderRowProps,
  TableHeaderRowRenderer,
  TableRowProps,
} from "react-virtualized/dist/es/Table";
import clsx from "clsx";
import TableCell from "@mui/material/TableCell";
import { AutoSizer } from "react-virtualized/dist/es/AutoSizer";
import Box from "@mui/material/Box";
import { SingleQueryDetails } from "../../../../api/queryApi";
import {
  SortableColumn,
  SortingState,
} from "../../../../components/table/SortableColumn";
import { percentageValueFormatter } from "../../../../utils/percentageValueFormatter";
import { createUseStyles } from "react-jss";

const ROW_HEIGHT = 41;
const HEADER_HEIGHT = 41;

export interface Row {
  catalog: string;
  schema: string;
  table: string;
  column: string;
  overall: number;
  filtering: number;
  projection: number;
}
interface ColumnProps {
  title: string;
  key: keyof Row;
  displayFunction?: (value: number) => string;
}

const columns: Array<ColumnProps> = [
  {
    title: "Catalog",
    key: "catalog",
  },
  {
    title: "Schema",
    key: "schema",
  },
  {
    title: "Table",
    key: "table",
  },
  {
    title: "Column",
    key: "column",
  },
  {
    title: "Overall",
    key: "overall",
    displayFunction: percentageValueFormatter,
  },
  {
    title: "Filtering",
    key: "filtering",
    displayFunction: percentageValueFormatter,
  },
  {
    title: "Projection",
    key: "projection",
    displayFunction: percentageValueFormatter,
  },
];

const useStyles = createUseStyles(() => ({
  root: {
    width: "100%",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  headerCell: {
    overflow: "hidden",
    letterSpacing: "0.14px",
    fontWeight: 700,
    flex: `1 0 ${100 / columns.length}%`,
    textAlign: "left",
    height: `10px`,
  },
  grid: {
    direction: "inherit",
    outline: "0",
    border: "1px solid #E4E7EF",
  },
  tableHeader: {
    border: "1px solid #E4E7EF",
    borderBottom: "none",
  },
  tableCell: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    cursor: "initial",
    height: `${ROW_HEIGHT}px`,
    fontSize: "14px",
    fontWeight: 400,
  },
  evenRow: {
    backgroundColor: "#FAFBFD",
  },
}));

interface AccelerationDetailsTableProps {
  query: SingleQueryDetails;
  searchTerm: string;
  data: Row[];
}

export const AccelerationDetailsTable: React.FunctionComponent<
  AccelerationDetailsTableProps
> = (params) => {
  const [sortState, setSortState] = React.useState<SortingState<keyof Row>>({
    sortBy: columns[0].key,
    sortOrder: "asc",
  });
  const classes = useStyles();

  const headerRenderer: TableHeaderRowRenderer = (
    props: TableHeaderRowProps
  ) => {
    return defaultHeaderRowRenderer({
      ...props,
      className: clsx(
        props.className,
        classes.tableHeader,
        classes.flexContainer
      ),
    });
  };

  const preparedData = useMemo(() => {
    const sorted = [
      ...params.data.sort((a, b) =>
        typeof a[sortState.sortBy] === "number"
          ? (a[sortState.sortBy] as number) - (b[sortState.sortBy] as number)
          : (a[sortState.sortBy] as string).localeCompare(
              b[sortState.sortBy] as string
            )
      ),
    ];
    return sortState.sortOrder === "desc" ? sorted.reverse() : sorted;
  }, [params.data, sortState]);

  const rowRenderer = (props: TableRowProps) =>
    defaultRowRenderer({
      ...props,
      className: clsx(props.className, classes.flexContainer, {
        [classes.evenRow]: props.index % 2 === 0,
      }),
    });

  return (
    <Box
      style={{
        minHeight: "250px",
        height: "calc(100vh - 750px)",
        maxHeight: `${HEADER_HEIGHT + ROW_HEIGHT * params.data.length}px`,
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Table
            height={height}
            width={width}
            rowHeight={ROW_HEIGHT}
            headerHeight={HEADER_HEIGHT}
            rowCount={preparedData.length}
            rowGetter={({ index }) => preparedData[index]}
            rowClassName={({ index }) => (index % 2 === 0 ? "even" : "odd")}
            headerRowRenderer={headerRenderer}
            rowRenderer={rowRenderer}
            gridClassName={classes.grid}
          >
            {columns.map((column, index) => (
              <Column
                width={100}
                key={index}
                dataKey={column.title.toLowerCase()}
                flexGrow={1}
                flexShrink={1}
                headerRenderer={() => (
                  <SortableColumn<keyof Row>
                    component="div"
                    label={column.title}
                    sortingKey={column.key}
                    sortingState={sortState}
                    setSortingState={setSortState}
                  />
                )}
                cellRenderer={(props) => (
                  <TableCell variant="body" className={classes.tableCell}>
                    {column.displayFunction
                      ? column.displayFunction(props.cellData)
                      : props.cellData}
                  </TableCell>
                )}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Box>
  );
};
