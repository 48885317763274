/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import { PageContent } from "../../../layout/PageContent";
import Grid from "@mui/material/Grid";
import { AccessLogTab } from "./access-log/AccessLogTab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTabStyles } from "../../../themes/useTabStyles";
import { ChangeLogTab } from "./change-log/ChangeLogTab";
import { FiltersRowModel } from "./FilterRow";
import { AuditLogFilter, newDefaultFilter } from "./AuditLogFilter";
import { useConfigContext } from "../../../app/ConfigContextProvider";

export const AuditLog: React.FunctionComponent = () => {
  return (
    <PageContent title="Audit log">
      <AuditLogContent />
    </PageContent>
  );
};

enum SelectedTab {
  ACCESS_LOG,
  CHANGES_LOG,
}

export interface AuditLogTabProp {
  appliedFilters: FiltersRowModel[];
}

const AuditLogContent: React.FunctionComponent = () => {
  const tabClasses = useTabStyles();
  const configContext = useConfigContext();
  const auditAccessLogEnabled = configContext?.auditAccessLogEnabled;

  const [currentTab, setCurrentTab] = useState(
    auditAccessLogEnabled ? SelectedTab.ACCESS_LOG : SelectedTab.CHANGES_LOG
  );
  const [appliedFilters, setAppliedFilters] = useState<FiltersRowModel[]>([
    newDefaultFilter(),
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tabs
          onChange={(e, value) => setCurrentTab(value)}
          value={currentTab}
          className={tabClasses.tabs}
          indicatorColor="primary"
          textColor="primary"
        >
          {auditAccessLogEnabled && (
            <Tab
              value={SelectedTab.ACCESS_LOG}
              className={tabClasses.tab}
              classes={{ selected: tabClasses.selected }}
              label={<span className={tabClasses.label}>Access logs</span>}
            />
          )}
          <Tab
            value={SelectedTab.CHANGES_LOG}
            className={tabClasses.tab}
            classes={{ selected: tabClasses.selected }}
            label={
              <span className={tabClasses.label}>Change logs (privileges)</span>
            }
          />
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <AuditLogFilter
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      </Grid>
      <Grid item xs={12}>
        {currentTab === SelectedTab.ACCESS_LOG && (
          <AccessLogTab appliedFilters={appliedFilters} />
        )}
        {currentTab === SelectedTab.CHANGES_LOG && (
          <ChangeLogTab appliedFilters={appliedFilters} />
        )}
      </Grid>
    </Grid>
  );
};
