/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Box from "@mui/material/Box";
import { parseISO, addSeconds, addMilliseconds } from "date-fns";
import { ClusterHistoryReports } from "../../../cluster/ClusterHistoryReports";
import {
  ClusterHistoryResponse,
  getClusterHistoryMetrics,
} from "../../../../api/clusterApi";
import { SingleQueryDetails } from "../../../../api/queryApi";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";

const useStyles = createUseStyles({
  root: {
    padding: "16px 0",
  },
});

interface QueryClusterResourcesProps {
  query: SingleQueryDetails;
}

export const QueryClusterResourcesTab: React.FunctionComponent<
  QueryClusterResourcesProps
> = ({ query }) => {
  const classes = useStyles();
  const [metrics, setMetrics] = useState<
    ClusterHistoryResponse | "loading" | "error"
  >("loading");

  const now = new Date();
  const submissionTime = parseISO(query.submissionTime);
  /* completionTime is not available for 'running' or 'queued' queries
                   so we will be sending the current time stamp to generate charts */
  const completionTime = query.completionTime
    ? parseISO(query.completionTime)
    : now;
  // in seconds
  const queryDuration =
    (completionTime.getTime() - submissionTime.getTime()) / 1000;
  const timeRangePadding = Math.max(queryDuration * 0.1, 75);
  const start = addSeconds(submissionTime, -timeRangePadding);
  const startHighlight = submissionTime;
  const endRange = addSeconds(completionTime, timeRangePadding);
  const end = endRange > now ? now : endRange;
  // ensure that we can highlight the execution time interval for queries that
  // finish very quickly
  const endHighlight =
    completionTime > submissionTime
      ? completionTime
      : addMilliseconds(submissionTime, 1);

  const fetchData = () => {
    getClusterHistoryMetrics({
      startDate: start,
      endDate: end,
    })
      .then((metrics) => {
        setMetrics(metrics);
      })
      .catch(() => setMetrics("error"));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box className={classes.root}>
      <Typography variant={"h5"}>Cluster resources</Typography>
      <Alert severity="info">
        Charts below show the cluster resources during the query execution. The
        grey, highlighted area indicates when the query ran.
      </Alert>

      <ClusterHistoryReports
        reports={metrics}
        highlightArea={{ start: startHighlight, end: endHighlight }}
      />
    </Box>
  );
};
