/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useCallback, useRef, useState } from "react";
import { TrinoClient } from "@starburstdata/query-editor";
import { optionalCallbacks } from "@starburstdata/query-editor/dist/client/trinoClientCallbacks";
import { TrinoResponseData } from "@starburstdata/query-editor/dist/api/trinoResponse";
import { statementSubmissionUrl } from "../ide/QueryEditor";

interface QueryClient {
  busy: boolean;
  error: string | null;
  execute(query: string): void;
  cancel(): Promise<void>;
  reset(): void;
}

export const useQueryClient = (
  onComplete: (data: TrinoResponseData) => void
): QueryClient => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const loadedData = useRef<TrinoResponseData>([]);

  const clientRef = useRef<TrinoClient>(new TrinoClient());

  const execute = useCallback((query: string) => {
    setBusy(true);
    setError(null);
    loadedData.current = [];

    clientRef.current.execute(
      query,
      optionalCallbacks({
        onData: (data: TrinoResponseData) => {
          loadedData.current = [...loadedData.current, ...data];
        },
        onFinish: () => {
          setBusy(false);
          onComplete(loadedData.current);
        },
        onError: (error) => {
          setBusy(false);
          setError(error);
        },
      }),
      {
        limit: 1_000_000,
        source: "insights-explorer",
        submissionUrl: statementSubmissionUrl,
      }
    );
  }, []);

  const reset = useCallback(() => {
    if (busy) {
      clientRef.current.cancel().finally(() => {
        setError(null);
        onComplete([]);
      });
    } else {
      setError(null);
      onComplete([]);
    }
  }, []);

  return {
    busy,
    error,
    execute,
    cancel: clientRef.current.cancel,
    reset,
  };
};
