/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createUseStyles } from "react-jss";
import { isAfter, isBefore, isValid } from "date-fns";
import Grid from "@mui/material/Grid";
import { clockSystem, localeDateFormat } from "./dateFormats";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TimePicker12h } from "./TimePicker12h";
import { TimePicker24h } from "./TimePicker24h";
import TextField from "@mui/material/TextField";

const dayStart: Date = new Date(2020, 1, 1, 0, 0);
const dayEnd: Date = new Date(2020, 1, 1, 23, 59);

type Size = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface DateTimePickerProps {
  placeholder: string;
  defaultTime: "day-start" | "day-end";
  value: Date | null;
  setValue: (newValue: Date | null) => void;
  size?: {
    date: {
      xs: Size;
      lg: Size;
    };
    time: {
      xs: Size;
      lg: Size;
    };
  };
  canDisableTime?: boolean;
  required?: boolean;
  onValidityChange?: (valid: boolean) => void;
}

const useStyles = createUseStyles({
  checkbox: {
    marginLeft: "14px",
    padding: "6px",
  },
  checkboxLabel: {
    fontSize: "0.875rem",
    marginLeft: "2px",
    userSelect: "none",
  },
});

export const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = ({
  value,
  setValue,
  placeholder,
  defaultTime,
  size = {
    date: {
      xs: 4,
      lg: 3,
    },
    time: {
      xs: 4,
      lg: 3,
    },
  },
  canDisableTime = false,
  required = false,
  onValidityChange = () => undefined,
}: DateTimePickerProps) => {
  const classes = useStyles();

  const getDefaultTime = () => {
    return defaultTime === "day-start" ? dayStart : dayEnd;
  };

  const parseDateTime = (): [Date | null, Date] => {
    return [value, value && isValid(value) ? value : getDefaultTime()];
  };

  const [date, setDate] = useState<Date | null>(parseDateTime()[0]);
  const [time, setTime] = useState<Date>(parseDateTime()[1]);
  const [timeDisabled, setTimeDisabled] = useState<boolean>(
    canDisableTime &&
      time.getHours() === getDefaultTime().getHours() &&
      time.getMinutes() === getDefaultTime().getMinutes()
  );
  const [timeInputValid, setTimeInputValid] = useState<boolean>(true);

  const notifyValueChange = (newTime = time, newDate = date): void => {
    if (!newDate || !isValid(newDate)) {
      setValue(null);
    } else {
      setValue(
        new Date(
          newDate.getFullYear(),
          newDate.getMonth(),
          newDate.getDate(),
          newTime.getHours(),
          newTime.getMinutes()
        )
      );
    }
  };

  const dateInvalid = (inputDate = date): boolean => {
    return (
      inputDate === null ||
      !isValid(inputDate) ||
      isBefore(inputDate, new Date(2010, 0, 1)) ||
      isAfter(inputDate, new Date(2070, 0, 1))
    );
  };

  const notifyValidityChange = (
    newDate: Date | null,
    timeInputValid: boolean
  ) => {
    const timeValid = timeInputValid || timeDisabled;
    const dateValid = !dateInvalid(newDate) || (!required && newDate === null);

    onValidityChange(timeValid && dateValid);
  };

  const onDateChange = (newDate: Date | null): void => {
    setDate(newDate);
    notifyValueChange(time, newDate);
    notifyValidityChange(newDate, timeInputValid);
  };

  const onTimeChange = (newTime: Date, valid: boolean): void => {
    setTime(newTime);
    notifyValueChange(newTime, date);

    setTimeInputValid(valid);
    notifyValidityChange(date, valid);
  };

  const onTimeDisabledChange = (disabled: boolean): void => {
    if (disabled) {
      setTimeDisabled(true);
      const newTime = getDefaultTime();
      setTime(newTime);
      notifyValueChange(newTime);
      notifyValidityChange(date, true);
    } else {
      setTimeDisabled(false);
    }
  };

  return (
    <>
      <Grid item xs={size.date.xs} lg={size.date.lg}>
        <DatePicker<Date | null>
          label={placeholder}
          inputFormat={localeDateFormat}
          value={date}
          onChange={onDateChange}
          renderInput={(props) => (
            <TextField {...props} variant="outlined" fullWidth margin="dense" />
          )}
          minDate={new Date(2010, 0, 1)}
          maxDate={new Date()}
          onError={(error) => onValidityChange(!error)}
        />
      </Grid>

      <Grid item xs={size.time.xs} lg={size.time.lg}>
        {clockSystem === 12 ? (
          <TimePicker12h
            value={time}
            onValueChange={onTimeChange}
            defaultTime={getDefaultTime()}
            timeDisabled={timeDisabled || dateInvalid()}
          />
        ) : (
          <TimePicker24h
            value={time}
            onValueChange={onTimeChange}
            defaultTime={getDefaultTime()}
            timeDisabled={timeDisabled || dateInvalid()}
          />
        )}
        {canDisableTime && (
          <FormControlLabel
            control={
              <Checkbox
                className={classes.checkbox}
                size="small"
                checked={timeDisabled}
                onChange={() => onTimeDisabledChange(!timeDisabled)}
                disabled={dateInvalid()}
              />
            }
            label={<span className={classes.checkboxLabel}>All day</span>}
          />
        )}
      </Grid>
    </>
  );
};
