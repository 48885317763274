/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  FunctionComponent,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  getApplicableRoles,
  PUBLIC_ROLE_ID,
} from "../../api/biac/biacRolesApi";
import { Spinner } from "../../components/spinner/Spinner";
import { ErrorBox } from "../../components/error/ErrorBox";
import isEqual from "lodash/isEqual";
import { CurrentRoleDialog } from "./CurrentRoleDialog";
import { outputEvents, SessionDiff } from "@starburstdata/query-editor";
import { onRoleChanged } from "../../api/biac/common";

export interface RoleBasic {
  id: number;
  name: string;
}

const fetchApplicableRoles = (): Promise<RoleBasic[]> =>
  getApplicableRoles(PUBLIC_ROLE_HEADER).then((roles) =>
    roles.map<RoleBasic>(
      ({
        role: {
          id,
          object: { name },
        },
      }) => ({
        id,
        name,
      })
    )
  );

const CURRENT_ROLE_KEY = "current-role";

const persistCurrentRole = (
  storage: Storage,
  user: string,
  role: RoleBasic | "ALL"
): void => {
  storage.setItem(`${CURRENT_ROLE_KEY}_${user}`, JSON.stringify(role));
};

const loadCurrentRole = (
  storage: Storage,
  user: string
): RoleBasic | "ALL" | null => {
  const value = storage.getItem(`${CURRENT_ROLE_KEY}_${user}`);
  return value ? JSON.parse(value) : null;
};

const clearCurrentRole = (storage: Storage, user: string): void => {
  storage.removeItem(`${CURRENT_ROLE_KEY}_${user}`);
};

const ALL_ROLE_HEADER = "system=ALL";
const createRoleHeader = (roleName: string) => `system=ROLE{${roleName}}`;

interface CurrentRole {
  currentRole: { id: number; name: string; defaultRole: boolean } | "ALL";
  roleHeader: string;
  reload: () => void;
  showDialog: () => void;
}

type RoleContextMode = CurrentRole | "disabled";

const PUBLIC_ROLE_NAME = "public";
export const PUBLIC_ROLE_HEADER = createRoleHeader(PUBLIC_ROLE_NAME);

const PUBLIC_ROLE: RoleBasic = {
  id: PUBLIC_ROLE_ID,
  name: PUBLIC_ROLE_NAME,
} as const;

export const RoleContext = React.createContext<RoleContextMode>("disabled");

const checkForRoleUpdate = (
  applicableRoles: RoleBasic[],
  currentRoleId: number,
  currentRoleName: string
): RoleBasic | null => {
  const matchedRole = applicableRoles.find(({ id }) => id === currentRoleId);
  if (!matchedRole) {
    // current role does not exist anymore, reset to public
    return PUBLIC_ROLE;
  }
  if (matchedRole.name !== currentRoleName) {
    // role name has changed, update the role name
    return matchedRole;
  }
  // no update required
  return null;
};

const checkAndUpdatePersistedRole = (
  applicableRoles: RoleBasic[],
  storage: Storage,
  user: string
) => {
  const persistedRole = loadCurrentRole(storage, user);
  if (persistedRole) {
    if (persistedRole === "ALL") {
      persistCurrentRole(storage, user, "ALL");
      return;
    }

    const role = checkForRoleUpdate(
      applicableRoles,
      persistedRole.id,
      persistedRole.name
    );
    if (role === PUBLIC_ROLE) {
      // persisted role does not exist anymore, clear what user previous selects
      clearCurrentRole(storage, user);
    } else if (role) {
      persistCurrentRole(storage, user, role);
    }
  }
};

const roleId = (currentRole: RoleContextMode): number | "ALL" => {
  if (currentRole === "disabled") {
    return PUBLIC_ROLE_ID;
  }

  if (currentRole.currentRole === "ALL") {
    return "ALL";
  }
  return currentRole.currentRole.id;
};

interface CurrentRoleContextProps {
  enabled: boolean;
  user: string;
  children: ReactElement;
}

export const CurrentRoleContext: FunctionComponent<CurrentRoleContextProps> = ({
  enabled,
  user,
  children,
}) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const applicableRoles = useRef<RoleBasic[]>([]);

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [busy, setBusy] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const reloadAndCheckCurrentRole = useCallback(() => {
    return fetchApplicableRoles().then((newApplicableRoles) => {
      if (!isEqual(applicableRoles.current, newApplicableRoles)) {
        checkAndUpdatePersistedRole(newApplicableRoles, sessionStorage, user);
        checkAndUpdatePersistedRole(newApplicableRoles, localStorage, user);

        setCurrentRole((prev) => {
          if (prev === "disabled") {
            return prev;
          }

          if (prev.currentRole === "ALL") {
            return {
              ...prev,
              currentRole: "ALL",
              roleHeader: ALL_ROLE_HEADER,
            };
          }

          const role = checkForRoleUpdate(
            newApplicableRoles,
            prev.currentRole.id,
            prev.currentRole.name
          );
          if (role) {
            return {
              ...prev,
              currentRole: {
                id: role.id,
                name: role.name,
                defaultRole: role === PUBLIC_ROLE, // current role has been reset, notify the user about it
              },
              roleHeader: createRoleHeader(role.name),
            };
          }
          return prev;
        });
        applicableRoles.current = newApplicableRoles;
      }
    });
  }, [user]);

  const reload = useCallback(
    () =>
      reloadAndCheckCurrentRole().catch(() => {
        // ignore errors from the reload - if failed it's not a big deal
      }),
    [reloadAndCheckCurrentRole]
  );

  const showDialog = useCallback(() => setDialogVisible(true), []);

  const hideDialog = useCallback(() => setDialogVisible(false), []);

  const [currentRole, setCurrentRole] = useState<RoleContextMode>(() => {
    if (!enabled) {
      return "disabled";
    }

    const roleFromSessionStorage = loadCurrentRole(sessionStorage, user);
    const roleFromLocalStorage = loadCurrentRole(localStorage, user);
    const role = roleFromSessionStorage ?? roleFromLocalStorage ?? PUBLIC_ROLE;
    if (role === "ALL") {
      return {
        currentRole: "ALL",
        roleHeader: ALL_ROLE_HEADER,
        reload,
        showDialog,
      };
    }
    return {
      currentRole: {
        id: role.id,
        name: role.name,
        defaultRole: !roleFromSessionStorage && !roleFromLocalStorage,
      },
      roleHeader: createRoleHeader(role.name),
      reload,
      showDialog,
    };
  });

  const setRole = useCallback(
    (role: RoleBasic | "ALL", remember: boolean) => {
      persistCurrentRole(sessionStorage, user, role);
      if (remember) {
        persistCurrentRole(localStorage, user, role);
      }

      if (role === "ALL") {
        setCurrentRole((prev) => {
          if (prev === "disabled") {
            return prev;
          }
          return {
            ...prev,
            currentRole: role,
            roleHeader: ALL_ROLE_HEADER,
          };
        });
        return;
      }

      setCurrentRole((prev) => {
        if (prev === "disabled") {
          return prev;
        }
        return {
          ...prev,
          currentRole: {
            id: role.id,
            name: role.name,
            defaultRole: false,
          },
          roleHeader: createRoleHeader(role.name),
        };
      });
    },
    [user]
  );

  useEffect(() => {
    if (!enabled) {
      return;
    }

    setBusy(true);
    setError(null);
    reloadAndCheckCurrentRole()
      .then(() => setBusy(false))
      .catch((e) => {
        setBusy(false);
        setError(e.message);
      });

    timeoutRef.current = setTimeout(() => reload(), 60_000);
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [reload]);

  useEffect(() => {
    setCurrentRole((prev) => {
      if (prev === "disabled") {
        return prev;
      }
      return { ...prev, reload };
    });
  }, [reload]);

  useEffect(() => {
    if (!enabled) {
      return;
    }
    const unsub = outputEvents.on("sessionUpdate", (diff: SessionDiff) => {
      if (diff.kind === "PROPERTY_CHANGE") {
        const roleHeader = diff.update["x-trino-role"];
        if (roleHeader) {
          const decodedRole = decodeURIComponent(roleHeader);
          const [, roleType, , roleName] =
            /(ROLE|ALL|NONE)({(.+?)})?/i.exec(decodedRole) ?? [];

          switch (roleType) {
            case "ALL":
              setRole("ALL", false);
              break;
            case "NONE":
              setRole(PUBLIC_ROLE, false); // default to public
              break;
            case "ROLE":
              if (!roleName) {
                setRole(PUBLIC_ROLE, false);
                return;
              }
              const role = applicableRoles.current.find(
                ({ name }) => name === roleName
              );
              if (role) {
                setRole(role, false);
              }
              break;
          }
        }
      }
    });
    return () => unsub();
  }, []);

  const roleHeader = currentRole === "disabled" ? null : currentRole.roleHeader;
  useEffect(() => {
    if (roleHeader) {
      onRoleChanged(roleHeader);
    }
  }, [roleHeader]);

  if (!enabled) {
    return children;
  }

  if (busy) {
    return <Spinner position="absolute" />;
  } else if (error) {
    return (
      <ErrorBox text="Could not load the page. Unexpected error occurred" />
    );
  }

  return (
    <>
      {dialogVisible && (
        <CurrentRoleDialog
          currentRoleId={roleId(currentRole)}
          setRole={setRole}
          closeDialog={hideDialog}
        />
      )}
      <RoleContext.Provider value={currentRole}>
        {children}
      </RoleContext.Provider>
    </>
  );
};

export const useRoleHeader = (): string | null => {
  const roleContext = useContext(RoleContext);
  return roleContext === "disabled" ? null : roleContext.roleHeader;
};
