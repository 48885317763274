/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import InputAdornment from "@mui/material/InputAdornment";
import { createUseStyles } from "react-jss";
import TextField from "@mui/material/TextField";
import Search from "@mui/icons-material/Search";
import React from "react";
import { palette } from "../../../../themes/palette";
import Clear from "@mui/icons-material/Clear";

interface SearchFieldProps {
  value: string;
  onValueChange: (value: string) => void;
  className?: string;
  hasHelperText?: boolean;
  placeholder?: string;
  minLength?: number;
}

export const SearchField: React.FunctionComponent<SearchFieldProps> = ({
  onValueChange,
  value,
  className = "",
  hasHelperText = true,
  placeholder = "Search",
  minLength = 2,
}: SearchFieldProps) => {
  const classes = useStyles();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange(e.target.value);
  };

  return (
    <TextField
      className={className}
      variant="outlined"
      size="small"
      placeholder={placeholder}
      value={value}
      autoComplete="off"
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search className={classes.searchIcon} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" onClick={() => onValueChange("")}>
            <Clear className={classes.clearIcon} />
          </InputAdornment>
        ),
        classes: { root: classes.searchInput },
      }}
      helperText={
        hasHelperText &&
        (value?.length > 0 && value?.length < minLength
          ? `Type at least ${minLength} characters`
          : " ")
      }
    />
  );
};

const useStyles = createUseStyles({
  searchInput: {
    fontSize: "0.875rem",
    borderRadius: "4px",
    height: "2.25rem",
    minWidth: "320px",
    "& input::placeholder": {
      color: palette.black54,
      opacity: 1,
    },
  },
  searchIcon: {
    color: palette.black54,
  },
  clearIcon: {
    color: palette.black54,
    cursor: "pointer",
    borderRadius: "50%",
    backgroundColor: palette.black12,
    padding: "4px",
    marginRight: "-4px",
  },
});
