/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { SingleQueryDetails } from "../../../../api/queryApi";
import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { palette } from "../../../../themes/palette";
import { darken } from "@mui/material/styles";
import { QueryAccelerationPercentageChart } from "./QueryAccelerationPercentageChart";
import { AccelerationDetails } from "./AccelerationDetails";
import { createUseStyles } from "react-jss";
import { getOverallLakeAccelerationData } from "../computeLakeAcceleration";

const useStyles = createUseStyles(() => ({
  text: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.496px",
    "& a": {
      color: palette.purple,
      textDecoration: "none",
      "&:hover": {
        color: darken(palette.purple, 0.5),
      },
    },
  },
}));

interface QueryAccelerationTabProps {
  query: SingleQueryDetails;
}

export const QueryAccelerationTab: React.FunctionComponent<
  QueryAccelerationTabProps
> = (params) => {
  const classes = useStyles();
  const { overall, filtering, projection } = useMemo(
    () => getOverallLakeAccelerationData(params.query),
    [params.query]
  );
  return (
    <>
      <Box my={2}>
        <Typography variant={"h5"}>Summary Statistics</Typography>
        <Typography variant="body1" className={classes.text} mt={2} mb={4}>
          The percentage of query splits that were handled by Smart Indexing and
          Caching out of of the total number of query splits
        </Typography>
        <Grid container spacing={3}>
          <Grid item sm={12} md={6} lg={4}>
            <QueryAccelerationPercentageChart
              ratio={overall}
              title="Overall"
              description="Combined score for Filtering operations (WHERE clause) and Projection operations (SELECT clause)"
              color="#65BF78"
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <QueryAccelerationPercentageChart
              ratio={filtering}
              title="Filtering"
              description="Score for Filtering operations (WHERE clause) only"
              color="#FC8721"
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <QueryAccelerationPercentageChart
              ratio={projection}
              title="Projection"
              description="Score for Projection operations (SELECT clause) only"
              color="#6C4E9B"
            />
          </Grid>
        </Grid>
      </Box>

      <AccelerationDetails query={params.query} />
    </>
  );
};
