/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  ComponentType,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import { Subscription } from "rxjs";
import { fromPromise } from "rxjs/internal-compatibility";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Spinner } from "../spinner/Spinner";
import { ErrorIndicator } from "../error/ErrorIndicator";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { palette } from "../../themes/palette";

const useStyles = createUseStyles({
  dialogPaper: {
    minWidth: "500px",
    minHeight: "210px",
  },
  title: {
    flex: 1,
    color: palette.nebulaNavy,
    fontWeight: 600,
    fontFamily: "montserrat, sans-serif",
    fontSize: "20px",
    lineHeight: "30px",
    padding: "24px",
  },
  titleAction: {
    color: palette.nebulaNavy,
    padding: 0,
  },
  content: {
    overflowX: "auto",
    height: "100%",
    fontSize: "1rem",
    color: palette.black,
    padding: "0 1.5rem 1.5rem",
    letterSpacing: "0.5px",
  },
  actions: {
    padding: "21px 24px",
  },
  spinner: {
    marginLeft: "0.5rem",
    width: "auto",
    paddingTop: 0,
    paddingBottom: 0,
  },
});

export interface ContentErrorProps {
  error: ReactNode;
}

interface SimpleDialogProps<ContentProps> {
  title: string;
  Content: ComponentType<ContentProps & ContentErrorProps>;
  contentProps: ContentProps;
  cancelButtonLabel?: string;
  confirmButtonLabel?: ReactNode;
  onConfirm: () => Promise<void>;
  isConfirmationButtonDisabled?: boolean;
  fullWidth?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  isOpen: boolean;
  close: () => void;
  startAdornmentAction?: ReactNode;
}

export function SimpleDialog<ContentProps>({
  title,
  Content,
  contentProps,
  cancelButtonLabel = "Cancel",
  confirmButtonLabel,
  onConfirm,
  isConfirmationButtonDisabled = false,
  fullWidth = false,
  maxWidth = "xs",
  isOpen,
  close,
  startAdornmentAction = null,
}: SimpleDialogProps<ContentProps>): ReactElement {
  const classes = useStyles();
  const subscription = useRef(new Subscription());
  const [errorMessage, setErrorMessage] = useState<string | string[]>("");
  const errorNode = useMemo<ReactNode>(
    () =>
      Array.isArray(errorMessage) ? (
        errorMessage.map((errorText, index) => (
          <ErrorIndicator key={index} text={errorText} />
        ))
      ) : (
        <ErrorIndicator text={errorMessage} />
      ),
    [errorMessage]
  );
  const [isBusy, setIsBusy] = useState(false);

  const handleConfirm = useCallback(() => {
    setIsBusy(true);
    subscription.current.add(
      fromPromise(onConfirm()).subscribe(
        () => {
          setIsBusy(false);
          close();
        },
        ({ message }) => {
          setErrorMessage(message);
          setIsBusy(false);
        }
      )
    );
  }, [onConfirm]);

  useEffect(() => {
    return () => subscription.current.unsubscribe();
  }, []);

  return (
    <>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        onClose={close}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle className={classes.title}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} textOverflow="ellipsis" overflow="hidden">
              {title}
            </Box>
            <Box>
              <IconButton
                className={classes.titleAction}
                onClick={close}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Typography component={DialogContent} className={classes.content}>
          <Content {...contentProps} error={errorNode} />
        </Typography>
        <DialogActions className={classes.actions}>
          {startAdornmentAction}
          <Button
            variant="outlined"
            color="primary"
            onClick={close}
            style={{ marginRight: "1rem" }}
          >
            {cancelButtonLabel}
          </Button>
          {confirmButtonLabel && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={isConfirmationButtonDisabled || isBusy}
            >
              {confirmButtonLabel}{" "}
              {isBusy && (
                <Spinner
                  className={classes.spinner}
                  position="relative"
                  size={10}
                />
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
