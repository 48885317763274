/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import {
  DatasetType,
  MaterializedView,
  View,
} from "../../../../../api/dataProduct/dataProductApi";
import { NoData } from "../../../../../components/error/NoData";
import { palette } from "../../../../../themes/palette";
import { DatasetDetailsEntry } from "./DatasetDetailsEntry";
import { ViewMetadata } from "./ViewMetadata";
import { MaterializedViewMetadata } from "./MaterializedViewMetadata";
import { DatasetFilterButton } from "./DatasetFilterButton";

interface DatasetDetailsProps {
  dataProductId: string;
  catalogName: string;
  schemaName: string;
  views: View[];
  materializedViews: MaterializedView[];
}

const useStyles = createUseStyles({
  datasetSection: {
    marginTop: "-1rem",
    marginRight: "1rem",
  },
  filterInfo: {
    fontSize: "0.875rem",
    fontWeight: 400,
    padding: "1.5rem 1.5rem 1.5rem 0",
    color: palette.black54,
  },
  filter: {
    padding: "1.5rem 1rem 1.5rem 0",
    marginLeft: "auto",
  },
  viewButton: {
    backgroundColor: palette.purple,
    "&:hover": {
      backgroundColor: palette.purple,
    },
  },
  viewButtonInactive: {
    color: palette.purple,
    borderColor: palette.purple,
    "&:hover": {
      borderColor: palette.purple,
      backgroundColor: palette.opaqueLightGrey,
    },
    minWidth: "5px",
  },
  materialViewButton: {
    backgroundColor: palette.teal,
    "&:hover": {
      backgroundColor: palette.teal,
    },
  },
  materialViewButtonInactive: {
    borderColor: palette.teal,
    color: palette.teal,
    "&:hover": {
      borderColor: palette.teal,
      backgroundColor: palette.opaqueLightGrey,
    },
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row",
  },
  headerBorder: {
    borderBottom: `1px solid ${palette.black12}`,
  },
  nodata: {
    fontSize: "0.75rem",
    color: palette.black54,
    textAlign: "center",
    paddingTop: "1rem",
  },
});

export const DatasetDetails: React.FunctionComponent<DatasetDetailsProps> = ({
  dataProductId,
  catalogName,
  schemaName,
  views,
  materializedViews,
}) => {
  const classes = useStyles();
  const datasetsCount = views.length + materializedViews.length;
  const [isViewVisible, setIsViewVisible] = useState<boolean>(true);
  const [isMaterializedViewVisible, setIsMaterializedViewVisible] =
    useState<boolean>(true);
  const alreadyUsedViewNames = useMemo<string[]>(
    () =>
      views
        .map(({ name }) => name)
        .concat(materializedViews.map(({ name }) => name)),
    [views, materializedViews]
  );

  const calculateFilteredDatasetCount = () => {
    let count = 0;
    if (isViewVisible) {
      count = count + views.length;
    }
    if (isMaterializedViewVisible) {
      count = count + materializedViews.length;
    }
    return count;
  };

  return (
    <div className={classes.datasetSection}>
      {datasetsCount === 0 ? (
        <NoData height={130} icon={"table"} />
      ) : (
        <div>
          <div className={clsx(classes.rowFlex, classes.headerBorder)}>
            <div className={classes.filterInfo}>
              Showing <b>{calculateFilteredDatasetCount()}</b> of{" "}
              <b>{datasetsCount}</b> datasets
            </div>
            <div className={classes.filter}>
              <DatasetFilterButton
                className={clsx({
                  [classes.viewButton]: isViewVisible,
                  [classes.viewButtonInactive]: !isViewVisible,
                })}
                handleOnClick={() => setIsViewVisible(!isViewVisible)}
                isContained={isViewVisible}
                label="VIEWS"
              />
              <DatasetFilterButton
                className={clsx({
                  [classes.materialViewButton]: isMaterializedViewVisible,
                  [classes.materialViewButtonInactive]:
                    !isMaterializedViewVisible,
                })}
                isContained={isMaterializedViewVisible}
                handleOnClick={() =>
                  setIsMaterializedViewVisible(!isMaterializedViewVisible)
                }
                label="MATERIALIZED VIEWS"
              />
            </div>
          </div>
          {calculateFilteredDatasetCount() === 0 && (
            <div className={classes.nodata}>No datasets match your filters</div>
          )}
          {isViewVisible &&
            views.map((view) => (
              <DatasetDetailsEntry
                key={view.name}
                dataProductId={dataProductId}
                catalogName={catalogName}
                schemaName={schemaName}
                dataset={view}
                metadata={<ViewMetadata updatedAt={view.updatedAt} />}
                alreadyUsedViewNames={alreadyUsedViewNames}
                type={DatasetType.VIEW}
              />
            ))}
          {isMaterializedViewVisible &&
            materializedViews.map((view) => (
              <DatasetDetailsEntry
                key={view.name}
                dataProductId={dataProductId}
                catalogName={catalogName}
                schemaName={schemaName}
                dataset={view}
                metadata={
                  <MaterializedViewMetadata
                    dataProductId={dataProductId}
                    viewName={view.name}
                    viewStatus={view.status}
                  />
                }
                alreadyUsedViewNames={alreadyUsedViewNames}
                type={DatasetType.MATERIALIZED_VIEW}
              />
            ))}
        </div>
      )}
    </div>
  );
};
