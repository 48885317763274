/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  getUserGlobalPermissions$,
  UserGlobalPermissions,
} from "../../../api/dataProduct/user/userPermissionApi";
import { Spinner } from "../../../components/spinner/Spinner";
import { ErrorIndicator } from "../../../components/error/ErrorIndicator";

export const UserGlobalPermissionContext =
  React.createContext<UserGlobalPermissions>({
    canCreateDataDomains: false,
    canCreateDataProducts: false,
  });

export const useUserGlobalPermissionContext = (): UserGlobalPermissions =>
  useContext(UserGlobalPermissionContext);

interface UserGlobalPermissionContextProviderProps {
  children: ReactNode;
}

type UserGlobalPermissionContextProviderState =
  | UserGlobalPermissions
  | "loading"
  | "error";

export const UserGlobalPermissionContextProvider: FunctionComponent<
  UserGlobalPermissionContextProviderProps
> = ({ children }: UserGlobalPermissionContextProviderProps) => {
  const [userGlobalPermissions, setUserGlobalPermissions] =
    useState<UserGlobalPermissionContextProviderState>("loading");

  useEffect(() => {
    const subscription = getUserGlobalPermissions$().subscribe(
      setUserGlobalPermissions,
      () => setUserGlobalPermissions("error")
    );
    return () => subscription.unsubscribe();
  }, []);

  if (userGlobalPermissions === "loading") {
    return <Spinner position="relative" delay={500} />;
  } else if (userGlobalPermissions === "error") {
    return (
      <ErrorIndicator text="We are having problem fetching permissions, try again later" />
    );
  } else {
    return (
      <UserGlobalPermissionContext.Provider value={userGlobalPermissions}>
        {children}
      </UserGlobalPermissionContext.Provider>
    );
  }
};
