/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from "react";
import { createUseStyles } from "react-jss";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide, { SlideProps } from "@mui/material/Slide";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faClone } from "@fortawesome/pro-regular-svg-icons";
import { Clipboard } from "../clipboard/Clipboard";
import { palette } from "../../themes/palette";

const useStyles = createUseStyles({
  appBar: {
    position: "relative",
    backgroundColor: palette.nebulaNavy,
  },
  title: {
    flex: 1,
  },
  content: {
    overflowX: "auto",
    height: "100%",
  },
  icon: {
    height: "1.5rem!important",
    width: "1.5rem!important",
  },
});

const Transition = React.forwardRef(function Transition(
  props: SlideProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FullScreenDialogProps {
  button: (open: () => void) => ReactElement<HTMLElement>;
  header: string;
  children: ReactElement<HTMLElement>;
  textToCopy?: string;
  actions?: ReactElement<HTMLElement>;
  onBeforeOpen?: () => void;
}

export const FullScreenDialog: React.FunctionComponent<
  FullScreenDialogProps
> = ({
  button,
  header,
  children,
  textToCopy,
  actions = null,
  onBeforeOpen = (): void => undefined,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (): void => {
    onBeforeOpen();
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      {button(handleClickOpen)}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {header}
            </Typography>

            {actions}
            <ClipboardIcon textToCopy={textToCopy} />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              size="large"
            >
              <FontAwesomeIcon className={classes.icon} icon={faTimes} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>{children}</div>
      </Dialog>
    </>
  );
};

interface ClipboardIconProps {
  textToCopy?: string;
}

const ClipboardIcon: React.FunctionComponent<ClipboardIconProps> = ({
  textToCopy,
}) => {
  const classes = useStyles();
  if (!textToCopy) {
    return null;
  }
  return (
    <Clipboard text={textToCopy ?? "-"}>
      <IconButton
        edge="end"
        color="inherit"
        style={{ marginRight: "0.25rem" }}
        size="large"
      >
        <FontAwesomeIcon className={classes.icon} icon={faClone} />
      </IconButton>
    </Clipboard>
  );
};
