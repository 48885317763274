/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/pro-regular-svg-icons";
import Grid from "@mui/material/Grid";
import { useChangeLogs } from "./useChangeLogs";
import { Spinner } from "../../../../components/spinner/Spinner";
import { ErrorBox } from "../../../../components/error/ErrorBox";
import { ChangesLogTable } from "./ChangesLogTable";
import { NoData } from "../../../../components/error/NoData";
import { AuditLogTabProp } from "../AuditLog";
import { DownloadCsvButton } from "../DownloadCsvButton";
import { AuditCsvDownloadTooltipButton } from "../AuditCsvDownloadTooltipButton";

export const ChangeLogTab: React.FunctionComponent<AuditLogTabProp> = ({
  appliedFilters,
}) => {
  const {
    records,
    isFetchingFirstRecords,
    isFetchingPage,
    fetchMore,
    hasMoreRecords,
    isError,
    errorMessage: errorDescription = "",
    fetchCsv,
  } = useChangeLogs(appliedFilters);
  const errorMessage = useMemo(
    () => `Could not load logs${errorDescription && ": " + errorDescription}`,
    [errorDescription]
  );
  const { retryButton, retryIcon } = useStyles();

  return (
    <Grid container spacing={1.5}>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <DownloadCsvButton
            fetchCsv={fetchCsv}
            fileName={"audit-change-logs.csv"}
            disabled={records.length === 0}
          />
          <AuditCsvDownloadTooltipButton />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {isFetchingFirstRecords ? (
          <Spinner position="relative" />
        ) : isError ? (
          <>
            <Box display="flex">
              <Button
                variant="text"
                color="primary"
                onClick={fetchMore}
                className={retryButton}
              >
                <FontAwesomeIcon icon={faRedo} className={retryIcon} />
                Retry
              </Button>
            </Box>
            <ErrorBox height={200} text={errorMessage} />
          </>
        ) : records.length ? (
          <ChangesLogTable
            logs={records}
            isFetchingPage={isFetchingPage}
            hasMoreRecords={hasMoreRecords}
            fetchMore={fetchMore}
          />
        ) : (
          <NoData height={220} icon="table" />
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = createUseStyles({
  retryButton: {
    marginLeft: "auto",
  },
  retryIcon: {
    marginRight: "1rem",
  },
});
