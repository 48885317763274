/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
  canCreateRole,
  createRole as createRoleRequest,
  deleteRole as deleteRoleRequest,
  getModifiableRoles,
  Role,
} from "../../api/biac/biacRolesApi";
import { useCallback, useEffect, useRef, useState } from "react";
import { Loadable } from "../../utils/loadable";

export interface RoleEntry {
  id: number;
  name: string;
  description: string | null;
  grantOption: boolean;
  canDropRole: boolean;
  canAddPrivileges: boolean;
}

interface Roles {
  roles: Loadable<RoleEntry[]>;
  canCreate: boolean;
  createRole(role: Role): Promise<void>;
  deleteRole(roleId: number): Promise<void>;
  reload(): Promise<void>;
}

export const useRoles = (
  currentRoleName: string,
  reloadCurrentRole: () => void
): Roles => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [roles, setRoles] = useState<Roles["roles"]>("loading");
  const [canCreate, setCanCreate] = useState<boolean>(false);

  const showDelayedLoading = useCallback(() => {
    if (timeoutRef.current) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      setRoles("loading");
      timeoutRef.current = undefined;
    }, 200);
  }, []);

  const clearLoading = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const checkIfUserCanCreateRoles = useCallback((): Promise<boolean> => {
    return canCreateRole(currentRoleName).catch(() => false);
  }, [currentRoleName]);

  const reload = useCallback((): Promise<void> => {
    return getModifiableRoles(currentRoleName)
      .then((roles) => {
        const entries = roles.map<RoleEntry>(
          ({
            role: {
              id,
              object: { description, name },
            },
            grantOption,
            canDropRole,
            canAddPrivileges,
          }) => ({
            id,
            name,
            description,
            grantOption,
            canDropRole,
            canAddPrivileges,
          })
        );
        setRoles(entries);
      })
      .catch(() => {
        setRoles("error");
      });
  }, [currentRoleName]);

  const createRole = useCallback(
    (role: Role): Promise<void> =>
      createRoleRequest(currentRoleName, role).then(() => reloadCurrentRole()),
    [currentRoleName]
  );

  const deleteRole = useCallback(
    (roleId: number): Promise<void> =>
      deleteRoleRequest(currentRoleName, roleId).then(() =>
        reloadCurrentRole()
      ),
    [currentRoleName]
  );

  useEffect(() => {
    showDelayedLoading();
    Promise.all([checkIfUserCanCreateRoles(), reload()]).then(
      ([canCreateRole]) => {
        setCanCreate(canCreateRole);
        clearLoading();
      }
    );
  }, [reload]);

  return {
    roles,
    canCreate,
    createRole,
    deleteRole,
    reload,
  };
};
