/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { ChartWrapper } from "../../../components/chart/ChartWrapper";
import { TimeLineChart } from "../../../components/chart/TimeLineChart";
import { CpuLoadHistory } from "../../../api/overviewApi";
import { prepareLineChartData } from "../../../utils/lineChart";
import Box from "@mui/material/Box";
import { defaultChartPalette } from "../../../themes/palette";
import { createUseStyles } from "react-jss";

interface WorkersChartProps {
  cpuUsage: CpuLoadHistory | "loading" | "error";
  height: number;
}

const useStyles = createUseStyles({
  root: {
    padding: "12px",
  },
  header: {
    fontSize: "0.875rem",
    marginTop: "0.25rem",
    marginBottom: "1rem",
    fontWeight: 600,
  },
});

export const WorkersChart: React.FunctionComponent<WorkersChartProps> = ({
  cpuUsage,
  height,
}) => {
  const classes = useStyles();

  const data = prepareLineChartData(
    "Running workers",
    cpuUsage,
    "time",
    ({ metric: { nodeCount } }) => nodeCount,
    { color: defaultChartPalette[4], fill: "origin" }
  );

  return (
    <Box className={classes.root}>
      <div className={classes.header}>Workers</div>
      <ChartWrapper data={data} height={height} containerless={true}>
        {(chartData): React.ReactNode => (
          <TimeLineChart
            data={chartData}
            height={height}
            fixedUnit={"minute"}
            yTicksLimit={3}
            xTicksLimit={5}
            xTicksMaxRotation={0}
            yMax={2}
            tooltipDateFormat={{
              "12h": "h:mm aaa",
              "24h": "H:mm",
            }}
            labelWidth={50}
          />
        )}
      </ChartWrapper>
    </Box>
  );
};
