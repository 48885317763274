/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useEffect } from "react";
import { merge, Observable } from "rxjs";
import { dataProductEvent$ } from "../../../api/dataProduct/dataProductEvent";
import { filter, switchMap, tap } from "rxjs/operators";
import { getAllTags$, Tag } from "../../../api/dataProduct/dataProductApi";
import { useFetchingState } from "../domain/useFetchingState";

export const useAllTagsState = () => {
  const allTagsState = useFetchingState<Tag[]>();

  useEffect(() => {
    const subscription = merge(
      new Observable((observer) => observer.next()),
      dataProductEvent$.pipe(filter(({ type }) => type === "tagsAssigned"))
    )
      .pipe(
        tap(allTagsState.setFetching),
        switchMap(() => getAllTags$()),
        tap((tags) => allTagsState.setData(tags))
      )
      .subscribe();
    return () => subscription.unsubscribe();
  }, []);
  return allTagsState;
};
