/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import uniqBy from "lodash/uniqBy";
import {
  Column,
  DataProductLink,
  DataProductOwner,
  Dataset,
  DatasetType,
  SchemaDataProduct,
  TagValue,
} from "../../../api/dataProduct/dataProductApi";
import { PublishFormData } from "./PublishFormData";

// @TODO: the state is described as PublishFormData, but the typing is misleading here,
// please see PublishFormData.dataProduct.datasets, which is typed as a Dataset[],
// but implementation is different, please see: updateDataset method, because of it
// it becomes Partial<Dataset>[], which cause a lot of troubles and errors, need fix
export function publishFormReducer(
  state: PublishFormData,
  action: PublishFormAction
): PublishFormData {
  switch (action.type) {
    case "setDataProductForm":
      return mapToDataProductForm(state, action.dataProduct);
    case "setDataProductFormFields":
      return {
        ...state,
        dataProduct: { ...state.dataProduct, ...action.dataProduct },
      };
    case "updateDataset":
      return updateDataset(state, action.datasetIndex, action.dataset);
    case "addDataset":
      return addDataset(state, action.dataset);
    case "cloneDataset":
      return cloneDataset(state, action.datasetIndex, action.cloneName);
    case "deleteDataset":
      return deleteDataset(state, action.datasetIndex);
    case "updateColumn":
      return updateColumn(state, action.datasetIndex, action.column);
    case "setTags":
      return { ...state, tags: action.tags };
    case "removeTag":
      return {
        ...state,
        tags: state.tags.filter((tag) => tag.value !== action.tagValue),
      };
    case "addTag":
      const uniqueTags = uniqBy(
        [...state.tags, { value: action.tagValue }],
        "value"
      );
      return {
        ...state,
        tags: uniqueTags,
      };
    case "addOwner":
      const owners = [...(state.dataProduct.owners || []), action.owner];
      return {
        ...state,
        dataProduct: { ...state.dataProduct, owners },
      };
    case "deleteOwner":
      return {
        ...state,
        dataProduct: {
          ...state.dataProduct,
          owners: state.dataProduct.owners?.filter(
            (o) => o.email !== action.email
          ),
        },
      };
    case "addLink":
      const relevantLinks = [
        ...(state.dataProduct.relevantLinks || []),
        action.link,
      ];
      return {
        ...state,
        dataProduct: { ...state.dataProduct, relevantLinks },
      };
    case "deleteLink":
      return {
        ...state,
        dataProduct: {
          ...state.dataProduct,
          relevantLinks: state.dataProduct.relevantLinks?.filter(
            (link) => link !== action.link
          ),
        },
      };
  }
}

export type OwnerAction =
  | { type: "addOwner"; owner: DataProductOwner }
  | { type: "deleteOwner"; email: string };

export type LinkAction =
  | { type: "addLink"; link: DataProductLink }
  | { type: "deleteLink"; link: DataProductLink };

export type PublishFormAction =
  | { type: "setDataProductForm"; dataProduct: SchemaDataProduct }
  | {
      type: "setDataProductFormFields";
      dataProduct: Partial<SchemaDataProduct>;
    }
  | { type: "updateDataset"; datasetIndex: number; dataset: Partial<Dataset> }
  | { type: "addDataset"; dataset?: Partial<Dataset> }
  | { type: "cloneDataset"; datasetIndex: number; cloneName: string }
  | { type: "deleteDataset"; datasetIndex: number }
  | {
      type: "updateColumn";
      datasetIndex: number;
      column: ColumnNameDescription;
    }
  | { type: "setTags"; tags: TagValue[] }
  | { type: "addTag"; tagValue: string }
  | { type: "removeTag"; tagValue: string }
  | OwnerAction
  | LinkAction;

function mapToDataProductForm(
  state: PublishFormData,
  dataProduct: SchemaDataProduct
) {
  const views = dataProduct.views.map((view) => {
    return {
      ...view,
      type: DatasetType.VIEW,
    };
  });
  const materializedViews = dataProduct.materializedViews.map((view) => ({
    ...view,
    type: DatasetType.MATERIALIZED_VIEW,
    refreshInterval:
      view.definitionProperties &&
      view.definitionProperties.refresh_interval &&
      view.definitionProperties.refresh_interval.slice(0, -1),
    incrementalColumn:
      view.definitionProperties && view.definitionProperties.incremental_column,
  }));
  const datasets = [...views, ...materializedViews];
  return {
    ...state,
    dataProduct: { ...dataProduct, datasets },
  };
}

function updateDataset(
  state: PublishFormData,
  datasetIndex: number,
  dataset: Partial<Dataset>
): PublishFormData {
  const updatedDatasets = [...state.dataProduct.datasets];
  const prevDataset = updatedDatasets[datasetIndex];
  updatedDatasets[datasetIndex] = { ...prevDataset, ...dataset };
  return {
    ...state,
    dataProduct: { ...state.dataProduct, datasets: updatedDatasets },
  };
}

function addDataset(
  state: PublishFormData,
  dataset?: Partial<Dataset>
): PublishFormData {
  let counter = state.dataProduct.datasets.length;
  const generateNextName = () => {
    counter += 1;
    return `dataset_${counter}`;
  };

  let newDatasetName = generateNextName();
  while (state.dataProduct.datasets.find((it) => it.name === newDatasetName)) {
    newDatasetName = generateNextName();
  }

  const updatedDatasets = [
    ...state.dataProduct.datasets,
    {
      type: dataset?.type ?? DatasetType.VIEW,
      name: dataset?.name ?? newDatasetName,
      description: dataset?.description ?? "",
      definitionQuery: dataset?.definitionQuery ?? "",
      columns: [],
    },
  ];
  return {
    ...state,
    dataProduct: { ...state.dataProduct, datasets: updatedDatasets },
  };
}

function cloneDataset(
  state: PublishFormData,
  datasetIndex: number,
  cloneName: string
): PublishFormData {
  const updatedDatasets = [
    ...state.dataProduct.datasets,
    {
      ...state.dataProduct.datasets[datasetIndex],
      name: cloneName,
    },
  ];
  return {
    ...state,
    dataProduct: { ...state.dataProduct, datasets: updatedDatasets },
  };
}

function deleteDataset(
  state: PublishFormData,
  datasetIndex: number
): PublishFormData {
  const updatedDatasets = [...state.dataProduct.datasets];
  updatedDatasets.splice(datasetIndex, 1);
  return {
    ...state,
    dataProduct: { ...state.dataProduct, datasets: updatedDatasets },
  };
}

function updateColumn(
  state: PublishFormData,
  datasetIndex: number,
  column: ColumnNameDescription
): PublishFormData {
  const updatedDatasets = [...state.dataProduct.datasets];
  const prevDataset = updatedDatasets[datasetIndex];
  const updatedColumns = [...prevDataset.columns];
  const updatedColIdx = prevDataset.columns.findIndex(
    (col) => col.name === column.name
  );
  updatedColumns[updatedColIdx] = {
    ...updatedColumns[updatedColIdx],
    ...column,
  };

  updatedDatasets[datasetIndex] = { ...prevDataset, columns: updatedColumns };
  return {
    ...state,
    dataProduct: { ...state.dataProduct, datasets: updatedDatasets },
  };
}

export type ColumnNameDescription = Pick<Column, "name" | "description">;
