/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState, MouseEvent, useCallback, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createUseStyles } from "react-jss";
import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons";
import Edit from "@mui/icons-material/Edit";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { palette } from "../../../themes/palette";
import { DataDomain } from "../../../api/dataProduct/dataDomain/dataDomainApi";
import { useDataDomainPermissionContext } from "../permission/DataDomainPersmissionContext";
import LockOutlined from "@mui/icons-material/LockOutlined";
import clsx from "clsx";

const useStyles = createUseStyles({
  icon: {
    marginRight: "0.5rem",
  },
});

interface DomainContextMenuProps {
  domain: DataDomain;
  onEditClick: () => void;
  onDeleteClick: () => void;
  setCanUserEdit: (value: boolean) => void;
}

export const DomainContextMenu: React.FunctionComponent<
  DomainContextMenuProps
> = ({
  domain,
  onEditClick,
  onDeleteClick,
  setCanUserEdit,
}: DomainContextMenuProps) => {
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleClick = useCallback(
    ({ currentTarget }: MouseEvent<HTMLElement>) => {
      setAnchorEl(currentTarget);
    },
    []
  );
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEdit = useCallback(() => {
    handleClose();
    onEditClick();
  }, [onEditClick]);

  const handleDelete = useCallback(() => {
    handleClose();
    onDeleteClick();
  }, [onDeleteClick]);

  const userPermissions = useDataDomainPermissionContext();
  useEffect(() => {
    setCanUserEdit(userPermissions.canUpdate);
  }, [userPermissions]);

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </IconButton>
      <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleClose}>
        <EditDomainMenuItem
          onEdit={handleEdit}
          iconClassName={styles.icon}
          canUserEdit={userPermissions?.canUpdate}
        />
        <DeleteDomainMenuItem
          domain={domain}
          onDelete={handleDelete}
          iconClassName={styles.icon}
          canUserDelete={userPermissions?.canDelete}
        />
      </Menu>
    </>
  );
};

interface EditDomainMenuItemProps {
  iconClassName: string;
  onEdit: () => void;
  canUserEdit: boolean | undefined;
}

const EditDomainMenuItem = ({
  onEdit,
  iconClassName,
  canUserEdit,
}: EditDomainMenuItemProps) => {
  const styles = useDomainMenuItemStyles();
  const tooltipText = !canUserEdit
    ? "You do not have permission to edit this domain"
    : "";
  return !canUserEdit ? (
    <MenuItem className={styles.disabled}>
      <Tooltip title={tooltipText} arrow>
        <div>
          <Edit className={iconClassName} />
          Edit domain
        </div>
      </Tooltip>
    </MenuItem>
  ) : (
    <MenuItem onClick={onEdit}>
      <Edit className={iconClassName} />
      Edit domain
    </MenuItem>
  );
};

const useDomainMenuItemStyles = createUseStyles({
  disabled: {
    color: palette.black54,
  },
  enabled: {
    color: palette.error,
  },
  icon: {
    marginBottom: "-4px",
  },
});

interface DeleteDomainProps {
  domain: DataDomain;
  iconClassName: string;
  onDelete: () => void;
  canUserDelete: boolean | undefined;
}

const DeleteDomainMenuItem = ({
  domain,
  onDelete,
  iconClassName,
  canUserDelete,
}: DeleteDomainProps) => {
  const styles = useDomainMenuItemStyles();
  const tooltipText = !canUserDelete
    ? "You do not have permission to delete this domain"
    : domain.assignedDataProducts.length
    ? "Domains can not be deleted if data products are assigned"
    : "";
  return domain.assignedDataProducts.length || !canUserDelete ? (
    <MenuItem className={styles.disabled}>
      <Tooltip title={tooltipText} arrow>
        <div>
          <LockOutlined className={clsx(iconClassName, styles.icon)} />
          Delete domain
        </div>
      </Tooltip>
    </MenuItem>
  ) : (
    <MenuItem onClick={onDelete} className={styles.enabled}>
      <>
        <DeleteOutline className={iconClassName} />
        Delete domain
      </>
    </MenuItem>
  );
};
