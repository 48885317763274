/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { graphlib } from "dagre";
import {
  getQueryPlanDetails$,
  SingleQueryPlanDetails,
} from "../../../../../api/queryApi";
import { Graph, GraphClasses } from "../../../../../components/graph/Graph";
import { calculateGraph } from "./queryLivePlanGraphService";
import {
  calculateLayout,
  getSubgraph,
} from "../../../../../components/graph/graphLayoutService";
import { GraphGroup } from "../../../../../components/graph/GroupCluster";
import { GraphEdge } from "../../../../../components/graph/GroupEdgePath";
import { GraphNode } from "../../../../../components/graph/GroupNode";

interface QueryLivePlanProps {
  queryId: string;
}

interface QueryState {
  initialized: boolean;
  ended: boolean;
  query?: SingleQueryPlanDetails;
}

interface GraphState {
  graphLayout: graphlib.Graph | null;
  nodes: GraphNode[];
  groups: GraphGroup[];
  edges: GraphEdge[];
  classes: GraphClasses;
}

export const QueryLivePlan: React.FunctionComponent<QueryLivePlanProps> = ({
  queryId,
}) => {
  const styles = useStyles();
  const [graphState, setGraphState] = useState<GraphState>({
    graphLayout: null,
    nodes: [],
    edges: [],
    groups: [],
    classes: {},
  });
  const [queryState, setQueryState] = useState<QueryState>({
    initialized: false,
    ended: false,
  });

  useEffect(() => {
    const dataSubscription = getQueryPlanDetails$(queryId, (query) => {
      const ended = query.finalQueryInfo;
      setQueryState((prevState) => ({
        ...prevState,
        query,
        ended,
        initialized: true,
      }));
    }).subscribe();
    return () => {
      dataSubscription.unsubscribe();
    };
  }, [queryId]);

  useEffect(() => {
    updateGraphLayout();
  }, [queryState]);

  const onNodeHover = useCallback((node: GraphNode) => {
    setGraphState((prevState) => {
      const nodeClasses: Record<string, string> = {};
      const subgraph = getSubgraph(prevState.graphLayout, node.id);
      prevState.nodes.forEach((n) => {
        if (!subgraph[n.id] && n.id !== node.id) {
          nodeClasses[n.id] = styles.nodeUnrelated;
        }
      });
      return {
        ...prevState,
        classes: {
          nodes: nodeClasses,
        },
      };
    });
  }, []);

  const onNodeHoverLost = useCallback(() => {
    setGraphState((prevState) => {
      return {
        ...prevState,
        classes: {
          nodes: {},
        },
      };
    });
  }, []);

  function updateGraphLayout(): void {
    if (!queryState.query) {
      return;
    }

    const graph = calculateGraph(queryState.query);
    const graphLayout = calculateLayout(graph.groups, graph.nodes, graph.edges);
    setGraphState({
      graphLayout,
      nodes: graph.nodes,
      edges: graph.edges,
      groups: graph.groups,
      classes: {},
    });
  }

  if (queryState.query === null || !queryState.initialized) {
    const label = queryState.initialized ? "Query not found" : "Loading";
    return (
      <div>
        <h4>{label}</h4>
      </div>
    );
  }

  let loadingMessage = null;
  if (queryState.query && !queryState.query.stages) {
    loadingMessage = (
      <div>
        <h4>
          Live plan graph will appear automatically when query starts running.
        </h4>
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <>
      {loadingMessage}
      {graphState.graphLayout && (
        <Graph
          classes={graphState.classes}
          layout={graphState.graphLayout}
          groups={graphState.groups}
          nodes={graphState.nodes}
          edges={graphState.edges}
          onNodeHover={onNodeHover}
          onNodeHoverLost={onNodeHoverLost}
        />
      )}
    </>
  );
};

const useStyles = createUseStyles({
  nodeUnrelated: {
    opacity: 0.2,
    transition: "300ms linear",
    transitionDelay: "300ms",
  },
});
