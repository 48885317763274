/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { GroupedReports } from "../../../../components/chart/GroupedReports";
import { CpuTimeBaseChart } from "./CpuTimeBaseChart";
import { QueriesCountBaseChart } from "./QueriesCountBaseChart";
import React from "react";
import { LazyReport } from "../LazyReport";
import {
  getCpuTimeByUser,
  getQueriesByUser,
} from "../../../../api/queryReportApi";

type Tab = "cpu" | "queries";

export const TopUsersUsageCharts: React.FunctionComponent = () => {
  return (
    <GroupedReports<Tab>
      title={"Top users"}
      tabs={[
        { tab: "cpu", label: "By CPU time" },
        { tab: "queries", label: "By queries" },
      ]}
    >
      {(tab) => (
        <>
          {tab === "cpu" && (
            <LazyReport name={"usersByCpuTime"} load={getCpuTimeByUser}>
              {(cpuTime) => (
                <CpuTimeBaseChart cpuTime={cpuTime} labelKey={"user"} />
              )}
            </LazyReport>
          )}
          {tab === "queries" && (
            <LazyReport name={"queriesByUser"} load={getQueriesByUser}>
              {(queriesCount) => (
                <QueriesCountBaseChart
                  queriesCount={queriesCount}
                  labelKey={"user"}
                />
              )}
            </LazyReport>
          )}
        </>
      )}
    </GroupedReports>
  );
};
