/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { DependencyList, RefObject, useRef, useEffect } from "react";
import { DeleteManager } from "./deleteManager";

export function useDeleteManager(
  dataProductId: string | undefined,
  dependencies: DependencyList
): RefObject<DeleteManager | undefined> {
  const manager = useRef<DeleteManager>();

  useEffect(() => {
    if (dataProductId) {
      manager.current = new DeleteManager(dataProductId);
    }

    return () => manager.current?.cancel();
  }, dependencies);

  return manager;
}
