/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

const POLL_INTERVAL_MS = 1000;
export const PROCESS_CANCELLED_MESSAGE = "Process has been canceled";

export function createDelayPromise() {
  return new Promise((resolve) => {
    setTimeout(() => resolve(undefined), POLL_INTERVAL_MS);
  });
}
