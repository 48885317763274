/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from "react";
import { createUseStyles } from "react-jss";
import { RatingScore } from "./RatingScore";
import {
  defaultColor,
  defaultStrokeColor,
  unratedColor,
  unratedStrokeColor,
} from "./RatingService";
import { RatingDialog } from "./RatingDialog";

const useStyles = createUseStyles({
  ratingScore: {
    cursor: "pointer",
    fontSize: "0.875rem",
    alignItems: "center",
    display: "flex",
  },
});

interface InteractiveRatingProps {
  userRate: number;
  entityId: string;
  entityName: string;
  voterCount: number;
  ratingsAverage?: number;
}

export const InteractiveRating: FunctionComponent<InteractiveRatingProps> = ({
  userRate,
  entityId,
  entityName,
  voterCount,
  ratingsAverage = 0,
}: InteractiveRatingProps) => {
  const styles = useStyles();
  return (
    <RatingDialog
      score={userRate}
      entityId={entityId}
      entityName={entityName}
      openDialogComponentFactory={(open) => (
        <RatingScore
          voterCount={voterCount}
          score={ratingsAverage}
          onClick={open}
          color={userRate ? defaultColor : unratedColor}
          strokeColor={userRate ? defaultStrokeColor : unratedStrokeColor}
          className={styles.ratingScore}
        />
      )}
    />
  );
};
