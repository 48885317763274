/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useContext } from "react";
import { PageContent } from "../../../layout/PageContent";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { createUseStyles } from "react-jss";
import { useHistory, useParams } from "react-router-dom";
import { RoleContext } from "../CurrentRoleContext";
import Typography from "@mui/material/Typography";
import { palette } from "../../../themes/palette";
import Link from "@mui/material/Link";
import { useRoles } from "../useRoles";
import { AvailablePrivileges } from "./AvailablePrivileges";
import { useDocUrl } from "../../../app/ConfigContextProvider";
import { ErrorScreen } from "../../../components/error/ErrorScreen";

const useStyles = createUseStyles({
  icon: {
    marginRight: "16px",
  },
  button: {
    marginRight: "36px",
  },
  textButton: {
    padding: "0px 8px",
  },
  roleHeader: {
    fontFamily: "montserrat, sans-serif",
    fontSize: "32px",
    fontWeight: 600,
    lineHeight: "48px",
    letterSpacing: "1px",
    color: palette.nebulaNavy,
    paddingBottom: "1.5rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  roleDescription: {
    fontSize: "1rem",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    alignItems: "center",
  },
});

interface AddPrivilegesRouteProps {
  roleId: string;
}

export const AddPrivileges: React.FunctionComponent = () => {
  const classes = useStyles();
  const { roleId } = useParams<AddPrivilegesRouteProps>();
  const history = useHistory();
  const goBack = useCallback(() => {
    history.push("/roles");
  }, [history]);
  const roleContext = useContext(RoleContext);

  if (roleContext === "disabled") {
    return (
      <ErrorScreen
        errorCode={404}
        errorMsg="Page not found"
        errorDescription="The page you're looking for doesn't exist."
      />
    );
  }

  const { roles } = useRoles(roleContext.roleHeader, roleContext.reload);

  const privilegesRole = Array.isArray(roles)
    ? roles.find(({ id }) => id === Number(roleId))
    : undefined;

  return (
    <>
      {privilegesRole && !privilegesRole.canAddPrivileges ? (
        <ErrorScreen
          errorCode={404}
          errorMsg="Page not found"
          errorDescription="The page you're looking for doesn't exist."
        />
      ) : (
        <PageContent
          title={
            <Box display={"flex"}>
              <Box mr={"auto"}>Add privileges</Box>
              <Button
                variant="text"
                color="primary"
                onClick={goBack}
                className={classes.button}
                classes={{ root: classes.textButton }}
              >
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className={classes.icon}
                />
                Back
              </Button>
            </Box>
          }
          loading={roles === "loading"}
          error={!privilegesRole ? "Access denied" : ""}
        >
          {privilegesRole && (
            <>
              <PrivilegesInfo privilegeName={privilegesRole.name} />
              <Box maxWidth="fit-content">
                <AvailablePrivileges
                  roleId={privilegesRole.id}
                  grantOption={privilegesRole.grantOption}
                  currentRoleName={roleContext.roleHeader}
                />
              </Box>
            </>
          )}
        </PageContent>
      )}
    </>
  );
};

const PrivilegesInfo: React.FunctionComponent<{ privilegeName: string }> = ({
  privilegeName,
}) => {
  const classes = useStyles();
  const docsUrl = useDocUrl() + "/security/biac-privileges.html";
  return (
    <Box mb={2}>
      <Typography className={classes.roleHeader}>
        Assign to: {privilegeName}
      </Typography>
      <Typography className={classes.roleDescription}>
        Grant or deny access to one or more specific catalogs, tables, or other
        other entities for this
        <br />
        role.&nbsp;
        <Link target="_blank" href={docsUrl}>
          Refer to our detailed documentation on assigning privileges to
          roles&nbsp;
          <FontAwesomeIcon
            color="secondary"
            icon={faArrowUpRightFromSquare}
            fontSize="1rem"
          />
        </Link>
      </Typography>
    </Box>
  );
};
