/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useEffect } from "react";
import { merge } from "rxjs";
import { getTags$, Tag } from "../../../api/dataProduct/dataProductApi";
import { filter, map } from "rxjs/operators";
import {
  dataProductEvent$,
  isTagsAssignedEvent,
} from "../../../api/dataProduct/dataProductEvent";
import { useFetchingState } from "../domain/useFetchingState";

export const useDataProductTagsState = (dataProductId: string) => {
  const dataProductTagsState = useFetchingState<Tag[]>();
  useEffect(() => {
    const subscription = merge(
      getTags$(dataProductId),
      dataProductEvent$.pipe(
        filter(isTagsAssignedEvent),
        map(({ payload: { tags } }) => tags)
      )
    ).subscribe({
      next: dataProductTagsState.setData,
      error: (err) => {
        dataProductTagsState.setError(err.message);
        return [];
      },
    });

    return () => subscription.unsubscribe();
  }, [dataProductId]);
  return dataProductTagsState;
};
