/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { IOBaseChart } from "./IOBaseChart";

type Base = { outputBytes: number };

interface OutputDataBaseChartProps<T extends Base> {
  outputData: T[] | "loading" | "error" | undefined;
  labelKey: keyof T;
  labelFormatter?: (inputLabel: string) => string;
}

export const OutputDataBaseChart = <T extends Base>({
  outputData,
  labelKey,
  labelFormatter,
}: OutputDataBaseChartProps<T>): JSX.Element => {
  return (
    <IOBaseChart
      data={outputData}
      labelKey={labelKey}
      valueKey={"outputBytes"}
      tooltipText={"Data written"}
      labelFormatter={labelFormatter}
    />
  );
};
