/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
const SEP_UI_SESSION = "sep-ui-session";

interface SepUISession {
  dismissedLogoutDialog: boolean;
}

export const persistLogoutDialogState = (
  dismissedLogoutDialog: boolean
): void => {
  sessionStorage.setItem(
    SEP_UI_SESSION,
    JSON.stringify({ dismissedLogoutDialog })
  );
};

export const retrieveLogoutDialogState = (): boolean | null => {
  const sepUIRoutingJson = sessionStorage.getItem(SEP_UI_SESSION);
  return sepUIRoutingJson
    ? (JSON.parse(sepUIRoutingJson) as SepUISession).dismissedLogoutDialog
    : null;
};

export const resetSepUISession = (): void => {
  sessionStorage.removeItem(SEP_UI_SESSION);
};
