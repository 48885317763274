/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../../../../themes/palette";
import { formatDate } from "../../../../../utils/formatDateInterval";

interface ViewMetadataProps {
  updatedAt: string | undefined;
}

const useStyles = createUseStyles({
  metadata: {
    fontSize: "0.875rem",
    color: palette.black54,
    paddingBottom: "1rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
});

export const ViewMetadata: React.FunctionComponent<ViewMetadataProps> = ({
  updatedAt,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.metadata}>
      Last updated: {formatDate(updatedAt)}
    </div>
  );
};
