/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { useCallback, useEffect, useRef, useState } from "react";
import { Loadable } from "../../utils/loadable";
import { getRolePrivileges } from "../../api/biac/biacRolesApi";
import sortBy from "lodash/sortBy";
import {
  Action,
  CategoryAttribute,
  Create,
  create as doCreate,
  EffectType,
  EntityCategory,
} from "../../api/biac/biacApi";

export interface RolePrivilege {
  grantId: number;
  entityCategory: EntityCategory;
  entityValue: string | undefined;
  entitySpecified: boolean;
  action: Action;
  grantOption: boolean;
  canManage: boolean;
  effect: EffectType;
}

interface RolePrivileges {
  privileges: Loadable<RolePrivilege[]> | null;
  error: string | null;
  setError(error: string | null): void;
  create(grants: Create, forceSave?: boolean): Promise<void>;
  reload(): Promise<void>;
}

export const userRolePrivileges = (
  currentRoleName: string,
  roleId: number
): RolePrivileges => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  const [privileges, setPrivileges] =
    useState<RolePrivileges["privileges"]>(null);
  const [error, setError] = useState<RolePrivileges["error"]>(null);

  const showDelayedLoading = useCallback(() => {
    if (timeoutRef.current) {
      return;
    }

    timeoutRef.current = setTimeout(() => {
      setPrivileges("loading");
      timeoutRef.current = undefined;
    }, 200);
  }, []);

  const clearLoading = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const reload = useCallback(
    (foreground?: boolean) => {
      setError(null);
      return getRolePrivileges(currentRoleName, roleId)
        .then((grants) => {
          const privileges = grants.map<RolePrivilege>(
            ({ grant: { id, object: grant }, canManage }) => {
              const entityCategory = grant.entity.attributes.filter(
                (attribute): attribute is CategoryAttribute =>
                  attribute.key === "category"
              )[0].value;

              const entityPrintValue = grant.entity.printableValue;
              const entityValue = entityPrintValue.entityText;

              return {
                grantId: id,
                entityCategory,
                entityValue,
                entitySpecified: entityPrintValue.specified,
                grantOption: grant.effect == "ALLOW_WITH_GRANT_OPTION",
                action: grant.action,
                canManage,
                effect: grant.effect,
              };
            }
          );
          setPrivileges(
            sortBy(privileges, ({ entityCategory, action }) => [
              entityCategory,
              action,
            ])
          );
        })
        .catch((e) => {
          if (foreground) {
            setPrivileges("error");
          }
          setError(e.message);
        });
    },
    [roleId, currentRoleName]
  );

  const create = useCallback(
    (grants: Create, forceSave = false): Promise<void> =>
      doCreate(currentRoleName, grants, forceSave),
    [currentRoleName]
  );

  useEffect(() => {
    showDelayedLoading();
    reload(true).then(() => clearLoading());
  }, [reload]);

  return {
    privileges,
    error,
    setError,
    create,
    reload,
  };
};
