/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from "react";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import { createUseStyles } from "react-jss";
import { palette } from "../../../themes/palette";

interface UserAndRolesTableCellProps {
  user: string;
  roles: string[];
}

const useStyles = createUseStyles(() => ({
  moreLabel: {
    color: palette.black54,
    marginLeft: "0.25rem",
  },
  ml: {
    marginLeft: "0.25rem",
  },
  allRolesTooltip: {
    margin: 0,
    padding: "0 0.5rem",
  },
}));

export const UserAndRolesTableCell: React.FunctionComponent<
  UserAndRolesTableCellProps
> = ({ user, roles }) => {
  const classes = useStyles();
  return (
    <>
      {roles.length > 1 ? (
        <Tooltip
          title={
            <ul className={classes.allRolesTooltip}>
              {roles.map((role) => (
                <li key={role}>{role}</li>
              ))}
            </ul>
          }
        >
          <span>
            {user}
            <span className={classes.ml}>({roles[0]})</span>
            <span className={classes.moreLabel}>
              ...+{roles.length - 1}
              <span className={classes.ml}>more</span>
            </span>
          </span>
        </Tooltip>
      ) : (
        <>
          {user}
          <span className={classes.ml}>({roles[0]})</span>
        </>
      )}
    </>
  );
};
