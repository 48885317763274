/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { StageDetail } from "../../../../api/queryApi";
import { QueryStageAccordionHeader } from "./QueryStageAccoridonHeader";
import { getColorByState } from "./stagesUtil";
import { QueryStageTaskTable } from "./QueryStageTaskTable";
import Typography from "@mui/material/Typography";
import { shadows } from "../../../../themes/shadows";

const useStyles = createUseStyles({
  root: {
    margin: "0 0 1rem 0",
  },
  accordionRoot: {
    borderLeft: "4px solid",
    borderRadius: "4px",
    boxShadow: shadows[1],
  },
  header: {
    fontSize: "1.125rem",
  },
});
const stageZero = "0";

interface QueryStageAccordionProps {
  stage: StageDetail;
  maxCpuTime: number;
}

export const QueryStageAccordion: React.FunctionComponent<
  QueryStageAccordionProps
> = ({ stage, maxCpuTime }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Accordion
        classes={{ root: classes.accordionRoot }}
        style={{
          borderColor: getColorByState(stage.state, stage.fullyBlocked),
        }}
        defaultExpanded={stage.id === stageZero}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <QueryStageAccordionHeader stage={stage} maxCpuTime={maxCpuTime} />
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            <Typography variant={"h5"}>Tasks</Typography>
            <QueryStageTaskTable tasks={stage.tasks} />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
