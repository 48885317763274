/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
  getInputDataByTable,
  getOutputDataByTable,
} from "../../../../api/queryReportApi";
import React from "react";
import { LazyReport } from "../LazyReport";
import { InputDataBaseChart } from "./InputDataBaseChart";
import { OutputDataBaseChart } from "./OutputDataBaseChart";
import { GroupedReports } from "../../../../components/chart/GroupedReports";
import { TopTablesChart } from "../TopTablesChart";

type Tab = "input" | "output";

export const TopTablesIOCharts: React.FunctionComponent = () => {
  return (
    <GroupedReports<Tab>
      title={"Top tables"}
      tabs={[
        { tab: "input", label: "By data read" },
        { tab: "output", label: "By data written" },
      ]}
    >
      {(tab) => (
        <>
          {tab === "input" && (
            <LazyReport name={"inputDataByTable"} load={getInputDataByTable}>
              {(tables) => (
                <TopTablesChart data={tables}>
                  {(inputData, labelFormatter) => (
                    <InputDataBaseChart
                      inputData={inputData}
                      labelKey={"name"}
                      labelFormatter={labelFormatter}
                    />
                  )}
                </TopTablesChart>
              )}
            </LazyReport>
          )}
          {tab === "output" && (
            <LazyReport name={"outputDataByTable"} load={getOutputDataByTable}>
              {(tables) => (
                <TopTablesChart data={tables}>
                  {(outputData, labelFormatter) => (
                    <OutputDataBaseChart
                      outputData={outputData}
                      labelKey={"name"}
                      labelFormatter={labelFormatter}
                    />
                  )}
                </TopTablesChart>
              )}
            </LazyReport>
          )}
        </>
      )}
    </GroupedReports>
  );
};
