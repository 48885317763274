/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { ReactElement } from "react";
import { DateTimePicker } from "../../../components/date/DateTimePicker";
import { format } from "date-fns";
import {
  localeDateFormat,
  localeTimeFormat,
} from "../../../components/date/dateFormats";
import { FilterCriteria } from "../../../api/biac/audit/auditLogApi";

export type FilterBy = keyof FilterCriteria;
type FilterValue = FilterCriteria[FilterBy];
type SetFilterValue = (newValue: FilterValue) => void;

interface Filter {
  label: string;
  component: (
    value: FilterValue,
    setValue: SetFilterValue,
    onValidityChange: (valid: boolean) => void
  ) => ReactElement<HTMLElement>;
  formatValue: (value: FilterValue) => string;
}

export const auditLogFilters = new Map<FilterBy, Filter>([
  [
    "startDate",
    {
      label: "After date",
      component: (
        value: FilterValue,
        setValue: SetFilterValue,
        onValidityChange: (valid: boolean) => void
      ): ReactElement<HTMLElement> => (
        <DateTimePicker
          key="start_date"
          placeholder="Pick start date"
          value={value as Date}
          setValue={setValue}
          defaultTime="day-start"
          onValidityChange={onValidityChange}
        />
      ),
      formatValue: (value: FilterValue): string =>
        format(value as Date, `${localeDateFormat} ${localeTimeFormat}`),
    },
  ],
  [
    "endDate",
    {
      label: "Before date",
      component: (
        value: FilterValue,
        setValue: SetFilterValue,
        onValidityChange: (valid: boolean) => void
      ): ReactElement<HTMLElement> => (
        <DateTimePicker
          key="end_date"
          placeholder="Pick end date"
          value={value as Date}
          setValue={setValue}
          defaultTime="day-end"
          onValidityChange={onValidityChange}
        />
      ),
      formatValue: (value: FilterValue): string =>
        format(value as Date, `${localeDateFormat} ${localeTimeFormat}`),
    },
  ],
]);
