/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { ErrorBox } from "./ErrorBox";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";

interface ErrorDialogProps {
  text: string;
  openErrorDialog: boolean;
  setOpenErrorDialog: (newState: boolean) => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  dialogMessage: {
    fontSize: "1.125rem",
    padding: "2rem",
    fontFamily: theme.typography.fontFamily,
    justifyContent: "center",
  },
}));

export const ErrorDialog: React.FunctionComponent<ErrorDialogProps> = ({
  text,
  openErrorDialog,
  setOpenErrorDialog,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpenErrorDialog(false);
  };

  return (
    <Dialog open={openErrorDialog} onClose={handleClose}>
      <Box className={classes.dialogMessage}>
        <ErrorBox height={250} text={text} />
        <Box display={"flex"} justifyContent={"center"} mt={2} mb={2}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
