/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createUseStyles } from "react-jss";
import { palette } from "../../themes/palette";
import { Theme } from "@mui/material/styles";

export const useTableStyles = createUseStyles((theme: Theme) => ({
  headerCell: {
    backgroundColor: palette.white,
    fontWeight: 600,
    letterSpacing: "0.5px",
    fontFamily: theme.typography.fontFamily,
  },
}));
