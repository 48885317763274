/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import { Spinner } from "../../../components/spinner/Spinner";
import { getUserDataProductPermissions$ } from "../../../api/dataProduct/dataProductApi";
import { UserEntityPermissions } from "../../../api/dataProduct/user/userPermissionApi";
import { ErrorBox } from "../../../components/error/ErrorBox";
import {
  isDataError,
  isDataFetching,
  useFetchingState,
} from "../domain/useFetchingState";

const DataProductPermissionContext = React.createContext<UserEntityPermissions>(
  {
    canUpdate: false,
    canPublish: false,
    canDelete: false,
  }
);

export const useDataProductPermissionContext = (): UserEntityPermissions =>
  useContext(DataProductPermissionContext);

interface DataProductPermissionContextProviderProps {
  children: ReactNode;
  dataProductId: string;
}

export const DataProductPermissionContextProvider: FunctionComponent<
  DataProductPermissionContextProviderProps
> = ({
  children,
  dataProductId,
}: DataProductPermissionContextProviderProps) => {
  const {
    state,
    setData: setUserEntityPermissions,
    setFetching,
    setError,
  } = useFetchingState<UserEntityPermissions>();

  useEffect(() => {
    setFetching();
    const subscription = getUserDataProductPermissions$(
      dataProductId
    ).subscribe(setUserEntityPermissions, ({ message }) => setError(message));
    return () => subscription.unsubscribe();
  }, [dataProductId]);

  if (isDataFetching(state)) {
    return <Spinner position="relative" delay={500} />;
  } else if (isDataError(state)) {
    return <ErrorBox text={state.message} />;
  } else {
    return (
      <DataProductPermissionContext.Provider value={state.data}>
        {children}
      </DataProductPermissionContext.Provider>
    );
  }
};
