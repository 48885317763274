/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import { QueryFailureReason } from "../../../../api/queryApi";
import { formatStackTrace } from "../../../../utils/stacktrace";
import lowerCase from "lodash/lowerCase";
import upperFirst from "lodash/upperFirst";
import { palette } from "../../../../themes/palette";
import { DetailsCell } from "../../../../components/details/DetailsCell";
import { DetailsRow } from "../../../../components/details/DetailsRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { Clipboard } from "../../../../components/clipboard/Clipboard";
import { faClone } from "@fortawesome/pro-regular-svg-icons";
import reactStringReplace from "react-string-replace";
import { QueryFullTextDialog } from "./QueryFullTextDialog";
import { QueryState } from "../../../../api/queriesApi";

type NotificationType = "TRUNCATED" | "CANCELED" | "ERROR";

const useStyles = createUseStyles({
  header: {
    fontSize: "1.125rem",
    letterSpacing: "0.5px",
  },
  warn: {
    color: palette.error,
    marginRight: "0.5rem",
  },
  attention: {
    color: palette.warningDark,
    marginRight: "0.5rem",
  },
  stacktrace: {
    whiteSpace: "pre-wrap",
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    fontSize: "0.75rem",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "0.75rem 1rem",
    minHeight: "4rem",
    borderRadius: "0.25rem",
    backgroundColor: "rgba(0, 0, 0, 0.01)",
    maxHeight: "300px",
    overflowX: "auto",
    overflowY: "auto",
    marginLeft: "12px",
    marginRight: "12px",
  },
  headerBar: {
    padding: "8px 16px 16px 12px",
  },
  copy: {
    padding: "8px",
    float: "right",
    margin: "-4px -8px 4px 4px",
  },
  icon: {
    fontSize: "1.25rem",
  },
  link: {
    color: palette.link,
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  truncationWarning: {
    padding: 12,
    fontSize: "0.875rem",
  },
});

interface QueryStateDetailsSectionProps {
  state: QueryState;
  failureReason: QueryFailureReason;
  queryText: string;
}

export const QueryStateDetailsSection: React.FunctionComponent<
  QueryStateDetailsSectionProps
> = ({ state, failureReason, queryText }) => {
  const userCancelErrorCode = 3;
  const classes = useStyles();
  const stacktrace = useMemo(
    () => failureReason && formatStackTrace(failureReason),
    [failureReason]
  );

  const extractLineNumber = (line: string): number | undefined => {
    const match = line.match(/line (\d+):\d+/);
    return match && match.length > 1 ? parseInt(match[1], 10) : undefined;
  };

  const warningOrError = (notificationType: NotificationType) => {
    switch (notificationType) {
      case "TRUNCATED":
        return (
          <Box>
            <Box className={classes.headerBar} display="flex">
              <div className={classes.header}>
                <FontAwesomeIcon
                  className={classes.attention}
                  icon={faExclamationTriangle}
                />
                Query results were truncated
              </div>
            </Box>
            <DetailsRow>
              <div className={classes.truncationWarning}>
                Most likely, the results exceeded a client threshold (for
                example, SQL tools might limit the number of rows they display).
              </div>
            </DetailsRow>
          </Box>
        );

      case "CANCELED":
        return (
          <Box>
            <Box className={classes.headerBar} display="flex">
              <div className={classes.header}>
                <FontAwesomeIcon
                  className={classes.warn}
                  icon={faExclamationTriangle}
                />
                Query was canceled by user
              </div>
            </Box>
          </Box>
        );

      case "ERROR":
        return (
          <Box mb={3}>
            <Box className={classes.headerBar} display="flex">
              <div className={classes.header}>
                <FontAwesomeIcon
                  className={classes.warn}
                  icon={faExclamationTriangle}
                />
                Failure reason
              </div>
            </Box>
            <DetailsRow>
              <DetailsCell
                header="Error type"
                value={
                  upperFirst(lowerCase(failureReason?.errorCode.type)) || "-"
                }
                xs={4}
                lg={3}
              />
              <DetailsCell
                header="Error name"
                value={
                  upperFirst(lowerCase(failureReason?.errorCode.name)) || "-"
                }
                xs={4}
                lg={3}
              />
              <DetailsCell
                header="Error code"
                value={failureReason?.errorCode.code || "-"}
                xs={4}
                lg={3}
              />
            </DetailsRow>
          </Box>
        );
    }
  };

  const stackTrace = (notificationType: NotificationType) => {
    if (notificationType !== "ERROR") {
      return null;
    }

    return (
      <Box p={3} className={classes.stacktrace}>
        <Clipboard text={stacktrace}>
          <IconButton color="primary" className={classes.copy} size="large">
            <FontAwesomeIcon className={classes.icon} icon={faClone} />
          </IconButton>
        </Clipboard>

        <span>
          {reactStringReplace(stacktrace, /(line \d+:\d+)/g, (match, i) => (
            <QueryFullTextDialog
              key={i}
              text={queryText}
              lineHighlight={extractLineNumber(match)}
            >
              {(open): JSX.Element => (
                <span className={classes.link} onClick={open}>
                  {match}
                </span>
              )}
            </QueryFullTextDialog>
          ))}
        </span>
      </Box>
    );
  };

  if (!stacktrace && !failureReason.errorCode) {
    return null;
  }

  const notificationType: NotificationType =
    failureReason.errorCode.code === userCancelErrorCode
      ? state === "FINISHED"
        ? "TRUNCATED"
        : "CANCELED"
      : "ERROR";

  return (
    <Box py={1}>
      {warningOrError(notificationType)}
      {stackTrace(notificationType)}
    </Box>
  );
};
