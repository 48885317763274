/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useMemo } from "react";
import { SimpleDialog } from "../../../../components/dialog/SimpleDialog";
import { ExpressionDialogContentProps, ExpressionForm } from "./ExpressionForm";
import { createExpression } from "./rowExpressionApi";
import { RowExpressionType } from "../MasksAndFilters";
import { Expression } from "../../../../api/biac/biacApi";

interface CreateExpressionDialogProps {
  isOpen: boolean;
  close: () => void;
  reloadRowExpressions: () => void;
  rowExpressionType: RowExpressionType;
}
const formStartState = {
  name: "",
  expression: "",
  description: "",
};
Object.freeze(formStartState);

export const CreateExpressionDialog: React.FunctionComponent<
  CreateExpressionDialogProps
> = ({ isOpen, close, reloadRowExpressions, rowExpressionType }) => {
  const [rowExpression, setRowExpression] = React.useState<Expression>({
    ...formStartState,
  });

  useEffect(() => {
    if (!isOpen) {
      setRowExpression({ ...formStartState });
    }
  }, [isOpen]);

  const handleConfirm = async () => {
    await createExpression(rowExpression, rowExpressionType);
    reloadRowExpressions();
  };
  const title = useMemo(
    () =>
      rowExpressionType === RowExpressionType.Mask
        ? "Create mask"
        : "Create filter",
    [rowExpressionType]
  );

  return (
    <SimpleDialog<ExpressionDialogContentProps>
      title={title}
      isConfirmationButtonDisabled={
        rowExpression.name.trim() !== rowExpression.name ||
        rowExpression.name === "" ||
        rowExpression.expression === ""
      }
      Content={ExpressionForm}
      contentProps={{
        rowExpression: rowExpression,
        setRowExpression: setRowExpression,
        rowExpressionType: rowExpressionType,
      }}
      onConfirm={handleConfirm}
      isOpen={isOpen}
      close={close}
      confirmButtonLabel="Create"
    />
  );
};
