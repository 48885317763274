/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { SelectProps } from "@mui/material/Select";
import MuiSelect from "@mui/material/Select";
import React from "react";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  select: {
    backgroundColor: "white",
  },
  selectInput: {
    "&:focus": {
      backgroundColor: "white",
    },
  },
});

export const Select: React.FunctionComponent<SelectProps> = (props) => {
  const classes = useStyles();
  return (
    <MuiSelect
      {...props}
      className={`${props.className ?? ""} ${classes.select}`}
      classes={{ ...props.classes, select: classes.selectInput }}
    />
  );
};
