/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import { ColumnMaskPrivilege } from "./ColumnMaskPrivilege";
import { palette } from "../../../../../themes/palette";
import { RowFilterPrivilege } from "./RowFilterPrivilege";
import Grid from "@mui/material/Grid";
import { MaskOrFilterData } from "./addition-privileges-constants";
import { EmptyOrValue } from "../../../../../utils/value";

const useStyles = createUseStyles((theme: Theme) => ({
  expandIcon: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    width: "2px",
    height: "2px",
    marginLeft: "10rem",
  },
  expandOpenIcon: {
    transform: "rotate(180deg)",
  },
  labelBox: {
    cursor: "pointer",
  },
  labelBoxDisabled: {
    pointerEvents: "none",
  },
}));

interface AdditionalPrivilegesProps {
  currentRoleName: string;
  catalogName: string | null;
  schemaName: string | null;
  tableName: EmptyOrValue<string | null>;
  columns: EmptyOrValue<string[] | null>;
  disabled: boolean;
  handleColumnMaskChange: (newState: MaskOrFilterData[]) => void;
  handleRowFilterChange: (newState: MaskOrFilterData[]) => void;
}

export const AdditionalPrivileges: React.FunctionComponent<
  AdditionalPrivilegesProps
> = ({
  currentRoleName,
  catalogName,
  schemaName,
  tableName,
  columns,
  disabled,
  handleColumnMaskChange,
  handleRowFilterChange,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const classes = useStyles();
  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    setExpanded(false);
  }, [!tableName.empty]);

  return (
    <Box pl={4} mt={1}>
      <Box
        alignItems="center"
        display="flex"
        mt={2}
        mb={2}
        onClick={handleExpandClick}
        className={clsx(classes.labelBox, {
          [classes.labelBoxDisabled]: tableName.empty || disabled,
        })}
      >
        <Typography
          variant="h5"
          style={{ marginBottom: "0.25rem", whiteSpace: "nowrap" }}
          display="flex"
          alignItems="center"
        >
          Add column masks and filters&nbsp;
          <Typography color={palette.black54}>(Optional)</Typography>
        </Typography>

        <IconButton
          className={clsx(classes.expandIcon, {
            [classes.expandOpenIcon]: expanded,
          })}
          aria-expanded={expanded}
          aria-label="Show more"
          size="large"
          disabled={tableName.empty || disabled}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      {!tableName.empty && !disabled && (
        <Collapse in={expanded} unmountOnExit timeout="auto">
          <>
            {tableName.value && (!columns.empty || columns.value?.length) && (
              <Grid mb={2}>
                <ColumnMaskPrivilege
                  catalogName={catalogName}
                  schemaName={schemaName}
                  tableName={tableName.value}
                  columns={columns}
                  currentRoleName={currentRoleName}
                  handleChange={handleColumnMaskChange}
                />
              </Grid>
            )}
            <RowFilterPrivilege
              currentRoleName={currentRoleName}
              catalogName={catalogName}
              schemaName={schemaName}
              tableName={tableName}
              handleChange={handleRowFilterChange}
            />
          </>
        </Collapse>
      )}
    </Box>
  );
};
