/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { deleteDataProduct } from "../../../../api/dataProduct/dataProductApi";
import { BaseManager } from "../BaseManager";

export class DeleteManager extends BaseManager {
  constructor(dataProductId: string) {
    super("delete", () => deleteDataProduct(dataProductId));
  }
}
