/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import IconButton from "@mui/material/IconButton";
import { createUseStyles } from "react-jss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React, { Key, PropsWithChildren } from "react";
import { palette } from "../../../themes/palette";
import clsx from "clsx";

interface SimpleTableProps<RowType> {
  idGenerator: (row: RowType, index: number) => Key;
  columns: Array<keyof RowType>;
  rows: RowType[];
  deleteRow: (row: RowType) => void;
  classes?: {
    tableClass?: string;
    rowClass?: string;
    columns?: { [key in keyof RowType]?: string };
  };
}

const useStyles = createUseStyles({
  container: {
    tableLayout: "fixed",
    "& tr td:last-child": {
      width: "4rem",
    },
  },
  row: {
    "td:first-child": {
      fontWeight: 500,
    },
  },
  column: {
    wordWrap: "unset",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  deleteButton: {
    color: palette.error,
  },
});

export function SimpleTable<RowType>({
  idGenerator,
  columns,
  rows,
  deleteRow,
  classes,
}: PropsWithChildren<SimpleTableProps<RowType>>): JSX.Element {
  const internalClasses = useStyles();

  return (
    <Table
      size="small"
      className={clsx(classes?.tableClass, internalClasses.container)}
    >
      <TableBody>
        {rows?.map((row, rowIndex) => (
          <TableRow
            key={idGenerator(row, rowIndex)}
            className={clsx(classes?.rowClass, internalClasses.row)}
          >
            {columns?.map((column) => (
              <TableCell
                className={clsx(
                  classes?.columns?.[column],
                  internalClasses.column
                )}
                key={column as string}
              >
                {row[column]}
              </TableCell>
            ))}

            <TableCell align="right">
              <IconButton
                disableRipple={true}
                onClick={() => deleteRow(row)}
                size="small"
                className={internalClasses.deleteButton}
              >
                <DeleteOutline />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
