/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent, ReactElement } from "react";
import { graphlib } from "dagre";
import { Rect } from "./Rect";
export interface GraphGroupBasic {
  id: string;
}

export interface GraphGroup<T = Record<string, unknown>>
  extends GraphGroupBasic {
  data: T;
  Component: FunctionComponent<T>;
  fill: string;
  stroke: string;
}

interface GroupClusterProps {
  layout: graphlib.Graph;
  groups: GraphGroup[];
}

export const GroupCluster = ({
  layout,
  groups,
}: GroupClusterProps): ReactElement => {
  return (
    <g>
      {groups.map((g) => {
        const n = layout.node(g.id);
        const rx = 5;
        const x = -n.width / 2 + n.x;
        const y = -n.height / 2 + n.y;
        const width = n.width;
        const height = n.height;
        const strokeWidth = 4;
        return (
          <Rect
            key={g.id}
            fill={g.fill}
            rx={rx}
            x={x}
            y={y}
            width={width}
            height={height}
            stroke={g.stroke}
            strokeWidth={strokeWidth}
          >
            <g.Component {...g.data} />
          </Rect>
        );
      })}
    </g>
  );
};
