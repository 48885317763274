/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { faEye } from "@fortawesome/pro-solid-svg-icons/faEye";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useMemo } from "react";
import { SchemaDataProduct } from "../../../api/dataProduct/dataProductApi";
import {
  useClientDocUrl,
  useConfigContext,
} from "../../../app/ConfigContextProvider";
import CodeBox from "../../../components/box/CodeBox";
import { palette } from "../../../themes/palette";
import Link from "@mui/material/Link";

interface ViewInBiToolProps {
  dataProduct: SchemaDataProduct;
}

const useStyles = createUseStyles((theme: Theme) => ({
  viewButton: {
    color: palette.purple,
    textDecoration: "none",
  },
  docsLink: {
    color: palette.purple,
  },
  dialogTitle: {
    flex: 1,
    color: palette.nebulaNavy,
    fontWeight: 600,
    fontFamily: "montserrat, sans-serif",
    fontSize: "20px",
    lineHeight: "30px",
    padding: "24px",
  },
  dialogActions: {
    padding: "21px 24px",
  },
  text: {
    wordBreak: "break-word",
    fontSize: "0.875rem",
    color: palette.black54,
  },
  legend: {
    wordBreak: "break-word",
    fontSize: "0.875rem",
    color: palette.black54,
    marginBottom: theme.spacing(2),
  },
  info: {
    "& .MuiFormControl-root": {
      marginTop: "4px",
    },
  },
}));

interface DataProductProperties {
  url: URL;
  user: string;
  schemaName?: string;
  catalogName?: string;
}

const getPort = (url: URL): string => {
  if (url.port === "" && url.protocol === "https:") {
    return "443";
  }

  return url.port;
};

const createJdbcUrl = ({
  url,
  user,
  schemaName,
  catalogName,
}: DataProductProperties): string => {
  const port = getPort(url);
  return `jdbc:trino://${url.hostname}:${port}/${catalogName}/${schemaName}?user=${user}`;
};

const createCli = ({
  url,
  schemaName,
  catalogName,
}: DataProductProperties): string => {
  const port = getPort(url);
  return `trino --server ${url.protocol}//${url.hostname}:${port} --catalog ${catalogName} --schema ${schemaName}`;
};

const createOdbcUrl = ({
  url,
  schemaName,
  catalogName,
}: DataProductProperties): string => {
  const port = getPort(url);
  return `Driver={Starburst ODBC Driver};Host=${url.hostname};Port=${port};Catalog=${catalogName};Schema=${schemaName};AuthenticationType={LDAP Authentication};SSL=1`;
};

export const ViewInBiTool: React.FunctionComponent<ViewInBiToolProps> = ({
  dataProduct,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const user = useConfigContext()?.user ?? "";
  const docUrl = useClientDocUrl();
  const dataProductInfo: DataProductProperties = useMemo(() => {
    return {
      url: new URL(`${location.protocol}//${location.host}`),
      user: user,
      schemaName: dataProduct.schemaName,
      catalogName: dataProduct.catalogName,
    };
  }, [dataProduct]);

  return (
    <div>
      <Button
        className={classes.viewButton}
        startIcon={<FontAwesomeIcon icon={faEye} />}
        variant="outlined"
        onClick={() => setOpen(true)}
      >
        View in BI tools
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle}>
          Connection info
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" className={classes.legend}>
            Copy the links below to share a connection to this data product with
            your team. For more information about connecting to other clients,
            refer to our&nbsp;
            <Link
              href={docUrl}
              className={classes.docsLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              documentation.
            </Link>
          </Typography>
          <Grid container spacing={2} className={classes.info}>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.text}>
                JDBC
              </Typography>
              <CodeBox text={createJdbcUrl(dataProductInfo)} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.text}>
                ODBC
              </Typography>
              <CodeBox text={createOdbcUrl(dataProductInfo)} />
              <Typography variant="caption" className={classes.text}>
                For more information please refer to our&nbsp;
                <Link
                  href="https://docs.starburst.io/data-consumer/clients/odbc.html"
                  className={classes.docsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ODBC driver page.
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.text}>
                CLI
              </Typography>
              <CodeBox text={createCli(dataProductInfo)} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.text}>
                Other clients
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <CodeBox label="User" text={user} />
                </Grid>
                <Grid item xs={5}>
                  <CodeBox label="Host" text={dataProductInfo.url.hostname} />
                </Grid>
                <Grid item xs={2}>
                  <CodeBox label="Port" text={getPort(dataProductInfo.url)} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
