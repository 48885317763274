/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { DependencyList, RefObject, useEffect, useRef } from "react";
import { RefreshManager } from "./refreshManager";

export function useRefreshManager(
  dataProductId: string,
  viewName: string,
  dependencies: DependencyList
): RefObject<RefreshManager | undefined> {
  const manager = useRef<RefreshManager>();

  useEffect(() => {
    if (dataProductId) {
      manager.current = new RefreshManager(dataProductId, viewName);
    }

    return () => manager.current?.cancel();
  }, dependencies);

  return manager;
}
