/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { PartialObserver } from "rxjs";

interface DataError {
  type: "error";
  message: string;
}
interface DataSuccess<DataType> {
  type: "success";
  data: DataType;
}
interface DataFetching {
  type: "fetching";
}
export type DataFetchingState<DataType> =
  | DataSuccess<DataType>
  | DataFetching
  | DataError;

export function isDataSuccess<DataType>(
  domainState: DataFetchingState<DataType>
): domainState is DataSuccess<DataType> {
  return domainState.type === "success";
}
export function isDataError(
  domainState: DataFetchingState<unknown>
): domainState is DataError {
  return domainState.type === "error";
}
export function isDataFetching(
  domainState: DataFetchingState<unknown>
): domainState is DataFetching {
  return domainState.type === "fetching";
}

const createDataError = (message: string): DataError => ({
  type: "error",
  message,
});

export const createDataSuccess = <DataType>(
  data: DataType
): DataSuccess<DataType> => ({
  type: "success",
  data,
});

const createDataFetching = (): DataFetching => ({
  type: "fetching",
});

export interface UseFetchingStateResult<DataType> {
  state: DataFetchingState<DataType>;
  setState: Dispatch<SetStateAction<DataFetchingState<DataType>>>;
  isFetching: boolean;
  isError: boolean;
  data: DataType | undefined;
  errorMessage: string | undefined;
  setData: (data: DataType) => void;
  setFetching: () => void;
  setError: (message: string) => void;
  observer: PartialObserver<DataType>;
}

export const useFetchingState = <
  DataType
>(): UseFetchingStateResult<DataType> => {
  const [state, setState] = useState<DataFetchingState<DataType>>({
    type: "fetching",
  });
  const setData: (data: DataType) => void = useCallback(
    (data: DataType) => setState(createDataSuccess(data)),
    []
  );
  const setError = useCallback(
    (message: string) => setState(createDataError(message)),
    []
  );
  const setFetching = useCallback(() => setState(createDataFetching()), []);
  const observer = useMemo<PartialObserver<DataType>>(
    () => ({
      error: ({ message }: { message: string }) => setError(message),
      next: setData,
    }),
    []
  );
  return {
    state,
    setState,
    isFetching: isDataFetching(state),
    isError: isDataError(state),
    data: isDataSuccess(state) ? state.data : undefined,
    errorMessage: isDataError(state) ? state.message : undefined,
    setData,
    setFetching,
    setError,
    observer,
  };
};
