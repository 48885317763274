/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import TableCell, {
  SortDirection,
  TableCellBaseProps,
} from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useTableStyles } from "./useTableStyles";

export type SortOrder = "asc" | "desc";
export interface SortingState<T extends string> {
  sortBy: T;
  sortOrder: SortOrder;
}

export interface SortableColumnProps<T extends string> {
  label: string | React.ReactElement;
  sortingKey: T;
  sortingState: SortingState<T>;
  setSortingState: (newState: SortingState<T>) => void;
  component?: React.ElementType<TableCellBaseProps>;
  IconComponent?: React.ComponentType<{ className: string }>;
  disabled?: boolean;
  color?: string;
}

export const SortableColumn = <T extends string>({
  label,
  sortingKey,
  sortingState,
  setSortingState,
  component = "div",
  IconComponent,
  disabled,
  color,
}: SortableColumnProps<T>): JSX.Element => {
  const tableStyle = useTableStyles();
  const onSortClick = (): void => {
    let sortOrder: SortDirection;
    if (sortingState.sortBy !== sortingKey) {
      sortOrder = "desc";
    } else if (sortingState.sortOrder === "desc") {
      sortOrder = "asc";
    } else {
      sortOrder = "desc";
    }

    setSortingState({
      sortBy: sortingKey,
      sortOrder,
    });
  };

  const direction =
    sortingState.sortBy === sortingKey ? sortingState.sortOrder : "desc";

  return (
    <TableCell
      sortDirection={direction}
      variant="head"
      component={component}
      className={tableStyle.headerCell}
      color={color}
    >
      <TableSortLabel
        active={sortingState.sortBy === sortingKey}
        direction={direction}
        onClick={onSortClick}
        disabled={disabled}
        IconComponent={IconComponent}
      >
        {label}
      </TableSortLabel>
    </TableCell>
  );
};
