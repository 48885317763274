/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { createUseStyles } from "react-jss";

import { DataDomain } from "../../../../api/dataProduct/dataDomain/dataDomainApi";
import { palette } from "../../../../themes/palette";
import { ReassignDomainDialog } from "./ReassignDomainDialog";
import { ReassignAllDialog } from "./ReassignAllDialog";

const useStyles = createUseStyles({
  table: {
    "& th, & td": {
      fontSize: "0.75rem",
    },
  },
  action: {
    color: palette.purple,
    textAlign: "end",
    cursor: "pointer",
  },
  noData: {
    fontSize: "0.75rem",
    padding: "2rem 0",
  },
  description: {
    color: palette.black54,
    paddingTop: "1rem",
  },
});

interface ReassignDomainProps {
  domain: DataDomain;
  allDomains: DataDomain[];
}

export const ReassignDomain: React.FunctionComponent<ReassignDomainProps> = ({
  domain,
  allDomains,
}: ReassignDomainProps) => {
  const styles = useStyles();
  return (
    <div>
      {domain.assignedDataProducts.length ? (
        <TableContainer>
          <Table className={styles.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  Data products assigned to this domain (
                  {domain.assignedDataProducts.length})
                </TableCell>
                <TableCell className={styles.action}>
                  <ReassignAllDialog domain={domain} allDomains={allDomains} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {domain.assignedDataProducts.map((dataProduct) => (
                <TableRow key={dataProduct.id}>
                  <TableCell>{dataProduct.name}</TableCell>
                  <TableCell className={styles.action}>
                    <ReassignDomainDialog
                      domain={domain}
                      product={dataProduct}
                      allDomains={allDomains}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className={styles.noData}>
          <div>Data products assigned to this domain (0)</div>
          <div className={styles.description}>
            No data assigned to this domain
          </div>
        </div>
      )}
    </div>
  );
};
