/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { DependencyList, useCallback, useEffect, useRef } from "react";
import { Subscription } from "rxjs";

export function useCallback$<CallBackParams extends unknown[]>(
  callback: (...args: CallBackParams) => Subscription,
  deps: DependencyList
): (...args: CallBackParams) => void {
  const subscriptions = useRef<Subscription[]>([]);
  useEffect(
    () => () => {
      subscriptions.current.forEach((sub) => sub.unsubscribe());
      subscriptions.current = [];
    },
    deps
  );
  return useCallback((...args: CallBackParams) => {
    subscriptions.current.push(callback(...args));
  }, deps);
}
