/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import clsx from "clsx";
import { Spinner } from "../../../components/spinner/Spinner";
import { ErrorBox } from "../../../components/error/ErrorBox";
import Link from "@mui/material/Link";
import { useRowFiltersFetchingState } from "./useRoleDetailsFetchingState";
import { ExpressionEntry } from "./entry/ExpressionEntry";
import { useRoleDetailsStyles } from "./RoleDetailsStyles";

interface RowFiltersListProps {
  currentRoleName: string;
  roleId: number;
}

export const RowFiltersList: React.FunctionComponent<RowFiltersListProps> = ({
  currentRoleName,
  roleId,
}) => {
  const classes = useRoleDetailsStyles();

  const {
    isError,
    errorMessage,
    setError,
    isFetching,
    data,
    reload,
    doDelete,
  } = useRowFiltersFetchingState(currentRoleName, roleId);

  const itemsCount = data ? data.length : 0;
  return (
    <>
      {isError && (
        <Grid item>
          <Alert
            variant="standard"
            severity="error"
            classes={{ message: classes.errorMessage }}
          >
            <Box display="flex" justifyContent="space-between">
              <div>{errorMessage}</div>
              <Link
                color="error"
                onClick={reload}
                className={classes.reloadLink}
              >
                Reload
              </Link>
            </Box>
          </Alert>
        </Grid>
      )}
      <Grid
        item
        className={clsx(classes.list, {
          [classes.listShort]: isError,
        })}
      >
        {isFetching && <Spinner position="relative" />}
        {isError && <ErrorBox height={200} text="Cannot load row filters" />}
        {itemsCount > 0 && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <div>Entity</div>
          </Box>
        )}
        {data &&
          data.map((obj) => (
            <ExpressionEntry
              key={obj.id}
              doDelete={() => doDelete(obj.id)}
              onDeleteSuccess={reload}
              onDeleteFailure={(e) => setError(e.message)}
              expression={obj.expression.object}
              entityDto={obj.entity}
            />
          ))}
        {itemsCount > 0 && <div className={classes.endBorder} />}
        {!isError && !isFetching && itemsCount === 0 && (
          <div className={classes.noContent}>No row filters</div>
        )}
      </Grid>
    </>
  );
};
