/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import {
  QueriesSorting,
  QueryDetails,
  QueryState,
} from "../../../api/queriesApi";
import { differenceInMinutes, parseISO } from "date-fns";
import { SortableColumn } from "../../../components/table/SortableColumn";
import { Link as RouterLink } from "react-router-dom";
import { SqlHighlight } from "../../../components/sql-highlight/SqlHighlight";
import { formatDuration } from "../../../utils/formatDuration";
import clsx from "clsx";
import {
  defaultRowRenderer,
  defaultHeaderRowRenderer,
  TableCellProps,
  TableHeaderRowProps,
  TableHeaderRowRenderer,
  TableRowProps,
  Column,
  Table,
  TableCellRenderer,
  TableRowRenderer,
} from "react-virtualized/dist/es/Table";
import { AutoSizer } from "react-virtualized/dist/es/AutoSizer";
import {
  isFooterRow,
  TableFooter,
  useTableFooterRowClassName,
} from "./TableFooter";
import {
  isLoadingRow,
  TableLoading,
  useTableLoadingRowClassName,
} from "./TableLoading";
import { TableData, useTableDataRowClassName } from "./TableData";
import { StatusIcon } from "../../../components/icon/StatusIcon";
import { fetchQueries } from "./queryHistorySlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { palette } from "../../../themes/palette";
import { ErrorBox } from "../../../components/error/ErrorBox";
import { NoData } from "../../../components/error/NoData";
import { navigateBackInHistoryParam } from "../details/QueryDetails";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import capitalize from "lodash/capitalize";

const useStyles = createUseStyles({
  root: {
    width: "100%",
  },
  statusIcon: {
    paddingTop: "0.25rem",
  },
  status: {
    fontSize: "1.25rem",
  },
  link: {
    color: palette.purple,
    letterSpacing: "0.5px",
  },
  truncated: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  noClick: {
    cursor: "initial",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
  },
  headerCell: {
    overflow: "hidden",
    letterSpacing: "0.5px",
    fontWeight: 600,
  },
  grid: {
    direction: "inherit",
    outline: "0",
    border: "1px solid #E4E7EF",
  },
  tableHeader: {
    border: "1px solid #E4E7EF",
    borderBottom: "none",
  },
});

const useFlexGrid = createUseStyles({
  5: {
    flex: "0 1 5%!important",
  },
  8: {
    flex: "0 1 8%!important",
  },
  9: {
    flex: "0 1 9%!important",
  },
  10: {
    flex: "0 1 10%!important",
  },
  11: {
    flex: "0 1 11%!important",
  },
  13: {
    flex: "0 1 13%!important",
  },
  15: {
    flex: "0 1 15%!important",
  },
  18: {
    flex: "0 1 18%!important",
  },
  34: {
    flex: "0 1 34%!important",
  },
});

interface QueriesTableProps {
  sorting: QueriesSorting;
  setSorting: (newState: QueriesSorting) => void;
}

export type QueriesTableRowData = QueryDetails | "loading-row" | "footer-row";

export const QueriesTable: React.FunctionComponent<QueriesTableProps> = ({
  sorting,
  setSorting,
}) => {
  const classes = useStyles();
  const flexGrid = useFlexGrid();
  const tableFooterRowClassName = useTableFooterRowClassName();
  const tableDataRowClassName = useTableDataRowClassName();
  const tableLoadingRowClassName = useTableLoadingRowClassName();

  const mdHeight = useMediaQuery("(min-height:740px)");
  const lgHeight = useMediaQuery("(min-height:1000px)");

  const dispatch: AppDispatch = useDispatch();
  const queries = useSelector(
    ({ queryHistory }: RootState) => queryHistory.queries
  );
  const lastUpdateDate = useSelector(
    ({ queryHistory }: RootState) => queryHistory.updateDate
  );

  const headerHeight = 57;
  const rowHeight = 50;
  const expectedRowsCount = lgHeight ? 12 : mdHeight ? 10 : 6;
  const rowsCount = Array.isArray(queries)
    ? Math.min(queries.length + 1, expectedRowsCount)
    : expectedRowsCount;
  const tableHeight = headerHeight + rowsCount * rowHeight;

  useEffect(() => {
    if (
      queries === undefined ||
      // refresh when the data is at least 10 minutes old
      (lastUpdateDate && differenceInMinutes(new Date(), lastUpdateDate) >= 10)
    ) {
      dispatch(fetchQueries());
    }
    // eslint-disable-next-line
  }, [queries]);

  const headerRenderer: TableHeaderRowRenderer = (
    props: TableHeaderRowProps
  ) => {
    return defaultHeaderRowRenderer({
      ...props,
      className: clsx(
        props.className,
        classes.tableHeader,
        classes.flexContainer
      ),
    });
  };

  const rowRenderer: TableRowRenderer = (props: TableRowProps) => {
    const defaultRenderer = (className = ""): React.ReactNode =>
      defaultRowRenderer({
        ...props,
        className: clsx(props.className, className),
      });

    const rowData: QueriesTableRowData = props.rowData;
    if (isLoadingRow(rowData)) {
      return defaultRenderer(tableLoadingRowClassName);
    } else if (isFooterRow(rowData)) {
      return defaultRenderer(tableFooterRowClassName);
    } else {
      return defaultRenderer(tableDataRowClassName(props.index));
    }
  };

  const cellRenderer =
    (
      renderer: (
        cellData: QueryDetails[keyof QueryDetails]
      ) => string | JSX.Element,
      align: "left" | "center" = "left"
    ): TableCellRenderer =>
    ({
      cellData,
      columnIndex,
      rowData: rowDataUntyped,
    }: TableCellProps): React.ReactNode => {
      const rowData: QueriesTableRowData = rowDataUntyped;

      if (isFooterRow(rowData)) {
        return (
          <TableFooter
            columnIndex={columnIndex}
            rowHeight={rowHeight}
            foundRows={queries?.length ?? 0}
          />
        );
      } else if (isLoadingRow(rowData)) {
        return <TableLoading rowHeight={rowHeight} columnIndex={columnIndex} />;
      } else {
        return (
          <TableData
            align={align}
            cellData={cellData}
            rowHeight={rowHeight}
            renderer={renderer}
          />
        );
      }
    };

  const provideRows = (
    processRows: (rows: QueriesTableRowData[]) => React.ReactNode
  ): React.ReactNode => {
    if (queries === undefined) {
      return <></>;
    } else if (queries.length === 0) {
      return <NoData height={220} icon={"table"} />;
    } else if (queries === "error") {
      return (
        <ErrorBox
          height={220}
          text="Could not load queries. Try again later."
        />
      );
    } else if (queries === "loading") {
      return processRows(
        new Array<"loading-row">(rowsCount).fill("loading-row")
      );
    } else {
      return processRows([...queries, "footer-row"]);
    }
  };

  const columnProps = (
    width: 5 | 8 | 9 | 10 | 11 | 13 | 15 | 18 | 34
  ): { headerClassName: string; width: number; className: string } => {
    return {
      headerClassName: `${classes.noClick} ${classes.headerCell} ${flexGrid[width]}`,
      width: 500,
      className: flexGrid[width],
    };
  };

  return (
    <Box className={classes.root}>
      {provideRows((rows) => (
        <Box style={{ height: tableHeight }}>
          <AutoSizer>
            {({ width }): React.ReactNode => (
              <Table
                height={tableHeight}
                width={width}
                rowHeight={rowHeight}
                gridClassName={classes.grid}
                headerHeight={headerHeight}
                rowCount={rows.length}
                rowGetter={({ index }): QueriesTableRowData => rows[index]}
                rowRenderer={rowRenderer}
                headerRowRenderer={headerRenderer}
              >
                <Column
                  headerRenderer={(): React.ReactNode => (
                    <TableCell
                      variant="head"
                      component="div"
                      className={clsx(
                        classes.flexContainer,
                        classes.headerCell
                      )}
                      style={{ justifyContent: "center" }}
                    >
                      Status
                    </TableCell>
                  )}
                  cellRenderer={cellRenderer(
                    (cellData) => (
                      <Tooltip
                        title={capitalize(cellData as QueryState)}
                        placement={"right"}
                      >
                        <span className={classes.statusIcon}>
                          <StatusIcon
                            state={cellData as QueryState}
                            className={classes.status}
                          />
                        </span>
                      </Tooltip>
                    ),
                    "center"
                  )}
                  dataKey={"state"}
                  {...columnProps(5)}
                />

                <Column
                  headerRenderer={(): React.ReactNode => (
                    <SortableColumn
                      label="Query ID"
                      sortingKey="id"
                      sortingState={sorting}
                      setSortingState={setSorting}
                    />
                  )}
                  cellRenderer={cellRenderer((cellData) => (
                    <Link
                      component={RouterLink}
                      to={{
                        pathname: `/query/${cellData}`,
                        state: { [navigateBackInHistoryParam]: true },
                      }}
                      className={`${classes.link} ${classes.truncated}`}
                    >
                      {cellData}
                    </Link>
                  ))}
                  dataKey={"queryId"}
                  {...columnProps(18)}
                />

                <Column
                  headerRenderer={(): React.ReactNode => (
                    <TableCell
                      component="div"
                      variant="head"
                      className={classes.headerCell}
                    >
                      Query text
                    </TableCell>
                  )}
                  cellRenderer={cellRenderer((cellData) => (
                    <SqlHighlight sql={cellData as string} webkitClamp={true} />
                  ))}
                  dataKey={"query"}
                  {...columnProps(34)}
                />

                <Column
                  headerRenderer={(): React.ReactNode => (
                    <SortableColumn
                      label="User"
                      sortingKey="user"
                      sortingState={sorting}
                      setSortingState={setSorting}
                    />
                  )}
                  cellRenderer={cellRenderer((cellData) => (
                    <Tooltip title={cellData} placement={"right"}>
                      <div className={classes.truncated}>{cellData}</div>
                    </Tooltip>
                  ))}
                  dataKey={"user"}
                  {...columnProps(8)}
                />

                <Column
                  headerRenderer={(): React.ReactNode => (
                    <SortableColumn
                      label="Create date"
                      sortingKey="createDate"
                      sortingState={sorting}
                      setSortingState={setSorting}
                    />
                  )}
                  cellRenderer={cellRenderer((cellData) => (
                    <Tooltip
                      title={parseISO(cellData as string).toLocaleString()}
                      placement={"right"}
                    >
                      <div className={classes.truncated}>
                        {parseISO(cellData as string).toLocaleString()}
                      </div>
                    </Tooltip>
                  ))}
                  dataKey={"createTime"}
                  {...columnProps(15)}
                />

                <Column
                  headerRenderer={(): React.ReactNode => (
                    <SortableColumn
                      label="CPU time"
                      sortingKey="cpuTime"
                      sortingState={sorting}
                      setSortingState={setSorting}
                    />
                  )}
                  cellRenderer={cellRenderer((cellData) => (
                    <div className={classes.truncated}>
                      {formatDuration(cellData as number)}
                    </div>
                  ))}
                  dataKey={"cpuTime"}
                  {...columnProps(9)}
                />

                <Column
                  headerRenderer={(): React.ReactNode => (
                    <SortableColumn
                      label="Elapsed time"
                      sortingKey="elapsedTime"
                      sortingState={sorting}
                      setSortingState={setSorting}
                    />
                  )}
                  cellRenderer={cellRenderer((cellData) => (
                    <div className={classes.truncated}>
                      {formatDuration(cellData as number)}
                    </div>
                  ))}
                  dataKey={"elapsedTime"}
                  {...columnProps(11)}
                />
              </Table>
            )}
          </AutoSizer>
        </Box>
      ))}
    </Box>
  );
};
