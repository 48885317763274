/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ComponentProps, ElementType } from "react";
import { DataProductLink } from "../../../api/dataProduct/dataProductApi";
import { LinkDetails } from "../details/LinkDetails";

interface LinkListProps<LinkWrapperType extends ElementType> {
  relevantLinks?: DataProductLink[];
  linkWrapper: LinkWrapperType;
  wrapperProps: ComponentProps<LinkWrapperType>;
}

export const LinkList = <LinkWrapperType extends ElementType>({
  relevantLinks = [],
  linkWrapper,
  wrapperProps,
}: LinkListProps<LinkWrapperType>) => {
  return (
    <>
      {relevantLinks.map((link, index) =>
        React.createElement(
          linkWrapper,
          {
            ...wrapperProps,
            key: index,
          },
          <LinkDetails article={link} />
        )
      )}
    </>
  );
};
