/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { createUseStyles } from "react-jss";
import { QueriesByTimeChart } from "./QueriesByTimeChart";
import { QueriesByTableChart } from "./QueriesByTableChart";
import { LeastActiveQueriesChart } from "./LeastActiveQueriesChart";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TopUsersUsageCharts } from "./usage/TopUsersUsageCharts";
import { TopSourcesUsageCharts } from "./usage/TopSourcesUsageCharts";
import { TopSourcesIOCharts } from "./io/TopSourcesIOCharts";
import { TopUsersIOCharts } from "./io/TopUsersIOCharts";
import { TopTablesIOCharts } from "./io/TopTablesIOCharts";
import { ConfigContext } from "../../../app/ConfigContextProvider";
import { NoData } from "../../../components/error/NoData";
import Alert from "@mui/material/Alert";

interface ReportsProps {
  showReports?: boolean;
}

const useStyles = createUseStyles({
  root: {
    padding: "0.25rem 0 0",
  },
  chartsContainer: {
    paddingTop: "1rem",
  },
  wideChart: {
    display: "flex",
    width: "100%",
  },
  narrowChart: {
    display: "flex",
    width: "100%",
  },
  "@media (min-width: 1300px)": {
    wideChart: {
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
    },
    narrowChart: {
      flexGrow: 0,
      maxWidth: "50%",
      flexBasis: "50%",
    },
  },
  "@media (min-width: 1600px)": {
    wideChart: {
      flexGrow: 0,
      maxWidth: "66.66%",
      flexBasis: "66.66%",
    },
    narrowChart: {
      flexGrow: 0,
      maxWidth: "33.33%",
      flexBasis: "33.33%",
    },
  },
});

export const Reports: React.FunctionComponent<ReportsProps> = ({
  showReports = true,
}) => {
  const classes = useStyles();
  const fullView = useContext(ConfigContext)?.allQueries;
  const threeColumns = useMediaQuery("(min-width:1600px)");

  const order = (
    orderConfig: number | { lg?: number; md?: number }
  ): React.CSSProperties => {
    if (typeof orderConfig === "number") {
      return { order: orderConfig };
    }
    const { lg, md } = orderConfig;

    if (threeColumns) {
      return { order: lg ?? 0 };
    } else {
      return { order: md ?? 0 };
    }
  };

  return (
    <Box p={1} className={classes.root}>
      {showReports ? (
        <>
          <Alert severity="info">
            Reports generated below do not include data for running and queued
            queries.
          </Alert>
          <Grid container spacing={2} className={classes.chartsContainer}>
            <Grid item className={classes.wideChart} style={order(0)}>
              <QueriesByTimeChart />
            </Grid>
            <Grid
              item
              className={classes.narrowChart}
              style={order({ lg: 10, md: 100 })}
            >
              <LeastActiveQueriesChart />
            </Grid>
            {fullView && (
              <Grid
                item
                className={classes.narrowChart}
                style={order({ lg: 20, md: 10 })}
              >
                <TopUsersUsageCharts />
              </Grid>
            )}
            <Grid
              item
              className={classes.narrowChart}
              style={order({ lg: 30, md: 30 })}
            >
              <TopSourcesUsageCharts />
            </Grid>
            <Grid
              item
              className={classes.narrowChart}
              style={order({ lg: fullView ? 40 : 60, md: 50 })}
            >
              <QueriesByTableChart />
            </Grid>
            {fullView && (
              <Grid
                item
                className={classes.narrowChart}
                style={order({ lg: 50, md: 20 })}
              >
                <TopUsersIOCharts />
              </Grid>
            )}
            <Grid
              item
              className={classes.narrowChart}
              style={order({ lg: fullView ? 60 : 40, md: 40 })}
            >
              <TopSourcesIOCharts />
            </Grid>
            <Grid
              item
              className={classes.narrowChart}
              style={order({ lg: 70, md: 60 })}
            >
              <TopTablesIOCharts />
            </Grid>
          </Grid>
        </>
      ) : (
        <NoData
          height={200}
          icon={"chart"}
          text={"No reports to display for running or queued queries"}
        />
      )}
    </Box>
  );
};
