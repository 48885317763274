/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactNode } from "react";

interface RectProps extends React.SVGProps<SVGRectElement> {
  children: ReactNode;
}

export const Rect: React.FunctionComponent<RectProps> = ({
  children,
  className,
  width,
  height,
  x,
  y,
  ...rectProps
}: RectProps) => {
  return (
    <g className={className}>
      <rect {...rectProps} width={width} height={height} x={x} y={y} />
      <foreignObject width={width} height={height} x={x} y={y}>
        {children}
      </foreignObject>
    </g>
  );
};
