/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useLayoutEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import Tooltip from "@mui/material/Tooltip";

const useStyles = createUseStyles({
  truncated: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

interface EllipsisProps {
  children: React.ReactNode;
}

export const Ellipsis: React.FunctionComponent<EllipsisProps> = ({
  children,
}) => {
  const classes = useStyles();
  const ref = useRef<HTMLDivElement>(null);
  const [ellipsisActive, setEllipsisActive] = useState(false);

  function isEllipsisActive(): boolean {
    return (
      ref.current !== null && ref.current.offsetWidth < ref.current.scrollWidth
    );
  }

  useLayoutEffect(() => {
    setEllipsisActive(isEllipsisActive());
  }, [children]);

  const content = (
    <div ref={ref} className={classes.truncated}>
      {children}
    </div>
  );

  if (ellipsisActive) {
    return <Tooltip title={content}>{content}</Tooltip>;
  } else {
    return content;
  }
};
