/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import React from "react";
import { faRocketLaunch } from "@fortawesome/pro-duotone-svg-icons";
import { palette } from "../../themes/palette";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";

interface SuccessBoxProps {
  height?: number;
  text: string;
}

const useStyles = createUseStyles((theme: Theme) => ({
  icon: {
    opacity: 0.85,
    marginLeft: "auto",
    marginRight: "auto",
    "--fa-primary-color": palette.teal,
    "--fa-secondary-color": "orangered",
    "--fa-primary-opacity": 1,
    "--fa-secondary-opacity": 1,
  },
  text: {
    color: palette.black54,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "1rem",
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
  },
}));

export const Success: React.FunctionComponent<SuccessBoxProps> = ({
  height = 250,
  text,
}) => {
  const classes = useStyles();

  return (
    <Box display={"flex"} flexDirection={"column"} style={{ height }}>
      <FontAwesomeIcon
        className={classes.icon}
        style={{
          marginTop: height > 150 ? height / 12 : 0,
          fontSize: Math.min(110, height * 0.6),
        }}
        icon={faRocketLaunch}
      />
      <Box className={classes.text}>{text}</Box>
    </Box>
  );
};
