/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import {
  getAccessibleRoles,
  getApplicableRoles,
} from "../../../../api/biac/biacRolesApi";
import CircularProgress from "@mui/material/CircularProgress";
import { ChooserErrorIcon } from "../../grants/ChooserErrorIcon";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { EmptyOrValue } from "../../../../utils/value";
import Popper from "@mui/material/Popper";
import Paper, { PaperProps } from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { addPrivilegesStyles } from "../add-privileges-styles";
import { PopperUnstyledProps } from "@mui/base/PopperUnstyled";
import Button from "@mui/material/Button";
import { palette } from "../../../../themes/palette";

export interface ChosenRole {
  id: number | null;
  name: string;
}

interface RoleInfo {
  label: string;
  roleId: number | null;
  description: string | null;
}

interface RoleSelectorProps {
  currentRoleName: string;
  allAccessible?: boolean;
  excludedRoles?: number[];
  value: EmptyOrValue<ChosenRole[] | null>;
  handleChange: (value: EmptyOrValue<ChosenRole[] | null>) => void;
}
export const RolesSelector: React.FunctionComponent<RoleSelectorProps> = ({
  currentRoleName,
  allAccessible,
  excludedRoles,
  value,
  handleChange,
}) => {
  const classes = addPrivilegesStyles();
  const [applicableRoles, setApplicableRoles] = useState<RoleInfo[]>([]);
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [roles, setRoles] = useState<RoleInfo[]>([]);
  const allRolesChecked = !value.empty && value.value === null;
  useEffect(() => {
    const selectedLength = value.value?.filter((currentVal) =>
      roles.some((role) => currentVal?.id === role.roleId)
    ).length;
    if (!selectedLength) {
      if (value.value) {
        const rolesFound: RoleInfo[] = [];
        applicableRoles.forEach((applicableRole) => {
          value.value?.forEach((val) => {
            if (applicableRole.roleId === val.id) {
              rolesFound.push(applicableRole);
            }
          });
        });
        setRoles(rolesFound ? rolesFound : []);
      } else {
        setRoles([]);
      }
    }
  }, [value]);

  useEffect(() => {
    setBusy(true);
    setError(null);
    (allAccessible ? getAccessibleRoles : getApplicableRoles)(currentRoleName)
      .then((applicableRoles) => {
        const roles = applicableRoles
          .filter(({ role: { id } }) =>
            excludedRoles ? !excludedRoles.includes(id) : true
          )
          .map<RoleInfo>(
            ({
              role: {
                id,
                object: { name, description },
              },
            }) => ({
              roleId: id,
              label: name,
              description,
            })
          );
        setBusy(false);
        setApplicableRoles(roles);
      })
      .catch((e) => {
        setBusy(false);
        setError(e.message);
      });
  }, [currentRoleName, excludedRoles]);

  useEffect(() => {
    return () => handleChange({ empty: true, value: null });
  }, []);

  const onChange = useCallback(
    (event: React.SyntheticEvent, value: RoleInfo[]) => {
      setRoles(value);
      setValues(value);
    },
    [roles]
  );

  const setValues = useCallback((value: RoleInfo[]) => {
    if (!value.length) {
      handleChange({
        empty: true,
        value: null,
      });
    } else {
      handleChange({
        empty: !value,
        value: value.map((val) => {
          return { name: val.label, id: val.roleId };
        }),
      });
    }
  }, []);

  const checkAllChange = useCallback(() => {
    setValues(applicableRoles);
    setRoles(applicableRoles);
  }, [applicableRoles]);

  const handleCheckStar = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        handleChange({
          empty: false,
          value: null,
        });
        setRoles([]);
      } else {
        handleChange({
          empty: true,
          value: null,
        });
      }
    },
    []
  );

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: RoleInfo,
      { selected }
    ) => {
      return (
        <li {...props}>
          <Checkbox style={{ marginRight: "8px" }} checked={selected} />
          <div>
            <div className={classes.optionLabel}>{option.label}</div>
            {option.description && (
              <div className={classes.optionDescription}>
                {option.description}
              </div>
            )}
          </div>
        </li>
      );
    },
    []
  );

  const renderPopupIcon = useCallback(() => {
    if (busy) {
      return <CircularProgress size={20} />;
    } else if (error) {
      return (
        <div>
          <ChooserErrorIcon title="Loading roles failed" />
        </div>
      );
    } else {
      return <ArrowDropDownOutlinedIcon />;
    }
  }, [busy, error]);

  const CustomPaper = useCallback(
    (props: PaperProps) => (
      <Paper style={{ borderRadius: "4px 4px 0 0" }} {...props} />
    ),
    []
  );

  const SelectAllButton = useCallback(
    (param: PopperUnstyledProps) => (
      <Popper {...param}>
        <Box {...param} />
        <Paper className={classes.popperComponentPaper}>
          <Box justifyContent="flex-end" display="flex">
            <Button
              variant="outlined"
              size="small"
              onMouseDown={(e) => {
                e.preventDefault();
              }}
              onClick={checkAllChange}
            >
              Select all roles
            </Button>
          </Box>
        </Paper>
      </Popper>
    ),
    [checkAllChange]
  );

  return (
    <Grid pl={1} maxWidth="fit-content">
      <Typography variant="h5">
        Which roles would you like to select?
      </Typography>
      <Typography className={classes.questionInfo}>
        Use the dropdown to select the roles. To select all currently defined
        roles, as well as roles defined in the future, check the{" "}
        <b>All roles</b> box instead.
      </Typography>
      <Box mb={1.5}>
        <FormControlLabel
          control={
            <Checkbox
              checked={allRolesChecked}
              onChange={handleCheckStar}
              id="select-star"
            />
          }
          label={
            <span>
              All roles{" "}
              <span style={{ color: palette.black54 }}>(use with caution)</span>
            </span>
          }
        />
      </Box>
      <Box mb={2}>
        <Autocomplete
          value={roles}
          multiple
          limitTags={2}
          clearText="Clear selected roles"
          id="multiple-limit-tags"
          options={applicableRoles}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) =>
            option.roleId === value.roleId
          }
          disableCloseOnSelect
          disabled={allRolesChecked || !applicableRoles.length}
          onChange={onChange}
          renderOption={renderOption}
          popupIcon={renderPopupIcon()}
          ChipProps={{
            className: classes.chip,
            deleteIcon: <CloseIcon className={classes.chipCloseIcon} />,
          }}
          renderInput={(params) => <TextField {...params} label="Roles" />}
          PopperComponent={SelectAllButton}
          PaperComponent={CustomPaper}
        />
      </Box>
    </Grid>
  );
};
