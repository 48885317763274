/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { AutoSizer } from "react-virtualized";
import Button from "@mui/material/Button";
import {
  TrinoResponseColumns,
  TrinoResponseData,
} from "@starburstdata/query-editor/dist/api/trinoResponse";
import { ResultTable } from "@starburstdata/query-editor";
import React, { useCallback, useEffect, useState } from "react";
import { NoData } from "../../../components/error/NoData";
import { Spinner } from "../../../components/spinner/Spinner";
import {
  useDataProductTrinoClient,
  ROW_LIMIT,
} from "../trinoClient/useDataProductTrinoClient";
import { createUseStyles } from "react-jss";
import { ErrorIndicator } from "../../../components/error/ErrorIndicator";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import { SimpleDialog } from "../../../components/dialog/SimpleDialog";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";

export enum PreviewDataButtonType {
  ICON = "icon-button",
  TEXT = "text-button",
}

interface PreviewDatasetProps {
  sqlQuery: string;
  disabled?: boolean;
  className?: string;
  buttonType?: PreviewDataButtonType;
}

const useStyles = createUseStyles({
  button: {
    marginBottom: "1.5rem",
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
});

export const PreviewDataset: React.FunctionComponent<PreviewDatasetProps> = ({
  sqlQuery,
  disabled = false,
  className,
  buttonType = PreviewDataButtonType.TEXT,
}) => {
  const classes = useStyles();
  const { isOpen, open, close } = useDialogOpenState();

  return (
    <>
      {buttonType === PreviewDataButtonType.ICON ? (
        <Tooltip
          title={
            disabled
              ? "Preview data is only available for published datasets"
              : "Preview dataset"
          }
          delayToolip={500}
          disableInteractive={true}
        >
          <span>
            <IconButton
              disableRipple
              color="primary"
              className={classes.iconButton}
              disabled={disabled}
              onClick={open}
            >
              <VisibilityIcon fontSize="medium" />
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className={`${classes.button} ${className}`}
          disabled={disabled}
          onClick={open}
        >
          <VisibilityIcon style={{ marginRight: "5px" }} fontSize="small" />
          Preview
        </Button>
      )}
      {isOpen && <PreviewDialog sqlQuery={sqlQuery} close={close} />}
    </>
  );
};

interface PreviewDialogProps {
  sqlQuery: string;
  close: () => void;
}

const PreviewDialog = ({ sqlQuery, close }: PreviewDialogProps) => {
  const onConfirm = useCallback(() => Promise.resolve(), []);
  const [previewDataResponse, setPreviewDataResponse] = useState<QueryResponse>(
    {
      loading: false,
      data: [],
      columns: [],
      errorMessage: undefined,
    }
  );

  const previewDataTrinoClient = useDataProductTrinoClient(
    {
      onReceiveColumnMetadata: (columns) => {
        setPreviewDataResponse((prev) => ({
          ...prev,
          columns,
        }));
      },
      onReceiveData: (data) => {
        setPreviewDataResponse((prev) => ({
          ...prev,
          data,
          error: undefined,
        }));
      },
      onError: (errorMessage) => {
        setPreviewDataResponse((prev) => ({ ...prev, errorMessage }));
      },
      onBusy: (busy) => {
        setPreviewDataResponse((prev) => ({
          ...prev,
          loading: busy,
        }));
      },
    },
    []
  );

  useEffect(() => {
    previewDataTrinoClient.execute(sqlQuery);
    return () => {
      previewDataTrinoClient.cancel();
    };
  }, [sqlQuery]);

  return (
    <SimpleDialog
      isOpen
      close={close}
      title="Data preview"
      cancelButtonLabel="Close"
      onConfirm={onConfirm}
      fullWidth={true}
      contentProps={previewDataResponse}
      Content={PreviewContent}
      maxWidth={false}
    />
  );
};

const PreviewContent = ({
  loading,
  errorMessage,
  data,
  columns,
}: QueryResponse) => {
  return loading ? (
    <Spinner position="relative" />
  ) : errorMessage ? (
    <div style={{ padding: "0 1rem" }}>
      <ErrorIndicator text={errorMessage} />
    </div>
  ) : (
    <PreviewResultTable data={data} columns={columns} />
  );
};

interface QueryResponse {
  loading: boolean;
  data: TrinoResponseData;
  columns: TrinoResponseColumns;
  errorMessage: string | undefined;
}

type PreviewResultTableProps = Pick<QueryResponse, "data" | "columns">;
const PreviewResultTable = ({ data, columns }: PreviewResultTableProps) => {
  const dataToRender = data.slice(0, ROW_LIMIT);
  return dataToRender.length ? (
    <AutoSizer disableHeight>
      {({ width }) => (
        <ResultTable
          data={dataToRender}
          columns={columns}
          height="fit-content-no-optimization"
          width={width}
        />
      )}
    </AutoSizer>
  ) : (
    <NoData height={250} icon="table" />
  );
};
