/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import round from "lodash/round";

// abbreviates whole numbers and ignores other ones
export function abbreviateWholeNumbers(value: number): string | undefined {
  if (Math.floor(value) === value) {
    return abbreviateNumber(value);
  }
}

// Iterated from: https://stackoverflow.com/questions/10599933/convert-long-number-into-abbreviated-string-in-javascript-with-a-special-shortn
export function abbreviateNumber(value: number): string {
  let newValue: number = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }

  newValue = round(newValue, newValue >= 100 ? 0 : 1);
  return `${newValue}${suffixes[suffixNum]}`;
}
