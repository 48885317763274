/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React, { useContext, useMemo } from "react";
import {
  StarburstQueryEditor,
  StarburstQueryEditorConfig,
} from "@starburstdata/query-editor";
import { QueryDetailsLink } from "./QueryDetailsLink";
import {
  ConfigContext,
  uiFeatureEnabled,
} from "../../app/ConfigContextProvider";
import { baseUrl } from "../../api/api";
import { UiFeatures } from "../../api/configApi";
import { RoleContext } from "../biac/CurrentRoleContext";
import { createUseStyles } from "react-jss";
import { QueryAccelerationLink } from "./QueryAccelerationLink";
import { AddToDataProductFromQueryLink } from "../dataproduct/components/AddToDataProductFromQueryLink";

const useStyles = createUseStyles({
  root: {
    margin: "8px 0 0 0",
    height: `calc(100vh - 56px - 16px)`,
  },
});

export const monacoWebWorkerPath = "/ui/insights/static/monaco";
export const grammarValidationUrl = `${baseUrl}/ide/statement/grammar`;
export const languageElementsUrl = `${baseUrl}/ide/language`;
export const statementSubmissionUrl = `${baseUrl}/ide/statement`;
export const worksheetsPersistenceUrl = `${baseUrl}/worksheets`;

export const QueryEditor: React.FunctionComponent = () => {
  const classes = useStyles();
  const worksheetsPersistenceEnabled =
    useContext(ConfigContext)?.worksheetsPersistenceEnabled;
  const user = useContext(ConfigContext)?.user;
  const dataProductEnabled = useContext(ConfigContext)?.dataProductEnabled;
  const queries = uiFeatureEnabled(UiFeatures.QUERIES);
  const role = useContext(RoleContext);

  const headers = useMemo(() => {
    if (role !== "disabled") {
      return {
        role: role.roleHeader,
      };
    } else {
      return {};
    }
  }, [role]);

  const roleId = useMemo(() => {
    if (role !== "disabled") {
      if (role.currentRole === "ALL") {
        return "ALL";
      } else {
        return `${role.currentRole.id}`;
      }
    } else {
      return undefined;
    }
  }, [role]);

  const config: StarburstQueryEditorConfig = useMemo(() => {
    return {
      queryDetailsLink: queries ? QueryDetailsLink : null,
      queryAccelerationLink: queries ? QueryAccelerationLink : null,
      enableLakeAccelerationInfo: true,
      createDataProductLink: dataProductEnabled
        ? AddToDataProductFromQueryLink
        : null,
      source: {
        explorer: "insights-explorer",
        editor: "insights",
      },
      api: {
        grammarValidation: grammarValidationUrl,
        statementSubmission: {
          url: statementSubmissionUrl,
          headers,
        },
        languageElements: languageElementsUrl,
        worksheetsPersistence: worksheetsPersistenceEnabled
          ? worksheetsPersistenceUrl
          : undefined,
      },
      staticFiles: {
        monaco: monacoWebWorkerPath,
      },
      className: classes.root,
      userId: user ?? "",
      roleId,
    };
  }, [queries, headers, roleId]);

  return <StarburstQueryEditor config={config} />;
};
