/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo, useState } from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../../../themes/palette";
import { RolePrivilege } from "../../userRolePrivileges";
import { deleteGrant, EffectType } from "../../../../api/biac/biacApi";
import capitalize from "lodash/capitalize";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import { faUserShield } from "@fortawesome/pro-solid-svg-icons/faUserShield";
import clsx from "clsx";
import { Clipboard } from "../../../../components/clipboard/Clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const useStyles = createUseStyles({
  buttonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    margin: "0.5rem",
  },
  row: {
    padding: "8px 8px 8px 16px",
    fontSize: "0.875rem",
    borderTop: `1px solid ${palette.black12}`,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  cell: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
  },
  buttonIcon: {
    color: palette.error,
    width: "1rem",
  },
  truncated: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "13rem",
    marginRight: "0.5rem",
  },
  adminOptionIcon: {
    marginRight: "0.25rem",
  },
  entity: {
    fontWeight: 600,
    marginLeft: "1px",
  },
  entityValue: {
    display: "flex",
    alignItems: "center",
    maxWidth: "15.5rem",
  },
  entitySpecified: {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    marginLeft: "1px",
  },
});

export const allowEffect: ReadonlyArray<EffectType> = [
  "ALLOW",
  "ALLOW_WITH_GRANT_OPTION",
];
interface PrivilegesEntryProps extends RolePrivilege {
  currentRoleName: string;
  onDelete(): void;
  onDeleteSuccess(): void;
  onDeleteFailure(e: Error): void;
}

export const PrivilegesEntry: React.FunctionComponent<PrivilegesEntryProps> = ({
  currentRoleName,
  grantId,
  entityCategory,
  entityValue,
  entitySpecified,
  grantOption,
  action,
  canManage,
  onDelete,
  onDeleteSuccess,
  onDeleteFailure,
  effect,
}) => {
  const classes = useStyles();
  const [busy, setBusy] = useState<boolean>(false);

  const deletePrivilege = useCallback(() => {
    setBusy(true);
    onDelete();

    Promise.all([
      deleteGrant(currentRoleName, grantId),
      new Promise<void>((resolve) => setTimeout(resolve, 700)), // minimum wait time for avoiding spinner flash
    ])
      .then(() => onDeleteSuccess())
      .catch((e) => {
        onDeleteFailure(e);
        setBusy(false);
      });
  }, [grantId, onDelete, onDeleteSuccess, onDeleteFailure, currentRoleName]);

  const entity = useMemo(() => {
    return (
      <span>
        {capitalize(entityCategory.replace(/_/g, " "))}
        {grantOption && (
          <Tooltip
            title={`Allow role receiving grant to grant to others`}
            disableInteractive
          >
            <span style={{ paddingLeft: "0.5rem" }}>
              <FontAwesomeIcon
                size="sm"
                className={classes.adminOptionIcon}
                icon={faUserShield}
              />
            </span>
          </Tooltip>
        )}
        {entityValue && (
          <div className={classes.entityValue}>
            <div
              className={clsx(classes.truncated, {
                [classes.entity]: !entitySpecified,
                [classes.entitySpecified]: entitySpecified,
              })}
            >
              <Tooltip title={entityValue} disableInteractive>
                <span>{entityValue}</span>
              </Tooltip>
            </div>
            <Clipboard text={entityValue}>
              <ContentCopyIcon color="secondary" fontSize="small" />
            </Clipboard>
          </div>
        )}
      </span>
    );
  }, [entityCategory, entityCategory]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={classes.row}
    >
      <div className={classes.cell} style={{ width: "16.5rem" }}>
        {entity}
      </div>
      <div className={classes.cell} style={{ flexGrow: 1 }}>
        <div
          style={{
            fontWeight: 700,
            color: allowEffect.includes(effect)
              ? palette.success
              : palette.error,
            minWidth: "3rem",
          }}
        >
          {(allowEffect.includes(effect) ? "Allow" : capitalize(effect)) + ": "}
        </div>
        <div>{capitalize(action)}</div>
      </div>
      {canManage && (
        <>
          {busy ? (
            <CircularProgress
              color="secondary"
              thickness={4}
              size={20}
              className={classes.buttonProgress}
            />
          ) : (
            <IconButton
              size="small"
              onClick={deletePrivilege}
              style={{ padding: "6px" }}
            >
              <FontAwesomeIcon className={classes.buttonIcon} icon={faTrash} />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};
