/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useTextInputValueChange } from "../../../../utils/useTextInputValueChange";
import { addPrivilegesStyles } from "../add-privileges-styles";

type IsTableFunctionInputValue = "yes" | "no";

interface IsTableFunctionSelectorProps {
  value: boolean;
  onChange: (newValue: boolean) => void;
}

export const IsTableFunctionSelector = ({
  onChange,
  value,
}: IsTableFunctionSelectorProps) => {
  const classes = addPrivilegesStyles();
  const isTableFunctionInputValue: IsTableFunctionInputValue = value
    ? "yes"
    : "no";
  const handleIsTableFunctionInputValueChange = useTextInputValueChange(
    (inputValue: string) =>
      onChange((inputValue as IsTableFunctionInputValue) === "yes"),
    []
  );

  return (
    <Grid pl={1} maxWidth="fit-content">
      <Typography variant="h5">Define data</Typography>
      <Typography className={classes.questionInfo}>
        Is this a table function?
      </Typography>
      <Box mb={1.5}>
        <FormControl>
          <RadioGroup
            aria-labelledby="is-table-function-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={isTableFunctionInputValue}
            onChange={handleIsTableFunctionInputValueChange}
            row
          >
            <Typography fontSize="0.875rem">
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </Typography>
          </RadioGroup>
        </FormControl>
      </Box>
    </Grid>
  );
};
