/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createUseStyles } from "react-jss";
import TextField from "@mui/material/TextField";
import {
  Catalog,
  DataProductForm,
  DataProductNameValidation,
  DataProductState,
  getSupportedCatalogs$,
  TagValue,
} from "../../../api/dataProduct/dataProductApi";
import { palette } from "../../../themes/palette";
import { TextFieldWithHint } from "../components/TextFieldWithHint";
import { DataProductWizardNavigation } from "./DataProductWizardNavigation";
import { PublishFormAction } from "./publishFormReducer";
import { ErrorIndicator } from "../../../components/error/ErrorIndicator";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import { DataDomain } from "../../../api/dataProduct/dataDomain/dataDomainApi";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { MarkDownIconWithDocsLink } from "../../../components/markDown/MarkDownIconWithDocsLink";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DataProductNameInput,
  isNameProblematic,
  PENDING_SCHEMA_NAME,
} from "./DataProductNameInput";
import {
  createDataSuccess,
  DataFetchingState,
  isDataFetching,
  isDataSuccess,
} from "../domain/useFetchingState";
import {
  getCharacterCountdownText,
  isNonEmpty,
} from "../../../utils/formUtils";
import { maximumFreeTextLength, maximumSummaryLength } from "../util/formUtils";

interface DataProductBasicInfoProps {
  dataProductForm: DataProductForm;
  allDomains: DataDomain[];
  tags: TagValue[];
  error: string | undefined;
  publishFormDispatch: Dispatch<PublishFormAction>;
  setWizardStep: (newValue: number) => void;
  setError: (newValue: string | undefined) => void;
}

const useStyles = createUseStyles({
  helperText: {
    fontSize: "1rem",
    color: palette.black54,
    paddingBottom: "1.5rem",
  },
  description: {
    paddingBottom: "1.5rem",
    marginRight: "1rem",
  },
  adornment: {
    paddingRight: "0.25rem",
  },
  markDownIcon: {
    alignItems: "end",
    paddingRight: "1rem",
  },
});

export const DataProductBasicInfo: React.FunctionComponent<
  DataProductBasicInfoProps
> = ({
  dataProductForm,
  allDomains,
  tags,
  error,
  publishFormDispatch,
  setWizardStep,
  setError,
}) => {
  const classes = useStyles();
  const updateMode = dataProductForm.id !== undefined;

  const isEmptyName = useMemo(() => {
    return !isNonEmpty(dataProductForm.name);
  }, [dataProductForm.name]);

  const canChangeName =
    !updateMode || dataProductForm.status === DataProductState.DRAFT;

  const [fetchingNameValidationInfo, setFetchingNameValidationInfo] = useState<
    DataFetchingState<DataProductNameValidation | undefined>
  >(createDataSuccess(undefined));

  const onValidationChange = useCallback((newFetchingNameValidationInfo) => {
    setFetchingNameValidationInfo(newFetchingNameValidationInfo);
  }, []);

  useEffect(() => {
    publishFormDispatch({
      type: "setDataProductFormFields",
      dataProduct: {
        schemaName:
          isDataSuccess(fetchingNameValidationInfo) &&
          fetchingNameValidationInfo.data?.generatedSchemaName !== undefined
            ? fetchingNameValidationInfo.data.generatedSchemaName
            : PENDING_SCHEMA_NAME,
      },
    });
  }, [fetchingNameValidationInfo]);

  const isValidSummary = useMemo(() => {
    return isNonEmpty(dataProductForm.summary);
  }, [dataProductForm.summary]);

  const isValidCatalog = useMemo(() => {
    return isNonEmpty(dataProductForm.catalogName);
  }, [dataProductForm.catalogName]);

  const [supportedCatalogNames, setSupportedCatalogNames] = useState<string[]>(
    []
  );

  const handleCatalogsFetchSuccess = useCallback((catalogs: Catalog[]) => {
    setSupportedCatalogNames(catalogs.map(({ catalogName }) => catalogName));
  }, []);

  useEffect(() => {
    const subscription = getSupportedCatalogs$().subscribe({
      next: (data) => {
        handleCatalogsFetchSuccess(data);
      },
      error: (error) => {
        setError(error.message);
      },
    });
    return () => subscription.unsubscribe();
  }, []);

  const hasProblemsWithName = useMemo(() => {
    return isNameProblematic(
      dataProductForm.name,
      isDataSuccess(fetchingNameValidationInfo)
        ? fetchingNameValidationInfo.data
        : undefined
    );
  }, [dataProductForm.name, fetchingNameValidationInfo]);

  const isNextStepDisabled =
    isEmptyName ||
    hasProblemsWithName ||
    !isValidCatalog ||
    !isValidSummary ||
    !dataProductForm.dataDomainId;

  return (
    <div>
      <Typography variant={"h3"}>Define data product</Typography>
      <div className={classes.helperText}>
        Define your data product by adding a title and description, selecting a
        catalog, and creating a schema. Title and description is the information
        users will see as they search for data products.
      </div>
      <ErrorIndicator text={error} />

      <Typography variant={"h5"}>General information</Typography>
      <Grid container>
        <Grid item xs={12}>
          <Grid item container xs={12} md={5}>
            <DataProductNameInput
              dataProductId={dataProductForm.id}
              value={dataProductForm.name}
              onChange={(value: string) =>
                publishFormDispatch({
                  type: "setDataProductFormFields",
                  dataProduct: {
                    name: value,
                  },
                })
              }
              onValidationChange={onValidationChange}
              canBeChanged={canChangeName}
              showTooltip
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item container xs={12} md={5}>
            <TextFieldWithHint
              fullWidth={true}
              label="Catalog"
              required={true}
              disabled={updateMode}
              value={dataProductForm.catalogName}
              select={true}
              tooltipText="Only Hive is currently supported"
              onValueChange={(value) => {
                publishFormDispatch({
                  type: "setDataProductFormFields",
                  dataProduct: {
                    catalogName: value,
                  },
                });
              }}
            >
              {supportedCatalogNames.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextFieldWithHint>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item container xs={12} md={5}>
            <TextFieldWithHint
              fullWidth={true}
              value={dataProductForm.schemaName}
              label="Schema name"
              tooltipText="The schema name is generated from Data product title."
              disabled={true}
              endAdornment={
                <InputAdornment
                  position="start"
                  classes={{ positionStart: classes.adornment }}
                >
                  {isDataFetching(fetchingNameValidationInfo) ? (
                    <CircularProgress color="secondary" size={15} />
                  ) : (
                    <FontAwesomeIcon icon={faLockAlt} />
                  )}
                </InputAdornment>
              }
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item container xs={12} md={5}>
            <TextFieldWithHint
              fullWidth={true}
              label="Domain"
              required={true}
              value={dataProductForm.dataDomainId ?? ""}
              select={true}
              tooltipText="Domain assigned to Data product"
              onValueChange={(value) => {
                publishFormDispatch({
                  type: "setDataProductFormFields",
                  dataProduct: {
                    dataDomainId: value,
                  },
                });
              }}
            >
              {allDomains.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextFieldWithHint>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextFieldWithHint
            fullWidth={true}
            value={dataProductForm.summary || ""}
            label="Data product summary"
            required
            multiline
            rows={3}
            maxLength={maximumSummaryLength}
            onValueChange={(value) =>
              publishFormDispatch({
                type: "setDataProductFormFields",
                dataProduct: { summary: value },
              })
            }
            tooltipText="This summary will appear in dashboard view"
            helperText={getCharacterCountdownText(
              dataProductForm.summary,
              maximumSummaryLength
            )}
          />
        </Grid>

        <Grid item container xs={12}>
          <TextField
            fullWidth
            value={dataProductForm.description || ""}
            label="Data product description"
            onChange={(e) =>
              publishFormDispatch({
                type: "setDataProductFormFields",
                dataProduct: { description: e.target.value },
              })
            }
            className={classes.description}
            variant="outlined"
            margin="dense"
            multiline={true}
            minRows={5}
            maxRows={30}
            inputProps={{ maxLength: maximumFreeTextLength }}
            InputProps={{
              endAdornment: <MarkDownIconWithDocsLink />,
              classes: { adornedEnd: classes.markDownIcon },
            }}
          />
        </Grid>
      </Grid>
      <DataProductWizardNavigation
        dataProductForm={dataProductForm}
        tags={tags}
        wizardStep={1}
        publishFormDispatch={publishFormDispatch}
        setWizardStep={setWizardStep}
        setError={setError}
        saveDisabled={isNextStepDisabled}
      />
    </div>
  );
};
