/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { palette } from "../../../../../themes/palette";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { Tooltip } from "../../../../../components/tooltip/Tooltip";
import {
  DataProductState,
  getMaterializedViewMetadata$,
  MaterializedViewRefreshMetadata,
} from "../../../../../api/dataProduct/dataProductApi";
import { formatDatetime } from "../../../../../utils/formatDateInterval";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import { useRefreshManager } from "../../../workflow/refreshManager/useRefreshManager";
import { useDataProductPermissionContext } from "../../../permission/DataProductPermissionContext";
import { PROCESS_CANCELLED_MESSAGE } from "../../../workflow/WorkflowUtils";

interface MaterializedViewMetadataProps {
  dataProductId: string;
  viewName: string;
  viewStatus?: DataProductState;
}

const useStyles = createUseStyles({
  metadata: {
    fontSize: "0.875rem",
    color: palette.black54,
    paddingBottom: "1rem",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  rowFlex: {
    display: "flex",
    flexDirection: "row",
  },
  startMargin: {
    margin: "0 0.3rem",
  },
  refresh: {
    fontSize: "0.875rem",
    lineHeight: "1rem",
    letterSpacing: "1.25px",
    color: palette.purple,
    fontWeight: 600,
    padding: "0 0.35rem",
  },
  error: {
    maxWidth: "20rem",
    marginLeft: "0.5rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  errorText: {
    color: palette.purple,
  },
});

const stripTrailingDecimalZeroes = (value: string): string => {
  return value.slice(0, -1).replace(/\.00$/, "");
};

export const MaterializedViewMetadata: React.FunctionComponent<
  MaterializedViewMetadataProps
> = ({ dataProductId, viewName, viewStatus }) => {
  const [refreshMetadata, setRefreshMetadata] =
    useState<MaterializedViewRefreshMetadata>();

  const [refreshInProgress, setRefreshInProgress] = useState<boolean>(true);

  const refreshManager = useRefreshManager(dataProductId, viewName, [
    dataProductId,
    viewName,
  ]);

  const userPermissions = useDataProductPermissionContext();

  const onError = useCallback(
    (error: string) => {
      setRefreshInProgress(false);
      const metadataWithError = refreshMetadata
        ? { ...refreshMetadata }
        : { refreshInterval: "" };
      metadataWithError.lastImport = {
        status: "ERROR",
        finishTime: "",
        error: error,
      };

      setRefreshMetadata(metadataWithError);
    },
    [refreshMetadata]
  );

  const doRefreshMetadata = useCallback(() => {
    setRefreshInProgress(true);
    return getMaterializedViewMetadata$(dataProductId, viewName).subscribe(
      (data) => {
        setRefreshMetadata(data);
        setRefreshInProgress(false);
      },
      (error) => {
        onError(error.message);
      }
    );
  }, [dataProductId, viewName]);

  useEffect(() => {
    const subscription = doRefreshMetadata();
    return () => subscription.unsubscribe();
  }, [dataProductId, viewName]);

  const classes = useStyles();

  const onRefreshClicked = async () => {
    if (refreshMetadata?.lastImport) {
      setRefreshMetadata({
        ...refreshMetadata,
        lastImport: {
          ...refreshMetadata.lastImport,
          status: "RUNNING",
        },
      });
    }
    setRefreshInProgress(true);

    return refreshManager.current
      ? refreshManager.current
          .start()
          .then(() => {
            doRefreshMetadata();
          })
          .catch((e) => {
            const errorObj = e.message;
            if (errorObj !== PROCESS_CANCELLED_MESSAGE) {
              onError(errorObj);
            }
          })
      : Promise.reject({
          message: "Delete manager do not exist",
        });
  };

  return (
    <>
      {refreshMetadata && (
        <div className={classes.metadata}>
          <div className={classes.rowFlex}>
            Last refresh:{" "}
            {refreshMetadata.lastImport?.status === "RUNNING" ? (
              <>
                <div>
                  <IconButton
                    className={classes.refresh}
                    onClick={doRefreshMetadata}
                    disabled={refreshInProgress}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faSync} spin={refreshInProgress} />
                  </IconButton>
                  Updating
                </div>
              </>
            ) : (
              <>
                <div className={clsx(classes.rowFlex, classes.startMargin)}>
                  <div>
                    {formatDatetime(refreshMetadata.lastImport?.finishTime)}
                  </div>

                  {userPermissions.canPublish &&
                    viewStatus === DataProductState.PUBLISHED &&
                    refreshMetadata.lastImport && (
                      <div
                        className={clsx(classes.rowFlex, classes.startMargin)}
                      >
                        <Button
                          className={classes.refresh}
                          onClick={onRefreshClicked}
                        >
                          Refresh now
                        </Button>
                      </div>
                    )}
                  {refreshMetadata.lastImport?.error && (
                    <div className={classes.error}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        color={palette.error}
                        className={classes.startMargin}
                      />
                      Failed:
                      <Tooltip
                        placement="top"
                        title={refreshMetadata.lastImport?.error}
                      >
                        <span className={classes.errorText}> view error</span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className={classes.rowFlex}>
            Next refresh:{" "}
            {formatDatetime(refreshMetadata.estimatedNextRefreshTime)}
          </div>
          <div className={classes.rowFlex}>
            Refresh interval:{" "}
            {refreshMetadata?.refreshInterval && (
              <>
                {stripTrailingDecimalZeroes(refreshMetadata.refreshInterval)}{" "}
                minutes
              </>
            )}
          </div>
          {refreshMetadata?.incrementalColumn && (
            <div className={classes.rowFlex}>
              Incremental column:{` ${refreshMetadata.incrementalColumn}`}
            </div>
          )}
        </div>
      )}
    </>
  );
};
