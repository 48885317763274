/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import {
  DataProductState,
  DatasetType,
  View,
} from "../../../../../api/dataProduct/dataProductApi";
import { palette } from "../../../../../themes/palette";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import Collapse from "@mui/material/Collapse";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { faClone } from "@fortawesome/pro-regular-svg-icons";
import { Clipboard } from "../../../../../components/clipboard/Clipboard";
import { DatasetStatus } from "../../DatasetStatus";
import {
  PreviewDataButtonType,
  PreviewDataset,
} from "../../../components/PreviewDataset";
import { createPreviewQuery } from "../../../trinoClient/useDataProductTrinoClient";
import { CloneDatasetModal } from "../../../components/CloneDatasetModal";
import { useDataProductPermissionContext } from "../../../permission/DataProductPermissionContext";

interface DatasetDetailsEntryProps {
  dataProductId: string;
  catalogName: string;
  schemaName: string;
  dataset: View;
  metadata: React.ReactNode;
  alreadyUsedViewNames: string[];
  type: DatasetType;
}

const useStyles = createUseStyles((theme: Theme) => ({
  header: {
    marginBottom: "3px",
    padding: "10px",
  },
  headerSection: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  datasetIcon: {
    fontSize: "0.6rem",
    borderRadius: "50%",
    marginRight: "0.75rem",
  },
  viewIconColor: {
    color: palette.purple,
  },
  materializedViewIconColor: {
    color: palette.teal,
  },
  body: {
    box: "borderLeft",
    padding: "0px 20px 20px",
    backgroundColor: palette.black3,
    marginBottom: "1rem",
  },
  datasetName: {
    marginRight: "2rem",
    minWidth: "20rem",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: "0.125rem",
  },
  description: {
    fontSize: "0.875rem",
    color: palette.black,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginRight: "1.5rem",
  },
  datasetFullNameContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: palette.white,
    border: `1px solid ${palette.nebulaNavy100}`,
    borderRadius: "0.25rem",
    padding: "0.4375rem 0.75rem 0.5625rem 0.75rem",
    marginRight: "2rem",
    width: "17rem",
    marginLeft: "20px",
  },
  datasetFullName: {
    color: palette.nebulaNavy300,
    fontSize: "0.75rem",
    fontFamily: "Monaco, monospace",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  collapseHeader: {
    borderBottom: `1px solid ${palette.black12}`,
  },
  expandHeader: {
    backgroundColor: palette.black3,
  },
  expandIcon: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
    width: "2px",
    height: "2px",
  },
  expandOpenIcon: {
    transform: "rotate(180deg)",
  },
  preview: {
    textAlign: "right",
  },
  previewButton: {
    margin: "0px",
  },
  tableContainer: {
    border: `1px solid ${palette.black12}`,
  },
  tableTitle: {
    fontWeight: 600,
    fontSize: "0.875rem",
  },
  tableRow: {
    backgroundColor: "white",
    "& td,th": {
      padding: "12px",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
    "& td:first-child": {
      paddingLeft: "16px",
    },
  },
  noRowBorder: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  evenRow: {
    backgroundColor: palette.black3,
  },
}));

export const DatasetDetailsEntry: React.FunctionComponent<
  DatasetDetailsEntryProps
> = ({
  dataProductId,
  catalogName,
  schemaName,
  dataset,
  metadata,
  type,
  alreadyUsedViewNames,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(false);
  const userPermissions = useDataProductPermissionContext();

  const handleExpandClick = (): void => {
    setExpanded((prevState) => !prevState);
  };

  return (
    <div>
      <div
        className={clsx(classes.header, {
          [classes.collapseHeader]: !expanded,
          [classes.expandHeader]: expanded,
        })}
      >
        <div className={classes.headerSection}>
          <FontAwesomeIcon
            className={clsx(classes.datasetIcon, {
              [classes.viewIconColor]: type === DatasetType.VIEW,
              [classes.materializedViewIconColor]:
                type === DatasetType.MATERIALIZED_VIEW,
            })}
            icon={faCircle}
          />
          <Typography variant="subtitle2" className={classes.datasetName}>
            {dataset.name}
          </Typography>
          <Tooltip title={dataset.description || false}>
            <div className={classes.description}>{dataset.description}</div>
          </Tooltip>
          <div className={classes.headerSection} style={{ marginLeft: "auto" }}>
            <DatasetStatus
              status={dataset.status}
              markedForDeletion={dataset.markedForDeletion}
            />
            <CloneDatasetModal
              view={dataset}
              dataProductId={dataProductId}
              schemaName={schemaName}
              catalogName={catalogName}
              type={type}
              namesAlreadyUsed={alreadyUsedViewNames}
              disabled={!userPermissions.canUpdate}
              tooltipTitle={
                userPermissions.canUpdate
                  ? ""
                  : "You do not have permission to edit this data product"
              }
            />
            <div className={classes.preview}>
              <PreviewDataset
                sqlQuery={createPreviewQuery(
                  catalogName,
                  schemaName,
                  dataset.name
                )}
                disabled={dataset.status !== DataProductState.PUBLISHED}
                buttonType={PreviewDataButtonType.ICON}
              />
            </div>
            <IconButton
              className={clsx(classes.expandIcon, {
                [classes.expandOpenIcon]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show more"
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          </div>
        </div>
        {expanded && (
          <div className={classes.headerSection} style={{ paddingTop: "1rem" }}>
            <div className={classes.datasetFullNameContainer}>
              <span className={classes.datasetFullName}>
                {catalogName}.{schemaName}.{dataset.name}
              </span>
              <Clipboard text={`${catalogName}.${schemaName}.${dataset.name}`}>
                <IconButton
                  id="copy-to-clipboard-btn"
                  disableRipple={true}
                  color="secondary"
                  size="small"
                >
                  <FontAwesomeIcon size="xs" icon={faClone} />
                </IconButton>
              </Clipboard>
            </div>
            {metadata}
          </div>
        )}
      </div>
      <Collapse
        className={classes.body}
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={classes.tableTitle}>
                  Column name
                </TableCell>
                <TableCell className={classes.tableTitle}>Data type</TableCell>
                <TableCell className={classes.tableTitle}>
                  Column description
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataset.columns.map((column, index) => (
                <TableRow
                  key={index}
                  className={clsx(classes.noRowBorder, classes.tableRow, {
                    [classes.evenRow]: index % 2 === 0,
                  })}
                >
                  <TableCell>{column.name}</TableCell>
                  <TableCell>{column.type}</TableCell>
                  <TableCell>{column.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </div>
  );
};
