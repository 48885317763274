/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { ChooserErrorIcon } from "../../grants/ChooserErrorIcon";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import { EmptyOrValue } from "../../../../utils/value";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { addPrivilegesStyles } from "../add-privileges-styles";
import { getDomains$ } from "../../../../api/dataProduct/dataDomain/dataDomainApi";
import FormControlLabel from "@mui/material/FormControlLabel";
import { palette } from "../../../../themes/palette";

export interface DataProductDomain {
  id: string;
  name: string;
}

interface DomainSelectorInfo {
  label: string;
  value: DataProductDomain | null;
  description?: string | null;
}

interface DomainSelectorProps {
  value: EmptyOrValue<DataProductDomain | null>;
  handleChange: (value: EmptyOrValue<DataProductDomain | null>) => void;
}

export const DomainSelector: React.FunctionComponent<DomainSelectorProps> = ({
  value,
  handleChange,
}) => {
  const classes = addPrivilegesStyles();
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [domains, setDomains] = useState<DomainSelectorInfo[]>([]);
  const [selectedDomain, setSelectedDomain] =
    useState<DomainSelectorInfo | null>(null);

  const allDomainsChecked = !value.empty && value.value === null;

  useEffect(() => {
    setBusy(true);
    setError(null);

    const subscription = getDomains$().subscribe({
      next: (domains) => {
        setDomains([
          ...domains.map((it) => ({
            label: it.name,
            value: { id: it.id, name: it.name },
            description: it.description,
          })),
        ]);
        setBusy(false);
      },
      error: (error) => {
        setBusy(false);
        setError(error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const domainFromProps = value.value;
    if (value.empty) {
      setSelectedDomain(null);
    } else if (domainFromProps) {
      if (selectedDomain?.value?.id !== domainFromProps.id) {
        setSelectedDomain({
          label: domainFromProps.name,
          value: { id: domainFromProps.id, name: domainFromProps.name },
        });
      }
    }
  }, [value]);

  useEffect(() => {
    return () => handleChange({ empty: true, value: null });
  }, []);

  const handleSelectAll = useCallback((event) => {
    setSelectedDomain(null);
    handleChange({
      empty: !event.target.checked,
      value: null,
    });
  }, []);

  const onChange = useCallback(
    (event: React.SyntheticEvent, value: DomainSelectorInfo | null) => {
      setSelectedDomain(value);
      setValues(value);
    },
    []
  );

  const setValues = useCallback((value: DomainSelectorInfo | null) => {
    if (!value) {
      handleChange({
        empty: true,
        value: null,
      });
    } else {
      handleChange({
        empty: !value,
        value: value.value,
      });
    }
  }, []);

  const renderOption = useCallback(
    (
      props: React.HTMLAttributes<HTMLLIElement>,
      option: DomainSelectorInfo
    ) => {
      return (
        <li {...props}>
          <div>
            <div className={classes.optionLabel}>{option.label}</div>
            {option.description && (
              <div className={classes.optionDescription}>
                {option.description}
              </div>
            )}
          </div>
        </li>
      );
    },
    []
  );

  const renderPopupIcon = useCallback(() => {
    if (busy) {
      return <CircularProgress size={20} />;
    } else if (error) {
      return (
        <div>
          <ChooserErrorIcon title="Loading domains failed" />
        </div>
      );
    } else {
      return <ArrowDropDownOutlinedIcon />;
    }
  }, [busy, error]);

  return (
    <Grid pl={1} maxWidth="fit-content">
      <Typography variant="h5">
        Which domain would you like to select?
      </Typography>
      <Typography className={classes.questionInfo}>
        Use the dropdown to select a specific domain. To select all currently
        defined domains, as well as domains defined in the future, check the{" "}
        <b>All domains</b> box instead.
      </Typography>
      <Box mb={1.5}>
        <FormControlLabel
          control={
            <Checkbox
              checked={allDomainsChecked}
              onChange={handleSelectAll}
              id="select-star"
            />
          }
          label={
            <span>
              All domains{" "}
              <span style={{ color: palette.black54 }}>(use with caution)</span>
            </span>
          }
        />
      </Box>
      <Box mb={3}>
        <Autocomplete
          value={selectedDomain ?? null}
          limitTags={2}
          clearText="Clear domain"
          options={domains}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) =>
            option.value?.id === value.value?.id
          }
          disabled={allDomainsChecked || !domains.length}
          onChange={onChange}
          renderOption={renderOption}
          popupIcon={renderPopupIcon()}
          ChipProps={{
            className: classes.chip,
            deleteIcon: <CloseIcon className={classes.chipCloseIcon} />,
          }}
          renderInput={(params) => <TextField {...params} label="Domains" />}
        />
      </Box>
    </Grid>
  );
};
