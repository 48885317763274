/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClusterHistoryCriteria } from "./ClusterHistoryFilters";
import { startOfDay } from "date-fns";

interface ClusterHistorySlice {
  filters: ClusterHistoryCriteria;
}

const initialState: ClusterHistorySlice = {
  filters: {
    startDate: startOfDay(new Date()),
    endDate: null,
  },
};

const clusterHistorySlice = createSlice({
  name: "cluster-history",
  initialState,
  reducers: {
    setFilters: (
      state,
      action: PayloadAction<ClusterHistoryCriteria>
    ): void => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = clusterHistorySlice.actions;
export default clusterHistorySlice.reducer;
