/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useContext, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import { Link, useHistory } from "react-router-dom";

import { ConfigContext, uiFeatureEnabled } from "../app/ConfigContextProvider";
import { RoleContext } from "../features/biac/CurrentRoleContext";
import { DefaultRoleNotification } from "../components/menu/DefaultRoleNotification";
import { faPeopleArrows } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logoutRoute } from "./login/routing/LoginRouting";
import { logout } from "../api/loginApi";
import { UiFeatures } from "../api/configApi";

const useStyles = createUseStyles((theme: Theme) => ({
  rootContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: theme.typography.fontFamily,
    minWidth: "200px",
    cursor: "pointer",
    transition: "opacity 0.3s",
    "&:hover": {
      opacity: 0.8,
    },
  },
  dataContainer: {
    flexDirection: "column",
    marginLeft: "8px",
  },
  email: {
    fontSize: "14px",
    letterSpacing: "0.15px",
  },
  roleName: {
    fontSize: "10px",
    fontWeight: 700,
    letterSpacing: "1.5px",
  },
  menuIcon: {
    width: "28px",
    height: "28px",
  },
  spacer: {
    flex: 1,
  },
  expandIcon: {
    marginLeft: "24px",
  },
  paper: {
    minWidth: "160px",
  },
  menuItemIcon: {
    fontSize: "20px",
    marginRight: "16px",
  },
  switchRoleMenuItemIcon: {
    fontSize: "15px",
    marginLeft: "2px",
    marginRight: "16px",
  },
}));

export const ProfileMenu: React.FunctionComponent = () => {
  const classes = useStyles();

  const userDetails = useContext(ConfigContext);
  const roleContext = useContext(RoleContext);

  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleExpand = (ev: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  if (!userDetails) {
    // in practice, we won't render until we get the user and the current role
    return <></>;
  }

  return (
    <>
      {roleContext === "disabled" ? (
        <div
          id="profile-menu"
          className={classes.rootContainer}
          onClick={handleExpand}
        >
          <AccountCircleIcon className={classes.menuIcon} />
          <div className={classes.dataContainer}>
            <div className={classes.email}>{userDetails.user}</div>
          </div>
          <div className={classes.spacer} />
          <ExpandMoreIcon className={classes.expandIcon} />
        </div>
      ) : (
        <DefaultRoleNotification
          hasDefaultRole={
            roleContext.currentRole !== "ALL" &&
            roleContext.currentRole.defaultRole
          }
          hide={Boolean(anchorEl)}
          currentRoleName="public"
        >
          <div
            id="profile-menu"
            className={classes.rootContainer}
            onClick={handleExpand}
          >
            <AccountCircleIcon className={classes.menuIcon} />
            <div className={classes.dataContainer}>
              <div className={classes.email}>{userDetails.user}</div>
              <div className={classes.roleName}>
                {roleContext.currentRole === "ALL"
                  ? roleContext.currentRole
                  : roleContext.currentRole.name}
              </div>
            </div>
            <div className={classes.spacer} />
            <ExpandMoreIcon className={classes.expandIcon} />
          </div>
        </DefaultRoleNotification>
      )}
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        disablePortal
      >
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              {roleContext !== "disabled" && (
                <MenuItem
                  onClick={() => {
                    roleContext.showDialog();
                    handleClose();
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPeopleArrows}
                    className={classes.switchRoleMenuItemIcon}
                  />
                  Switch role
                </MenuItem>
              )}
              {uiFeatureEnabled(UiFeatures.SETTINGS) && (
                <MenuItem component={Link} to="/settings">
                  <SettingsIcon className={classes.menuItemIcon} />
                  Settings
                </MenuItem>
              )}
              <LogOutMenuItem />
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

const LogOutMenuItem: React.FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();

  const logoutAndRedirect = () => {
    logout().finally(() => {
      history.push(logoutRoute);
    });
  };

  return (
    <MenuItem id="logout-menu-item" onClick={logoutAndRedirect}>
      <ExitToAppIcon className={classes.menuItemIcon} />
      Log out
    </MenuItem>
  );
};
