/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { PersistenceEnabledBiac } from "../../components/biac/PersistenceEnabledBiac";
import {
  AggregateUsageMetrics,
  CumulativeCpuUsageResponse,
  filtersToSearchCriteria,
  getAggregateUsageMetrics,
  getUsageMetricsTimeline,
  UsageMetricsSearchCriteria,
} from "../../api/usageApi";
import { UsageMetricsOverview } from "./UsageMetricsOverview";
import { UsageMetricsFilters } from "./UsageMetricsFilters";
import { UsageMetricsCriteria } from "./usageMetricsSlice";
import { AuthorizedUserBiac } from "../../components/biac/AuthorizedUserBiac";
import { PageContent } from "../../layout/PageContent";
import { UiFeatures } from "../../api/configApi";

const useStyles = createUseStyles({
  root: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    minHeight: "calc(100% - 16px)",
  },
});

export const UsageMetrics: React.FunctionComponent = () => {
  const classes = useStyles();
  const filters = useSelector(
    ({ usageMetrics }: RootState) => usageMetrics.filters
  );

  const [usageMetricsSummary, setUsageMetricsSummary] = useState<
    AggregateUsageMetrics | "loading" | "error"
  >("loading");

  const [usageMetricsTimeline, setUsageMetricsTimeline] = useState<
    CumulativeCpuUsageResponse | "loading" | "error"
  >("loading");

  const fetchUsageMetricsSummary = (criteria: UsageMetricsSearchCriteria) => {
    setUsageMetricsSummary("loading");
    getAggregateUsageMetrics(criteria)
      .then((metrics) => {
        setUsageMetricsSummary(metrics);
      })
      .catch(() => setUsageMetricsSummary("error"));
  };

  const fetchUsageMetricsTimeline = (criteria: UsageMetricsSearchCriteria) => {
    setUsageMetricsTimeline("loading");
    getUsageMetricsTimeline(criteria)
      .then((metrics) => {
        setUsageMetricsTimeline(metrics);
      })
      .catch(() => setUsageMetricsTimeline("error"));
  };

  const applyFilters = (filters: UsageMetricsCriteria): void => {
    const searchCriteria = filtersToSearchCriteria(filters);

    if (searchCriteria) {
      fetchUsageMetricsSummary(searchCriteria);
      fetchUsageMetricsTimeline(searchCriteria);
    }
  };

  useEffect(() => {
    applyFilters(filters);
  }, [filters]);

  return (
    <PersistenceEnabledBiac>
      <AuthorizedUserBiac uiFeature={UiFeatures.USAGE_METRICS}>
        <PageContent title="Usage metrics">
          <Box className={classes.root}>
            <UsageMetricsFilters />
            <UsageMetricsOverview
              cpuUsage={usageMetricsTimeline}
              summary={usageMetricsSummary}
            />
          </Box>
        </PageContent>
      </AuthorizedUserBiac>
    </PersistenceEnabledBiac>
  );
};
