/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Box from "@mui/material/Box";
import React from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { palette } from "../../../themes/palette";

export interface ChartLegendItemProps {
  color: string;
  label: string;
  value: string | number | null;
  className?: string;
  enabled: {
    value: boolean;
    setValue: (newValue: boolean) => void;
  };
  bulletSize?: string;
}

const useStyles = createUseStyles({
  item: {
    fontSize: "0.875rem",
    cursor: "pointer",
    userSelect: "none",
    "&:hover": {
      color: palette.black54,
      transition: "color 0.1s linear",
    },
  },
  disabled: {
    opacity: 0.4,
  },
  icon: {
    marginRight: "0.5rem",
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline",
  },
});

export const ChartLegendItem: React.FunctionComponent<ChartLegendItemProps> = ({
  color,
  label,
  value,
  className = "",
  enabled: { setValue: setEnabled, value: enabled },
  bulletSize = "0.75rem",
}) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      alignItems="center"
      className={clsx(classes.item, className, {
        [classes.disabled]: !enabled,
      })}
      onClick={() => setEnabled(!enabled)}
    >
      <FontAwesomeIcon
        icon={faCircle}
        className={classes.icon}
        style={{
          color: color,
          fontSize: bulletSize,
        }}
      />
      <div className={classes.text}>
        <span>
          {label}
          {value !== null && (
            <span>
              {": "}
              {value}
            </span>
          )}
        </span>
      </div>
    </Box>
  );
};
