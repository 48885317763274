/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { FontLoader } from "./FontLoader";
import { materialUiTheme } from "../themes/materialUiTheme";
import { JssProvider, ThemeProvider as JssThemeProvider } from "react-jss";

interface ThemingProps {
  children: React.ReactElement;
}

export const Theming: React.FunctionComponent<ThemingProps> = ({
  children,
}) => {
  return (
    <JssProvider>
      <StyledEngineProvider injectFirst>
        <MuiThemeProvider theme={materialUiTheme}>
          <JssThemeProvider theme={materialUiTheme}>
            <CssBaseline />
            <FontLoader>{children}</FontLoader>
          </JssThemeProvider>
        </MuiThemeProvider>
      </StyledEngineProvider>
    </JssProvider>
  );
};
