/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { Dispatch, Reducer, useReducer } from "react";
import { DataDomainFormData } from "./domainFormService";

export const useDomainFormReducer = (
  initialState: DataDomainFormData
): [DataDomainFormData, Dispatch<DomainFormAction>] =>
  useReducer<Reducer<DataDomainFormData, DomainFormAction>>(
    domainFormReducer,
    initialState
  );

function domainFormReducer(
  state: DataDomainFormData,
  action: DomainFormAction
) {
  switch (action.type) {
    case "setName":
      return { ...state, name: action.payload };
    case "setDescription":
      return { ...state, description: action.payload };
    case "setSchemaLocation":
      return { ...state, schemaLocation: action.payload };
    case "setState":
      return { ...action.payload };
    default:
      return state;
  }
}

export type DomainFormAction =
  | {
      type: "setName" | "setSchemaLocation" | "setDescription";
      payload: string;
    }
  | {
      type: "setState";
      payload: DataDomainFormData;
    };

export const createSetNameAction = (payload: string): DomainFormAction => ({
  type: "setName",
  payload,
});
export const createSetSchemaLocationAction = (
  payload: string
): DomainFormAction => ({ type: "setSchemaLocation", payload });
export const createSetDescriptionAction = (
  payload: string
): DomainFormAction => ({ type: "setDescription", payload });
export const createSetStateAction = (
  payload: DataDomainFormData
): DomainFormAction => ({ type: "setState", payload });
