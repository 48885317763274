/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from "react";
import { TopEntitiesChart } from "../../../../components/chart/TopEntitiesChart";
import { ChartWrapper } from "../../../../components/chart/ChartWrapper";
import { HorizontalBarChart } from "../../../../components/chart/HorizontalBarChart";
import { formatDuration } from "../../../../utils/formatDuration";

type Base = { cpuTime: number };

interface CpuTimeBaseChartProps<T extends Base> {
  cpuTime: T[] | "loading" | "error" | undefined;
  labelKey: keyof T;
}

export const CpuTimeBaseChart = <T extends Base>({
  cpuTime,
  labelKey,
}: CpuTimeBaseChartProps<T>): JSX.Element => {
  const slicesLimit = 5;
  const height = 200;

  return (
    <TopEntitiesChart<T>
      data={cpuTime}
      labelKey={labelKey}
      valueKey="cpuTime"
      limit={slicesLimit}
    >
      {(topUsersData): ReactElement => (
        <ChartWrapper data={topUsersData} height={height} containerless={true}>
          {(chartData) => (
            <HorizontalBarChart
              data={chartData}
              tooltipText="CPU time"
              height={height}
              tooltipValueFormatter={formatDuration}
              xLabelFormatter="duration"
            />
          )}
        </ChartWrapper>
      )}
    </TopEntitiesChart>
  );
};
