/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import SvgIconComponent from "@mui/material/SvgIcon";
import { createUseStyles } from "react-jss";
import { UiFeatures } from "../../api/configApi";
import { uiFeatureEnabled } from "../../app/ConfigContextProvider";
import { SidePanelItemRaw } from "./SidePanelItemRaw";

const useStyles = createUseStyles({
  sidePanelIcon: {
    minWidth: "40px",
    marginLeft: "8px",
    "& .MuiSvgIcon-root": {
      fill: "#000000BC",
    },
  },
  sidePanelLink: {
    textDecoration: "none!important",
  },
  sidePanelBadge: {
    pointerEvents: "none",
    minWidth: "20px",
  },
  showText: {
    visibility: "visible",
    transition: "0.1s cubic-bezier(0.4, 0, 1,1)",
  },
  compactNavIcon: {
    padding: "4px 0",
  },
  hideTextForCompactNav: {
    opacity: 0,
    transition: "opacity 0.1s cubic-bezier(0.4, 0, 1,1)",
  },
});

interface SidePanelItemProps {
  id?: string;
  icon?: typeof SvgIconComponent;
  text: string;
  to: string | string[];
  badge?: typeof SvgIconComponent;
  badgeProps?: {
    classes?: {
      root?: string;
    };
  };
  openNav: boolean;
  uiFeature?: UiFeatures;
}

const isSelected = (pathname: string, to: string): boolean => {
  // a special case for the root page
  if (to === "/" && pathname !== "/") {
    return false;
  }
  return pathname.startsWith(to);
};

export const SidePanelItem: React.FunctionComponent<SidePanelItemProps> = ({
  id,
  icon,
  text,
  to,
  badge,
  badgeProps,
  openNav,
  uiFeature,
}: SidePanelItemProps) => {
  const classes = useStyles();
  const location = useLocation();
  const path = typeof to === "string" ? to : to[0];
  if (uiFeature) {
    if (!uiFeatureEnabled(uiFeature)) {
      return <></>;
    }
  }

  return (
    <Link
      id={id}
      component={RouterLink}
      to={path}
      color="inherit"
      className={classes.sidePanelLink}
    >
      <SidePanelItemRaw
        icon={icon}
        text={text}
        openNav={openNav}
        badge={badge}
        badgeProps={badgeProps}
        isSelected={isSelected(location.pathname, path)}
      />
    </Link>
  );
};
