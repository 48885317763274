/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageContent } from "../../../layout/PageContent";
import { DefaultTab } from "../../../components/tab/DefaultTab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import { RowExpressionsOfType } from "./Expressions/RowExpressionsOfType";
import { useTabStyles } from "../../../themes/useTabStyles";
import { createUseStyles } from "react-jss";
import { getExpressionsOfType } from "./Expressions/rowExpressionApi";
import { Persisted } from "../../../api/biac/common";
import { Expression } from "../../../api/biac/biacApi";
import clsx from "clsx";
import { RoleContext } from "../CurrentRoleContext";
import { ErrorScreen } from "../../../components/error/ErrorScreen";
import { TabBadge } from "../../../components/tab/TabBadge";
import { ErrorBox } from "../../../components/error/ErrorBox";

enum TabsEnum {
  Masks = "Mask",
  Filters = "Filters",
}

export enum RowExpressionType {
  Mask,
  Filter,
}

const useStyles = createUseStyles({
  mainWithSidePanelOpen: {
    marginRight: "512px",
  },
});

export const getEntityDisplayName = (type: RowExpressionType) => {
  switch (type) {
    case RowExpressionType.Mask:
      return "Mask";
    case RowExpressionType.Filter:
      return "Filter";
  }
};

export const MasksAndFilters: React.FunctionComponent = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.Masks);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const tabClasses = useTabStyles();
  const [masks, setMasks] = useState<Persisted<Expression>[]>([]);
  const [filters, setFilters] = useState<Persisted<Expression>[]>([]);

  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoadingMasks, setIsLoadingMasks] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);

  const roleContext = useContext(RoleContext);
  if (roleContext === "disabled") {
    return (
      <ErrorScreen
        errorCode={404}
        errorMsg="Page not found"
        errorDescription="The page you're looking for doesn't exist."
      />
    );
  }

  const switchTab = useCallback((tab: TabsEnum) => {
    setSidePanelOpen(false);
    setTab(tab);
  }, []);

  const loadMasks = useCallback(() => {
    setIsLoadingMasks(true);
    return getExpressionsOfType(RowExpressionType.Mask)
      .then((res) => {
        setIsLoadingMasks(false);
        setMasks(res);
      })
      .catch((e) => setErrorMessage(e.message));
  }, []);

  const loadFilters = useCallback(() => {
    setIsLoadingFilters(true);
    return getExpressionsOfType(RowExpressionType.Filter)
      .then((res) => {
        setIsLoadingFilters(false);
        setFilters(res);
      })
      .catch((e) => setErrorMessage(e.message));
  }, []);

  useEffect(() => {
    loadMasks();
    loadFilters();
  }, []);

  if (errorMessage) {
    return <ErrorBox text={errorMessage} />;
  }
  return (
    <div className={sidePanelOpen ? classes.mainWithSidePanelOpen : undefined}>
      <PageContent title="Masks and filters">
        <Tabs value={tab} className={tabClasses.tabs}>
          <DefaultTab
            label={
              <div className={clsx(tabClasses.label)}>
                Masks
                {!isLoadingMasks && <TabBadge value={masks.length} />}
              </div>
            }
            onClick={() => switchTab(TabsEnum.Masks)}
            value={TabsEnum.Masks}
          />
          <DefaultTab
            label={
              <span className={clsx(tabClasses.label)}>
                Row filters
                {!isLoadingFilters && <TabBadge value={filters.length} />}
              </span>
            }
            onClick={() => switchTab(TabsEnum.Filters)}
            disableRipple
            value={TabsEnum.Filters}
          />
        </Tabs>

        <Box mt={4}>
          {tab === TabsEnum.Masks && (
            <RowExpressionsOfType
              rowExpressionType={RowExpressionType.Mask}
              setSidePanelOpen={setSidePanelOpen}
              expressions={masks}
              reload={loadMasks}
              isLoading={isLoadingMasks}
            />
          )}
          {tab === TabsEnum.Filters && (
            <RowExpressionsOfType
              rowExpressionType={RowExpressionType.Filter}
              setSidePanelOpen={setSidePanelOpen}
              expressions={filters}
              reload={loadFilters}
              isLoading={isLoadingFilters}
            />
          )}
        </Box>
      </PageContent>
    </div>
  );
};
