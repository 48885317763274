/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FormEvent, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { DateTimePicker } from "../../components/date/DateTimePicker";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import { createUseStyles } from "react-jss";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { v4 as uuidv4 } from "uuid";
import { startOfDay } from "date-fns";

const useStyles = createUseStyles({
  label: {
    fontSize: "0.8125rem",
    marginLeft: "8px",
    marginBottom: "4px",
  },
});

export interface ClusterHistoryCriteria {
  startDate: Date | null;
  endDate: Date | null;
}

interface ClusterHistoryFiltersProps {
  filters: ClusterHistoryCriteria;
  setFilters: (filters: ClusterHistoryCriteria) => void;
}

export const ClusterHistoryFilters: React.FunctionComponent<
  ClusterHistoryFiltersProps
> = ({ filters, setFilters }) => {
  const classes = useStyles();
  const theme = useTheme();
  const twoLines = useMediaQuery(theme.breakpoints.down("md"));
  const size: { date: { xs: 7; lg: 7 }; time: { xs: 5; lg: 5 } } = {
    date: {
      xs: 7,
      lg: 7,
    },
    time: {
      xs: 5,
      lg: 5,
    },
  };
  const [localFilters, setLocalFilters] = useState(filters);
  const [startDateKey, setStartDateKey] = useState(uuidv4());
  const [endDateKey, setEndDateKey] = useState(uuidv4());
  const [valid, setValid] = useState({
    startDate: true,
    endDate: true,
  });

  const resetFilters = () => {
    // key update forces datepicker components to rerender
    setStartDateKey(uuidv4());
    setEndDateKey(uuidv4());

    const defaultFilters = {
      startDate: startOfDay(new Date()),
      endDate: null,
    };

    setLocalFilters(defaultFilters);
    setFilters(defaultFilters);
  };

  const submitForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFilters(localFilters);
  };

  return (
    <Card>
      <form onSubmit={submitForm}>
        <Box m={2}>
          <Grid container spacing={twoLines ? 0 : 3}>
            <Grid item lg={6} md={10} sm={12}>
              <div className={classes.label}>Start date *</div>
              <Grid container spacing={1}>
                <DateTimePicker
                  key={startDateKey}
                  required={true}
                  placeholder="Choose or type date"
                  value={localFilters.startDate}
                  setValue={(startDate) =>
                    setLocalFilters((prevState) => ({
                      ...prevState,
                      startDate,
                    }))
                  }
                  defaultTime="day-start"
                  size={size}
                  canDisableTime={true}
                  onValidityChange={(valid) =>
                    setValid((prevState) => ({
                      ...prevState,
                      startDate: valid,
                    }))
                  }
                />
              </Grid>
            </Grid>
            <Grid item lg={6} md={10} sm={12}>
              <div className={classes.label}>End date</div>
              <Grid container spacing={1}>
                <DateTimePicker
                  key={endDateKey}
                  placeholder="Choose or type date"
                  value={localFilters.endDate}
                  setValue={(endDate) =>
                    setLocalFilters((prevState) => ({ ...prevState, endDate }))
                  }
                  defaultTime="day-end"
                  size={size}
                  canDisableTime={true}
                  onValidityChange={(valid) =>
                    setValid((prevState) => ({
                      ...prevState,
                      endDate: valid,
                    }))
                  }
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent={"center"}
                mb={1}
                mt={twoLines ? 3 : 0}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!valid.endDate || !valid.startDate}
                >
                  Apply filters
                </Button>

                <Button
                  style={{ marginLeft: "0.5rem" }}
                  variant="outlined"
                  color="primary"
                  onClick={resetFilters}
                >
                  Reset filters
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Card>
  );
};
