/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import {
  DataProductState,
  SchemaDataProduct,
} from "../../api/dataProduct/dataProductApi";
import { palette } from "../../themes/palette";
import Container from "@mui/material/Container";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {
  SortableColumn,
  SortableColumnProps,
} from "../../components/table/SortableColumn";
import TableBody from "@mui/material/TableBody";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { BookmarkDataProduct, BookmarkIcon } from "./components/Bookmark";
import { PreventDefaultClick } from "../../components/prevent-default/PreventDefaultClick";
import { formatDate } from "../../utils/formatDateInterval";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { SortDescriptor } from "./dataProductSortingService";
import { InteractiveRating } from "./rating/InteractiveRating";
import { createDataProductDetailsPath } from "./routing/dataProductRoutingUtils";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Spinner } from "../../components/spinner/Spinner";
import { LoadMoreIntersectionButton } from "./pagination/LoadMoreIntersectionButton";

const useStyles = createUseStyles((theme: Theme) => ({
  root: {
    padding: "0",
  },
  dataContainer: {
    display: "flex",
    flex: 1,
    borderTop: `1px solid ${palette.nebulaNavy50}`,
    borderRight: `1px solid ${palette.nebulaNavy50}`,
    borderLeft: `1px solid ${palette.nebulaNavy50}`,
  },
  title: {
    fontWeight: 600,
    letterSpacing: "0.5px",
    fontFamily: theme.typography.fontFamily,
    backgroundColor: "transparent",
  },
  bookmarkCell: {
    width: "5px",
  },
  bookmarkedComponent: {
    color: palette.black,
  },
  name: {
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  summary: {
    maxWidth: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  owner: {
    maxWidth: 50,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  status: {
    maxWidth: 150,
    paddingTop: "0",
    paddingBottom: "0",
  },
  statusIndicator: {
    whiteSpace: "nowrap",
    padding: "7px 10px",
    fontSize: "0.625rem",
    lineHeight: "16px",
    letterSpacing: "1.5px",
    fontWeight: 600,
    borderRadius: "4px",
    color: palette.infoDark,
    backgroundColor: palette.infoLight,
    border: `1px solid ${palette.info}`,
  },
  pendingStatus: {
    backgroundColor: palette.hiliteLight,
    color: palette.hiliteDark,
    border: `1px solid ${palette.hilite}`,
  },
}));

interface DataProductListProps {
  dataProducts: SchemaDataProduct[];
  hasMoreRecords: boolean;
  isFetchingPage: boolean;
  fetchMore: () => void;
  sorting: SortDescriptor;
  setSorting: (newValue: SortDescriptor) => void;
}

export const DataProductList: React.FunctionComponent<DataProductListProps> = ({
  dataProducts,
  sorting,
  hasMoreRecords,
  isFetchingPage,
  fetchMore,
  setSorting,
}) => {
  const styles = useStyles();
  return (
    <Container className={styles.root} maxWidth={false}>
      <div className={styles.dataContainer}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <CustomizedSortableColumn
                  label={<BookmarkIcon isActive />}
                  sortingKey="BOOKMARK"
                  sortingState={sorting}
                  setSortingState={setSorting}
                />
                <CustomizedSortableColumn
                  label="Data product"
                  sortingKey="NAME"
                  sortingState={sorting}
                  setSortingState={setSorting}
                />
                <TableCell
                  variant="head"
                  component="th"
                  className={styles.title}
                >
                  Summary
                </TableCell>
                <CustomizedSortableColumn
                  label="Rating"
                  sortingKey="RATINGS_AVERAGE"
                  sortingState={sorting}
                  setSortingState={setSorting}
                />
                <CustomizedSortableColumn
                  label="Created by"
                  sortingKey="CREATED_BY"
                  sortingState={sorting}
                  setSortingState={setSorting}
                />
                <CustomizedSortableColumn
                  label="Date"
                  sortingKey="CREATED_AT"
                  sortingState={sorting}
                  setSortingState={setSorting}
                />
                <CustomizedSortableColumn
                  label="Status"
                  sortingKey="STATUS"
                  sortingState={sorting}
                  setSortingState={setSorting}
                />
                <CustomizedSortableColumn
                  label="Last queried"
                  sortingKey="LAST_QUERIED_AT"
                  sortingState={sorting}
                  setSortingState={setSorting}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataProducts.map((dataProduct) => (
                <TableRow key={dataProduct.id}>
                  <TableCell className={styles.bookmarkCell}>
                    <PreventDefaultClick>
                      <BookmarkDataProduct
                        isBookmarked={dataProduct.userData.isBookmarked}
                        dataProductId={dataProduct.id}
                        className={
                          dataProduct.userData.isBookmarked
                            ? styles.bookmarkedComponent
                            : undefined
                        }
                      />
                    </PreventDefaultClick>
                  </TableCell>
                  <TableCell className={styles.name}>
                    <Link
                      component={RouterLink}
                      to={createDataProductDetailsPath(dataProduct.id)}
                      underline="none"
                    >
                      {dataProduct.name}
                    </Link>
                  </TableCell>
                  <TableCell className={styles.summary}>
                    {dataProduct.summary}
                  </TableCell>
                  <TableCell className={styles.summary}>
                    <InteractiveRating
                      entityName={dataProduct.name}
                      entityId={dataProduct.id}
                      userRate={dataProduct.userData.rating || 0}
                      voterCount={dataProduct.ratingsCount}
                      ratingsAverage={dataProduct.ratingsAverage}
                    />
                  </TableCell>
                  <TableCell className={styles.owner}>
                    {dataProduct.createdBy}
                  </TableCell>
                  <TableCell>
                    {formatDate(dataProduct.createdAt, "MM/dd/yy")}
                  </TableCell>
                  <TableCell className={styles.status}>
                    {dataProduct.status === DataProductState.DRAFT && (
                      <Typography
                        variant="overline"
                        className={styles.statusIndicator}
                      >
                        {dataProduct.status}
                      </Typography>
                    )}
                    {dataProduct.status ===
                      DataProductState.PENDING_CHANGES && (
                      <Typography
                        variant="overline"
                        className={clsx(
                          styles.statusIndicator,
                          styles.pendingStatus
                        )}
                      >
                        PENDING CHANGES
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {formatDate(dataProduct.lastQueriedAt, "MM/dd/yy")}
                  </TableCell>
                </TableRow>
              ))}
              {hasMoreRecords && (
                <TableRow>
                  <TableCell colSpan={8}>
                    {isFetchingPage ? (
                      <Spinner size={50} position="relative" />
                    ) : (
                      <LoadMoreIntersectionButton onLoadMore={fetchMore} />
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

type CustomizedSortableColumnProps<T extends string> = Pick<
  SortableColumnProps<T>,
  "label" | "sortingKey" | "sortingState" | "setSortingState"
>;

const CustomizedSortableColumn = <T extends string>({
  label,
  sortingKey,
  sortingState,
  setSortingState,
}: CustomizedSortableColumnProps<T>) => (
  <SortableColumn
    label={label}
    sortingKey={sortingKey}
    sortingState={sortingState}
    setSortingState={setSortingState}
    component="th"
    IconComponent={ArrowDropDownIcon}
    color={palette.black}
  />
);
