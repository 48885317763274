/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import Grid from "@mui/material/Grid";
import { SampleQueryDto } from "../../../api/dataProduct/dataProductApi";
import { QueryEditorWithDialog } from "../../../components/sql-highlight/QueryEditorWithDialog";
import Button from "@mui/material/Button";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Edit from "@mui/icons-material/Edit";
import { palette, sqlHighlightStyle } from "../../../themes/palette";
import IconButton from "@mui/material/IconButton";
import { faClone } from "@fortawesome/pro-regular-svg-icons";
import { Clipboard } from "../../../components/clipboard/Clipboard";
import { useDialogOpenState } from "../../../components/dialog/useDialogOpenState";
import { DeleteDialog } from "../../../components/dialog/DeleteDialog";
import { useDataProductPermissionContext } from "../permission/DataProductPermissionContext";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import { isEmpty, maximumTitleLength } from "../util/formUtils";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { TextFieldWithHint } from "../components/TextFieldWithHint";
import { PreviewDataset } from "../components/PreviewDataset";

interface SampleQueryProps {
  query?: SampleQueryDto;
  existingNames: Set<string>;
  onUpdate: (updated: SampleQueryDto) => Promise<void>;
  canCancel?: boolean;
  onCancel?: () => void;
  onDelete?: () => Promise<void>;
  inEditMode?: boolean;
  disablePreview?: boolean;
}

const useStyles = createUseStyles({
  queryNameTooltip: {
    marginTop: "0.75rem",
  },
  editor: {
    width: "100%",
    paddingRight: "0.5rem",
  },
  codeContainer: {
    position: "relative",
    "& #code-box": {
      width: "100%",
      height: "auto",
    },
    "& #copy-to-clipboard-btn": {
      position: "absolute",
      top: "1.2rem",
      left: "calc(100% - 0.7rem)",
      transform: "translate(-50%, -50%)",
    },
  },
  code: {
    border: sqlHighlightStyle.border,
    borderRadius: sqlHighlightStyle.borderRadius,
    backgroundColor: sqlHighlightStyle.backgroundColor,
    width: "100%",
    overflow: "auto",
    maxHeight: "20rem",
  },
  fallbackText: {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
    padding: "1.5rem 0 0 1.5rem",
  },
  sampleQueryName: {
    color: palette.black54,
  },
  previewButton: {
    marginBottom: "0",
  },
  deleteButton: {
    color: palette.error,
  },
});

const MAX_QUERY_TEXT_SIZE_FOR_SYNTAX_HIGHLIGHT = 25000;

export const SampleQuery: React.FunctionComponent<SampleQueryProps> = ({
  query,
  onDelete,
  onUpdate,
  canCancel = true,
  onCancel,
  inEditMode = false,
  existingNames,
  disablePreview = false,
}) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(inEditMode);

  const [queryName, setQueryName] = useState(query?.name ?? "");
  const [queryText, setQueryText] = useState(query?.query);
  const syntaxHighlightingEnabled =
    (queryText?.length ?? 0) < MAX_QUERY_TEXT_SIZE_FOR_SYNTAX_HIGHLIGHT;
  const userPermissions = useDataProductPermissionContext();

  const cancelEditing = async () => {
    if (onCancel) {
      onCancel();
    }
    setQueryName(query?.name ?? "");
    setQueryText(query?.query);
    setEdit(false);
  };

  const editView = () => {
    const hasNonUniqueName =
      query?.name !== queryName && existingNames.has(queryName);
    return (
      <>
        <Grid item xs={12} container>
          <TextFieldWithHint
            value={queryName}
            onValueChange={(value) => setQueryName(value)}
            label="Query name"
            required={true}
            error={hasNonUniqueName}
            tooltipText="Short description of the query example"
            helperText={
              hasNonUniqueName ? "Query name should be unique" : undefined
            }
            maxLength={maximumTitleLength}
          />
        </Grid>
        <Grid item xs={12}>
          <QueryEditorWithDialog
            inputElementClass={classes.editor}
            onQueryChanged={setQueryText}
            query={queryText}
            label="SQL query example"
            required
          />
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction="row"
          justifyContent="flex-end"
          spacing={1}
        >
          {canCancel && (
            <Grid item>
              <Button variant="outlined" onClick={cancelEditing}>
                Cancel
              </Button>
            </Grid>
          )}
          <Grid item mr={1}>
            <Tooltip
              title={
                !userPermissions?.canUpdate
                  ? "You do not have permission to edit this data product"
                  : ""
              }
            >
              <span>
                <Button
                  disabled={
                    isEmpty(queryName) ||
                    isEmpty(queryText) ||
                    hasNonUniqueName ||
                    !userPermissions.canUpdate
                  }
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    await onUpdate({ name: queryName, query: queryText ?? "" });
                    setEdit(false);
                  }}
                >
                  Save
                </Button>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </>
    );
  };

  const readView = () => (
    <>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={12} container alignItems={"center"}>
          <Grid item container xs={6} justifyContent={"flex-start"}>
            <Grid item className={classes.sampleQueryName}>
              <Typography variant={"subtitle2"}>{queryName}</Typography>
            </Grid>
          </Grid>
          <Grid
            style={{ margin: 0 }}
            item
            container
            xs={6}
            justifyContent={"flex-end"}
            alignItems={"center"}
          >
            <Grid item>
              <DeleteSampleQueryModal
                name={queryName}
                onConfirm={() => (onDelete ? onDelete() : Promise.resolve())}
              />
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  !userPermissions?.canUpdate
                    ? "You do not have permission to edit this data product"
                    : ""
                }
              >
                <span>
                  <IconButton
                    color="primary"
                    disabled={!userPermissions.canUpdate}
                    onClick={() => {
                      setEdit(true);
                    }}
                    size="small"
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item style={{ padding: 0 }}>
              <PreviewDataset
                sqlQuery={queryText ?? ""}
                className={classes.previewButton}
                disabled={disablePreview}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} className={classes.codeContainer}>
          <Grid id="code-box" item xs={12} className={classes.code}>
            {syntaxHighlightingEnabled ? (
              <>
                <SyntaxHighlighter
                  showLineNumbers={true}
                  customStyle={{
                    backgroundColor: "transparent",
                    margin: 0,
                    display: "flex",
                    flexDirection: "column",
                    overflow: "none",
                  }}
                  language="sql"
                  style={prism}
                  wrapLines={true}
                >
                  {queryText}
                </SyntaxHighlighter>
              </>
            ) : (
              <div className={classes.fallbackText}>{queryText}</div>
            )}
          </Grid>
          <Clipboard text={queryText ?? ""}>
            <IconButton
              id="copy-to-clipboard-btn"
              disableRipple={true}
              color="secondary"
              size="small"
            >
              <FontAwesomeIcon size={"xs"} icon={faClone} />
            </IconButton>
          </Clipboard>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Grid container spacing={1}>
      {edit && editView()}
      {!edit && readView()}
    </Grid>
  );
};

interface DeleteSampleQueryModalProps {
  name: string;
  onConfirm: () => Promise<void>;
}

export const DeleteSampleQueryModal: React.FunctionComponent<
  DeleteSampleQueryModalProps
> = ({ name, onConfirm }: DeleteSampleQueryModalProps) => {
  const { isOpen, close, open } = useDialogOpenState();
  const userPermissions = useDataProductPermissionContext();
  const classes = useStyles();

  return (
    <>
      <Tooltip
        title={
          !userPermissions?.canUpdate
            ? "You do not have permission to edit this data product"
            : ""
        }
      >
        <span>
          <IconButton
            onClick={open}
            size="small"
            className={classes.deleteButton}
            disabled={!userPermissions.canUpdate}
          >
            <DeleteOutline />
          </IconButton>
        </span>
      </Tooltip>
      {isOpen && (
        <DeleteDialog
          entityType="query sample"
          entityHumanName={name}
          onConfirm={onConfirm}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};
