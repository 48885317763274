/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { faLockAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { createUseStyles } from "react-jss";
import TextField from "@mui/material/TextField";
import noop from "lodash/noop";
import React from "react";
import { Column } from "../../../api/dataProduct/dataProductApi";
import { ColumnNameDescription } from "../publish/publishFormReducer";
import { Mode } from "./ViewSchema";

interface ViewColumnProps {
  column: Column;
  mode?: Mode;
  updateColumnDescription?: (column: ColumnNameDescription) => void;
}

const useStyles = createUseStyles({
  adornment: {
    marginLeft: "4px",
  },
  subTitle: {
    fontSize: "0.875rem",
    paddingTop: "1.5rem",
    fontWeight: 500,
    paddingLeft: "16px",
  },
});

export const ViewColumn: React.FunctionComponent<ViewColumnProps> = ({
  column,
  mode,
  updateColumnDescription = noop,
}) => {
  const classes = useStyles();
  const isReadOnly = mode === "display";

  return (
    <div>
      <Grid container direction="row" alignItems="center" spacing={1}>
        <Grid item md={3} sm={12}>
          <TextField
            value={column?.name}
            disabled={true}
            variant="outlined"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ positionStart: classes.adornment }}
                >
                  <FontAwesomeIcon icon={faLockAlt} />
                </InputAdornment>
              ),
            }}
            inputProps={{ style: { textOverflow: "ellipsis" } }}
          />
        </Grid>
        <Grid item md={3} sm={12}>
          <TextField
            value={column?.type}
            disabled={true}
            variant="outlined"
            margin="dense"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="start"
                  classes={{ positionStart: classes.adornment }}
                >
                  <FontAwesomeIcon icon={faLockAlt} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item md={6} sm={12}>
          <TextField
            variant="outlined"
            margin="dense"
            label="Column description"
            style={{ width: "100%" }}
            value={column.description}
            disabled={isReadOnly}
            onChange={(e) =>
              updateColumnDescription({
                name: column.name,
                description: e.target.value,
              })
            }
            InputProps={{
              endAdornment: isReadOnly && (
                <InputAdornment
                  position="start"
                  classes={{ positionStart: classes.adornment }}
                >
                  <FontAwesomeIcon icon={faLockAlt} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};
