/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useMemo } from "react";
import { createUseStyles } from "react-jss";
import TextField from "@mui/material/TextField";
import { Tooltip } from "../../../components/tooltip/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import { palette } from "../../../themes/palette";
import clsx from "clsx";

interface TextFieldWithHintProps {
  value: string | undefined;
  label: string;
  children?: React.ReactNode;
  required?: boolean;
  tooltipText?: string;
  onValueChange?: (newValue: string) => void;
  className?: string;
  select?: boolean;
  helperText?: string;
  disabled?: boolean;
  endAdornment?: React.ReactNode;
  multiline?: boolean;
  rows?: number;
  error?: boolean;
  maxLength?: number;
  fullWidth?: boolean;
  containerClass?: string;
  margin?: "none" | "dense" | "normal";
}

const useStyles = createUseStyles({
  textField: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "0.75rem",
  },
  text: {
    minWidth: "20.5rem",
  },
  helperIcon: {
    marginTop: "0.5rem",
    marginLeft: "1.25rem",
  },
  helperButton: {
    height: "1.25rem",
    width: "1.25rem",
    marginTop: "0.75rem",
    color: palette.nebulaNavy200,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  fullWidth: {
    width: "100%",
  },
});

export const TextFieldWithHint: React.FunctionComponent<
  TextFieldWithHintProps
> = ({
  value,
  onValueChange = () => void 0,
  children,
  label,
  required = false,
  select = false,
  tooltipText: toolTipText,
  helperText,
  className = "",
  disabled = false,
  endAdornment,
  multiline = false,
  rows,
  error = false,
  maxLength = 50,
  fullWidth = false,
  containerClass,
  margin = "dense",
}) => {
  const classes = useStyles();

  const containerClasses = useMemo(() => {
    if (fullWidth) {
      return clsx(containerClass, classes.fullWidth, classes.textField);
    } else {
      return clsx(containerClass, classes.textField);
    }
  }, [containerClass, fullWidth]);

  return (
    <div className={containerClasses}>
      <TextField
        fullWidth={fullWidth}
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        variant="outlined"
        margin={margin}
        label={label}
        required={required}
        helperText={helperText}
        disabled={disabled}
        select={select}
        InputProps={{
          endAdornment,
        }}
        multiline={multiline}
        rows={rows}
        className={clsx(className, classes.text)}
        error={error}
        inputProps={{ maxLength: maxLength }}
      >
        {children}
      </TextField>
      {toolTipText && (
        <Tooltip title={toolTipText} placement="right-start">
          <IconButton
            size="small"
            tabIndex={-1}
            className={classes.helperButton}
          >
            <InfoIcon className={classes.helperIcon} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};
