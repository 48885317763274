/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { FunctionComponent } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import { ChangeLogEntry } from "./useChangeLogs";
import { palette } from "../../../../themes/palette";
import { Tooltip } from "../../../../components/tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield } from "@fortawesome/pro-solid-svg-icons/faUserShield";
import clsx from "clsx";
import { Spinner } from "../../../../components/spinner/Spinner";
import { LoadMoreIntersectionButton } from "../../../dataproduct/pagination/LoadMoreIntersectionButton";
import { parseISO } from "date-fns";
import { TextWithTooltip } from "../TextWithTooltip";
import { UserAndRolesTableCell } from "../UserAndRolesTableCell";

interface ChangesTableProps {
  logs: ChangeLogEntry[];
  hasMoreRecords: boolean;
  isFetchingPage: boolean;
  fetchMore: () => void;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    maxHeight: "calc(100vh - 9.75rem)",
    border: `1px solid ${palette.nebulaNavy50}`,
  },
  truncated: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  adminOptionIcon: {
    marginRight: "0.25rem",
  },
  entitySpecified: {
    fontFamily: 'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
  },
  tableHeadCell: {
    fontWeight: 600,
    letterSpacing: "0.5px",
    fontFamily: theme.typography.fontFamily,
  },
}));

export const ChangesLogTable: FunctionComponent<ChangesTableProps> = ({
  logs,
  hasMoreRecords,
  isFetchingPage,
  fetchMore,
}) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader style={{ tableLayout: "fixed" }}>
        <colgroup>
          <col style={{ width: "7%" }} />
          <col style={{ width: "12%" }} />
          <col style={{ width: "16%" }} />
          <col />
          <col style={{ width: "15%" }} />
          <col style={{ width: "17%" }} />
          <col style={{ width: "11rem" }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>Operation</TableCell>
            <TableCell className={classes.tableHeadCell}>Entity kind</TableCell>
            <TableCell className={classes.tableHeadCell}>
              What changed
            </TableCell>
            <TableCell className={classes.tableHeadCell}>Entity</TableCell>
            <TableCell className={classes.tableHeadCell}>
              Role affected
            </TableCell>
            <TableCell className={classes.tableHeadCell}>User (Role)</TableCell>
            <TableCell className={classes.tableHeadCell}>
              Time of change
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs.map((log) => (
            <TableRow hover key={log.id}>
              <TableCell className={classes.truncated}>
                <TextWithTooltip text={log.operation} />
              </TableCell>
              <TableCell className={classes.truncated}>
                <TextWithTooltip text={log.entityKind} />
              </TableCell>
              <TableCell className={classes.truncated}>
                <TextWithTooltip text={log.whatChanged} />
              </TableCell>
              <TableCell
                className={clsx(classes.truncated, {
                  [classes.entitySpecified]: log.entitySpecified,
                })}
              >
                {log.grantOption ? (
                  <Tooltip title={log.grantOption}>
                    <span>
                      <FontAwesomeIcon
                        size="sm"
                        className={classes.adminOptionIcon}
                        icon={faUserShield}
                      />
                      {log.entity}
                    </span>
                  </Tooltip>
                ) : (
                  <TextWithTooltip text={log.entity} />
                )}
              </TableCell>
              <TableCell className={classes.truncated}>
                <TextWithTooltip text={log.roleAffected} />
              </TableCell>
              <TableCell className={classes.truncated}>
                <UserAndRolesTableCell user={log.user} roles={log.roles} />
              </TableCell>
              <TableCell className={classes.truncated}>
                {parseISO(log.atTime.toString()).toLocaleString()}
              </TableCell>
            </TableRow>
          ))}
          {hasMoreRecords && (
            <TableRow>
              <TableCell colSpan={7}>
                {isFetchingPage ? (
                  <Spinner size={50} position="relative" />
                ) : (
                  <LoadMoreIntersectionButton onLoadMore={fetchMore} />
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
