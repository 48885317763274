/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useContext } from "react";
import {
  downloadAuditChangeLogs,
  getAuditChangeLogs,
} from "../../../../api/biac/audit/auditLogApi";
import { RoleContext } from "../../CurrentRoleContext";
import { FiltersRowModel } from "../FilterRow";
import { AuditLogs, useFetchAuditLogs } from "../useFetchAuditLogs";

export interface ChangeLogEntry {
  id: number;
  operation:
    | "Create"
    | "Delete"
    | "Assign"
    | "Unassign"
    | "Add"
    | "Remove"
    | "?";
  entityKind: string;
  whatChanged: string;
  entity: string;
  entitySpecified: boolean;
  grantOption: string | null;
  roleAffected: string | null;
  user: string;
  roles: string[];
  atTime: Date;
}

export const useChangeLogs = (
  appliedFilters: FiltersRowModel[]
): AuditLogs<ChangeLogEntry> => {
  const roleContext = useContext(RoleContext);
  if (roleContext === "disabled") {
    throw Error("Illegal state - RoleContext is required");
  }
  return useFetchAuditLogs(
    appliedFilters,
    getAuditChangeLogs,
    downloadAuditChangeLogs
  );
};
