/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import React from "react";
import { ClusterHistoryResponse } from "../../api/clusterApi";
import { ClusterCpuUsageChart } from "./chart/ClusterCpuUsageChart";
import { MemoryUsageChart } from "./chart/MemoryUsageChart";
import { ClusterSizeChart } from "./chart/ClusterSizeChart";
import { ClusterQueriesChart } from "./chart/ClusterQueriesChart";
import { HighlightAreaProps } from "../../components/chart/TimeLineChart";
import { AlignedCharts } from "../../components/chart/AlignedCharts";
import useMediaQuery from "@mui/material/useMediaQuery";

interface ClusterHistoryReportsProps {
  reports: ClusterHistoryResponse | "loading" | "error";
  highlightArea?: HighlightAreaProps;
}

export const ClusterHistoryReports: React.FunctionComponent<
  ClusterHistoryReportsProps
> = ({ reports, highlightArea }) => {
  const lgHeight = useMediaQuery("(min-height:1000px)");
  const { bigChart, smallChart } = {
    bigChart: lgHeight ? 210 : 180,
    smallChart: lgHeight ? 190 : 140,
  };

  return (
    <Box my={3}>
      <AlignedCharts>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={12}>
            <Card>
              <ClusterCpuUsageChart
                cpuUsage={reports}
                height={bigChart}
                highlightArea={highlightArea}
              />
            </Card>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Card>
              <MemoryUsageChart
                memoryUsage={reports}
                height={bigChart}
                highlightArea={highlightArea}
              />
            </Card>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Card>
              <ClusterSizeChart
                size={reports}
                height={smallChart}
                highlightArea={highlightArea}
              />
            </Card>
          </Grid>

          <Grid item lg={6} xs={12}>
            <Card>
              <ClusterQueriesChart
                queries={reports}
                height={smallChart}
                highlightArea={highlightArea}
              />
            </Card>
          </Grid>
        </Grid>
      </AlignedCharts>
    </Box>
  );
};
