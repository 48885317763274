/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { baseUrl } from "./api";
import { ClusterHistoryCriteria } from "../features/cluster/ClusterHistoryFilters";
import { formatISO } from "date-fns";
import axios from "axios";

export type ClusterHistoryResponse = Array<{
  sampleIntervalStart: string;
  sampleIntervalEnd: string;
  nodes: number;
  cpus: number;
  totalQueryMemory: number;
  usedQueryMemory: number;
  totalHeapSize: number;
  usedHeapSize: number;
  systemCpuLoad: number;
  processCpuLoad: number;
  runningQueries: number;
  queuedQueries: number;
}>;

export const getClusterHistoryMetrics = (
  filters: ClusterHistoryCriteria
): Promise<ClusterHistoryResponse> => {
  const params = {
    filter: {
      startTime: filters?.startDate ? formatISO(filters.startDate) : undefined,
      endTime: filters?.endDate ? formatISO(filters.endDate) : undefined,
    },
  };

  return axios
    .get(`${baseUrl}/history/metrics`, { params })
    .then((response) => response.data);
};
