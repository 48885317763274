/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { ClusterInfo, getClusterInfo } from "../../api/configApi";
import { v4 as uuidv4 } from "uuid";
import { differenceInMilliseconds } from "date-fns";
import moment from "moment";
import { palette } from "../../themes/palette";
import ListItemIcon from "@mui/material/ListItemIcon";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "../tooltip/Tooltip";
import clsx from "clsx";

const useStyles = createUseStyles({
  root: {
    color: palette.nebulaNavy400,
    fontSize: "1rem",
    padding: "0.5rem 1.125rem",
    lineHeight: "1.5rem",
  },
  row: {
    whiteSpace: "nowrap",
    wordBreak: "break-word",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  sidePanelIcon: {
    minWidth: "40px",
    marginLeft: "17px",
    "& .MuiSvgIcon-root": {
      fill: "#000000BC",
    },
  },
  toolTipTextStyle: {
    color: palette.nebulaNavy,
    padding: "unset",
  },
});

const ClusterInfoBox: React.FunctionComponent<ClusterInfoProps> = ({
  openNav,
}) => {
  const classes = useStyles();
  const [, setRefresher] = useState<string>(uuidv4());
  const [clusterInfo, setClusterInfo] = useState<ClusterInfo | null>(null);
  const [lastUpdateDate, setLastUpdateDate] = useState<Date | null>(null);

  const fetchClusterInfo = (): void => {
    getClusterInfo()
      .then((info) => {
        setClusterInfo(info);
        setLastUpdateDate(new Date());
      })
      .catch(() => setClusterInfo(null));
  };

  useEffect(() => {
    fetchClusterInfo();
    const fetchingIntervalHandler = setInterval(() => {
      fetchClusterInfo();
    }, 60 * 1000);

    const refreshIntervalHandler = setInterval(() => {
      setRefresher(uuidv4());
    }, 1000);

    return (): void => {
      clearTimeout(fetchingIntervalHandler);
      clearTimeout(refreshIntervalHandler);
    };
  }, []);

  const row = (title: string, value: string): React.ReactNode => {
    return (
      <div className={classes.row}>
        <span>{title}:</span> {value}
      </div>
    );
  };

  const getUptime = (): string => {
    if (!lastUpdateDate || !clusterInfo) {
      return "-";
    }

    const sinceLastUpdate = differenceInMilliseconds(
      new Date(),
      lastUpdateDate
    );

    return moment
      .duration(clusterInfo.uptime + sinceLastUpdate)
      .format("d[d] h[h] m[m] s[s]", {
        largest: 2,
        trim: "large",
      });
  };

  return (
    <div
      className={clsx(classes.root, { [classes.toolTipTextStyle]: !openNav })}
    >
      {row("Version", clusterInfo?.nodeVersion.version ?? "-")}
      {row("JDK version", clusterInfo?.javaVersion ?? "-")}
      {row("Environment", clusterInfo?.environment ?? "-")}
      {row("Uptime", getUptime())}
    </div>
  );
};

interface ClusterInfoProps {
  openNav: boolean;
}

export const ClusterInformation: React.FunctionComponent<ClusterInfoProps> = ({
  openNav,
}) => {
  const classes = useStyles();

  if (!openNav) {
    return (
      <Tooltip
        title={<ClusterInfoBox openNav={openNav} />}
        placement={"right-end"}
      >
        <ListItemIcon className={classes.sidePanelIcon}>
          <InfoIcon />
        </ListItemIcon>
      </Tooltip>
    );
  }
  return <ClusterInfoBox openNav={openNav} />;
};
