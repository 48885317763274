/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */

import { createUseStyles } from "react-jss";
import { palette } from "../../../themes/palette";

export const addPrivilegesStyles = createUseStyles({
  questionInfo: {
    fontSize: "0.875rem",
    lineHeight: "24px",
    letterSpacing: "0.14px",
    alignItems: "center",
    marginBottom: "0.875rem",
  },
  chip: {
    color: palette.nebulaNavy700,
    backgroundColor: palette.nebulaNavy50,
    padding: "4px 8px 4px 8px",
    maxWidth: "40%",
    margin: "2px",
  },
  chipCloseIcon: {
    color: `${palette.nebulaNavy700}!important`,
    fontSize: "1rem!important",
  },
  autoCompletePaper: {
    borderRadius: "4px 4px 0 0!important",
  },
  popperComponentPaper: {
    padding: "1rem!important",
    borderRadius: "0 0 4px 4px!important",
  },
  optionLabel: {
    textOverflow: "ellipsis",
    maxWidth: "400px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  toolTipOptionDescription: {
    fontSize: "0.875rem",
    color: palette.black54,
  },
  optionDescription: {
    fontSize: "0.875rem",
    color: palette.black54,
    textOverflow: "ellipsis",
    maxWidth: "400px",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  maxWidthChip: {
    maxWidth: "80%!important",
  },
});
