/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useMemo, useState } from "react";
import uniqBy from "lodash/uniqBy";
import { createUseStyles } from "react-jss";
import TextField from "@mui/material/TextField";
import {
  patchDataProduct,
  saveTagsForDataProduct,
  SchemaDataProduct,
  Tag,
} from "../../../api/dataProduct/dataProductApi";
import {
  DataDomain,
  getDomains$,
} from "../../../api/dataProduct/dataDomain/dataDomainApi";
import Grid from "@mui/material/Grid";
import { EditableOwnerDetails, OwnerDetails } from "./OwnerDetails";
import { ErrorIndicator } from "../../../components/error/ErrorIndicator";
import { TagDetails } from "../../../components/tag/TagDetails";
import { LinkList } from "../components/LinkList";
import { Metadata } from "./Metadata";
import { ViewInBiTool } from "./ViewInBiTool";
import Typography from "@mui/material/Typography";
import { EditableSection } from "../edit/EditableSection";
import MenuItem from "@mui/material/MenuItem";
import { AddOwner } from "../publish/DataProductOwners";
import { AddLink } from "../publish/DataProductLinks";
import { useAllTagsState } from "../tag/useAllTagsState";
import { useDataProductTagsState } from "../tag/useDataProductTagsState";
import { EditableLinkDetails } from "./LinkDetails";
import { TagTextField } from "../../../components/tag/TagTextField";

interface DetailsSummarySectionProps {
  dataProduct: SchemaDataProduct;
}

const useClasses = createUseStyles({
  header: {
    marginTop: "2.5rem",
  },
});

export const DetailsSummarySection: React.FunctionComponent<
  DetailsSummarySectionProps
> = ({ dataProduct }) => {
  const internalClasses = useClasses();

  const [allDomains, setAllDomains] = useState<DataDomain[]>([]);
  const [currentDomain, setCurrentDomain] = useState<DataDomain>();
  const [domainError, setDomainError] = useState<string>("");

  useEffect(() => {
    const subscription = getDomains$().subscribe({
      next: (data) => setAllDomains(data),
      error: (error) => setDomainError(error.message),
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setCurrentDomain(
      allDomains.find(({ id }) => id === dataProduct.dataDomainId)
    );
  }, [dataProduct.dataDomainId, allDomains]);

  const { data: allTags } = useAllTagsState();
  const allTagNames = useMemo(
    () => allTags?.map(({ value }) => value) || [],
    [allTags]
  );
  const { data: assignedTags } = useDataProductTagsState(dataProduct.id);

  return (
    <div>
      <EditableSection<OwnersFormData>
        header="Data product owners"
        createForm={() => createOwnersFormData(dataProduct)}
        isFormChanged={areOwnersFormDataDifferent}
        onSave={(formData) => patchDataProduct(dataProduct.id, formData)}
        classes={internalClasses}
      >
        {(isEditModeOn, editedFormData, dispatchEditedFormData) => (
          <>
            {isEditModeOn ? (
              <>
                {editedFormData.owners?.map((owner, ownerIndex) => (
                  <Grid item key={ownerIndex}>
                    <EditableOwnerDetails
                      productOwner={owner}
                      onDeleteClick={() =>
                        dispatchEditedFormData((prevState) => ({
                          owners: prevState.owners?.filter(
                            (_, index) => index !== ownerIndex
                          ),
                        }))
                      }
                    />
                  </Grid>
                ))}
                <AddOwner
                  onAdd={(newOwner) => {
                    dispatchEditedFormData((prevState) => ({
                      owners: [...(prevState.owners || []), newOwner],
                    }));
                    return Promise.resolve();
                  }}
                />
              </>
            ) : (
              dataProduct.owners?.map((owner, ownerIndex) => (
                <Grid item key={ownerIndex}>
                  <OwnerDetails productOwner={owner} />
                </Grid>
              ))
            )}
          </>
        )}
      </EditableSection>

      <EditableSection<TagsFormData>
        header="Tags"
        createForm={() => createTagsFormData(assignedTags)}
        isFormChanged={areTagsFormDataDifferent}
        onSave={(formData) => saveTagsForDataProduct(dataProduct.id, formData)}
        classes={internalClasses}
      >
        {(isEditModeOn, editedFormData, dispatchEditedFormData) => (
          <div>
            <TagDetails
              tags={isEditModeOn ? editedFormData : assignedTags || []}
              removeTag={
                isEditModeOn
                  ? (tagValue) =>
                      dispatchEditedFormData((prevState) =>
                        prevState.filter(({ value }) => value !== tagValue)
                      )
                  : undefined
              }
            />
            {isEditModeOn && (
              <TagTextField
                allTagNames={allTagNames}
                onChange={(tagValue) =>
                  dispatchEditedFormData((prevState) =>
                    uniqBy<Tag>(
                      [...prevState, { value: tagValue } as Tag],
                      "value"
                    )
                  )
                }
              />
            )}
          </div>
        )}
      </EditableSection>

      <EditableSection<DomainFormData>
        header="Domain"
        createForm={() => createDomainFormData(dataProduct)}
        isFormChanged={areDomainFormDataDifferent}
        onSave={(formData) => patchDataProduct(dataProduct.id, formData)}
        classes={internalClasses}
      >
        {(isEditModeOn, editedFormData, dispatchEditedFormData) => (
          <>
            {isEditModeOn ? (
              <TextField
                fullWidth
                label="Domain"
                variant="outlined"
                margin="dense"
                required
                value={editedFormData.dataDomainId}
                select
                onChange={({ target: { value } }) =>
                  dispatchEditedFormData((prevState) => ({
                    ...prevState,
                    dataDomainId: value,
                  }))
                }
              >
                {allDomains.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              currentDomain && <div>{currentDomain.name}</div>
            )}
            <ErrorIndicator text={domainError} />
          </>
        )}
      </EditableSection>

      <EditableSection<LinksFormData>
        header="Relevant links"
        createForm={() => createLinksFormData(dataProduct)}
        isFormChanged={areLinksFormDataDifferent}
        onSave={(formData) => patchDataProduct(dataProduct.id, formData)}
        classes={internalClasses}
      >
        {(isEditModeOn, editedFormData, dispatchEditedFormData) =>
          isEditModeOn ? (
            <>
              <div>
                {editedFormData.relevantLinks?.map((link, linkIndex) => (
                  <EditableLinkDetails
                    key={linkIndex}
                    article={link}
                    onDeleteClick={() =>
                      dispatchEditedFormData((prevState) => ({
                        relevantLinks: prevState.relevantLinks?.filter(
                          (_, index) => index !== linkIndex
                        ),
                      }))
                    }
                  />
                ))}
              </div>
              <AddLink
                onAdd={(newLink) => {
                  dispatchEditedFormData((prevState) => ({
                    ...prevState,
                    relevantLinks: [
                      ...(prevState.relevantLinks || []),
                      newLink,
                    ],
                  }));
                  return Promise.resolve();
                }}
              />
            </>
          ) : (
            <LinkList
              relevantLinks={dataProduct.relevantLinks}
              linkWrapper={Grid}
              wrapperProps={{ item: true }}
            />
          )
        }
      </EditableSection>

      <Typography variant="h5" className={internalClasses.header}>
        Details
      </Typography>
      <Metadata dataProduct={dataProduct} />

      <div className={internalClasses.header}>
        <ViewInBiTool dataProduct={dataProduct} />
      </div>
    </div>
  );
};

type OwnersFormData = Pick<SchemaDataProduct, "owners">;
function createOwnersFormData({
  owners = [],
}: SchemaDataProduct): OwnersFormData {
  return {
    owners: owners.map((owner) => ({
      ...owner,
    })),
  };
}
function areOwnersFormDataDifferent(
  { owners: owners1 = [] }: OwnersFormData,
  { owners: owners2 = [] }: OwnersFormData
): boolean {
  if (owners1.length === owners2.length) {
    return !owners1.every(
      (owner, index) =>
        owner.email === owners2[index].email &&
        owner.name === owners2[index].name
    );
  }
  return true;
}

type TagsFormData = Tag[];
function createTagsFormData(tags: Tag[] = []): TagsFormData {
  return tags.map((tag) => ({ ...tag }));
}
function areTagsFormDataDifferent(
  form1: TagsFormData,
  form2: TagsFormData
): boolean {
  if (form1.length === form2.length) {
    return !form1.every(
      (tag, index) =>
        tag.id === form2[index].id && tag.value === form2[index].value
    );
  }
  return true;
}

type DomainFormData = Pick<SchemaDataProduct, "dataDomainId">;
function createDomainFormData({
  dataDomainId,
}: SchemaDataProduct): DomainFormData {
  return { dataDomainId };
}
function areDomainFormDataDifferent(
  { dataDomainId: dataDomainId1 }: DomainFormData,
  { dataDomainId: dataDomainId2 }: DomainFormData
): boolean {
  return dataDomainId1 !== dataDomainId2;
}

type LinksFormData = Pick<SchemaDataProduct, "relevantLinks">;
function createLinksFormData({
  relevantLinks = [],
}: SchemaDataProduct): LinksFormData {
  return { relevantLinks: relevantLinks.map((link) => ({ ...link })) };
}
function areLinksFormDataDifferent(
  { relevantLinks: relevantLinks1 = [] }: LinksFormData,
  { relevantLinks: relevantLinks2 = [] }: LinksFormData
): boolean {
  if (relevantLinks1.length === relevantLinks2.length) {
    return !relevantLinks1.every(
      (link, index) =>
        link.url === relevantLinks2[index].url &&
        link.label === relevantLinks2[index].label
    );
  }
  return true;
}
