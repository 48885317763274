/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { graphlib } from "dagre";
import { palette } from "../../themes/palette";

export interface GraphEdge {
  id: string;
  from: string;
  to: string;
  hidden?: boolean;
}

interface GroupEdgePathProps {
  layout: graphlib.Graph;
  edges: GraphEdge[];
}

export const GroupEdgePath: React.FunctionComponent<GroupEdgePathProps> = ({
  layout,
  edges,
}: GroupEdgePathProps) => {
  return (
    <g>
      {edges.map((edge) => {
        if (edge.hidden) {
          return null;
        }
        const layoutInfo = layout.edge(edge.from, edge.to);
        const d = layoutInfo.points
          .map((point, index) => {
            if (index === 0) {
              return `M ${point.x} ${point.y}`;
            } else if (isNaN(point.x)) {
              return `V ${point.y}`;
            } else if (isNaN(point.y)) {
              return `H ${point.x}`;
            } else {
              return `L ${point.x} ${point.y}`;
            }
          })
          .join(" ");
        return (
          <path
            key={edge.id}
            d={d}
            strokeWidth={3}
            stroke={palette.teal}
            fill="transparent"
            markerEnd="url(#arrow-end)"
            markerStart="url(#arrow-start)"
          />
        );
      })}
    </g>
  );
};
