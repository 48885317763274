/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  DataProductState,
  getSampleQueries,
  SampleQueryDto,
  saveSampleQueries,
} from "../../../api/dataProduct/dataProductApi";
import { Spinner } from "../../../components/spinner/Spinner";
import { SampleQuery } from "./SampleQuery";
import Button from "@mui/material/Button";
import { fromPromise } from "rxjs/internal-compatibility";
import { ErrorBox } from "../../../components/error/ErrorBox";
import { useDataProductPermissionContext } from "../permission/DataProductPermissionContext";
import { Tooltip } from "../../../components/tooltip/Tooltip";

interface SampleQueriesViewProps {
  dataProductId: string;
  dataProductStatus: DataProductState | undefined;
}

const useStyles = createUseStyles({
  root: {
    paddingRight: "0.3rem",
  },
  title: {
    fontWeight: 600,
    margin: 0,
  },
  error: {
    marginRight: "0.3rem",
  },
});

export const SampleQueriesView: React.FunctionComponent<
  SampleQueriesViewProps
> = ({ dataProductId, dataProductStatus }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [queries, setQueries] = useState<SampleQueryDto[]>([]);
  const [existingNames, setExistingNames] = useState<Set<string>>(new Set());
  const [addingNewQuery, setAddingNewQuery] = useState(false);
  const userPermissions = useDataProductPermissionContext();

  useEffect(() => {
    setLoading(true);
    setError("");
    const subscription = fromPromise(getSampleQueries(dataProductId)).subscribe(
      {
        next: (data) => {
          setQueries(data);
          setLoading(false);
          if (data.length === 0) {
            setAddingNewQuery(true);
          }
        },
        error: (error) => {
          setError(error.message);
          setLoading(false);
        },
      }
    );
    return () => subscription.unsubscribe();
  }, [dataProductId]);

  useEffect(() => {
    setExistingNames(new Set(queries.map((it) => it.name)));
  }, [queries]);

  const saveAndSet = async (toSave: SampleQueryDto[]) => {
    try {
      await saveSampleQueries(dataProductId, toSave);
      setQueries(toSave);
    } catch (e) {
      setError(e.message);
    }
  };

  const deleteQuery = (toDelete: SampleQueryDto) => async () => {
    const indexToDelete = queries.indexOf(toDelete);
    const slice = queries.slice();
    slice.splice(indexToDelete, 1);
    await saveAndSet(slice);

    if (slice.length === 0) {
      setAddingNewQuery(true);
    }
  };

  const updateQuery =
    (original: SampleQueryDto) => async (changed: SampleQueryDto) => {
      const indexToReplace = queries.indexOf(original);
      const slice = queries.slice();
      slice[indexToReplace] = changed;
      await saveAndSet(slice);
    };

  const onAddNew = async (newQuery: SampleQueryDto) => {
    const slice = queries.slice();
    slice.push(newQuery);
    await saveAndSet(slice);
    setAddingNewQuery(false);
  };
  const isDataProductNotPublished =
    dataProductStatus !== DataProductState.PUBLISHED;

  return (
    <Grid className={classes.root} container spacing={4}>
      {loading && <Spinner position="relative" />}
      {error && (
        <Grid item xs={12} className={classes.error}>
          <ErrorBox text={error} />
        </Grid>
      )}
      {!loading && !error && (
        <>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Usage examples
            </Typography>
          </Grid>
          {queries.map((query) => {
            return (
              <Grid key={query.name} item xs={12}>
                <SampleQuery
                  query={query}
                  existingNames={existingNames}
                  onDelete={deleteQuery(query)}
                  onUpdate={updateQuery(query)}
                  disablePreview={isDataProductNotPublished}
                />
              </Grid>
            );
          })}
          <Grid item xs={12} container spacing={0}>
            {addingNewQuery && (
              <>
                <Grid item xs={12}>
                  <Typography variant={"body2"}>
                    Create a usage example below
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <SampleQuery
                    inEditMode={true}
                    existingNames={existingNames}
                    onUpdate={onAddNew}
                    canCancel={queries.length > 0}
                    onCancel={() => {
                      setAddingNewQuery(false);
                    }}
                    disablePreview={isDataProductNotPublished}
                  />
                </Grid>
              </>
            )}
            {!addingNewQuery && (
              <Grid item xs={3}>
                <Tooltip
                  title={
                    !userPermissions?.canUpdate
                      ? "You do not have permission to edit this data product"
                      : ""
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      disabled={!userPermissions.canUpdate}
                      color="primary"
                      onClick={() => {
                        setAddingNewQuery(true);
                      }}
                    >
                      Add usage example
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};
