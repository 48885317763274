/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Grid from "@mui/material/Grid";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import { palette } from "../../themes/palette";
import clsx from "clsx";
import { HintTooltip } from "../tooltip/HintTooltip";
import { Tooltip } from "../tooltip/Tooltip";

type Size = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface QueryDetailsRowProps {
  header: string;
  value: React.ReactElement | string | number | undefined;
  tooltip?: React.ReactNode;
  classes?: {
    header?: string;
    value?: string;
  };
  xs?: Size;
  md?: Size;
  lg?: Size;
  xl?: Size;
}

export const DetailsCell: React.FunctionComponent<QueryDetailsRowProps> = ({
  header,
  value,
  tooltip = null,
  xs = 4,
  md = undefined,
  lg = undefined,
  xl = undefined,
  classes: classesFromProps = {},
}) => {
  return (
    <>
      <Grid item xs={xs} md={md} lg={lg} xl={xl}>
        <DetailsCellHeader
          text={header}
          tooltip={tooltip}
          className={classesFromProps?.header}
        />
        <DetailsCellValue value={value} className={classesFromProps?.value} />
      </Grid>
    </>
  );
};

interface DetailsCellHeaderProps {
  className?: string;
  text: string;
  tooltip?: React.ReactNode;
}

export const DetailsCellHeader: React.FunctionComponent<
  DetailsCellHeaderProps
> = ({ className, text, tooltip }: DetailsCellHeaderProps) => {
  const styles = useHeaderStyles();
  return (
    <div className={clsx(styles.root, className)}>
      <span>{text}</span>
      <HintTooltip
        title={tooltip}
        classes={{ icon: styles.hintIcon, button: styles.hint }}
      />
    </div>
  );
};

const useHeaderStyles = createUseStyles((theme: Theme) => ({
  root: {
    display: "flex",
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: "1rem",
    height: "1.125rem",
    marginBottom: "0.25rem",
    color: palette.black54,
    textTransform: "uppercase",
    fontFamily: theme.typography.fontFamily,
    whiteSpace: "nowrap",
  },
  hintIcon: {
    fontSize: "1.0625rem",
  },
  hint: {
    height: "1.375rem",
    fontSize: "1.0625rem",
    width: "1.375rem",
    marginLeft: "0.25rem",
    marginTop: "-2px",
  },
}));

const useValueStyles = createUseStyles({
  value: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "1.125rem",
    lineHeight: "1.75rem",
    fontFamily: "montserrat, sans-serif",
    fontWeight: 600,
  },
  placeholder: {
    marginLeft: "0.125rem",
    fontFamily: "montserrat, sans-serif",
    fontWeight: 600,
    fontSize: "1rem",
  },
});

interface DetailsCellValueProps {
  className?: string;
  value: React.ReactElement | string | number | undefined;
  valueTooltip?: React.ReactNode;
}

export const DetailsCellValue: React.FunctionComponent<
  DetailsCellValueProps
> = ({ className, value, valueTooltip }: DetailsCellValueProps) => {
  const classes = useValueStyles();
  return (
    <Tooltip title={valueTooltip}>
      {value ? (
        <div className={clsx(classes.value, className)}>{value}</div>
      ) : (
        <div className={clsx(classes.placeholder, className)}>-</div>
      )}
    </Tooltip>
  );
};
