/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useStyles } from "../themes/styles";
import { Spinner } from "../components/spinner/Spinner";
import Box from "@mui/material/Box";
import { ErrorBox } from "../components/error/ErrorBox";

interface PageContentProps {
  title?: React.ReactNode;
  loading?: boolean;
  error?: string;
  children: React.ReactNode;
}

export const PageContent: React.FunctionComponent<PageContentProps> = ({
  loading,
  error,
  title,
  children,
}) => {
  const classes = useStyles();

  if (loading) {
    return <Spinner position="relative" />;
  }

  return (
    <>
      {title && (
        <Typography variant="h4" component="h4" className={classes.title}>
          {title}
        </Typography>
      )}
      <Divider />
      {error && <ErrorBox text={error} />}
      <Box id="page-content" className={classes.contentBounds}>
        {children}
      </Box>
    </>
  );
};
