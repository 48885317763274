/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  parseISO,
  parseJSON,
} from "date-fns";
import { clockSystem } from "../components/date/dateFormats";
import moment from "moment";

type DataGranularityCategory =
  | "few days"
  | "day"
  | "few hours"
  | "hour"
  | "few minutes"
  | "minute";

const detectGranularityCategory = (
  daysDiff: number,
  hoursDiff: number,
  minutesDiff: number
): DataGranularityCategory => {
  if (daysDiff > 1) {
    return "few days";
  } else if (daysDiff === 1) {
    return "day";
  } else if (hoursDiff > 1) {
    return "few hours";
  } else if (hoursDiff === 1) {
    return "hour";
  } else if (minutesDiff > 1) {
    return "few minutes";
  } else {
    return "minute";
  }
};

const getDataGranularity = (
  point1: Date,
  point2: Date
): DataGranularityCategory => {
  const millisDiff = differenceInMilliseconds(point2, point1);

  const daysDiff = differenceInDays(millisDiff, 0);
  const hoursDiff = differenceInHours(millisDiff, 0);
  const minutesDiff = differenceInMinutes(millisDiff, 0);

  return detectGranularityCategory(daysDiff, hoursDiff, minutesDiff);
};

const standardizeDateString = (dateString: string) => {
  return dateString.replace(".000Z", "Z").replace(".000+00:00", "Z");
};

const getEndDate = <T extends Record<string, unknown>>(
  data: Array<T> | "error" | "loading" | undefined,
  sampleStartDate: Date,
  startDate: (record: T) => string,
  endDate: (record: T) => string
): Date | undefined => {
  const isoSampleStartDate = standardizeDateString(
    sampleStartDate.toISOString()
  );

  if (typeof data === "string" || data === undefined) {
    return undefined;
  }

  const record = data.find(
    (elem) => standardizeDateString(startDate(elem)) === isoSampleStartDate
  );

  if (record) {
    return parseISO(endDate(record));
  }
};

export const formatDateInterval =
  <T extends Record<string, unknown>>(
    data: Array<T> | "error" | "loading" | undefined,
    startDateExtractor: (record: T) => string,
    endDateExtractor: (record: T) => string
  ) =>
  (beginDate: Date): string => {
    const closingDate = getEndDate(
      data,
      beginDate,
      startDateExtractor,
      endDateExtractor
    );
    let dateFormat =
      clockSystem === 12 ? "MMM d, yyyy, h:mm:ss a" : "MMM d, yyyy, H:mm:ss";

    if (!closingDate) {
      return format(beginDate, dateFormat);
    }

    const granularityCategory = getDataGranularity(beginDate, closingDate);

    let showClosingDate = false;
    if (granularityCategory === "day") {
      dateFormat = "MMMM d, yyyy";
    } else if (granularityCategory === "few hours") {
      dateFormat = clockSystem === 12 ? "MMM d, ha" : "MMM d, H:mm";
      showClosingDate = true;
    } else if (granularityCategory === "hour") {
      dateFormat = clockSystem === 12 ? "MMM d, ha" : "MMM d, H:mm";
    } else if (granularityCategory === "few minutes") {
      dateFormat = clockSystem === 12 ? "h:mm a" : "H:mm";
      showClosingDate = true;
    } else if (granularityCategory === "minute") {
      dateFormat = clockSystem === 12 ? "h:mm a" : "H:mm";
    }

    return `${format(beginDate, dateFormat)}${
      showClosingDate ? format(closingDate, " - " + dateFormat) : ""
    }`;
  };

export function formatDate(
  date?: string,
  formatType = "MMMM d, y",
  fallbackValue = ""
): string {
  if (!date) {
    return fallbackValue;
  }
  return format(parseJSON(date), formatType);
}

export const formatDatetime = (datetime: string | undefined): string => {
  if (!datetime) {
    return "";
  }
  return `${moment(datetime).format("D MMM YYYY, h:mm:ss A")}`;
};
