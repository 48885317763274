/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { useCallback, useState } from "react";

interface DialogOpenState {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

export function useDialogOpenState(): DialogOpenState {
  const [isOpen, setIsOpen] = useState(false);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  return {
    isOpen,
    open,
    close,
  };
}
