/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import {
  QueryPlanNodeInfo,
  QueryPlanStageInfo,
  SingleQueryPlanDetails,
  StageState,
  StageStats,
} from "../../../../../api/queryApi";

import { palette } from "../../../../../themes/palette";
import { getStageIdNum } from "./utils";

export const edgeColor = palette.purple;
export const edgeConnectorColor = palette.teal;

export interface PlanNodeInfo {
  id: string;
  name: string;
  identifier: string;
  details: string;
  sources: string[];
  remoteSources: string[];
}

export type StageNodeInfo = {
  stageId: string;
  id: string;
  root: string;
  stageStats: StageStats;
  state: StageState;
  nodes: Map<string, PlanNodeInfo>;
};

export function getStages(
  queryDetails: SingleQueryPlanDetails
): Map<string, StageNodeInfo> {
  const stages: Map<string, StageNodeInfo> = new Map();
  queryDetails.stages.forEach((stage) => {
    flattenStage(stage, stages);
  });
  return stages;
}

function flattenStage(
  stageInfo: QueryPlanStageInfo,
  result: Map<string, StageNodeInfo>
) {
  const nodes = new Map();
  flattenNode(stageInfo.plan, nodes);
  const stageIdNum = getStageIdNum(stageInfo.stageId);
  result.set(stageIdNum, {
    stageId: stageInfo.stageId,
    id: stageIdNum,
    root: stageInfo.plan.id,
    stageStats: stageInfo.stageStats,
    state: stageInfo.state,
    nodes: nodes,
  });
}

function mapDescriptorToIdentifier(
  descriptor: QueryPlanNodeInfo["descriptor"]
): string {
  return Object.entries(descriptor)
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("\n");
}

function flattenNode(
  node: QueryPlanNodeInfo,
  result: Map<string, PlanNodeInfo>
) {
  result.set(node.id, {
    id: node.id,
    name: node["name"],
    identifier: mapDescriptorToIdentifier(node.descriptor),
    details: node["details"].join("\n"),
    sources: node.children.map((node) => node.id),
    remoteSources: node.descriptor.sourceFragmentIds
      ? JSON.parse(node.descriptor.sourceFragmentIds).map(
          (id: number) => `${id}`
        )
      : [],
  });

  node.children.forEach(function (child) {
    flattenNode(child, result);
  });
}
