/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import { palette } from "../../themes/palette";
import Alert from "@mui/material/Alert";
import { useRoleGrants } from "./useRoleGrants";
import { ErrorBox } from "../../components/error/ErrorBox";
import { Spinner } from "../../components/spinner/Spinner";
import { GrantEntry } from "./GrantEntry";
import { GrantToRole } from "./GrantToRole";
import Link from "@mui/material/Link";

const useStyles = createUseStyles({
  errorMessage: {
    width: "100%",
  },
  grantsList: {
    maxHeight: "calc(100vh - 15rem)",
    overflowY: "auto",
  },
  grantsListShort: {
    maxHeight: "calc(100vh - 19rem)",
    overflowY: "auto",
  },
  header: {
    padding: "16px",
    fontSize: "0.875rem",
    fontWeight: 600,
  },
  endBorder: {
    borderTop: `1px solid ${palette.black12}`,
  },
});

interface RoleGrantsProps {
  currentRoleName: string;
  roleId: number;
  roleName: string;
  grantOption: boolean;
  hideGrantSidePanel(): void;
}

export const RoleGrants: React.FunctionComponent<RoleGrantsProps> = ({
  currentRoleName,
  roleId,
  roleName,
  grantOption,
  hideGrantSidePanel,
}) => {
  const classes = useStyles();
  const { grants, error, setError, grantRoleToSubject, reload } = useRoleGrants(
    currentRoleName,
    roleId
  );
  const grantsCount = Array.isArray(grants) ? grants.length : 0;

  const users = useMemo(
    () =>
      Array.isArray(grants)
        ? grants
            .filter(({ subjectKind }) => subjectKind === "user")
            .map(({ subject }) => subject)
        : [],
    [grants]
  );
  const groups = useMemo(
    () =>
      Array.isArray(grants)
        ? grants
            .filter(({ subjectKind }) => subjectKind === "group")
            .map(({ subject }) => subject)
        : [],
    [grants]
  );
  const roles = useMemo(
    () =>
      Array.isArray(grants)
        ? grants
            .filter(({ subjectKind }) => subjectKind === "role")
            .map(({ subjectId }) => subjectId)
            .filter((roleId): roleId is number => !!roleId)
        : [],
    [grants]
  );

  const [grantDialogVisible, setGrantDialogVisible] = useState<boolean>(false);
  const showGrantDialog = useCallback(() => {
    setGrantDialogVisible(true);
  }, []);
  const closeGrantDialog = useCallback(() => {
    setGrantDialogVisible(false);
  }, []);

  return (
    <>
      {grantDialogVisible && (
        <GrantToRole
          currentRoleName={currentRoleName}
          roleId={roleId}
          roleName={roleName}
          grantOption={grantOption}
          users={users}
          groups={groups}
          roles={roles}
          grantRoleToSubject={grantRoleToSubject}
          reload={reload}
          closeDialog={closeGrantDialog}
        />
      )}
      <Grid
        container
        wrap="nowrap"
        direction="column"
        spacing={2}
        style={{ width: "480px", margin: "0.5rem 1rem" }}
      >
        <Grid item>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span style={{ fontSize: "1.125rem", fontWeight: 600 }}>
              Assign: {roleName}
            </span>
            <IconButton
              size="small"
              onClick={hideGrantSidePanel}
              style={{ padding: "6px" }}
            >
              <FontAwesomeIcon icon={faTimes} style={{ width: "1rem" }} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={showGrantDialog}>
            Assign
          </Button>
        </Grid>
        {error && (
          <Grid item>
            <Alert
              variant="standard"
              severity="error"
              classes={{ message: classes.errorMessage }}
            >
              <Box display="flex" justifyContent="space-between">
                <div>{error}</div>
                <Link
                  color="error"
                  onClick={reload}
                  style={{ cursor: "pointer", fontWeight: 600 }}
                >
                  Reload
                </Link>
              </Box>
            </Alert>
          </Grid>
        )}
        <Grid
          item
          className={clsx(classes.grantsList, {
            [classes.grantsListShort]: !!error,
          })}
        >
          <>
            {grants === "loading" && <Spinner position="relative" />}
            {grants === "error" && (
              <ErrorBox
                height={200}
                text="Cannot load assigned users/groups/roles"
              />
            )}
            {grantsCount > 0 && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                className={classes.header}
              >
                <div style={{ width: "8rem" }}>Kind</div>
                <div style={{ flexGrow: 1 }}>Subject</div>
              </Box>
            )}
            {Array.isArray(grants) &&
              grants.map(
                ({
                  grantId,
                  subjectKind,
                  subject,
                  subjectId,
                  grantOption,
                  canManage,
                }) => (
                  <GrantEntry
                    key={grantId}
                    currentRoleName={currentRoleName}
                    roleName={roleName}
                    grantId={grantId}
                    subjectKind={subjectKind}
                    subject={subject}
                    subjectId={subjectId}
                    grantOption={grantOption}
                    canManage={canManage}
                    onUnassign={() => setError(null)}
                    onUnassignSuccess={reload}
                    onUnassignFailure={(e) => setError(e.message)}
                  />
                )
              )}
            {grantsCount > 0 && <div className={classes.endBorder} />}
            {Array.isArray(grants) && grantsCount === 0 && (
              <div
                style={{
                  color: palette.black54,
                  fontWeight: 500,
                  textAlign: "center",
                  letterSpacing: 0.1,
                }}
              >
                No users/groups/roles
              </div>
            )}
          </>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={hideGrantSidePanel}
            style={{ marginTop: "0.5rem" }}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
