/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from "react";
import Box from "@mui/material/Box";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import clsx from "clsx";
import {
  UsageMetricsErrorResponse,
  UsageMetricsPreviewResponse,
} from "../../api/usageApi";
import { ErrorBox } from "../../components/error/ErrorBox";
import { Spinner } from "../../components/spinner/Spinner";
import { Loadable } from "../../utils/loadable";
import { palette } from "../../themes/palette";
import { Success } from "./Success";
import Typography from "@mui/material/Typography";

const useStyles = createUseStyles((theme: Theme) => ({
  truncated: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  tableContainer: {
    maxHeight: 400,
    maxWidth: 1200,
    borderTop: `1px solid ${palette.black12}`,
    borderBottom: `1px solid ${palette.black12}`,
  },
  header: {
    padding: "20px 16px 0",
  },
  noRowBorder: {
    "&:last-child th, &:last-child td": {
      borderBottom: 0,
    },
  },
  tableRow: {
    "& th": {
      fontWeight: 600,
      backgroundColor: palette.white,
    },
    "& td,th": {
      padding: "12px",
    },
    "& td": {
      borderBottom: `1px solid ${palette.black12}`,
    },
    "& td:first-child,th:first-child": {
      paddingLeft: "16px",
    },
  },
  evenRow: {
    backgroundColor: "rgba(0, 0, 0, 0.02)",
  },
  warn: {
    color: palette.error,
    marginRight: "0.5rem",
  },
  success: {
    color: palette.green,
    marginRight: "0.5rem",
  },
  dialogMessage: {
    fontSize: "1.125rem",
    padding: "2rem",
    fontFamily: theme.typography.fontFamily,
    justifyContent: "center",
  },
}));

interface UsageMetricsPreviewProps {
  usageMetrics:
    | Loadable<UsageMetricsPreviewResponse>
    | UsageMetricsErrorResponse
    | "done"
    | null;
  handleSend: () => void;
  handleClose: () => void;
}

export const UsageMetricsPreview: React.FunctionComponent<
  UsageMetricsPreviewProps
> = ({ usageMetrics, handleSend, handleClose }) => {
  const classes = useStyles();

  const waitForData = (
    processFn: (
      usageMetricsSample: UsageMetricsPreviewResponse
    ) => ReactElement<HTMLElement>
  ): ReactElement<HTMLElement> | null => {
    if (usageMetrics === "error") {
      return (
        <Dialog open={true} onClose={handleClose}>
          <Box className={classes.dialogMessage}>
            <ErrorBox
              height={250}
              text={"Could not load usage metrics preview. Try again later."}
            />
            <Box display={"flex"} justifyContent={"center"} mt={2} mb={2}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>
      );
    } else if (usageMetrics instanceof UsageMetricsErrorResponse) {
      return (
        <Dialog open={true} onClose={handleClose}>
          <Box className={classes.dialogMessage}>
            <ErrorBox
              height={250}
              text={
                usageMetrics.isConfigurationError()
                  ? `Encountered a configuration error while sending usage metrics to Starburst: [${usageMetrics.getConfigurationErrorMessage()}]`
                  : "Could not send usage metrics to Starburst. Try again later."
              }
            />
            <Box display={"flex"} justifyContent={"center"} mt={2} mb={2}>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Dialog>
      );
    } else if (usageMetrics === "loading") {
      return (
        <Dialog fullWidth={true} open={true} onClose={handleClose}>
          <Spinner position="relative" delay={500} />
        </Dialog>
      );
    } else if (usageMetrics === "done") {
      return (
        <Dialog fullWidth={true} open={true} onClose={handleClose}>
          <Box className={classes.dialogMessage}>
            <Success text={"The usage data has been sent successfully."} />
          </Box>
          <Box
            display={"flex"}
            padding={2}
            justifyContent={"center"}
            mt={2}
            mb={2}
          >
            <Button variant="contained" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Dialog>
      );
    } else if (usageMetrics === null) {
      return null;
    } else {
      return processFn(usageMetrics);
    }
  };

  return waitForData((rows) => (
    <Dialog open={true} onClose={handleClose} maxWidth={"xl"}>
      <Box className={classes.header}>
        <Typography variant={"h5"}>
          This is a sample of the usage data that will be sent
        </Typography>
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader style={{ tableLayout: "fixed" }}>
          <TableHead className={classes.tableRow}>
            <TableRow>
              <TableCell width="10%">Node environment</TableCell>
              <TableCell width="10%">Instance ID</TableCell>
              <TableCell width="15%">Cluster start time</TableCell>
              <TableCell width="7%">Version</TableCell>
              <TableCell width="18%">Sample time</TableCell>
              <TableCell width="8%">CPU time</TableCell>
              <TableCell width="8%">Available time</TableCell>
              <TableCell width="7%">Cores</TableCell>
              <TableCell width="7%">Active nodes</TableCell>
              <TableCell width="10%">Signature</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((usage, index) => (
              <TableRow
                key={usage.time.toString()}
                hover
                className={clsx(classes.noRowBorder, classes.tableRow, {
                  [classes.evenRow]: index % 2 === 0,
                })}
              >
                <TableCell className={classes.truncated}>
                  {usage.nodeEnvironment}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.instanceId}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.startTime}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.nodeVersion}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.time}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.cumulativeCpuTime}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.cumulativeAvailableCpuTime}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.cores}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.activeNodes}
                </TableCell>
                <TableCell className={classes.truncated}>
                  {usage.signature}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display={"flex"} justifyContent={"center"} mt={2} mb={2}>
        <Box m={1}>
          <Button variant="contained" color="primary" onClick={handleSend}>
            <FontAwesomeIcon
              icon={faPaperPlane}
              style={{ marginRight: "8px" }}
            />
            Send
          </Button>
        </Box>
        <Box m={1}>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            <FontAwesomeIcon icon={faBan} style={{ marginRight: "8px" }} />
            Cancel
          </Button>
        </Box>
      </Box>
    </Dialog>
  ));
};
