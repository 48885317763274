/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useMemo } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useHistory } from "react-router-dom";
import Alert from "@mui/material/Alert";
import {
  CreateGrantError,
  ErrorDataDetails,
  Grant,
  GrantDto,
  GrantError,
} from "../../../../api/biac/biacApi";
import { Persisted } from "../../../../api/biac/common";

interface SavePrivilegesDialogProps {
  reset(): void;
  open: boolean;
  setOpen(newState: boolean): void;
  error: CreateGrantError | null;
  forceSave: () => Promise<void>;
}

export const SavePrivilegesDialog: React.FunctionComponent<
  SavePrivilegesDialogProps
> = ({ reset, open, setOpen, error, forceSave }) => {
  const history = useHistory();
  const goBack = useCallback(() => {
    history.push("/roles");
  }, [history]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddLater = () => {
    handleClose();
    reset();
    goBack();
  };

  const handleCloseAndReset = () => {
    reset();
    handleClose();
  };

  const grantCollisionErrors = useGrantCollisionErrors(error);

  return (
    <Dialog
      open={open}
      aria-labelledby="save-dialog-title"
      aria-describedby="save-dialog-description"
      PaperProps={{ sx: { width: "30%" } }}
    >
      <DialogTitle
        id="save-dialog-title"
        fontWeight={600}
        fontFamily={"montserrat, sans-serif"}
      >
        {error ? "Could not add privilege :" : "Add another privilege?"}
      </DialogTitle>
      <DialogContent>
        {error ? (
          grantCollisionErrors.hasDuplicates ? (
            <Alert variant="standard" severity="warning">
              {grantCollisionErrors.duplicateMessage}
            </Alert>
          ) : grantCollisionErrors.hasConflicts ? (
            <Alert variant="standard" severity="warning">
              {grantCollisionErrors.conflictMessage}
            </Alert>
          ) : (
            <Alert variant="standard" severity="warning">
              {error.message}
            </Alert>
          )
        ) : (
          <Alert severity="success">Privileges successfully created!</Alert>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: "0 1.5rem 1rem" }}>
        {!grantCollisionErrors.hasCollision && (
          <Button onClick={handleAddLater} variant="outlined">
            {error ? "Cancel" : "No, add later"}
          </Button>
        )}
        {error ? (
          <Button
            onClick={handleClose}
            variant={
              grantCollisionErrors.hasCollision ? "outlined" : "contained"
            }
          >
            Go back to editing
          </Button>
        ) : (
          <Button onClick={handleCloseAndReset} variant="contained">
            Yes, add another privilege
          </Button>
        )}
        {grantCollisionErrors.hasCollision && (
          <Button onClick={forceSave} variant="contained">
            Save privileges
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

function extractGrantCollisionErrors({
  conflictingGrants,
  duplicateGrants,
}: ErrorDataDetails) {
  const hasConflicts = !!(conflictingGrants && conflictingGrants.length);
  const hasDuplicates = !!(duplicateGrants && duplicateGrants.length);
  const conflictMessage = hasConflicts
    ? mapConflictingGrantsToErrorMessage(conflictingGrants)
    : null;
  const duplicateMessage = hasDuplicates
    ? mapDuplicateGrantsToErrorMessage(duplicateGrants)
    : null;
  return {
    hasCollision: hasConflicts || hasDuplicates,
    hasConflicts,
    hasDuplicates,
    conflictingGrants,
    duplicateGrants,
    conflictMessage,
    duplicateMessage,
  };
}

const useGrantCollisionErrors = (error: CreateGrantError | null) =>
  useMemo(() => {
    if (error?.response?.status === 409) {
      return extractGrantCollisionErrors(error.response.data.details);
    }
    return {
      hasCollision: false,
      hasConflicts: false,
      hasDuplicates: false,
      conflictingGrants: null,
      duplicateGrants: null,
      conflictMessage: null,
      duplicateMessage: null,
    };
  }, [error]);

function mapConflictingGrantsToErrorMessage(errors: GrantError[]): string {
  return `One or more of these privileges already exist. Saving these will override existing:\n ${errors
    .map(({ oldGrant }) => getGrantDescription(oldGrant))
    .join(", ")}`;
}

function mapDuplicateGrantsToErrorMessage(
  errors: Persisted<GrantDto>[]
): string {
  return `One or more of these privileges already exist. Saving will have no effect for those duplicates but new ones will be added:\n ${errors
    .map(({ object }) => getGrantDescription(object))
    .join(", ")}`;
}

function getGrantDescription(grant: Grant): string {
  return `${grant.effect} ${grant.action}`;
}
