/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import head from "lodash/head";
import React from "react";
import ChartComponent, { ChartComponentProps } from "react-chartjs-2";

// todo: replace with native mechanism introduced in Chart.js 3
// https://github.com/chartjs/Chart.js/issues/2850
export const closeTooltip = (
  chartRef: React.MutableRefObject<ChartComponent<ChartComponentProps> | null>
): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const chart: any = chartRef?.current?.chartInstance;
  chart.tooltip._active = [];
  chart.tooltip.update(true);
  chart.draw();
};

export const closeTooltipOnLeave =
  (
    chartRef: React.MutableRefObject<ChartComponent<ChartComponentProps> | null>
  ) =>
  (event: MouseEvent, activeElements: Array<{ _index: number }>): void => {
    if (head(activeElements)?._index !== undefined) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const chart: any = chartRef?.current?.chartInstance;
    if (chart.tooltip?._active?.length > 0) {
      closeTooltip(chartRef);
    }
  };
