/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import capitalize from "lodash/capitalize";
import { Action } from "../../../api/biac/biacApi";
import { palette } from "../../../themes/palette";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { addPrivilegesStyles } from "./add-privileges-styles";
interface EntityActionsProps {
  effectSelection: string;
  actions: Partial<Record<Action, boolean>>;
  handleEffectChange(event: React.ChangeEvent<HTMLInputElement>): void;
  handleAllActionChange({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>): void;
  handleActionChange({
    target: { checked, name },
  }: React.ChangeEvent<HTMLInputElement>): void;
}

export const EntityActions: React.FunctionComponent<EntityActionsProps> = ({
  effectSelection,
  handleEffectChange,
  actions,
  handleAllActionChange,
  handleActionChange,
}) => {
  const checkedActions = Object.values(actions).filter(
    (checked) => checked
  ).length;
  const allActionsChecked = checkedActions === Object.keys(actions).length;
  const classes = addPrivilegesStyles();

  const label = (
    <span>
      Deny <span style={{ color: palette.black54 }}>(uncommon)</span>
    </span>
  );

  return (
    <Grid item pl={1} pr={1}>
      <FormControl
        required
        fullWidth
        error={checkedActions === 0}
        margin="dense"
        component="fieldset"
      >
        <Typography variant="h5">
          Do you want to allow or deny access?
        </Typography>
        <Typography className={classes.questionInfo}>
          Select whether this privilege will allow or deny access. Privileges
          that deny access are uncommon.
        </Typography>
        <FormControl>
          <RadioGroup
            aria-labelledby="allow-deny-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={effectSelection}
            onChange={handleEffectChange}
            row
          >
            <Typography fontSize={"0.875rem"}>
              <FormControlLabel
                value="ALLOW"
                control={<Radio />}
                label="Allow"
              />
              <FormControlLabel
                value="DENY"
                control={<Radio />}
                label={label}
              />
            </Typography>
          </RadioGroup>
        </FormControl>
        <Typography variant="h5" mb={1} mt={1}>
          What can they do?
        </Typography>
        <Typography className={classes.questionInfo} mb={2}>
          Allow or deny users the ability to perform the following actions on
          roles :
        </Typography>
        {Object.entries(actions).length > 1 && (
          <>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={allActionsChecked}
                    onChange={handleAllActionChange}
                  />
                }
                label="All privileges below"
              />
            </FormGroup>
            <Box mt={1} mb={1}>
              <Divider />
            </Box>
          </>
        )}
        <FormGroup row>
          {Object.entries(actions).map(([action, checked]) => {
            return (
              <FormControlLabel
                key={action}
                control={
                  <Checkbox
                    color="primary"
                    checked={checked}
                    onChange={handleActionChange}
                    name={action}
                  />
                }
                label={capitalize(action)}
                style={{ width: "calc(50% - 16px)" }}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Grid>
  );
};
