/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { useTableStyles } from "./useTableStyles";
import TableCell from "@mui/material/TableCell";
import clsx from "clsx";

interface SimpleTableHeaderProps {
  children?: React.ReactNode;
  className?: string;
}

export const TableHeaderCell: React.FunctionComponent<
  SimpleTableHeaderProps
> = ({ children, className }) => {
  const styles = useTableStyles();
  return (
    <TableCell className={`${clsx(className, styles.headerCell)}`}>
      {children}
    </TableCell>
  );
};
