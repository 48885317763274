/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { useCallback, useContext, useState } from "react";
import { createUseStyles } from "react-jss";
import Box from "@mui/material/Box";
import { RolesTable } from "./RolesTable";
import Button from "@mui/material/Button";
import { CreateRoleDialog } from "./CreateRoleDialog";
import { useRoles } from "./useRoles";
import Card from "@mui/material/Card";
import { RoleGrants } from "./RoleGrants";
import Grid from "@mui/material/Grid";
import { RoleDetails } from "./RoleDetails";
import { RoleContext } from "./CurrentRoleContext";
import { PageContent } from "../../layout/PageContent";
import { ErrorScreen } from "../../components/error/ErrorScreen";

const useStyles = createUseStyles({
  root: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    marginTop: "1.25rem",
  },
  sidePanelRoot: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    height: "calc(100vh - 56px)",
    marginTop: "-0.5rem",
  },
  sidePanel: {
    height: "100%",
  },
});

export const Roles: React.FunctionComponent = () => {
  const classes = useStyles();
  const roleContext = useContext(RoleContext);
  if (roleContext === "disabled") {
    return (
      <ErrorScreen
        errorCode={404}
        errorMsg="Page not found"
        errorDescription="The page you're looking for doesn't exist."
      />
    );
  }

  const { roles, canCreate, createRole, deleteRole, reload } = useRoles(
    roleContext.roleHeader,
    roleContext.reload
  );

  const [createDialogVisible, setCreateDialogVisible] =
    useState<boolean>(false);
  const showCreateDialog = useCallback(() => {
    setCreateDialogVisible(true);
  }, []);
  const closeCreateDialog = useCallback(() => {
    setCreateDialogVisible(false);
  }, []);

  const [grantPanelRoleId, setGrantPanelRoleId] = useState<number | undefined>(
    undefined
  );
  const [privilegesPanelRoleId, setPrivilegesPanelRoleId] = useState<
    number | undefined
  >(undefined);

  const showGrantSidePanel = useCallback((roleId: number) => {
    setGrantPanelRoleId(roleId);
    setPrivilegesPanelRoleId(undefined);
  }, []);
  const hideAssignSidePanel = useCallback(() => {
    setGrantPanelRoleId(undefined);
  }, []);
  const showPrivilegesSidePanel = useCallback((roleId: number) => {
    setGrantPanelRoleId(undefined);
    setPrivilegesPanelRoleId(roleId);
  }, []);
  const hidePrivilegesSidePanel = useCallback(() => {
    setPrivilegesPanelRoleId(undefined);
  }, []);

  const assignRole = Array.isArray(roles)
    ? roles.find(({ id }) => id === grantPanelRoleId)
    : undefined;
  const privilegesRole = Array.isArray(roles)
    ? roles.find(({ id }) => id === privilegesPanelRoleId)
    : undefined;

  return (
    <>
      {createDialogVisible && (
        <CreateRoleDialog
          createRole={createRole}
          closeDialog={closeCreateDialog}
          reload={reload}
        />
      )}
      <Grid container wrap="nowrap">
        <Grid item>
          <PageContent title="Roles and privileges">
            {canCreate && (
              <Button
                variant="contained"
                color="primary"
                onClick={showCreateDialog}
              >
                Create role
              </Button>
            )}
            <Box className={classes.root}>
              <RolesTable
                roles={roles}
                deleteRole={deleteRole}
                showGrantSidePanel={showGrantSidePanel}
                showPrivilegesSidePanel={showPrivilegesSidePanel}
                reload={reload}
              />
            </Box>
          </PageContent>
        </Grid>
        {assignRole && (
          <Grid item className={classes.sidePanelRoot}>
            <Card className={classes.sidePanel}>
              <RoleGrants
                currentRoleName={roleContext.roleHeader}
                roleId={assignRole.id}
                roleName={assignRole.name}
                grantOption={assignRole.grantOption}
                hideGrantSidePanel={hideAssignSidePanel}
              />
            </Card>
          </Grid>
        )}
        {privilegesRole && (
          <Grid item className={classes.sidePanelRoot}>
            <Card className={classes.sidePanel}>
              <RoleDetails
                currentRoleName={roleContext.roleHeader}
                roleId={privilegesRole.id}
                roleName={privilegesRole.name}
                canAddPrivileges={privilegesRole.canAddPrivileges}
                hidePrivilegesSidePanel={hidePrivilegesSidePanel}
              />
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};
