/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import { QueryAnalysisCard } from "./QueryAnalysisCard";
import { SingleQueryDetails } from "../../../../api/queryApi";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { QueryAdvancedDetailsSection } from "./QueryAdvancedDetailsSection";
import { QueryTablesSection } from "./QueryTablesSection";
import { createUseStyles } from "react-jss";
import { Theme } from "@mui/material/styles";

interface QueryAdvancedTabProps {
  query: SingleQueryDetails;
}

const useStyles = createUseStyles((theme: Theme) => ({
  left: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "16px",
    },
  },
}));

export const QueryAdvancedTab: React.FunctionComponent<
  QueryAdvancedTabProps
> = ({ query }) => {
  const classes = useStyles();
  return (
    <Box my={2}>
      <Grid container spacing={1}>
        <Grid item lg={8} xs={12}>
          <Box display="flex" flexDirection="column" height="100%">
            <QueryTablesSection tables={query.tables ?? []} />
            <QueryAnalysisCard queryStats={query.queryPlan} />
          </Box>
        </Grid>
        <Grid item lg={4} xs={12}>
          <Box className={classes.left}>
            <QueryAdvancedDetailsSection query={query} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
