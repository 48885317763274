/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import Box from "@mui/material/Box";
import React, { ReactElement } from "react";
import { AggregateUsageMetrics } from "../../api/usageApi";
import { ErrorBox } from "../../components/error/ErrorBox";
import { DetailsRow } from "../../components/details/DetailsRow";
import { DetailsCell } from "../../components/details/DetailsCell";
import { createUseStyles } from "react-jss";
import { formatDuration } from "../../utils/formatDuration";
import { parseISO } from "date-fns";
import { CostManagerDetailRow } from "./cost-manager/CostManagerDetailRow";
import clsx from "clsx";

const useStyles = createUseStyles({
  header: {
    padding: "8px 12px 20px 0",
    fontFamily: "montserrat, sans-serif",
    fontSize: "1.125rem",
    fontWeight: 600,
  },
  itemValue: {
    fontSize: "1.125rem",
    fontWeight: 600,
  },
  estimatedCostValue: {
    marginTop: "0.313rem",
  },
});

interface UsageMetricsSummaryProps {
  aggregateMetrics: AggregateUsageMetrics | "error";
}

export const UsageMetricsSummary: React.FunctionComponent<
  UsageMetricsSummaryProps
> = ({ aggregateMetrics }) => {
  const classes = useStyles();
  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  const waitForData = (
    processFn: (
      usageMetrics: AggregateUsageMetrics
    ) => ReactElement<HTMLElement>
  ): ReactElement<HTMLElement> => {
    if (aggregateMetrics === "error") {
      return (
        <ErrorBox text={"Could not load usage metrics. Try again later."} />
      );
    } else {
      return processFn(aggregateMetrics);
    }
  };

  return (
    <>
      {waitForData((usageMetrics) => (
        <Box>
          <Box className={classes.header}>Usage summary</Box>
          <DetailsRow>
            <DetailsCell
              header="Start date"
              value={parseISO(usageMetrics.dateRangeStart).toLocaleString(
                undefined,
                dateOptions
              )}
              classes={{ value: classes.itemValue }}
              xs={6}
            />
            <DetailsCell
              header="End date"
              value={parseISO(usageMetrics.dateRangeEnd).toLocaleString(
                undefined,
                dateOptions
              )}
              classes={{ value: classes.itemValue }}
              xs={6}
            />
          </DetailsRow>
          <DetailsRow>
            <DetailsCell
              header="Aggregate vCPU time"
              value={formatDuration(usageMetrics.wallTimeSeconds * 1000)}
              classes={{ value: classes.itemValue }}
              xs={6}
            />
            <DetailsCell
              header="Average daily usage"
              value={`${Math.round(usageMetrics.averageDailyUsage)} vCPU hours`}
              classes={{ value: classes.itemValue }}
              xs={6}
            />
          </DetailsRow>
          <DetailsRow>
            <DetailsCell
              header="Min cluster size"
              value={`${usageMetrics.minCores} vCPUs`}
              classes={{ value: classes.itemValue }}
              xs={6}
            />
            <DetailsCell
              header="Max cluster size"
              value={`${usageMetrics.maxCores} vCPUs`}
              classes={{ value: classes.itemValue }}
              xs={6}
            />
          </DetailsRow>
          <DetailsRow>
            <DetailsCell
              header="Cluster restarts"
              value={usageMetrics.clusterRestarts}
              classes={{ value: classes.itemValue }}
              xs={12}
            />
          </DetailsRow>
          <CostManagerDetailRow
            cpuUsageSeconds={usageMetrics.wallTimeSeconds}
            classes={{
              value: clsx(classes.itemValue, classes.estimatedCostValue),
            }}
          />
        </Box>
      ))}
    </>
  );
};
