/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React, { ReactElement } from "react";
import { DateTimePicker } from "../../../components/date/DateTimePicker";
import { TextFilter } from "./TextFilter";
import { QueryState } from "../../../api/queriesApi";
import Grid from "@mui/material/Grid";
import capitalize from "lodash/capitalize";
import { format } from "date-fns";
import {
  localeDateFormat,
  localeTimeFormat,
} from "../../../components/date/dateFormats";
import { QueryStateSelector } from "./QueryStateSelector";

export interface FilterCriteria {
  sql: string | null;
  user: string | null;
  group: string | null;
  id: string | null;
  startDate: Date | null;
  endDate: Date | null;
  status: QueryState[] | null;
  catalog: string | null;
  schema: string | null;
}

type FilterValue = FilterCriteria[keyof FilterCriteria];
type SetFilterValue = (newValue: FilterValue) => void;

export const queryFilters = (
  config: { allQueries?: boolean } = {
    allQueries: true,
  }
): Array<{
  filterBy: keyof FilterCriteria;
  label: string;
  component: (
    value: FilterValue,
    setValue: SetFilterValue,
    onValidityChange: (valid: boolean) => void
  ) => ReactElement<HTMLElement>;
  formatValue: (value: FilterValue) => string;
}> => [
  {
    filterBy: "startDate",
    label: "After date",
    component: (
      value: FilterValue,
      setValue: SetFilterValue,
      onValidityChange: (valid: boolean) => void
    ): ReactElement<HTMLElement> => (
      <DateTimePicker
        key="start_date"
        placeholder="Pick start date"
        value={value as Date}
        setValue={setValue}
        defaultTime="day-start"
        onValidityChange={onValidityChange}
      />
    ),
    formatValue: (value: FilterValue): string =>
      format(value as Date, `${localeDateFormat} ${localeTimeFormat}`),
  },
  {
    filterBy: "endDate",
    label: "Before date",
    component: (
      value: FilterValue,
      setValue: SetFilterValue,
      onValidityChange: (valid: boolean) => void
    ): ReactElement<HTMLElement> => (
      <DateTimePicker
        key="end_date"
        placeholder="Pick end date"
        value={value as Date}
        setValue={setValue}
        defaultTime="day-end"
        onValidityChange={onValidityChange}
      />
    ),
    formatValue: (value: FilterValue): string =>
      format(value as Date, `${localeDateFormat} ${localeTimeFormat}`),
  },
  {
    filterBy: "sql",
    label: "Query text",
    component: (
      value: FilterValue,
      setValue: SetFilterValue
    ): ReactElement<HTMLElement> => (
      <TextFilter
        key="sql"
        value={value as string}
        setValue={setValue}
        placeholder="Search by query fragment"
      />
    ),
    formatValue: (value: FilterValue): string => value as string,
  },
  ...(config?.allQueries
    ? [
        {
          filterBy: "user" as keyof FilterCriteria,
          label: "User",
          component: (
            value: FilterValue,
            setValue: SetFilterValue
          ): ReactElement<HTMLElement> => (
            <TextFilter
              key="user"
              value={value as string}
              setValue={setValue}
              placeholder="Search by user name"
            />
          ),
          formatValue: (value: FilterValue): string => value as string,
        },
        {
          filterBy: "group" as keyof FilterCriteria,
          label: "User group",
          component: (
            value: FilterValue,
            setValue: SetFilterValue
          ): ReactElement<HTMLElement> => (
            <TextFilter
              key="group"
              value={value as string}
              setValue={setValue}
              placeholder="Search by user group"
            />
          ),
          formatValue: (value: FilterValue): string => value as string,
        },
      ]
    : []),
  {
    filterBy: "id",
    label: "Query ID",
    component: (
      value: FilterValue,
      setValue: SetFilterValue
    ): ReactElement<HTMLElement> => (
      <TextFilter
        key="id"
        value={value as string}
        setValue={setValue}
        placeholder="Search by query ID"
      />
    ),
    formatValue: (value: FilterValue): string => value as string,
  },
  {
    filterBy: "status",
    label: "Status",
    component: (
      value: FilterValue,
      setValue: SetFilterValue
    ): ReactElement<HTMLElement> => (
      <Grid item lg={6} xs={8}>
        <QueryStateSelector
          value={(value as QueryState[]) || []}
          setValue={setValue}
        />
      </Grid>
    ),
    formatValue: (value: FilterValue): string =>
      (value as QueryState[])
        .map((queryState) => capitalize(queryState))
        .join(", "),
  },
  {
    filterBy: "catalog",
    label: "Catalog",
    component: (
      value: FilterValue,
      setValue: SetFilterValue
    ): ReactElement<HTMLElement> => (
      <TextFilter
        key="catalog"
        value={value as string}
        setValue={setValue}
        placeholder="Search by catalog"
      />
    ),
    formatValue: (value: FilterValue): string => value as string,
  },
  {
    filterBy: "schema",
    label: "Schema",
    component: (
      value: FilterValue,
      setValue: SetFilterValue
    ): ReactElement<HTMLElement> => (
      <TextFilter
        key="schema"
        value={value as string}
        setValue={setValue}
        placeholder="Search by schema"
      />
    ),
    formatValue: (value: FilterValue): string => value as string,
  },
];
