/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import React from "react";
import Tab, { TabProps, TabTypeMap } from "@mui/material/Tab";
import { useTabStyles } from "../../themes/useTabStyles";

export const DefaultTab = <
  D extends React.ElementType = TabTypeMap["defaultComponent"],
  P = unknown
>(
  params: TabProps<D, P> & { badge?: number }
) => {
  const tabClasses = useTabStyles();
  const defaultProps = {
    className: tabClasses.tab,
    disableRipple: true,
    classes: { selected: tabClasses.selected },
  };
  const preparedProps = {
    ...defaultProps,
    ...params,
    ...{
      label:
        typeof params.label === "string" ? (
          <span className={tabClasses.label}>{params.label}</span>
        ) : (
          params.label
        ),
    },
  };
  return <Tab {...preparedProps} />;
};
