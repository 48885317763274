/*
 * Copyright Starburst Data, Inc. All rights reserved.
 *
 * THIS IS UNPUBLISHED PROPRIETARY SOURCE CODE OF STARBURST DATA.
 * The copyright notice above does not evidence any
 * actual or intended publication of such source code.
 *
 * Redistribution of this material is strictly prohibited.
 */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getQueries,
  GetQueryApiResponse,
  QueriesSorting,
  QueryDetails,
} from "../../../api/queriesApi";
import { FilterCriteria } from "./queryFilters";
import { AppThunk } from "../../../app/store";
import { clearReports } from "../report/reportSlice";
import { addDays, startOfDay } from "date-fns";

interface QueryHistorySlice {
  filters: FilterCriteria | null;
  sorting: QueriesSorting;
  queries: QueryDetails[] | "loading" | "error" | undefined;
  updateDate: Date | null;
}

export const initialQueryHistoryFilters = (): FilterCriteria => ({
  startDate: startOfDay(addDays(new Date(), -14)),
  endDate: null,
  group: null,
  status: null,
  user: null,
  sql: null,
  id: null,
  catalog: null,
  schema: null,
});

const initialState: QueryHistorySlice = {
  filters: initialQueryHistoryFilters(),
  sorting: {
    sortBy: "createDate",
    sortOrder: "desc",
  },
  queries: undefined,
  updateDate: null,
};

const queryHistorySlice = createSlice({
  name: "query-history",
  initialState,
  reducers: {
    markAsLoading: (state): void => {
      state.updateDate = null;
      state.queries = "loading";
    },
    markAsError: (state): void => {
      state.queries = "error";
    },
    clearQueries: (state): void => {
      state.queries = undefined;
    },
    dataLoaded: (state, action: PayloadAction<QueryDetails[]>): void => {
      state.queries = action.payload;
      state.updateDate = new Date();
    },
    setSorting: (state, action: PayloadAction<QueriesSorting>): void => {
      state.sorting = action.payload;
    },
    setFilters: (state, action: PayloadAction<FilterCriteria | null>): void => {
      state.filters = action.payload;
    },
  },
});

const {
  dataLoaded,
  markAsLoading,
  markAsError,
  setFilters,
  setSorting,
  clearQueries,
} = queryHistorySlice.actions;

export const fetchQueries =
  (): AppThunk =>
  (dispatch, getState): void => {
    dispatch(markAsLoading());

    const state = getState().queryHistory;
    const delay = (ms: number): Promise<unknown> => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    // make calls to last at least 0.5s to prevent screen blinking
    Promise.all([getQueries(state.filters, state.sorting), delay(500)])
      .then(([result]) => dispatch(dataLoaded(result as GetQueryApiResponse)))
      .catch(() => dispatch(markAsError()));
  };

export const handleSortingChange =
  (sorting: QueriesSorting): AppThunk =>
  (dispatch): void => {
    dispatch(setSorting(sorting));
    dispatch(clearQueries());
  };

export const refreshView =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(clearQueries());
    dispatch(clearReports());
  };

export const handleFiltersChange =
  (filters: FilterCriteria | null): AppThunk =>
  (dispatch): void => {
    dispatch(setFilters(filters));
    dispatch(refreshView());
  };

export default queryHistorySlice.reducer;
